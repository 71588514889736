import type { GeneratorKey } from '@/constants/core/common.constant'
import type { EGeneratorType } from '@/enums/device/generator/generator-type.enum'

export enum DeviceType {
  VP3701 = 'VP3701',
  VP3711 = 'VP3711',
  GENERATOR = 'GENERATOR'
}

export type THarmonic = {
  normAmp?: number
  dclevel: number
  baseFreq: number
  baseAmp: number
  nharm: number
  ampSet: number
  lastAmp: number
  phaseSet: number
  phaseShift: number
  whiteNoise: number
  m1_Type: number
  m1_BaseFreq: number
  m1_DeepA: number
  m1_DeepT: number
  m1_Nharm: number
  m1_AmpSet: number
  m1_LastAmp: number
  m1_PhaseSet: number
  m1_PhaseShift: number
  m2_Type: number
  m2_BaseFreq: number
  m2_DeepA: number
  m2_DeepT: number
  m2_Nharm: number
  m2_AmpSet: number
  m2_LastAmp: number
  m2_PhaseSet: number
  m2_PhaseShift: number
}

export interface IGenerator {
  name: string
  generatorId: string
  fs?: string
  tsig?: string
  type: EGeneratorType
  squareSettings?: {
    normAmp: number
    squareBaseFreq: number
    squareLowLevel: number
    squareHighToLow: number
    squareDuty: number
    squarePhase: number
  } | null
  harmonicCommonSettings?: {
    harmonics: THarmonic[]
    normAmp: number
  } | null
  wavSettings?: {
    fileName: string
    fileLength: number
    md5Hash: string
    fileId: string
  }
  dskSettings?: {
    fileName: string
    fileLength: number
    md5Hash: string
    dskFileId: string
    datFileId: string
  }
}

export interface IDeviceVP3701 {
  vp3701Id: string
  serialNumber: string
  powerType: 'BATTERY' | 'EXTERNAL' | 'NONE'
  version: string
  ip: string
  name: string
  portSetting: number
  portData: number
  connectionStatus: 'MEASURING_POINT_CONNECTED' | 'NO_ONE_MEASURING_POINT_CONNECTED'
  statusUpdatingTimestamp: string
  status: 'ONLINE' | 'OFFLINE'
}

export type TCreatedDeviceVP3701 = Pick<IDeviceVP3701, 'ip' | 'name' | 'portSetting' | 'portData'>

interface IFormData {
  [GeneratorKey.Name]: string
  [GeneratorKey.Type]: string
  [GeneratorKey.Fs]: number
  [GeneratorKey.TSig]: number
  [GeneratorKey.NormAmp]: number
  [GeneratorKey.SquareBaseFreq]: number
  [GeneratorKey.SquareLowLevel]: number
  [GeneratorKey.SquareHighToLow]: number
  [GeneratorKey.SquareDuty]: number
  [GeneratorKey.SquarePhase]: number
  [GeneratorKey.AmpSet]: number
  [GeneratorKey.BaseAmp]: number
  [GeneratorKey.BaseFreq]: number
  [GeneratorKey.Dclevel]: number
  [GeneratorKey.LastAmp]: number
  [GeneratorKey.M1_AmpSet]: number
  [GeneratorKey.M1_BaseFreq]: number
  [GeneratorKey.M1_DeepA]: number
  [GeneratorKey.M1_DeepT]: number
  [GeneratorKey.M1_LastAmp]: number
  [GeneratorKey.M1_Nharm]: number
  [GeneratorKey.M1_PhaseSet]: number
  [GeneratorKey.M1_Type]: number
  [GeneratorKey.M1_PhaseShift]: number
  [GeneratorKey.M2_AmpSet]: number
  [GeneratorKey.M2_BaseFreq]: number
  [GeneratorKey.M2_DeepT]: number
  [GeneratorKey.M2_DeepA]: number
  [GeneratorKey.M2_LastAmp]: number
  [GeneratorKey.M2_Nharm]: number
  [GeneratorKey.M2_PhaseSet]: number
  [GeneratorKey.M2_PhaseShift]: number
  [GeneratorKey.M2_Type]: number
  [GeneratorKey.Nharm]: number
  [GeneratorKey.PhaseSet]: number
  [GeneratorKey.PhaseShift]: number
  [GeneratorKey.WhiteNoise]: number
  moduleOneState: number
  moduleTwoState: number
  generatorType: string
}

export type FormDataType = Partial<IFormData>

export type SelectType = {
  value: number
  label: string
}
