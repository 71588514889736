import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonToolbar from '@/components/UI/buttons/button-toolbar/button-toolbar'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import Popup from '@/components/UI/elements/popup/popup'
import TableWrapper from '@/components/UI/elements/table-wrapper/table-wrapper'
import FormInputNumber from '@/components/UI/form-controls/form-input-number/form-input-number'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasurementQuery } from '@/store/api/measurements.api'
import { ColorPicker, Form, Table } from 'antd'
import type { Color } from 'antd/es/color-picker'
import { useForm, useWatch } from 'antd/es/form/Form'
import {
  type Dispatch,
  type FC,
  type SetStateAction,
  useEffect,
  useState
} from 'react'
import { shallowEqual } from 'react-redux'

import styles from './popup-express-section.module.css'

interface IProps {
  setIsPopupOpen: Dispatch<SetStateAction<boolean>>
  isPopupOpen: boolean
}

type TCellValues = {
  mark: number
  fill: string
  fl: number
  fh: number
  key: number
}

const expressSectionFills: string[] = [
  '#2500FF',
  '#FF00DD',
  '#FF0000',
  '#F2FF00',
  '#00FF22',
  '#00E7FF'
]

const expressSectionPresets = [{ label: 'Цвета', colors: expressSectionFills }]

const PopupExpressSection: FC<IProps> = ({ setIsPopupOpen, isPopupOpen }) => {
  const [form] = useForm()
  const formValues = useWatch([], form)
  const { pushSection, updateSection, removeSection } = useActions()
  const { sections, selectedMeasurementId } = useTypedSelector(
    (state) => ({ ...state.expressSectionReducer, ...state.globalReducer }),
    {
      equalityFn: shallowEqual
    }
  )
  const { data: measurement } = useGetMeasurementQuery(selectedMeasurementId)
  const [expressSectionFill, setExpressSectionFill] = useState(
    expressSectionFills[0]
  )
  const [selectedSection, setSelectedSection] = useState<null | number>(null)

  const [isValidForm, setIsValidForm] = useState(true)

  const maxValue = Number(
    measurement?.programSpecterDescription?.specterLineCount?.split('_')[2]
  )

  const handleFinishForm = (
    values?: {
      expressSectionFl: number
      expressSectionFh: number
    },
    action?: 'update'
  ) => {
    if (action === 'update' && values !== undefined) {
      if (selectedSection !== null) {
        updateSection({
          section: {
            fl: values.expressSectionFl,
            fh: values.expressSectionFh,
            fill: expressSectionFill
          },
          index: selectedSection
        })
      }
    } else {
      const fields: {
        expressSectionFl: number
        expressSectionFh: number
      } = form.getFieldsValue()

      let fill = expressSectionFills[sections.length]

      if (sections.length >= 6) {
        fill = expressSectionFills[0]
      }

      pushSection({
        fl: fields.expressSectionFl,
        fh: fields.expressSectionFh,
        fill
      })
    }
  }

  const dataSource: TCellValues[] = sections.map((section, index) => ({
    mark: index + 1,
    fill: section.fill,
    fl: section.fl,
    fh: section.fh,
    key: index
  }))

  const handleOnChangeColorPicker = (value: Color, values: TCellValues) => {
    updateSection({
      section: {
        fl: values.fl,
        fh: values.fh,
        fill: value.toHexString()
      },
      index: values.key
    })
  }

  const columns = [
    {
      title: 'Метка',
      dataIndex: 'mark',
      key: 'mark',
      width: '10%'
    },
    {
      title: 'Цвет',
      dataIndex: 'fill',
      key: 'fill',
      width: '10%',
      render: (fill: string, values: TCellValues) => (
        <ColorPicker
          onChangeComplete={(value) => handleOnChangeColorPicker(value, values)}
          value={fill}
          presets={expressSectionPresets}
        />
      )
    },
    {
      title: 'Fl, Гц',
      dataIndex: 'fl',
      key: 'fi',
      width: '40%'
    },
    {
      title: 'Fh, Гц',
      dataIndex: 'fh',
      key: 'fh',
      width: '40%'
    }
  ]

  const handleOnSelectSection = (record: TCellValues) => {
    setSelectedSection(record.key)
    setExpressSectionFill(record.fill)
  }

  const validateFl = () => (_: unknown, value: string) => {
    const fieldFh = form.getFieldValue('expressSectionFh')
    const delta =
      (Number(
        measurement?.programSpecterDescription?.visualFreqLimit?.split('_')[1]
      ) /
        maxValue) *
      2

    if (Number(value) > fieldFh || Number(value) === fieldFh) {
      return Promise.reject(
        new Error(`Поле не должно быть больше или равно Fh`)
      )
    }
    if (Number(value) + delta > fieldFh) {
      return Promise.reject(
        new Error(`Разница Fl и Fh должна быть больше ${delta.toPrecision(2)}`)
      )
    }
    return Promise.resolve()
  }

  const validateFh = () => (_: unknown, value: string) => {
    const fieldFl = form.getFieldValue('expressSectionFl')
    const delta =
      (Number(
        measurement?.programSpecterDescription?.visualFreqLimit?.split('_')[1]
      ) /
        maxValue) *
      2

    if (Number(value) < fieldFl || Number(value) === fieldFl) {
      return Promise.reject(
        new Error(`Поле не должно быть меньше или равно Fl`)
      )
    }
    if (Number(value) - delta < fieldFl) {
      return Promise.reject(
        new Error(`Разница Fl и Fh должна быть больше ${delta.toPrecision(2)}`)
      )
    }

    return Promise.resolve()
  }

  const handleRemoveSection = () => {
    if (selectedSection !== null) {
      removeSection(selectedSection)
      setSelectedSection(null)
    }
  }

  const handleOnChangeInput = () => {
    form.validateFields()
  }

  useEffect(() => {
    if (selectedSection !== null && isPopupOpen) {
      form.setFieldsValue({
        expressSectionFl: sections[selectedSection].fl,
        expressSectionFh: sections[selectedSection].fh
      })
    }
  }, [form, sections, selectedSection, isPopupOpen])

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setIsValidForm(true)
      })
      .catch(() => {
        setIsValidForm(false)
      })
  }, [form, formValues])

  return (
    <Popup
      onCancel={() => {
        setIsPopupOpen(false)
        form.resetFields()
      }}
      title={'Управление сечениями'}
      isOpen={isPopupOpen}
    >
      <Form
        className={styles.form}
        onFinish={(values) => handleFinishForm(values, 'update')}
        form={form}
      >
        <TableWrapper>
          <Table
            pagination={false}
            rowSelection={{
              type: 'radio',
              onSelect: handleOnSelectSection
            }}
            dataSource={dataSource}
            columns={columns}
          />
        </TableWrapper>

        <ButtonsWrapper className={styles['buttons-wrapper']}>
          <ButtonToolbar
            disabled={selectedSection === null}
            onClick={handleRemoveSection}
            icon={'remove'}
            className={styles.button}
          />
          <ButtonToolbar
            disabled={!isValidForm}
            onClick={() => handleFinishForm()}
            icon={'add'}
            className={styles.button}
          />
        </ButtonsWrapper>

        <FormInputNumber
          labelContent={'Fl'}
          name={'expressSectionFl'}
          minValue={0}
          customRules={[{ validator: validateFl() }]}
          onChange={handleOnChangeInput}
          maxValue={maxValue - 1}
          required
        />
        <FormInputNumber
          labelContent={'Fh'}
          name={'expressSectionFh'}
          minValue={0}
          maxValue={maxValue - 1}
          customRules={[{ validator: validateFh() }]}
          onChange={handleOnChangeInput}
          required
        />

        <ButtonPrimary
          disabled={selectedSection === null}
          className={styles.button}
          htmlType={'submit'}
          title={'Обновить'}
        />
      </Form>
    </Popup>
  )
}

export default PopupExpressSection
