import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import { EUnitType } from '@/enums/measurment/unit-type.enum'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import { getTypeMeasurement } from '@/utils/measurement/get-type-measurement'

export const getUnitMeasurement = (measurement: IMeasurement) => {
  if (measurement?.unitType) {
    return measurement.unitType
  }

  const typeMeasurement = getTypeMeasurement(measurement)
  const isTemperatureDescription =
    typeMeasurement === ETypeMeasurement.TemperatureDescription

  return isTemperatureDescription ? EUnitType.DEGREES_CELSIUS : EUnitType.G
}
