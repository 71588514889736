import { MAX_COUNT_KINEMATIC_BLOCK } from '@/constants/kinematic-scheme.constant'
import { ApiResource, ApiTag } from '@/constants/store/api.constants'
import type {
  ICreateKinematicSchemeResponse,
  IDefinitionRemoveCurrentKinematicElement,
  IGetKinematicSchemeElementsResponse,
  IGetKinematicSchemeResponse,
  IKinematicElement,
  IKinematicScheme,
  TCreateKinematicElementBody
} from '@/types/kinematic.type'

import { baseApi } from './api-base-query'

export const kinematicApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getKinematics: builder.query<IGetKinematicSchemeResponse, string | undefined>({
      query: (nodeId) => ({
        url: `${ApiResource.Kinematic}?nodeId=${nodeId}`,
        method: 'GET'
      }),
      providesTags: [ApiTag.Kinematic]
    }),

    // Получение данных выбранной кинематической схемы
    getCurrentKinematic: builder.query<IKinematicScheme, string | undefined>({
      query: (kinematicSchemeId) => ({
        url: `${ApiResource.Kinematic}/${kinematicSchemeId}`,
        method: 'GET'
      }),
      providesTags: [ApiTag.Kinematic]
    }),

    // Получение конкретного кинематического блока
    getCurrentKinematicElement: builder.query<
      IKinematicElement,
      { kinematicSchemeId?: string; kinematicElementId?: string }
    >({
      query: ({ kinematicSchemeId, kinematicElementId }) => ({
        url: `${ApiResource.Kinematic}/${kinematicSchemeId}/elements/${kinematicElementId}`,
        method: 'GET'
      }),
      providesTags: [ApiTag.Kinematic]
    }),

    // Получение кинематических блоков
    getKinematicElements: builder.query<IGetKinematicSchemeElementsResponse, string | undefined>({
      query: (kinematicSchemeId) => ({
        url: `${ApiResource.Kinematic}/${kinematicSchemeId}/elements`,
        method: 'GET',
        params: { size: MAX_COUNT_KINEMATIC_BLOCK }
      }),
      providesTags: [ApiTag.Kinematic]
    }),

    createKinematic: builder.mutation<ICreateKinematicSchemeResponse, IKinematicScheme>({
      query: (data) => ({ url: `${ApiResource.Kinematic}`, method: 'POST', body: data }),
      invalidatesTags: [ApiTag.Kinematic]
    }),

    changeKinematic: builder.mutation<null, IKinematicScheme>({
      query: (data) => ({
        url: `${ApiResource.Kinematic}/${data.kinematicSchemeId}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: [ApiTag.Kinematic]
    }),

    changeKinematicElement: builder.mutation<IKinematicElement, IKinematicElement>({
      query: (data) => ({
        url: `${ApiResource.Kinematic}/${data.kinematicSchemeId}/elements/${data.kinematicBlockId}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: [ApiTag.Kinematic]
    }),

    addKinematicElement: builder.mutation<
      IKinematicElement,
      TCreateKinematicElementBody & Pick<IKinematicElement, 'kinematicSchemeId'>
    >({
      query: ({ kinematicSchemeId, ...data }) => ({
        url: `${ApiResource.Kinematic}/${kinematicSchemeId}/elements`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [ApiTag.Kinematic]
    }),

    removeKinematic: builder.mutation<null, string>({
      query: (kinematicSchemeId) => ({
        url: `${ApiResource.Kinematic}/${kinematicSchemeId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [ApiTag.Kinematic]
    }),

    removeCurrentKinematicElement: builder.mutation<null, IDefinitionRemoveCurrentKinematicElement>({
      query: ({ kinematicSchemeId, kinematicElementId }) => ({
        url: `${ApiResource.Kinematic}/${kinematicSchemeId}/elements/${kinematicElementId}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetKinematicsQuery,
  useLazyGetKinematicsQuery,
  useGetCurrentKinematicQuery,
  useLazyGetCurrentKinematicQuery,
  useGetCurrentKinematicElementQuery,
  useLazyGetCurrentKinematicElementQuery,
  useGetKinematicElementsQuery,
  useLazyGetKinematicElementsQuery,
  useChangeKinematicMutation,
  useChangeKinematicElementMutation,
  useCreateKinematicMutation,
  useRemoveKinematicMutation,
  useAddKinematicElementMutation,
  useRemoveCurrentKinematicElementMutation
} = kinematicApi
