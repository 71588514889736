import { isNumber } from 'lodash'

type TGetStepSlider = (desiredSteps: number, rangeValues?: number[]) => number | undefined

export const getStepSlider: TGetStepSlider = (desiredSteps, rangeValues) => {
  if (rangeValues) {
    const [minValue, maxValue] = rangeValues
    if (isNumber(maxValue) && isNumber(minValue)) {
      const rawStep = (maxValue - minValue) / desiredSteps
      return rawStep
    }
  }
}
