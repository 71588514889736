import type { FC, PropsWithChildren } from 'react'
import React from 'react'

import styles from './chart-tooltip.module.css'

const ChartTooltip: FC<PropsWithChildren> = ({ children }) => (
  <div className={styles['chart-tooltip']}>{children}</div>
)

export default ChartTooltip
