export const enum GbdParam {
  Z1 = 'z1',
  Z2 = 'z2',
  Z3 = 'z3'
}

export enum GbdOut {
  Frgb = 'frgb',
  Fo2 = 'fo2',
  Fo3 = 'fo3',
  Fz = 'fz'
}
