import AdForm from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/ad-form/ad-form'
import BdForm from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bd-form/bd-form'
import BrForm from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/br-form/br-form'
import CdForm from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/cd-form/cd-form'
import DcgForm from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/dcg-form/dcg-form'
import DcmForm from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/dcm-form/dcm-form'
import DrawerTitle from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/drawer-title/drawer-title'
import DriveMechForm from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/drive-mech-form/drive-mech-form'
import GbForm from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/gb-form/gb-form'
import GbTypeForm from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/gb-type-form/gb-type-form'
import KinematicPoints from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/kinematic-points/kinematic-points'
import SimpleKinematic from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/simple-kinematic/simple-kinematic'
import SynchMechForm from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/synch-mech-form/synch-mech-form'
import TgbForm from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/tgb-form/tgb-form'
import TnForm from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/tn-form/tn-form'
import WheelTypeForm from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/wheel-type-form/wheel-type-form'
import { additionalInformationKb } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type { IKinematicElement } from '@/types/kinematic.type'
import { EKinematicBlockType } from '@/types/kinematic.type'
import { Drawer, Tabs } from 'antd'
import type { FC } from 'react'
import React, { type ReactNode } from 'react'

import styles from './drawer-kinematic.module.css'

type DrawerKinematicType = {
  onClose: () => void
  openDrawer: boolean
  kinematicElement: IKinematicElement | null
}

interface ITabs {
  label: ReactNode
  icon: ReactNode
  children: ReactNode
}

const DrawerKinematic: FC<DrawerKinematicType> = ({
  onClose,
  openDrawer,
  kinematicElement
}) => {
  const blockType = kinematicElement?.kinematicBlockType
  if (!blockType) {
    return null
  }

  const { image } = additionalInformationKb[blockType]

  const formComponents = {
    [EKinematicBlockType.BR]: (
      <BrForm
        onClose={onClose}
        kinematicElement={kinematicElement}
        image={image}
      />
    ),
    [EKinematicBlockType.SH]: (
      <SimpleKinematic
        onClose={onClose}
        kinematicElement={kinematicElement}
        image={image}
      />
    ),
    [EKinematicBlockType.AD]: (
      <AdForm
        onClose={onClose}
        kinematicElement={kinematicElement}
        image={image}
      />
    ),
    [EKinematicBlockType.SD]: (
      <SynchMechForm
        onClose={onClose}
        kinematicElement={kinematicElement}
        image={image}
      />
    ),
    [EKinematicBlockType.SG]: (
      <SynchMechForm
        onClose={onClose}
        kinematicElement={kinematicElement}
        image={image}
      />
    ),
    [EKinematicBlockType.DCM]: (
      <DcmForm
        onClose={onClose}
        kinematicElement={kinematicElement}
        image={image}
      />
    ),
    [EKinematicBlockType.DCG]: (
      <DcgForm
        onClose={onClose}
        kinematicElement={kinematicElement}
        image={image}
      />
    ),
    [EKinematicBlockType.GB]: (
      <GbForm
        onClose={onClose}
        kinematicElement={kinematicElement}
        image={image}
      />
    ),
    [EKinematicBlockType.BD]: (
      <BdForm
        onClose={onClose}
        kinematicElement={kinematicElement}
        image={image}
      />
    ),
    [EKinematicBlockType.GBD]: (
      <GbTypeForm
        onClose={onClose}
        image={image}
        kinematicElement={kinematicElement}
      />
    ),
    [EKinematicBlockType.GBT]: (
      <GbTypeForm
        onClose={onClose}
        image={image}
        kinematicElement={kinematicElement}
      />
    ),
    [EKinematicBlockType.TGB]: (
      <TgbForm
        onClose={onClose}
        image={image}
        kinematicElement={kinematicElement}
      />
    ),
    [EKinematicBlockType.TW]: (
      <WheelTypeForm
        onClose={onClose}
        kinematicElement={kinematicElement}
        image={image}
      />
    ),
    [EKinematicBlockType.CW]: (
      <WheelTypeForm
        onClose={onClose}
        kinematicElement={kinematicElement}
        image={image}
      />
    ),
    [EKinematicBlockType.BF]: null,
    [EKinematicBlockType.BS]: null,
    [EKinematicBlockType.TN]: <TnForm image={image} />,
    [EKinematicBlockType.CD]: (
      <CdForm
        onClose={onClose}
        kinematicElement={kinematicElement}
        image={image}
      />
    ),
    [EKinematicBlockType.CN]: <TnForm image={image} />,
    [EKinematicBlockType.HD]: (
      <DriveMechForm
        onClose={onClose}
        image={image}
        kinematicElement={kinematicElement}
      />
    ),
    [EKinematicBlockType.PD]: (
      <DriveMechForm
        onClose={onClose}
        image={image}
        kinematicElement={kinematicElement}
      />
    ),
    [EKinematicBlockType.CP]: (
      <SimpleKinematic
        onClose={onClose}
        kinematicElement={kinematicElement}
        image={image}
      />
    )
  }

  const tabs: ITabs[] = [
    {
      label: 'Настройка КБ',
      icon: <span className={`material-symbols-sharp`}>settings</span>,
      children: (
        <div className={styles['setting-container']}>
          {formComponents[blockType as EKinematicBlockType.CP]}
        </div>
      )
    },
    {
      label: 'Точки измерения',
      icon: <span className={`material-symbols-sharp`}>speed</span>,
      children: (
        <KinematicPoints
          kinematicElement={kinematicElement}
          onClose={onClose}
        />
      )
    }
  ]

  return (
    <Drawer
      title={
        <DrawerTitle
          title={kinematicElement?.kinematicBlockName}
          description={additionalInformationKb[blockType].label}
        />
      }
      onClose={onClose}
      open={openDrawer}
      width={700}
    >
      <Tabs
        defaultActiveKey='0'
        type='card'
        centered
        className={styles['tabs']}
        items={tabs.map((item, index) => ({
          ...item,
          key: index.toString()
        }))}
      />
    </Drawer>
  )
}

export default DrawerKinematic
