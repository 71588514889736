import CommonLevelForm from '@/app/configuration/components/equipments/components/measurements/components/common-level-form/common-level-form'
import TemperatureForm from '@/app/configuration/components/equipments/components/measurements/components/temperature-form/temperature-form'
import type { IMeasurementData } from '@/app/configuration/components/equipments/components/measurements/components/threshold/threshold.interface'
import {
  mapFromInitializationThreshold,
  mapFromRequestMeasurement,
  mapFromRequestThreshold
} from '@/app/configuration/components/equipments/components/measurements/components/threshold/threshold.service'
import ToleranceControlSettings from '@/app/configuration/components/equipments/components/measurements/components/threshold/tolerance-control-settings/tolerance-control-settings'
import PopupToleranceControl from '@/app/configuration/components/equipments/components/measurements/components/threshold/zones-table/components/popup-zones/popup-tolerance-control'
import ZonesTable from '@/app/configuration/components/equipments/components/measurements/components/threshold/zones-table/zones-table'
import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import ContentWrapper from '@/components/UI/elements/content-wrapper/content-wrapper'
import ControlsWrapper from '@/components/UI/elements/controls-wrapper/controls-wrapper'
import HeaderConfiguration from '@/components/UI/elements/header-work-space/header-configuration'
import Spinner from '@/components/UI/elements/spinner/spinner'
import SwitchIndicator from '@/components/UI/elements/switch-indicator/switch-indicator'
import FormItemApp from '@/components/UI/form-controls/form-item-mcm/form-item-app'
import PanelTitle from '@/components/UI/form-controls/form-title/panel-title'
import TextLabel from '@/components/UI/form-controls/text-label/text-label'
import DocTooltip from '@/components/doc-tooltip/doc-tooltip'
import { ButtonTitle } from '@/constants/core/common.constant'
import { FormKey } from '@/constants/doc-tooltip.constant'
import { MeasurementLabel, MeasurementName } from '@/constants/measurement/measurements-form.constant'
import { mapMeasurementType } from '@/constants/measurement/measurements.constant'
import { EViewMode } from '@/enums/common/view-mode.enum'
import { EEquipmentContent } from '@/enums/equipments/equipment-view.enum'
import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasurementQuery, useGetThresholdQuery, useUpdateThresholdMutation } from '@/store/api/measurements.api'
import type { IThreshold, IThresholdForm } from '@/types/threshold/threshold.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import styles from './threshold.module.css'

interface IProps {
  measurementId?: string
}

const Threshold: FC<IProps> = ({ measurementId }) => {
  const { setThresholdData, setEquipmentView } = useActions()
  const [measurementFields, setMeasurementFields] = useState<IMeasurementData>({})
  const [thresholdForm] = useForm<IThresholdForm>()
  const { data: measurement } = useGetMeasurementQuery(measurementId, {
    skip: !measurementId
  })
  const { data: threshold, isLoading: isLoadingThreshold } = useGetThresholdQuery(measurementId, {
    skip: !measurementId
  })
  const [updateThreshold, { isLoading: isUpdating }] = useUpdateThresholdMutation()
  const { zones, rifts } = useTypedSelector((state) => state.thresholdReducer)

  const isCommonLevelDescription = measurementFields.measurementType === ETypeMeasurement.CommonLevelDescription
  const isTemperatureDescription = measurementFields.measurementType === ETypeMeasurement.TemperatureDescription

  useEffect(() => {
    if (measurement) {
      const measurementFieldsFromRequest = mapFromRequestMeasurement(measurement)
      setMeasurementFields(measurementFieldsFromRequest)

      if (threshold) {
        const thresholdFromRequest = mapFromRequestThreshold(threshold)
        thresholdForm.setFieldsValue(thresholdFromRequest)
        setThresholdData({
          zones: thresholdFromRequest.zones,
          rifts: thresholdFromRequest.rifts,
          baseRiftValue: thresholdFromRequest.baseRiftValue,
          riftCalcType: thresholdFromRequest.riftCalcType,
          riftCalcEnabled: thresholdFromRequest.riftCalcEnabled
        })
        return
      }

      const thresholdFromInitialization = mapFromInitializationThreshold()
      thresholdForm.setFieldsValue(thresholdFromInitialization)
      setThresholdData({
        zones: thresholdFromInitialization.zones,
        rifts: thresholdFromInitialization.rifts,
        baseRiftValue: thresholdFromInitialization.baseRiftValue,
        riftCalcType: thresholdFromInitialization.riftCalcType,
        riftCalcEnabled: thresholdFromInitialization.riftCalcEnabled
      })
    }
  }, [measurement, setThresholdData, threshold, thresholdForm])

  if (!measurementId) {
    return null
  }

  const handleUpdateThresholdFinish = async () => {
    const thresholdFields = thresholdForm.getFieldsValue()
    const thresholdData: IThreshold = { ...thresholdFields, zones, rifts }

    try {
      await updateThreshold({ thresholdData, measurementId }).unwrap()
      successNotificationCreate('Допусковый контроль сохранён')
    } catch (e) {
      errorNotificationCreate(e)
      console.error(e)
    }
  }

  const getMeasurementTypeLabel = (typeMeasurement?: ETypeMeasurement) => {
    if (typeMeasurement) {
      return mapMeasurementType[typeMeasurement]
    }
  }

  const handleExitClick = () => {
    setEquipmentView({
      content: EEquipmentContent.MEASUREMENT,
      mode: EViewMode.EDITING
    })
  }

  if (isLoadingThreshold) {
    return <Spinner />
  }

  return (
    <>
      <HeaderConfiguration title={`Допусковый контроль ${measurement?.name}`} />
      <ContentWrapper>
        <Form className={styles.container} form={thresholdForm} onFinish={handleUpdateThresholdFinish}>
          <ControlsWrapper toolTip={<DocTooltip theme='light' formKey={FormKey.FormOne} />}>
            <PanelTitle>Параметры вида измерения</PanelTitle>
            <FormItemApp name={MeasurementName.MeasurementName} label={MeasurementLabel.Name}>
              <TextLabel manualValue={measurementFields.measurementName} />
            </FormItemApp>
            <FormItemApp name={MeasurementName.MeasurementType} label={MeasurementLabel.MeasurementType}>
              <TextLabel manualValue={getMeasurementTypeLabel(measurementFields.measurementType)} />
            </FormItemApp>
            <FormItemApp name={MeasurementName.DeviceType} label={MeasurementLabel.DeviceType}>
              <TextLabel manualValue={measurementFields.deviceType} />
            </FormItemApp>
            {isTemperatureDescription && (
              <TemperatureForm
                isReaderMode={true}
                isWriterMode={false}
                manualCoef={measurementFields.coef}
                manualTemperatureType={measurementFields.temperatureType}
              />
            )}
            {isCommonLevelDescription && (
              <CommonLevelForm
                isReaderMode={true}
                isWriterMode={false}
                detector={measurementFields.detector}
                commonLevelType={measurementFields.commonLevelType}
              />
            )}
            <FormItemApp name={MeasurementName.isActivated} label={MeasurementLabel.isActivated}>
              <TextLabel
                manualValue={
                  <SwitchIndicator
                    trueContent={'Активирован'}
                    falseContent={'Выключен'}
                    sourceBoolean={measurementFields.isActivated}
                  />
                }
              />
            </FormItemApp>
          </ControlsWrapper>
          <ControlsWrapper
            className={styles['form-control']}
            toolTip={<DocTooltip theme='light' formKey={FormKey.FormOne} />}
          >
            <ToleranceControlSettings toleranceForm={thresholdForm} />
          </ControlsWrapper>
          <ZonesTable thresholdForm={thresholdForm} />
          <ControlsWrapper className={styles['form-buttons']}>
            <ButtonsWrapper>
              <ButtonPrimary onClick={handleExitClick} htmlType={'button'} title={ButtonTitle.Exit} />
              <ButtonPrimary htmlType={'submit'} title={ButtonTitle.Save} isLoading={isUpdating} />
            </ButtonsWrapper>
          </ControlsWrapper>
        </Form>
      </ContentWrapper>
      <PopupToleranceControl />
    </>
  )
}

export default Threshold
