import { SliceName } from '@/constants/store/slices.constant'
import { EViewMode } from '@/enums/common/view-mode.enum'
import { EEquipmentContent } from '@/enums/equipments/equipment-view.enum'
import type { IEquipmentsUiState } from '@/store/reducers/equipments/equipments-ui.interface'
import type { IEquipmentView } from '@/types/equipments/equipment-view.interface'
import type { ITreeNode } from '@/types/tree/tree-node.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import type { FC, Key } from 'react'

const initialState: IEquipmentsUiState = {
  equipmentView: {
    content: EEquipmentContent.NULL,
    mode: EViewMode.EDITING
  },
  treeMenu: [],
  selectedTreeItems: []
}

export const equipmentsUiSlice = createSlice({
  name: SliceName.EquipmentsUi,
  initialState,
  reducers: {
    setEquipmentView(state, action: PayloadAction<IEquipmentView>) {
      state.equipmentView = action.payload
    },
    setTreeMenu(state, action: PayloadAction<ITreeNode[]>) {
      state.treeMenu = action.payload
    },
    setSelectedTreeItems(state, action: PayloadAction<Key[]>) {
      state.selectedTreeItems = action.payload
    },
    updateTreeMenuTitle(state, action: PayloadAction<{ id: string; newTitle: string }>) {
      const { id, newTitle } = action.payload

      const updateNode = (nodes: ITreeNode[]): ITreeNode[] =>
        nodes.map((node) => {
          if (node.key === id) {
            const updatedNode = cloneDeep(node)
            updatedNode.title = newTitle
            return updatedNode
          } else if (node.children) {
            return {
              ...node,
              children: updateNode(node.children)
            }
          }
          return node
        })

      // Обновить treeMenu с помощью рекурсивной функции
      state.treeMenu = updateNode(state.treeMenu)
    },
    updateTreeMenuItem(
      state,
      action: PayloadAction<{
        id: string
        name: string
        parentKey: string
        group: string
        icon: FC
      }>
    ) {
      const { name, id, parentKey, group, icon } = action.payload

      const updateNode = (nodes: ITreeNode[]): ITreeNode[] =>
        nodes.map((node) => {
          if (node.key === parentKey) {
            const updatedNode = { ...node }
            if (!updatedNode.children) {
              updatedNode.children = []
            }
            updatedNode.children.push({
              key: id,
              title: name,
              group: group,
              icon
            } as ITreeNode)
            return updatedNode
          }

          if (node.children) {
            return {
              ...node,
              children: updateNode(node.children)
            }
          }

          return node
        })

      state.treeMenu = updateNode(state.treeMenu)
    }
  }
})
