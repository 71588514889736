import FieldWrapper from '@/components/UI/elements/field-wrapper/field-wrapper'
import type { FormItemProps, SwitchProps } from 'antd'
import { Form, Switch } from 'antd'
import type { TooltipPlacement } from 'antd/es/tooltip'
import cn from 'classnames'
import type { FC } from 'react'
import React from 'react'

import styles from './form-input-switch.module.css'

type FormInputProps = {
  labelInput: string
  classDisabled?: boolean
  placement?: TooltipPlacement
  tooltipKey?: string
}

type TProps = FormInputProps & SwitchProps & FormItemProps

const FormInputSwitch: FC<TProps> = ({
  labelInput,
  name,
  required,
  className,
  tooltipKey,
  ...props
}) => (
  <FieldWrapper tooltipKey={tooltipKey} className={className}>
    <Form.Item
      className={cn(styles['item'], styles['color--green'])}
      label={<span style={{ fontWeight: 'bold' }}>{labelInput}</span>}
      rules={[
        {
          required: required,
          message: `Поле «${labelInput}» обязательно`
        }
      ]}
      name={name}
      valuePropName='checked'
    >
      <Switch {...props} />
    </Form.Item>
  </FieldWrapper>
)

export default FormInputSwitch
