import ButtonToolbar from '@/components/UI/buttons/button-toolbar/button-toolbar'
import Control from '@/components/icons/control/control'
import { EViewMode } from '@/enums/common/view-mode.enum'
import { EEquipmentContent } from '@/enums/equipments/equipment-view.enum'
import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasurementQuery } from '@/store/api/measurements.api'
import { getTypeMeasurement } from '@/utils/measurement/get-type-measurement'
import { Tooltip } from 'antd'
import type { FC } from 'react'
import React from 'react'

interface IProps {
  measurementId?: string
  measurementOpened: boolean
  toleranceControlOpened: boolean
}

const ALLOWED_TYPES_MEASUREMENT = [ETypeMeasurement.TemperatureDescription, ETypeMeasurement.CommonLevelDescription]

const EquipmentTools: FC<IProps> = ({ measurementId, measurementOpened, toleranceControlOpened }) => {
  const { setEquipmentView, setSelectedMeasurementId } = useActions()
  const { equipmentView } = useTypedSelector((state) => state.equipmentsUiReducer)
  const { data: currentMeasurement } = useGetMeasurementQuery(measurementId, {
    skip: !measurementId
  })

  const checkAccessToleranceButton = () => {
    if ((currentMeasurement && measurementOpened) || toleranceControlOpened) {
      const currentType = getTypeMeasurement(currentMeasurement)
      return currentType ? ALLOWED_TYPES_MEASUREMENT.includes(currentType) : false
    }
  }

  const handleAddMachine = () => {
    setEquipmentView({
      content: EEquipmentContent.MACHINE,
      mode: EViewMode.ADDITION
    })
  }

  const handleAddPoint = () => {
    setEquipmentView({
      content: EEquipmentContent.POINT,
      mode: EViewMode.ADDITION
    })
  }

  const handleAddMeasurement = () => {
    setSelectedMeasurementId(undefined)
    setEquipmentView({
      content: EEquipmentContent.MEASUREMENT,
      mode: EViewMode.ADDITION
    })
  }

  const handleOpenToleranceControl = () => {
    if (toleranceControlOpened) {
      setEquipmentView({
        content: EEquipmentContent.MEASUREMENT,
        mode: EViewMode.EDITING
      })
      return
    }
    setEquipmentView({
      content: EEquipmentContent.TOLERANCE,
      mode: EViewMode.NULL
    })
  }

  const handleAddEquipmentClick = (mode: EViewMode, currentContent?: EEquipmentContent) => {
    if (currentContent && mode !== EViewMode.ADDITION) {
      const handlers = {
        [EEquipmentContent.NULL]: handleAddMachine,
        [EEquipmentContent.MACHINE]: handleAddPoint,
        [EEquipmentContent.POINT]: handleAddMeasurement,
        [EEquipmentContent.MEASUREMENT]: handleAddMeasurement,
        [EEquipmentContent.TOLERANCE]: handleOpenToleranceControl
      }

      return handlers[currentContent]()
    }
  }

  return (
    <>
      <Tooltip title={'Добавить объект'} placement='right'>
        <ButtonToolbar
          icon={'add'}
          placement={'bottomRight'}
          onClick={() => handleAddEquipmentClick(equipmentView.mode, equipmentView.content)}
          disabled={equipmentView.mode === EViewMode.ADDITION}
        />
      </Tooltip>
      <ButtonToolbar
        title='Настроить допусковый контроль'
        disabled={!checkAccessToleranceButton()}
        activated={toleranceControlOpened}
        onClick={handleOpenToleranceControl}
      >
        <Control />
      </ButtonToolbar>
      <ButtonToolbar onClick={() => console.log('delete')} title={'Удалить объект'} icon={'delete'} disabled={true} />
    </>
  )
}

export default EquipmentTools
