import StatusIndicator from '@/app/configuration/components/devices/devices-tree/status-indicator/status-indicator'
import { DeviceTypeId, DeviceTypeLabel } from '@/constants/device/device.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetGeneratorsQuery, useGetVp3701ListQuery } from '@/store/api/devices.api'
import type { ISelectedDevice } from '@/types/device/selected-device.interface'
import type { IVp3701 } from '@/types/device/vp3701.interface'
import type { IGenerator } from '@/types/devices.type'
import type { ITreeNode } from '@/types/tree/tree-node.interface'
import { replaceDecimalPoint } from '@/utils/common'
import { FolderOutlined, QuestionOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { Flex, Tree } from 'antd'
import type { EventDataNode } from 'antd/es/tree'
import type { FC, Key } from 'react'
import { useEffect, useMemo, useState } from 'react'

import styles from './device-tree.module.css'

const getNameWithBatteryCharge = (device: IVp3701) => {
  const batteryCharge = replaceDecimalPoint(device.batteryCharge.toFixed(2))
  return `${device.name} | ${batteryCharge} В`
}

const mapVp3701ForTree = (vp3701Collection: IVp3701[]) =>
  vp3701Collection.map((device) => ({
    key: device.vp3701Id,
    title: getNameWithBatteryCharge(device),
    isLeaf: true,
    className: styles['tree-item'],
    icon: (
      <Flex gap={5} align='center'>
        <VideoCameraOutlined rotate={90} />
        <StatusIndicator status={device.status} />
      </Flex>
    )
  }))

const mapGeneratorForTree = (generators: IGenerator[]) =>
  generators.map((generator) => ({
    key: generator.generatorId,
    title: generator.name,
    isLeaf: true,
    className: styles['tree-item'],
    icon: <QuestionOutlined />
  }))

const DevicesTree: FC = () => {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([])
  const { selectedDevice } = useTypedSelector((state) => state.devicesReducer)
  const { setSelectedDevice } = useActions()
  const { data: getVp3701Response, isSuccess } = useGetVp3701ListQuery(undefined, { pollingInterval: 1000 })
  const { data: getGeneratorsResponse } = useGetGeneratorsQuery()
  const vp3701List = getVp3701Response?.content
  const generators = getGeneratorsResponse?.content

  useEffect(() => {
    if (isSuccess) {
      setExpandedKeys([DeviceTypeId.Vp3701])
    }
  }, [isSuccess])

  useEffect(() => {
    selectedDevice?.id ? setSelectedKeys([selectedDevice.id]) : setSelectedKeys([])
  }, [selectedDevice?.id, setSelectedDevice])

  const deviceTree = useMemo<ITreeNode[]>(
    () => [
      {
        key: DeviceTypeId.Vp3701,
        title: DeviceTypeLabel.Vp3701,
        icon: <FolderOutlined />,
        children: vp3701List ? mapVp3701ForTree(vp3701List) : []
      },
      {
        key: DeviceTypeId.Generator,
        title: DeviceTypeLabel.Generator,
        icon: <FolderOutlined />,
        children: generators ? mapGeneratorForTree(generators) : []
      }
    ],
    [generators, vp3701List]
  )

  const handleDeviceSelect = (keys: Key[], info: { node: EventDataNode<ITreeNode> }) => {
    setSelectedKeys(keys)
    const { node } = info

    const selectedGenerator: ISelectedDevice = {
      id: node.key.toString(),
      position: node.pos
    }

    setSelectedDevice(selectedGenerator)
  }

  const handleExpand = (keys: Key[]) => {
    setExpandedKeys(keys)
  }

  return (
    <Tree
      className={styles['tree']}
      showIcon={true}
      showLine={true}
      treeData={deviceTree}
      onSelect={handleDeviceSelect}
      expandedKeys={expandedKeys}
      onExpand={handleExpand}
      selectedKeys={selectedKeys}
    />
  )
}

export default DevicesTree
