import type { IFormCd } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/cd-form/cd-form.type'
import type { IDriveMechOut } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/drive-mech-form/drive-mech-form.type'
import type { ITableDataOuts } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/out-table/out-table.type'
import {
  KbParam,
  TypeSource
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type { IFormKb } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.type'
import {
  calculateValue,
  handleName,
  handleValue
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.util'
import { REAL_NUMBER } from '@/config/connection.config'
import type { IIn, IKinematicElement, IOut } from '@/types/kinematic.type'
import { formatFloat, isNumber } from '@/utils/common'

export const parseServerData = (kinematicElement: IKinematicElement) => {
  const { description, ins, outs } = kinematicElement

  interface IResult {
    fields: IFormKb
    ins: IIn[] | null
    outs: IDriveMechOut[] | null
  }

  const result: IResult = {
    fields: {
      [KbParam.Description]: description,
      [KbParam.TypeSource]: TypeSource.Missing,
      [KbParam.FreqCoefForIn]:
        ins && ins[0].freqCoefForIn ? ins[0].freqCoefForIn : 1,
      [KbParam.KinematicBlockSourceId]: null,
      [KbParam.FreqOutSource]: null
    },
    ins: null,
    outs: null
  }

  if (ins) {
    result.fields[KbParam.TypeSource] = ins[0].kinematicBlockSourseId
      ? TypeSource.Kb
      : TypeSource.Missing
    result.fields[KbParam.KinematicBlockSourceId] =
      ins[0].kinematicBlockSourseId
    result.fields[KbParam.FreqOutSource] = ins[0].freqInValue
    result.ins = ins
  }

  if (outs) {
    result.outs = outs.map((item) => ({
      ...item,
      label: 'Частота вращения'
    }))
  }
  return result
}

export const getCalculatedResults = (fieldsValue: IFormCd, ins: IIn[]) => {
  const { freqOutSource, freqCoefForIn } = fieldsValue
  const { freqOutCoefMachineSourse, freqOutNameSourse } = ins[0]

  const freqInValue = calculateValue(freqOutSource, freqCoefForIn)
  const freqInCoefMachine = calculateValue(
    freqOutCoefMachineSourse,
    freqCoefForIn
  )

  const result: IOut[] = [
    {
      freqOutName: freqOutNameSourse,
      freqOutCoefKb: freqCoefForIn || null,
      freqOutCoefMachine: freqInCoefMachine
        ? formatFloat(freqInCoefMachine, REAL_NUMBER)
        : null,
      freqOutValue: freqInValue ? formatFloat(freqInValue, REAL_NUMBER) : null
    }
  ]

  return result
}

export const parseDataForChange = (
  kinematicElement: IKinematicElement,
  fields: IFormKb,
  ins: IIn[],
  outs: IOut[]
): IKinematicElement => {
  const { description, kinematicBlockSourseId, freqCoefForIn } = fields

  return {
    ...kinematicElement,
    description: description,
    ins: [
      {
        freqInName: ins[0].freqOutNameSourse,
        freqInValue: ins[0].freqInValue,
        freqOutCoefMachineSourse: ins[0].freqOutCoefMachineSourse,
        freqInCoefMachine:
          isNumber(freqCoefForIn) && isNumber(ins[0].freqOutCoefMachineSourse)
            ? ins[0].freqOutCoefMachineSourse * freqCoefForIn
            : 1,
        kinematicBlockSourseId: kinematicBlockSourseId,
        freqOutCoefIndexSourse: ins[0].freqOutCoefIndexSourse || 0,
        freqOutNameSourse: ins[0].freqOutNameSourse || 'Fin1',
        freqCoefForIn: isNumber(freqCoefForIn) ? freqCoefForIn : 1,
        measurementSourseId: null
      }
    ],
    outs: outs
  }
}

export const formatDataForTable = (outs: IOut[]): ITableDataOuts[] => {
  const data = outs.map((out) => ({
    label: 'Частота вращения',
    freqOutName: handleName(out.freqOutName),
    freqOutCoefKb: handleValue(out.freqOutCoefKb),
    freqOutCoefMachine: handleValue(out.freqOutCoefMachine),
    freqOutValue: handleValue(out.freqOutValue)
  }))

  return data.map<ITableDataOuts>((item, key) => ({ ...item, key }))
}
