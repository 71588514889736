import Submenu from '@/components/UI/elements/submenu/submenu'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import WorkSpace from '@/components/UI/layouts/work-space/work-space'
import GroupList from '@/components/group-list/group-list'
import MnemonicMain from '@/components/mnemonic/mnemonic-main/mnemonic-main'
import { machineConditionLinks } from '@/constants/navigation/links.constant'
import type { FC } from 'react'

const MnemonicCondition: FC = () => (
  <Container>
    <LeftBar>
      <GroupList />
    </LeftBar>
    <WorkSpace>
      <Submenu links={machineConditionLinks} />
      <MnemonicMain condition={true} />
    </WorkSpace>
  </Container>
)

export default MnemonicCondition
