import { ApiResource } from '@/constants/store/api.constants'
import type { IBearing, IBearingsResponse } from '@/types/bearing/bearing.type'
import type { IManufacturer } from '@/types/bearing/manufacturer.type'
import queryString from 'query-string'

import { baseApi } from './api-base-query'

interface IOptionsRequestGetBearings {
  typeSrch: string
  manufacturer?: string
  isCustom?: boolean
  page?: number
  size?: number
}

export const bearingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBearings: builder.query<IBearingsResponse, IOptionsRequestGetBearings>({
      query: (options) => {
        const stringifyParams = queryString.stringify({ ...options })
        return {
          url: `${ApiResource.Bearing}?${stringifyParams}`
        }
      }
    }),
    getBearing: builder.query<IBearing, string>({
      query: (bearingId) => ({
        url: `${ApiResource.Bearing}/${bearingId}`
      })
    }),
    addBearing: builder.mutation<{ id: string }, IBearing>({
      query: (data) => ({
        url: ApiResource.Bearing,
        method: 'POST',
        body: data
      })
    }),
    getManufactures: builder.query<IManufacturer[], void>({
      query: () => ({
        url: `${ApiResource.Bearing}/manufacturer`
      })
    })
  })
})

export const { useLazyGetBearingsQuery, useAddBearingMutation, useGetManufacturesQuery, useLazyGetBearingQuery } =
  bearingApi
