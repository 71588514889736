import { baseApi } from '@/store/api/api-base-query'
import { chartsUiSlice } from '@/store/reducers/charts/charts-ui.slice'
import { expressAnalysisSlice } from '@/store/reducers/charts/express-analysis.slice'
import { connectionUiSlice } from '@/store/reducers/connection/connection.slide'
import { devicesSlice } from '@/store/reducers/device/devices.slice'
import { equipmentsUiSlice } from '@/store/reducers/equipments/equipments-ui.slice'
import { globalSlice } from '@/store/reducers/global-states/global.slice'
import { kinematicUiSlice } from '@/store/reducers/kinematic/kinematic-ui/kinematic-ui.slice'
import { mnemonicSlice } from '@/store/reducers/mnemonic/mnemonic.slice'
import { reportsSlice } from '@/store/reducers/reports.slice'
import { serverSettingsSlice } from '@/store/reducers/server-settings.slice'
import { thresholdSlice } from '@/store/reducers/threshold/threshold.slice'
import { combineReducers } from 'redux'

import { machinesApi } from '../api/machines.api'
import { expressBarsSlice } from './charts/express-bars.slice'
import { expressMarkersSlice } from './charts/express-markers.slice'
import { expressSectionSlice } from './charts/express-section.slice'
import { expressStrobeSlice } from './charts/express-strobe.slice'
import { configurationSlice } from './configuration.slice'
import { generatorSlice } from './generator.slice'
import { kinematicSlice } from './kinematic/kinematic.slice'
import { measurementResultsSlice } from './measurementResults.slice'
import { planSlice } from './plan.slice'

export const rootReducer = combineReducers({
  [machinesApi.reducerPath]: baseApi.reducer,
  configurationReducer: configurationSlice.reducer,
  devicesReducer: devicesSlice.reducer,
  planReducer: planSlice.reducer,
  serverSettingsReducer: serverSettingsSlice.reducer,
  thresholdReducer: thresholdSlice.reducer,
  generatorReducer: generatorSlice.reducer,
  measurementResultsReducer: measurementResultsSlice.reducer,
  reportsReducer: reportsSlice.reducer,
  kinematicReducer: kinematicSlice.reducer,
  expressStrobeReducer: expressStrobeSlice.reducer,
  expressSectionReducer: expressSectionSlice.reducer,
  expressMarkersReducer: expressMarkersSlice.reducer,
  expressBarsReducer: expressBarsSlice.reducer,
  kinematicUiReducer: kinematicUiSlice.reducer,
  chartsUiReducer: chartsUiSlice.reducer,
  globalReducer: globalSlice.reducer,
  equipmentsUiReducer: equipmentsUiSlice.reducer,
  mnemonicReducer: mnemonicSlice.reducer,
  connectionReducer: connectionUiSlice.reducer,
  expressAnalysisReducer: expressAnalysisSlice.reducer
})

export type RootState = ReturnType<typeof rootReducer>
