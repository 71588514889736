const calculateSKZ = (strobeValuesData: number[]) => {
  const strobePowValuesData = strobeValuesData.map((value) =>
    Math.pow(value, 2)
  )

  const strobesSum = strobePowValuesData.reduce((prev, curr) => prev + curr, 0)

  const skz = Math.sqrt(strobesSum / strobePowValuesData.length)

  return skz
}

export default calculateSKZ
