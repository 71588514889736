import { EDeviceType } from '@/enums/device/device-type.enum'

export const DeviceValue = {
  VP3701: 'vp3701',
  Generator: 'generator'
}

export const DeviceLabel = {
  VP3701: 'VP-3701',
  Generator: 'Генератор'
}

export const mapDeviceType = {
  [EDeviceType.VP3701]: 'VP-3701',
  [EDeviceType.VP3711]: 'VP-3711',
  [EDeviceType.GENERATOR]: 'Генератор'
}
