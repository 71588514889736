import type { DataItem } from '@/app/configuration/components/devices/generator/generator.type'
import { GeneratorKey, STEP_COUNT_FREQ, STEP_COUNT_PERCENT } from '@/constants/core/common.constant'
import type { InputRef } from 'antd'
import { Form, Input, InputNumber } from 'antd'
import React, { useEffect, useRef } from 'react'

interface EditableCellProps {
  editing: boolean
  title: React.ReactNode
  children: React.ReactNode
  dataIndex: keyof DataItem
  record: DataItem
}

const CellEditableSquare: React.FC<EditableCellProps> = ({
  editing,
  children,
  record,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  title,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dataIndex,
  ...restProps
}) => {
  const inputRef = useRef<InputRef>(null)

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [editing])

  const getInputComponent = (key: GeneratorKey) => {
    switch (key) {
      case GeneratorKey.Name:
        return <Input ref={inputRef} />
      case GeneratorKey.Fs:
        return <InputNumber step={STEP_COUNT_FREQ} disabled={true} />
      case GeneratorKey.TSig:
        return <InputNumber disabled={true} />
      case GeneratorKey.NormAmp:
        return <InputNumber step={0.01} disabled={true} />
      case GeneratorKey.SquareBaseFreq:
        return <InputNumber step={STEP_COUNT_FREQ} />
      case GeneratorKey.SquareLowLevel:
        return <InputNumber step={STEP_COUNT_FREQ} />
      case GeneratorKey.SquareHighToLow:
        return <InputNumber step={STEP_COUNT_PERCENT} min={0} max={100} />
      case GeneratorKey.SquareDuty:
        return <InputNumber />
      case GeneratorKey.SquarePhase:
        return <InputNumber step={STEP_COUNT_PERCENT} min={0} max={100} />
      default:
        return <Input />
    }
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={record.key}
          rules={[
            {
              required: true,
              message: `Значение поля «${record.label}» необходимо заполнить`
            }
          ]}
        >
          {getInputComponent(record.key)}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

export default CellEditableSquare
