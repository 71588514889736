export const TokenScheme = {
  colorPrimary: '#000',
  colorPrimaryBorder: '#BBBBBBFF',
  colorBorder: '#BBBBBBFF',
  borderRadiusSM: 0,
  fontFamily: 'Inter, Arial, Helvetica, sans-serif',
  borderRadiusLG: 0,
  borderRadius: 0,
  cellPaddingBlock: 4,
  cellPaddingInline: 4,
  padding: 4
} as const
