import type { TToleranceControlForm } from '@/app/configuration/components/equipments/components/measurements/components/threshold/threshold.interface'
import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import FormItemApp from '@/components/UI/form-controls/form-item-mcm/form-item-app'
import { ButtonTitle } from '@/constants/core/common.constant'
import { ThresholdLabel, ThresholdName } from '@/constants/threshold/threshold.constant'
import { ERiftCalcType } from '@/enums/threshold/rift-calc-type.enum'
import { EThresholdLevel } from '@/enums/threshold/threshold-level.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { Form, InputNumber } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { orderBy } from 'lodash'
import type { FC } from 'react'
import React from 'react'

interface IProps {
  checkDuplicateRiftValue: () => (test: unknown, value: string) => Promise<void>
}

const FormAddToleranceControl: FC<IProps> = ({ checkDuplicateRiftValue }) => {
  const { setIsOpenThresholdPopup, setSelectedRift, setRifts, setZones } = useActions()
  const { riftCalcType, riftCalcEnabled, baseRiftValue, zones, rifts } = useTypedSelector(
    (state) => state.thresholdReducer
  )
  const [toleranceControlAddForm] = useForm<TToleranceControlForm>()
  const isMultiplication = riftCalcType === ERiftCalcType.MULTIPLICATION
  const isIncrement = riftCalcType === ERiftCalcType.INCREMENT

  const handleAddZone = () => {
    const { coef, hysteresisCoef, riftValue } = toleranceControlAddForm.getFieldsValue()

    const newRift = {
      riftValue: riftCalcEnabled ? (isIncrement ? coef + baseRiftValue : coef * baseRiftValue) : riftValue,
      hysteresisCoef: hysteresisCoef,
      coef: riftCalcEnabled ? coef : 0
    }

    const newRifts = [...rifts, newRift]
    const orderedNewRifts = orderBy(newRifts, 'riftValue', 'desc')
    const newZones = [...zones, { dangerousType: EThresholdLevel.NONE }]
    const foundIndexNewRift = orderedNewRifts.indexOf(newRift)

    setRifts(orderedNewRifts)
    setZones(newZones)
    setSelectedRift(foundIndexNewRift)

    setIsOpenThresholdPopup(false)
    toleranceControlAddForm.resetFields()
  }

  const handlePopupClose = () => {
    setIsOpenThresholdPopup(false)
  }

  const labelCoef = isMultiplication ? ThresholdLabel.Coef : ThresholdLabel.Increment

  return (
    <Form form={toleranceControlAddForm} onFinish={handleAddZone}>
      {riftCalcEnabled ? (
        <FormItemApp
          name={ThresholdName.Coef}
          label={labelCoef}
          rules={[{ required: true, message: 'Поле обязательно' }]}
          initialValue={1}
        >
          <InputNumber autoFocus={true} placeholder={`Введите значение ${labelCoef.toLowerCase()}`} />
        </FormItemApp>
      ) : (
        <FormItemApp
          name={ThresholdName.RiftValue}
          label={ThresholdLabel.RiftValue}
          rules={[{ validator: checkDuplicateRiftValue() }, { required: true, message: 'Поле обязательно' }]}
          initialValue={0}
        >
          <InputNumber placeholder='Введите значение границы' />
        </FormItemApp>
      )}
      <FormItemApp
        label={ThresholdLabel.HysteresisCoef}
        name={ThresholdName.HysteresisCoef}
        initialValue={0}
        rules={[{ required: true, message: 'Поле обязательно' }]}
      >
        <InputNumber placeholder='Введите значение гистерезиса' />
      </FormItemApp>
      <ButtonsWrapper>
        <ButtonPrimary title={ButtonTitle.Cancel} htmlType={'button'} onClick={handlePopupClose} />
        <ButtonPrimary title={ButtonTitle.Submit} htmlType={'submit'} />
      </ButtonsWrapper>
    </Form>
  )
}

export default FormAddToleranceControl
