import EquipmentItem from '@/components/UI/elements/equipment-item/equipment-item'
import StatisticAlIIndicator from '@/components/UI/elements/indicators/statistical-indicator/statistic-alI-indicator'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetAllNodesQuery } from '@/store/api/nodes.api'
import type { ISelectedMeasuringPoint } from '@/types/point/point.type'
import cn from 'classnames'
import type { FC } from 'react'

import styles from '../points-list.module.css'

interface IProps {
  point: ISelectedMeasuringPoint
  onSelect: (point: ISelectedMeasuringPoint['pointId']) => void
}

const PointItem: FC<IProps> = ({ point, onSelect }) => {
  const { selectedPointId } = useTypedSelector((state) => state.globalReducer)

  const { data: dataAllNodesState } = useGetAllNodesQuery()

  const foundNodeState = dataAllNodesState?.find(
    (node) => node.measuringPointId === point.pointId
  )

  return (
    <div
      className={cn(styles['point-button'], {
        [styles['point-button-active']]: point.pointId === selectedPointId
      })}
      onClick={() => onSelect(point.pointId)}
    >
      <EquipmentItem
        indicator={
          foundNodeState?.measuringPointId ? (
            <StatisticAlIIndicator nodeState={foundNodeState} />
          ) : null
        }
        name={point.name}
        paused={point.paused}
      />
    </div>
  )
}

export default PointItem
