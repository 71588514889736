import FormInputNumber from '@/components/UI/form-controls/form-input-number/form-input-number'
import FormSelect from '@/components/UI/form-controls/form-select/form-select'
import {
  GeneratorKey,
  STEP_COUNT_FREQ,
  STEP_COUNT_ROUND,
  distrAmplitudes,
  phases,
  typeSetModule
} from '@/constants/core/common.constant'
import { GeneratorLabel } from '@/constants/device/deviceOptions'
import useFade from '@/hooks/use-fade'
import type { FC } from 'react'

import styles from '@/app/configuration/components/devices/popup-generator/form-items-harmonic-signal/form-items-harmonic-signal.module.css'

const FormItemsModuleTwo: FC = () => {
  const { ref, style } = useFade()
  return (
    <div ref={ref} style={style} className={styles.columns}>
      <div>
        <FormSelect
          labelContent={GeneratorLabel.M2_Type}
          name={GeneratorKey.M2_Type}
          options={typeSetModule}
          placeholder={'Выберите амплитуду распределения'}
          initialValue={0}
          autoFocus={true}
        />
        <FormInputNumber
          labelContent={GeneratorLabel.M2_DeepA}
          step={STEP_COUNT_FREQ}
          name={GeneratorKey.M2_DeepA}
          initialValue={1}
          required={true}
        />
        <FormInputNumber
          labelContent={GeneratorLabel.M2_Nharm}
          step={STEP_COUNT_FREQ}
          name={GeneratorKey.M2_Nharm}
          initialValue={1}
          required={true}
        />
        <FormSelect
          labelContent={GeneratorLabel.M2_PhaseSet}
          name={GeneratorKey.M2_PhaseSet}
          options={phases}
          placeholder={'Выберите фазовое распределение'}
          initialValue={0}
        />
      </div>
      <div>
        <FormInputNumber
          labelContent={GeneratorLabel.M2_BaseFreq}
          step={STEP_COUNT_FREQ}
          name={GeneratorKey.M2_BaseFreq}
          initialValue={1}
          required={true}
        />
        <FormInputNumber
          labelContent={GeneratorLabel.M2_DeepT}
          step={STEP_COUNT_FREQ}
          name={GeneratorKey.M2_DeepT}
          required={true}
        />
        <FormSelect
          labelContent={GeneratorLabel.M2_AmpSet}
          name={GeneratorKey.M2_AmpSet}
          options={distrAmplitudes}
          placeholder={'Выберите закон изменения ряда'}
          initialValue={0}
        />
        <FormInputNumber
          labelContent={GeneratorLabel.M2_PhaseShift}
          step={STEP_COUNT_ROUND}
          name={GeneratorKey.M2_PhaseShift}
          min={-180}
          max={100}
          required={true}
        />
      </div>
    </div>
  )
}

export default FormItemsModuleTwo
