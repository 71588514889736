export const Vp3701Labels = {
  Name: 'Наименование устройства',
  Comment: 'Комментарий',
  Location: 'Местоположение',
  SerialNumber: 'Серийный номер',
  Modification: 'Модификация',
  Status: 'Статус соединения',
  PowerType: 'Тип питания',
  Firmware: 'Версия внутреннего ПО',
  Ssid: 'SSID',
  AccelType: 'Тип акселерометра',
  ThermoType: 'Тип датчика температуры',
  BatteryCharge: 'Напряжение источника',
  ChannelUnitType1: 'Вибрация, X',
  ChannelUnitType2: 'Вибрация, Y',
  ChannelUnitType3: 'Вибрация, Z',
  ChannelUnitType4: 'Температура, ИК',
  ChannelUnitType5: 'Температура, КК',
  ConnectionStatus: 'Статус подключения к ТИ',
  LastErrors: 'Ошибки',
  StatusUpdatingTimestamp: 'Время обновления статуса'
} as const
