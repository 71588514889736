import { FormBranch } from '@/constants/devices.constant'
import { SliceName } from '@/constants/store/slices.constant'
import type { IDeviceInitialState } from '@/types/device/device-initial-state.interface'
import type { ISelectedDevice } from '@/types/device/selected-device.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: IDeviceInitialState = {
  handleForceUpdate: () => null,
  isEditGenerator: false,
  isOpenPopupGenerator: false,
  formGeneratorStep: 1,
  formGeneratorBranch: FormBranch.Common,
  formGeneratorData: null,
  moduleOneActive: 0,
  moduleTwoActive: 0,
  isLoadingData: false,
  selectedGeneratorId: undefined,
  selectedDevice: { id: undefined }
}

export const devicesSlice = createSlice({
  name: SliceName.Devices,
  initialState,
  reducers: {
    setIsEditGenerator(state, action: PayloadAction<boolean>) {
      state.isEditGenerator = action.payload
    },
    setForceUpdate(state, action: PayloadAction<() => void>) {
      state.handleForceUpdate = action.payload
    },
    setIsOpenPopupGenerator(state, action: PayloadAction<boolean>) {
      state.isOpenPopupGenerator = action.payload
    },
    setFormGeneratorBranch(state, action: PayloadAction<FormBranch>) {
      state.formGeneratorBranch = action.payload
    },
    setFormGeneratorStep(state, action: PayloadAction<number>) {
      state.formGeneratorStep = action.payload
    },
    setFormGeneratorData(state, action) {
      state.formGeneratorData = action.payload
    },
    setModuleOneActive(state, action: PayloadAction<number>) {
      const value = action.payload
      state.moduleOneActive = value
    },
    setModuleTwoActive(state, action: PayloadAction<number>) {
      state.moduleTwoActive = action.payload
    },
    setIsLoadingData(state, action: PayloadAction<boolean>) {
      state.isLoadingData = action.payload
    },
    setSelectedGeneratorId(state, action: PayloadAction<string>) {
      state.selectedGeneratorId = action.payload
    },
    setSelectedDevice(state, action: PayloadAction<ISelectedDevice>) {
      state.selectedDevice = action.payload
    }
  }
})
