export enum SettingsTitle {
  Main = 'Основной',
  Profile = 'Профиль',
  Server = 'Сервер',
  License = 'Лицензия'
}

export enum ServerType {
  Cloud = 'CLOUD',
  Local = 'LOCAL'
}

export const serverTypeMap = {
  [ServerType.Cloud]: 'Облачный сервер',
  [ServerType.Local]: 'Локальный сервер'
}

export const KEY_BASE_URL = 'baseUrl'
