import ChartTooltip from '@/app/machine-condition/components/results/components/charts/components/tooltip-wrapper/chart-tooltip'
import { UNIT_GZ } from '@/constants/core/common.constant'
import type { FC } from 'react'
import type { TooltipProps } from 'recharts'
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

type TooltipScalarProps = {
  isLog?: boolean
  fMax?: number
  dfValue: number
} & TooltipProps<ValueType, NameType>

const formatFreqValue = ({
  value,
  isLog,
  fMax,
  dfValue
}: {
  value: string
  isLog?: boolean
  fMax?: number
  dfValue?: number
}) => {
  if (isLog && fMax && dfValue) {
    return Number(Math.pow(10, Number(value) * Math.log10(fMax / dfValue)).toPrecision(6))
  }

  return Number(Number(value).toPrecision(6))
}

const TooltipSpectrum: FC<TooltipScalarProps> = ({ active, payload, isLog, dfValue, fMax }) => {
  if (active && payload) {
    const currentData = payload?.[0]?.payload

    return (
      <ChartTooltip>
        <span>A {currentData?.amplitude}</span>
        <span>
          F {/*{formatFreqValue({*/}
          {/*  value: currentData?.frequency,*/}
          {/*  isLog,*/}
          {/*  dfValue,*/}
          {/*  fMax*/}
          {/*})}{' '}*/}
          {currentData?.frequency} {UNIT_GZ}
        </span>
      </ChartTooltip>
    )
  }

  return null
}

export default TooltipSpectrum
