import FormInputNumber from '@/components/UI/form-controls/form-input-number/form-input-number'
import FormInput from '@/components/UI/form-controls/form-input/form-input'
import { GeneratorKey, STEP_COUNT_FREQ } from '@/constants/core/common.constant'
import { DEFAULT_NORM_AMP } from '@/constants/default-values.constant'
import { GeneratorLabel } from '@/constants/device/deviceOptions'
import useFade from '@/hooks/use-fade'
import { useFocusInputAd } from '@/hooks/use-focus-input-ad'
import type { IGeneratorsGetResponse } from '@/store/api/devices.api'
import { addLeadingZeros } from '@/utils/common'
import type { FC } from 'react'
import React from 'react'

type TProps = {
  data: IGeneratorsGetResponse | undefined
  isLoading: boolean
}

const FormItemsParameters: FC<TProps> = ({ data, isLoading }) => {
  const { ref, style } = useFade()
  const inputRef = useFocusInputAd([data])

  const getTemplateName = () => {
    if (data) {
      return `Генератор № ${addLeadingZeros(Number(data.content.length) + 1, 3)}`
    }

    return 'Генератор № '
  }

  return (
    <div ref={ref} style={style}>
      <FormInput
        labelContent={GeneratorLabel.Name}
        initialValue={getTemplateName()}
        name={GeneratorKey.Name}
        isLoading={isLoading}
        inputRef={inputRef}
        defaultPlaceholder={true}
        required={true}
        tooltipKey='deviceName'
      />
      <FormInputNumber
        labelContent={GeneratorLabel.Fs}
        name={GeneratorKey.Fs}
        step={STEP_COUNT_FREQ}
        initialValue={0}
        disabled={true}
        tooltipKey='deviceName'
      />
      <FormInputNumber
        labelContent={GeneratorLabel.NormAmp}
        name={GeneratorKey.NormAmp}
        step={STEP_COUNT_FREQ}
        initialValue={DEFAULT_NORM_AMP}
        disabled={true}
      />
      <FormInputNumber labelContent={GeneratorLabel.TSig} name={GeneratorKey.TSig} initialValue={0} disabled={true} />
    </div>
  )
}

export default FormItemsParameters
