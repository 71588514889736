import { SliceName } from '@/constants/store/slices.constant'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type TExpressSection = {
  fl: number
  fh: number
  fill: string
}

export type TExpressSectionResult = {
  title: string
  isDb: boolean
  data: { skz: number; am: number; fm: number }[]
}

interface IInitialExpressData {
  sections: TExpressSection[]
  results: TExpressSectionResult[]
  isSectionExpressBarOpen: boolean
}

const initialState: IInitialExpressData = {
  sections: [],
  results: [],
  isSectionExpressBarOpen: false
}

export const expressSectionSlice = createSlice({
  name: SliceName.ExpressSection,
  initialState,
  reducers: {
    setIsExpressBarSectionOpen(state, action: PayloadAction<boolean>) {
      state.isSectionExpressBarOpen = action.payload
    },

    setExpressSection(
      state,
      action: PayloadAction<{ section: TExpressSection; index: number }>
    ) {
      const index = action.payload.index
      state.sections[index] = action.payload.section
    },

    pushSection(state, action: PayloadAction<TExpressSection>) {
      state.sections.push(action.payload)
    },

    addExpressSectionResult(
      state,
      action: PayloadAction<TExpressSectionResult>
    ) {
      const foundIndex = state.results.findIndex(
        (result) => result.title === action.payload.title
      )

      if (foundIndex === -1) {
        state.results.push(action.payload)
      } else {
        state.results[foundIndex] = {
          ...state.results[foundIndex],
          ...action.payload
        }
      }
    },

    updateSection(
      state,
      action: PayloadAction<{ index: number; section: TExpressSection }>
    ) {
      state.sections[action.payload.index] = action.payload.section
    },

    removeSection(state, action: PayloadAction<number>) {
      state.results = []
      state.sections.splice(action.payload, 1)
    },

    resetExpressResults(state) {
      state.results = []
    },

    resetExpressSections(state) {
      state.sections = []
    }
  }
})
