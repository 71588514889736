import { levelColor } from '@/constants/node-state/level-color.constant'
import { EKinematicViewMode } from '@/enums/kinematic/kinematic-view-mode.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetAllNodesQuery } from '@/store/api/nodes.api'
import type { IKinematicElement } from '@/types/kinematic.type'
import { getQualifierPointState } from '@/utils/measurement/get-qualifier-point-state'
import { getReducePointState } from '@/utils/measurement/get-reduce-point-state'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { Image } from 'react-konva'

type TKinematicBlock = {
  kinematicElement: IKinematicElement
  statusConnection?: string
  image?: HTMLImageElement
}

const StatusBlock: FC<TKinematicBlock> = ({
  kinematicElement,
  statusConnection,
  image
}) => {
  const { data: dataAllNodesState } = useGetAllNodesQuery()
  const { setSelectedKinematic } = useActions()
  const [fillColor, setFillColor] = useState<string | undefined>(undefined)
  const { viewMode } = useTypedSelector((state) => state.kinematicUiReducer)

  useEffect(() => {
    const commonStatePoints = getReducePointState(
      kinematicElement.measurementPointIds,
      dataAllNodesState
    )
    const level = getQualifierPointState(commonStatePoints)
    if (level && viewMode === EKinematicViewMode.Status) {
      const color = levelColor[level]
      setFillColor(color)
    }
  }, [dataAllNodesState, kinematicElement.measurementPointIds, viewMode])

  const handleSelectionClick = () => {
    setSelectedKinematic(kinematicElement)
  }

  return (
    <Image
      y={kinematicElement.y}
      x={kinematicElement.x}
      width={kinematicElement.width}
      height={kinematicElement.height}
      image={image}
      stroke={statusConnection}
      fill={fillColor}
      strokeWidth={3}
      onClick={handleSelectionClick}
    />
  )
}

export default StatusBlock
