import type { TKinematicBearing } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/br-form/br-form.type'
import { handleValueWithZero } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.util'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import styles from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type BearingGeometricType = {
  bearing: TKinematicBearing | null
  isFetchingBearing: boolean
}

interface IBearingGeometric {
  key: number
  label: string
  value: number | string
}

export const BearingGeometric: FC<BearingGeometricType> = ({
  bearing,
  isFetchingBearing
}) => {
  const [bearingGeometric, setBearingGeometric] = useState<
    IBearingGeometric[] | undefined
  >(undefined)

  useEffect(() => {
    if (bearing) {
      const data: IBearingGeometric[] = [
        {
          key: 1,
          label: 'Диаметр внутреннего кольца',
          value: handleValueWithZero(bearing.din)
        },
        {
          key: 2,
          label: 'Диаметр наружного кольца',
          value: handleValueWithZero(bearing.dout)
        },
        {
          key: 3,
          label: 'Диаметр тел качения',
          value: handleValueWithZero(bearing.dtk)
        },
        {
          key: 4,
          label: 'Количество тел качения',
          value: handleValueWithZero(bearing.n)
        },
        {
          key: 5,
          label: 'Угол контакта, градусов',
          value: handleValueWithZero(bearing.alfa)
        }
      ]

      setBearingGeometric(data)
      return
    }

    setBearingGeometric(undefined)
  }, [bearing])

  const columns: ColumnsType<IBearingGeometric> = [
    {
      title: 'Геометрические размеры подшипников',
      dataIndex: 'label'
    },
    {
      title: 'Значение',
      dataIndex: 'value'
    }
  ]

  return (
    <Table
      className={styles['drawer-kinematic-table']}
      columns={columns}
      dataSource={bearingGeometric}
      bordered={true}
      pagination={false}
      loading={isFetchingBearing}
    />
  )
}
