import type { TMarker } from '@/store/reducers/charts/express-markers.slice'
import type { IChartSpectrum } from '@/types/chart/chart-spectrun.type'
import type { Dispatch, MutableRefObject, SetStateAction } from 'react'
import { Fragment } from 'react'
import { ReferenceDot, ReferenceLine } from 'recharts'

import { binarySearch } from './calculates.helper'

type TRenderMarkersProps = {
  markerOptions: TMarker[]
  refIndexMarker: MutableRefObject<number>
  setIsAreaDragMarker: Dispatch<SetStateAction<boolean>>
  chartData: IChartSpectrum[]
}

const markersColors = ['#000', '#ff9774', '#0b7ae8']

export const renderExpressMarkersChart = ({
  markerOptions,
  refIndexMarker,
  setIsAreaDragMarker,
  chartData
}: TRenderMarkersProps) => {
  const calculateCoordinatesMarkers = (currentMarker: TMarker) => {
    let index = currentMarker.index

    if (currentMarker.index === null) {
      index = binarySearch(
        chartData,
        Number(currentMarker.freq),
        0,
        chartData.length - 1
      )?.index
    }

    return Number(index)
  }

  return markerOptions.map((markerOption, index) => {
    const currentIndex = calculateCoordinatesMarkers(markerOption)

    return (
      <Fragment key={index}>
        <ReferenceLine
          x={chartData[currentIndex]?.frequency}
          label={{
            position: 'insideTopLeft',
            value: index + 1,
            fill: '#000',
            stroke: 'blue'
          }}
          stroke={markersColors[index]}
          strokeWidth={2}
          strokeOpacity={0.7}
          cursor={'move'}
          onMouseDown={() => {
            setIsAreaDragMarker(true)
            refIndexMarker.current = index
          }}
        />

        <ReferenceDot
          r={4}
          fill={refIndexMarker.current === index ? '#0b7ae8' : '#000'}
          x={chartData[currentIndex]?.frequency}
          y={chartData[currentIndex]?.amplitude}
        />
      </Fragment>
    )
  })
}
