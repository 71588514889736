import { useGetMnemoStatsQuery } from '@/store/api/mnemo.api'

import { useGetCurrentMnemoScheme } from './use-get-current-mnemo-scheme'

export const useGetMnemoStats = () => {
  const { data: currentMnemoSchemeData } = useGetCurrentMnemoScheme()

  const mnemoStatsQuery = useGetMnemoStatsQuery(
    currentMnemoSchemeData?.mnemoSchemeId || '',
    {
      skip: !currentMnemoSchemeData?.mnemoSchemeId
    }
  )

  return mnemoStatsQuery
}
