import { getUnitMeasurement } from '@/app/machine-condition/components/results/components/charts/charts.service'
import {
  getChartData,
  getChartThresholds,
  getRangeValues
} from '@/app/machine-condition/components/results/components/charts/components/chart-scalar/chart-scalar.service'
import TooltipScalar from '@/app/machine-condition/components/results/components/charts/components/chart-scalar/components/tooltip-scalar/tooltip-scalar'
import ChartsTools from '@/app/machine-condition/components/results/components/charts/components/chart-tools/charts-tools'
import ChartUnits from '@/app/machine-condition/components/results/components/charts/components/chart-units/chart-units'
import ChartWrapper from '@/app/machine-condition/components/results/components/charts/components/chart-wrapper/chart-wrapper'
import SliderApp from '@/app/machine-condition/components/results/components/charts/components/slider-app/slider-app'
import Spinner from '@/components/UI/elements/spinner/spinner'
import { chartThresholdColorZones } from '@/constants/threshold/threshold.constant'
import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import { EUnitType } from '@/enums/measurment/unit-type.enum'
import useGetMeasurement from '@/hooks/api/use-get-measurement.hook'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetThresholdQuery } from '@/store/api/measurements.api'
import type { IMeasurementScalarResult } from '@/types/chart/charts.type'
import { chartFormatValue } from '@/utils/chart/chart-format-value'
import { generateGraphTicks } from '@/utils/chart/generate-graph-ticks'
import { getStepSlider } from '@/utils/chart/get-step-slider'
import { getTypeMeasurement } from '@/utils/measurement/get-type-measurement'
import { convertBaseAcceleration, convertTemperature } from '@/utils/measurement/units-converters'
import { Flex } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import type { FC } from 'react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

type ChartTemperatureProps = {
  title: string
  scalarData?: IMeasurementScalarResult[]
}

const ChartScalar: FC<ChartTemperatureProps> = ({ title, scalarData }) => {
  const { setIsBarsExpressOpen, setIsMarkersExpressOpen, setIsExpressBarStrobeOpen, setIsExpressBarSectionOpen } =
    useActions()
  const { selectedMeasurementId } = useTypedSelector((state) => state.globalReducer)
  const { data: measurement } = useGetMeasurement()
  const { data: threshold, isLoading: isLoadingThreshold } = useGetThresholdQuery(selectedMeasurementId, {
    skip: !selectedMeasurementId
  })
  const [sliderDisabled, setSliderDisabled] = useState(true)
  const [sliderValues, setSliderValues] = useState<number[]>([0, 0])
  const [sliderIndexes, setSliderIndexes] = useState<number[]>([0, 0])
  const [targetUnit, setTargetUnit] = useState<EUnitType>(EUnitType.G)
  const unitMeasurement = measurement ? getUnitMeasurement(measurement) : undefined
  const isCommonLevelDescription = getTypeMeasurement(measurement) === ETypeMeasurement.CommonLevelDescription

  const ticksX = generateGraphTicks(sliderIndexes, 14)
  const ticksY = generateGraphTicks(sliderValues, 8)
  const chartThreshold = useMemo(() => {
    if (threshold && unitMeasurement) {
      const converter = isCommonLevelDescription ? convertBaseAcceleration : convertTemperature
      const chartThresholds = getChartThresholds(threshold, unitMeasurement, targetUnit, sliderValues, converter)
      return chartThresholds
    }
  }, [sliderValues, isCommonLevelDescription, targetUnit, threshold, unitMeasurement])
  const chartData = useMemo(() => {
    if (!scalarData || !unitMeasurement) {
      return undefined
    }

    return getChartData(scalarData, unitMeasurement, targetUnit, isCommonLevelDescription)
  }, [isCommonLevelDescription, scalarData, targetUnit, unitMeasurement])
  const rangeIndexes = useMemo<number[]>(() => (chartData ? [1, chartData.length] : [0, 0]), [chartData])

  const rangeValues = useMemo<number[] | undefined>(
    () => (chartData ? getRangeValues(chartData, sliderIndexes, sliderDisabled) : undefined),
    [chartData, sliderDisabled, sliderIndexes]
  )

  const stepY = getStepSlider(20, rangeValues)

  useEffect(() => {
    setSliderIndexes(rangeIndexes)
  }, [rangeIndexes])

  useEffect(() => {
    if (rangeValues) {
      setSliderValues(rangeValues)
    }
  }, [rangeValues])

  const handleResetValueDoubleClick = () => {
    if (rangeValues) {
      setSliderValues(rangeValues)
    }
  }

  const handleResetIndexesDoubleClick = () => {
    if (rangeIndexes) {
      setSliderIndexes(rangeIndexes)
    }
  }

  const handleSliderDisableCheckboxChange = (evt: CheckboxChangeEvent) => {
    const notChecked = !evt.target.checked
    setSliderDisabled(notChecked)
  }

  if (isLoadingThreshold) {
    return <Spinner />
  }

  if (scalarData?.length === 0) {
    return null
  }

  return (
    <ChartWrapper title={title}>
      <Flex>
        <SliderApp
          min={rangeValues ? rangeValues[0] : 0}
          max={rangeValues ? rangeValues[1] : 0}
          value={sliderValues}
          onChange={setSliderValues}
          range={{ draggableTrack: true }}
          vertical={true}
          step={stepY}
          tooltip={{ formatter: chartFormatValue }}
          onDoubleClick={handleResetValueDoubleClick}
          onCheckboxChange={handleSliderDisableCheckboxChange}
          disabled={sliderDisabled}
        />
        <Flex flex={1} vertical={true} gap={5}>
          <ChartsTools>
            <ChartUnits onSetUnitChange={setTargetUnit} unit={targetUnit} isBaseUnits={isCommonLevelDescription} />
          </ChartsTools>
          <ResponsiveContainer height={400}>
            <ComposedChart data={chartData}>
              <XAxis
                dataKey='index'
                type='number'
                domain={sliderIndexes}
                axisLine={true}
                ticks={ticksX}
                tick={{ fill: 'black' }}
                allowDataOverflow={true}
              />
              <YAxis
                dataKey='value'
                axisLine={true}
                domain={sliderValues}
                allowDataOverflow={true}
                ticks={ticksY}
                tickLine={true}
                tick={{ fill: 'black' }}
              />
              <Line
                isAnimationActive={false}
                dot={{ fill: '#444', stroke: '#444', strokeWidth: 0.5 }}
                dataKey='value'
                stroke='#444444'
                strokeWidth={0}
              />
              <CartesianGrid strokeDasharray='3 3' />
              <ReferenceArea fill={'red'} opacity={0.3} stroke={'black'} />
              {chartThreshold?.map((thresholdItem, index) => (
                <Fragment key={index}>
                  <ReferenceLine y={thresholdItem.hysteresis} stroke={'red'} strokeDasharray='5 5' />
                  <ReferenceArea
                    y1={thresholdItem.lower}
                    y2={thresholdItem.upper}
                    fill={chartThresholdColorZones[thresholdItem.thresholdLevel]}
                    fillOpacity={0.2}
                  />
                  <ReferenceLine y={thresholdItem.lower} stroke={'black'} />
                </Fragment>
              ))}
              <Tooltip content={<TooltipScalar unit={targetUnit} />} isAnimationActive={false} />
            </ComposedChart>
          </ResponsiveContainer>
          <SliderApp
            onDoubleClick={handleResetIndexesDoubleClick}
            min={rangeIndexes[0]}
            max={rangeIndexes[1]}
            value={sliderIndexes}
            onChange={setSliderIndexes}
            range={{ draggableTrack: true }}
          />
        </Flex>
      </Flex>
    </ChartWrapper>
  )
}

export default ChartScalar
