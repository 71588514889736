import type { FormKey } from '@/constants/doc-tooltip.constant'
import json from '@/docs/docs.json'
import type { FormsContentType } from '@/types/doc-tooltip.type'
import { Tooltip } from 'antd'
import cn from 'classnames'
import type { FC } from 'react'

import styles from './doc-tooltip.module.css'

type FormTooltipType = {
  theme?: 'dark' | 'light'
  formKey?: FormKey | string
  simpleText?: string | JSX.Element
  className?: string
}

const DocTooltip: FC<FormTooltipType> = ({ formKey, theme, className, simpleText, ...props }) => {
  const buildContent = (): React.JSX.Element | null => {
    if (!formKey) {
      return null
    }

    const formsContent: FormsContentType = json
    const formContent = formsContent[formKey]

    return (
      <div className={cn(styles['tooltip'])} {...props}>
        <h2>{formContent.header}</h2>
        {formContent.body.paragraphs.map((paragraph, paragraphIndex) => (
          <p key={paragraphIndex}>
            {paragraph.elements.map(({ text, link }, elementIndex) =>
              link ? (
                <a key={elementIndex} href={link} target='_blank' rel='noreferrer'>
                  {`${text}`}
                </a>
              ) : (
                ` ${text} `
              )
            )}
          </p>
        ))}
      </div>
    )
  }

  return (
    <Tooltip title={simpleText || buildContent()} trigger='click'>
      <span
        className={cn(styles['icon-info'], className, 'material-symbols-sharp', {
          [styles['icon-info-light']]: theme === 'light',
          [styles['icon-info-dark']]: theme === 'dark'
        })}
      >
        info
      </span>
    </Tooltip>
  )
}

export default DocTooltip
