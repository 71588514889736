/* eslint-disable no-var */
import { SliceName } from '@/constants/store/slices.constant'
import type { TMeasurementResult } from '@/store/api/measurements.api'
import type { IMeasurementScalarResult } from '@/types/chart/charts.type'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: {
  measurementResults: {
    results: TMeasurementResult[]
    scalarResults: IMeasurementScalarResult[]
    isScalar: boolean
    isLoading: boolean
  }
} = {
  measurementResults: {
    results: [],
    scalarResults: [],
    isScalar: false,
    isLoading: false
  }
}

export const measurementResultsSlice = createSlice({
  name: SliceName.MeasurementResults,
  initialState,
  reducers: {
    addResults(
      state,
      action: PayloadAction<{
        results: TMeasurementResult[]
        isScalar: boolean
      }>
    ) {
      if (action.payload.isScalar) {
        state.measurementResults.scalarResults = action.payload.results
          .map((result) => ({
            value: Number(result.scalarValue?.value),
            time: result.timestamp
          }))
          .reverse()
      } else {
        state.measurementResults.results = action.payload.results
      }

      state.measurementResults.isScalar = action.payload.isScalar
    },

    setIsLoadingMeasurementResults(
      state,
      action: PayloadAction<{
        isLoading: boolean
      }>
    ) {
      state.measurementResults.isLoading = action.payload.isLoading
    }
  }
})
