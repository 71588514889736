import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { TMarker } from '@/store/reducers/charts/express-markers.slice'
import { useEffect, useRef, useState } from 'react'
import type { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'

const useExpressMarkers = () => {
  const { setExpressMarkerOptions } = useActions()
  const { markers } = useTypedSelector((state) => state.expressMarkersReducer)
  const [markerOptions, setMarkerOptions] = useState<TMarker[]>(markers)
  const refIndexMarker = useRef(0)

  const [isAreaDrag, setIsAreaDrag] = useState(false)

  const resetDefaultSettings = () => {
    if (isAreaDrag) {
      setExpressMarkerOptions({
        markerOption: markerOptions[refIndexMarker.current],
        index: refIndexMarker.current
      })
      setIsAreaDrag(false)
    }
  }

  const onMouseMove = (e: CategoricalChartState) => {
    if (isAreaDrag) {
      setMarkerOptions((prev) => {
        if (e?.activePayload !== undefined) {
          const newPrev = [...prev]
          const target = e.activePayload[0]?.payload

          const { frequency, index } = target

          newPrev[refIndexMarker.current] = { freq: frequency, index }

          return [...newPrev]
        }

        return prev
      })
    }
  }

  const onMouseUp = () => {
    resetDefaultSettings()
  }

  const onMouseLeave = () => {
    resetDefaultSettings()
  }

  useEffect(() => {
    setMarkerOptions(markers)
  }, [markers])

  return {
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    setIsAreaDrag,
    setMarkerOptions,
    markerOptions,
    refIndexMarker
  }
}
export default useExpressMarkers
