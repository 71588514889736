import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import Popup from '@/components/UI/elements/popup/popup'
import FormInformation from '@/components/UI/form-controls/form-information/form-information'
import FormInputNumber from '@/components/UI/form-controls/form-input-number/form-input-number'
import FormInputSwitch from '@/components/UI/form-controls/form-input-switch/form-input-switch'
import FormInput from '@/components/UI/form-controls/form-input/form-input'
import FormRadioGroup from '@/components/UI/form-controls/form-radio-group/form-radio-group'
import FormUi from '@/components/UI/form-controls/form-ui/form-ui'
import { BASE_URL_CLOUD } from '@/config/connection.config'
import { ButtonTitle } from '@/constants/core/common.constant'
import { ServerLabel, ServerName, optionsServerType } from '@/constants/settings/settings-form.constant'
import { ServerType } from '@/constants/settings/settings.constant'
import type { FormInstance, RadioChangeEvent } from 'antd'
import type { SwitchChangeEventHandler } from 'antd/es/switch'
import type { FC } from 'react'
import React from 'react'

import styles from './popup-server-settings.module.css'

type PopupServerSettingsProps = {
  serverForm: FormInstance
  isOpen: boolean
  onClosePopup: () => void
  onFinish: () => void
  isFetching: boolean
  onChangeType: (evt: RadioChangeEvent) => void
  serverType: ServerType
}

const PopupServerSettings: FC<PopupServerSettingsProps> = ({
  serverForm,
  isOpen,
  onClosePopup,
  onFinish,
  isFetching,
  onChangeType,
  serverType
}) => {
  const handleChangeSsl: SwitchChangeEventHandler = (checked) => {
    const numberPort = checked ? 443 : 80
    serverForm.setFieldValue(ServerName.Port, numberPort)
  }

  return (
    <Popup isOpen={isOpen} title='Установка нового соединения' onCancel={onClosePopup}>
      <FormUi form={serverForm} onFinish={onFinish}>
        <FormRadioGroup
          options={optionsServerType}
          onChange={onChangeType}
          optionType='button'
          buttonStyle='solid'
          name={ServerName.Type}
          initialValue={serverType}
          className={styles['radio-group']}
        />
        {serverType === ServerType.Local ? (
          <>
            <FormInput labelContent={ServerLabel.ServerName} name={ServerName.ServerName} />
            <FormInputSwitch labelInput={ServerLabel.Ssl} name={ServerName.Ssl} onChange={handleChangeSsl} />
            <div className={styles['inputs-wrapper']}>
              <FormInput
                labelContent={ServerLabel.ServerAddress}
                name={ServerName.ServerAddress}
                layout='vertical'
                placeholder='Введите адрес'
              />
              <FormInputNumber
                labelContent={ServerLabel.Port}
                name={ServerName.Port}
                layout='vertical'
                placeholder='Введите порт'
                required={true}
              />
            </div>
          </>
        ) : null}
        {serverType === ServerType.Cloud ? (
          <FormInformation label='Адрес облачного сервера' value={BASE_URL_CLOUD} layout='vertical' />
        ) : null}
        <ButtonsWrapper>
          <ButtonPrimary
            title={ButtonTitle.Exit}
            htmlType={'button'}
            onClick={onClosePopup}
            isLoadingSkeleton={false}
          />
          <ButtonPrimary
            title={'Установить соединение'}
            htmlType={'submit'}
            isLoadingSkeleton={false}
            isLoading={isFetching}
          />
        </ButtonsWrapper>
      </FormUi>
    </Popup>
  )
}

export default PopupServerSettings
