import { useTypedSelector } from '@/hooks/use-typed-selector'
import classNames from 'classnames'
import type {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  LegacyRef,
  MouseEventHandler,
  PropsWithChildren
} from 'react'

import styles from './chart-wrapper.module.css'

type ChartWrapperProps = {
  title: string
  chartRef?: LegacyRef<HTMLDivElement>
  resultId?: string
  onChartClick?: () => void
  chartButtonRef?: LegacyRef<HTMLButtonElement> | null
} & DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

const ChartWrapper: FC<PropsWithChildren<ChartWrapperProps>> = ({
  title,
  children,
  chartRef,
  resultId,
  onChartClick,
  chartButtonRef,
  ...props
}) => {
  const { selectedResultId } = useTypedSelector(
    (state) => state.chartsUiReducer
  )

  const handleSelectResult: MouseEventHandler<HTMLButtonElement> = () => {
    if (onChartClick) {
      onChartClick()
    }
  }

  return (
    <button
      onClick={handleSelectResult}
      type={'button'}
      className={styles['chart']}
      ref={chartButtonRef}
      {...props}
    >
      <div className={styles['content']}>
        <h2
          className={classNames(styles['chart-header'], {
            [styles['chart-header-active']]: selectedResultId === resultId
          })}
        >
          {title}
        </h2>
      </div>
      <div className={styles['chart-inner-wrapper']} ref={chartRef}>
        {children}
      </div>
    </button>
  )
}

export default ChartWrapper
