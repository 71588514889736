import { useGetAllNodesQuery } from '@/store/api/nodes.api'
import type { IMachineInfo } from '@/types/machine.type'
import cn from 'classnames'
import type { ReactNode } from 'react'
import { Draggable } from 'react-beautiful-dnd'

import styles from '@/components/group-list/components/node-item/note-item.module.css'

import NodeItem from '../node-item/node-item'

interface IDataNode {
  title: ReactNode
  key: string
  selectable?: boolean
  children?: IDataNode[]
}

interface IProps {
  machines: IMachineInfo[]
}

const NodesTreeData = ({ machines }: IProps) => {
  const { data: dataAllNodesState } = useGetAllNodesQuery(undefined, {
    pollingInterval: 3000,
    skipPollingIfUnfocused: true
  })

  const machinesNodes = machines.map((machine, index) => ({
    key: machine.machineId,
    selectable: true,
    title: (
      <Draggable
        key={machine.machineId}
        draggableId={machine.machineId}
        index={index}
      >
        {(draggableProvided, draggableSnapshot) => (
          <div
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            className={cn({
              [styles.dragging]: draggableSnapshot.isDragging
            })}
          >
            <NodeItem dataAllNodesState={dataAllNodesState} machine={machine} />
          </div>
        )}
      </Draggable>
    )
  }))

  const treeData: IDataNode[] = [
    {
      title: (
        <NodeItem
          dataAllNodesState={dataAllNodesState}
          machine={{ name: 'Группа 1', machineId: 'g1', paused: false }}
        />
      ),
      key: 'g1',
      selectable: false,
      children: machinesNodes
    }
  ]

  return treeData
}

export default NodesTreeData
