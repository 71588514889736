import cn from 'classnames'
import type {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  PropsWithChildren
} from 'react'
import React from 'react'

import styles from '@/components/UI/elements/controls-wrapper/controls-wrapper.module.css'

type ConfigurationWrapperType = {
  toolTip?: JSX.Element
  className?: string
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const ControlsWrapper: FC<PropsWithChildren<ConfigurationWrapperType>> = ({
  children,
  toolTip,
  className,
  ...props
}) => (
  <div {...props} className={cn(styles['configuration-form'], className)}>
    <>
      {toolTip && <div className={styles.header}>{toolTip}</div>}
      <div className={styles['inner-wrapper']}>{children}</div>
    </>
  </div>
)

export default ControlsWrapper
