import { ApiResource, ApiTag } from '@/constants/store/api.constants'
import type { IConnection, TCreateConnection } from '@/types/connection.type'

import { baseApi } from './api-base-query'

interface IGetConnectionsResponse {
  content?: IConnection[]
}

export const connectionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getConnections: builder.query<
      IGetConnectionsResponse,
      { measuringPoint: string | undefined; deviceId: string }
    >({
      query: ({ measuringPoint, deviceId }) => ({
        url: `${ApiResource.Connections}?measuringPoint=${measuringPoint}&vp3701Id=${deviceId}`
      }),
      providesTags: [ApiTag.Connections]
    }),

    createConnections: builder.mutation<{ id?: string }, TCreateConnection>({
      query: (data) => ({
        url: ApiResource.Connections,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [ApiTag.Connections]
    }),

    deleteConnections: builder.mutation<null, string>({
      query: (connectionId) => ({
        url: `${ApiResource.Connections}/${connectionId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [ApiTag.Connections]
    })
  })
})

export const {
  useGetConnectionsQuery,
  useDeleteConnectionsMutation,
  useCreateConnectionsMutation
} = connectionsApi
