export const enum DcgParamName {
  Description = 'description',
  TypeSource = 'typeSource',
  FreqCoefForIn = 'freqCoefForIn',
  FreqOutSource = 'freqOutSource',
  Fu = 'fu',
  N2p = 'n2p',
  Nr = 'nr',
  Nc = 'nc'
}

export enum DcgOutName {
  Fu = 'fu',
  Frdcg = 'frdcg',
  Fe = 'fe',
  Fnl = 'fnl',
  Fdcgz = 'fdcgz',
  Fdcgc = 'fdcgc'
}
