import type { SelectType } from '@/types/devices.type'

export enum Size {
  Small = 'Sm',
  Medium = 'Md'
}

export enum Layout {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

// TODO: Необходимо заменить строковые значения на ENUM во всем проекте.
export enum ButtonTitle {
  Submit = 'Добавить',
  Next = 'Далее',
  Cancel = 'Отмена',
  Exit = 'Выйти',
  Edit = 'Редактировать',
  Remove = 'Удалить',
  SetConnection = 'Установить связь',
  RemoveConnection = 'Разорвать связь',
  Save = 'Сохранить',
  Update = 'Обновить',
  Reset = 'Сброс'
}

export enum StatusValue {
  Null = 'Значение отсутствует',
  Online = 'Связь установлена',
  Offline = 'Связь отсутствует',
  NoInformation = 'Нет информации',
  NullNumber = '0'
}

export const DATE_TEMPLATE = 'YYYY-MM-DD, HH:mm:ss'

export enum TypeGeneratorLabel {
  Square = 'Дискретный',
  Harmonic = 'Гармонический'
}

export enum TypeGeneratorValue {
  Square = 'square',
  Harmonic = 'harmonic'
}

export enum TitleButton {
  Add = 'Добавить устройство',
  Edit = 'Настроить устройство',
  Delete = 'Удалить устройство',
  Control = 'Контроль'
}

export enum GeneratorKey {
  GeneratorId = 'generatorId',
  Name = 'name',
  Type = 'type',
  Fs = 'fs',
  TSig = 'tsig',
  NormAmp = 'normAmp',
  SquareBaseFreq = 'squareBaseFreq',
  SquareLowLevel = 'squareLowLevel',
  SquareHighToLow = 'squareHighToLow',
  SquareDuty = 'squareDuty',
  SquarePhase = 'squarePhase',
  NameSignal = 'nameSignal',
  DskSettings = 'dskSettings',
  AmpSet = 'ampSet',
  BaseAmp = 'baseAmp',
  BaseFreq = 'baseFreq',
  Dclevel = 'dclevel',
  LastAmp = 'lastAmp',
  M1_AmpSet = 'm1_AmpSet',
  M1_BaseFreq = 'm1_BaseFreq',
  M1_DeepA = 'm1_DeepA',
  M1_DeepT = 'm1_DeepT',
  M1_LastAmp = 'm1_LastAmp',
  M1_Nharm = 'm1_Nharm',
  M1_PhaseSet = 'm1_PhaseSet',
  M1_Type = 'm1_Type',
  M1_PhaseShift = 'm1_PhaseShift',
  M2_AmpSet = 'm2_AmpSet',
  M2_BaseFreq = 'm2_BaseFreq',
  M2_DeepT = 'm2_DeepT',
  M2_DeepA = 'm2_DeepA',
  M2_LastAmp = 'm2_LastAmp',
  M2_Nharm = 'm2_Nharm',
  M2_PhaseSet = 'm2_PhaseSet',
  M2_PhaseShift = 'm2_PhaseShift',
  M2_Type = 'm2_Type',
  Nharm = 'nharm',
  PhaseSet = 'phaseSet',
  PhaseShift = 'phaseShift',
  WhiteNoise = 'whiteNoise'
}

export const distrAmplitudes: SelectType[] = [
  { label: 'Лин. спад', value: 0 },
  { label: 'Эксп. спад', value: 1 },
  { label: 'Четные х1,5', value: 2 },
  { label: 'Четные х2', value: 3 },
  { label: 'Четные х4', value: 4 }
]

export const phases: SelectType[] = [
  { label: 'Равномерно', value: 0 },
  { label: 'Случайно', value: 1 }
]

export const typeModule: SelectType[] = [
  { label: 'Амплитудная', value: 0 },
  { label: 'Фазовая', value: 1 }
]

export const ampSetModule: SelectType[] = [
  { label: 'Амплитудная', value: 0 },
  { label: 'Фазовая', value: 1 }
]

export const phaseSetModule: SelectType[] = [
  { label: 'Равномерно', value: 0 },
  { label: 'Случайно', value: 1 }
]

export const typeSetModule: SelectType[] = [
  { label: 'Амплитудная', value: 0 },
  { label: 'Фазовая', value: 1 }
]

export const STEP_COUNT_FREQ = 0.01
export const STEP_COUNT_PERCENT = 10
export const STEP_COUNT_ROUND = 5
export const UNIT_GZ = 'Гц'

export const NO_DATA_AVAILABLE = 'Данные отсутствуют'
