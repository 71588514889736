import { ERiftCalcType } from '@/enums/threshold/rift-calc-type.enum'
import { EThresholdLevel } from '@/enums/threshold/threshold-level.enum'

export const ThresholdName = {
  ControlEnabled: 'controlEnabled',
  BaseRiftValue: 'baseRiftValue',
  RiftCalcEnabled: 'riftCalcEnabled',
  RiftCalcType: 'riftCalcType',
  BaseRiftValueAutoCorrectEnabled: 'baseRiftValueAutoCorrectEnabled',
  SkipLimit: 'skipLimit',
  Coef: 'coef',
  HysteresisCoef: 'hysteresisCoef',
  RiftValue: 'riftValue',
  Rifts: 'rifts',
  Zones: 'zones'
} as const

export const ThresholdLabel = {
  ControlEnabled: 'Контроль состояния',
  BaseRiftValue: 'Базовый уровень',
  RiftCalcEnabled: 'Автоматический расчёт',
  RiftCalcType: 'Вид расчёта',
  BaseRiftValueAutoCorrectEnabled: 'Автоматическая корректировка',
  SkipLimit: 'Количество повторений',
  Coef: 'Коэффициент',
  Increment: 'Приращение',
  HysteresisCoef: 'Гистерезис',
  RiftValue: 'Граница'
} as const

export enum LabelRiftCalc {
  Multiplication = 'Коэффициент',
  Increment = 'Приращение'
}

export const riftCalcTypeValues = [
  { value: ERiftCalcType.MULTIPLICATION, label: LabelRiftCalc.Multiplication },
  { value: ERiftCalcType.INCREMENT, label: LabelRiftCalc.Increment }
]

export const riftCalcTypeLabels: { [key in ERiftCalcType]: LabelRiftCalc } = {
  [ERiftCalcType.INCREMENT]: LabelRiftCalc.Increment,
  [ERiftCalcType.MULTIPLICATION]: LabelRiftCalc.Multiplication
}

export const chartThresholdColorZones: Readonly<
  Record<EThresholdLevel, string>
> = {
  [EThresholdLevel.LEVEL_1]: '#19FF00FF',
  [EThresholdLevel.LEVEL_2]: '#8CFF00FF',
  [EThresholdLevel.LEVEL_3]: '#FFFF00FF',
  [EThresholdLevel.LEVEL_4]: '#FF0000FF',
  [EThresholdLevel.NONE]: '#00000019'
} as const

export const initializedRiftZoneData = {
  [ThresholdName.Rifts]: [{ coef: 0, riftValue: 0, hysteresisCoef: 0 }],
  [ThresholdName.Zones]: [
    { dangerousType: EThresholdLevel.NONE },
    { dangerousType: EThresholdLevel.NONE }
  ]
}
