import { ApiResource, ApiTag } from '@/constants/store/api.constants'
import { baseApi } from '@/store/api/api-base-query'
import type { IServerName } from '@/types/settings/settings.type'

export const serverNameApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getServerName: builder.query<IServerName, void>({
      query: () => ({
        url: ApiResource.ServerName,
        method: 'GET'
      }),
      providesTags: [ApiTag.ServerName]
    }),

    updateServerName: builder.mutation<null, IServerName>({
      query: (updatedServerName) => ({
        url: ApiResource.ServerName,
        method: 'PUT',
        body: updatedServerName
      }),
      invalidatesTags: [ApiTag.ServerName]
    })
  })
})

export const { useGetServerNameQuery, useUpdateServerNameMutation } = serverNameApi
