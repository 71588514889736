import type { ModalProps } from 'antd'
import { Modal } from 'antd'
import type { FC } from 'react'

const AppModal: FC<ModalProps> = ({ ...props }) => {
  console.log(`AppModal component is working`)

  return <Modal centered={true} {...props} maskClosable={false} title='Анализ' />
}

export default AppModal
