import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import Popup from '@/components/UI/elements/popup/popup'
import FormInputNumber from '@/components/UI/form-controls/form-input-number/form-input-number'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { type Dispatch, type FC, type SetStateAction, useEffect } from 'react'

interface IExpressBarPopup {
  setIsPopupOpen: Dispatch<SetStateAction<boolean>>
  isPopupOpen: boolean
}

const ExpressBarPopup: FC<IExpressBarPopup> = ({
  setIsPopupOpen,
  isPopupOpen
}) => {
  const { barOptions } = useTypedSelector((state) => state.expressBarsReducer)
  const { setExpressBarOptions } = useActions()
  const [form] = useForm()

  const handleOnFinish = (values: {
    mainFreq: number
    countOfMainBars: number
    modulateFreq: number
    countOfModulateBars: number
  }) => {
    const options = {
      mainFreq: values.mainFreq,
      mainIndex: null,
      modulateFreq: Math.abs(values.modulateFreq),
      modulateIndex: null,
      countOfMainBars: Math.round(values.countOfMainBars),
      countOfModulateBars: Math.abs(Math.round(values.countOfModulateBars))
    }

    setExpressBarOptions(options)

    setIsPopupOpen(false)
  }

  const handleOnReset = () => {
    setIsPopupOpen(false)
  }

  useEffect(() => {
    if (isPopupOpen) {
      form.setFieldsValue(barOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupOpen])

  return (
    <Popup
      onCancel={() => setIsPopupOpen(false)}
      title={'Управление рядами'}
      isOpen={isPopupOpen}
    >
      <Form form={form} onFinish={handleOnFinish} onReset={handleOnReset}>
        <FormInputNumber
          labelContent={'Частота A'}
          minValue={0}
          name={'mainFreq'}
          required
        />
        <FormInputNumber
          labelContent={'Количество гармоник А'}
          minValue={0}
          name={'countOfMainBars'}
          required
        />

        <FormInputNumber labelContent={'Частота m'} name={'modulateFreq'} />
        <FormInputNumber
          labelContent={'Количество гармоник m'}
          name={'countOfModulateBars'}
        />

        <ButtonsWrapper>
          <ButtonPrimary htmlType={'reset'} title={'Отмена'} />
          <ButtonPrimary htmlType={'submit'} title={'Сохранить'} />
        </ButtonsWrapper>
      </Form>
    </Popup>
  )
}
export default ExpressBarPopup
