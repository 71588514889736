import { notification } from 'antd'

/*
export const successNotificationCreate = (message: string) =>
  notification.success({
    message: message,
    top: 1.8,
    duration: 2,
  });

export const errorNotificationCreate = (error: any) =>
  notification.error({
    message: `Код ошибки: ${error?.status}.`,
    description: `Обратитесь в службу поддержки`,
    top: 1.8,
    duration: 2,
  });
*/

notification.config({
  placement: 'topRight',
  top: 12,
  duration: 2
})

export const successNotificationCreate = (message: string) => {
  notification.info({
    message
  })
}

export const errorNotificationCreate = (
  // eslint-disable-next-line
  error: any,
  info?: { message: string; description?: string }
) => {
  if (info) {
    const { message, description } = info

    notification.error({
      message,
      description
    })
    return
  }
  notification.error({
    message: `Код ошибки: ${error?.status || '400'}.`,
    description: error.data
      ? error.data.message
      : error.message || `Обратитесь в службу поддержки`
  })
}

export const infoNotificationCreate = (title: string, message: string) => {
  notification.info({
    message: title,
    description: message,
    placement: 'bottomRight',
    duration: 10
  })
}
