import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import FormInputNumber from '@/components/UI/form-controls/form-input-number/form-input-number'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import Form, { useForm } from 'antd/es/form/Form'
import type { FC, LegacyRef, MutableRefObject } from 'react'
import { useEffect } from 'react'
import { shallowEqual } from 'react-redux'

import styles from '../../express-sidebar.module.css'

import type { TExpressAnalysis } from '../../../../charts'
import StrobeResultItem from './strobe-result-item'

interface IProps {
  expressAnalysisItems: TExpressAnalysis
  itemStrobesRefs: MutableRefObject<LegacyRef<HTMLLIElement>[] | null>
  onClickExpressStrobeButton: (index: number) => void
  durationInSec?: number
  dataLength: number
}

const ExpressStrobe: FC<IProps> = ({
  expressAnalysisItems,
  itemStrobesRefs,
  onClickExpressStrobeButton,
  durationInSec,
  dataLength
}) => {
  const [form] = useForm()
  const { setStrobeData } = useActions()
  const { strobes, selectedResultIndex } = useTypedSelector(
    (state) => ({
      ...state.expressStrobeReducer,
      ...state.chartsUiReducer
    }),
    {
      equalityFn: shallowEqual
    }
  )

  const strobeData = strobes[selectedResultIndex]?.strobeData
  const handleOnFinish = (values: {
    expressFreq: string
    strobeStart: string
    strobePeriod: string
    strobeDuration: string
  }) => {
    setStrobeData({
      index: selectedResultIndex,
      strobeData: {
        start: Number(values.strobeStart),
        duration: Number(values.strobeDuration),
        period: Number(values.strobePeriod)
      }
    })
  }

  const getValidMaxValue = () => {
    const strobePeriod = form.getFieldValue('strobePeriod')
    return strobePeriod - strobePeriod * 0.01
  }

  useEffect(() => {
    form.setFieldsValue({
      expressFreq: (1 / strobeData?.period)?.toPrecision(2),
      strobeStart: strobeData?.start?.toPrecision(3),
      strobePeriod: strobeData?.period?.toPrecision(3),
      strobeDuration: strobeData?.duration?.toPrecision(3)
    })
  }, [dataLength, durationInSec, form, strobeData])

  return (
    <Form onFinish={handleOnFinish} form={form} className={styles['container-express']}>
      <div className={styles['input-container']}>
        <FormInputNumber
          name={'expressFreq'}
          className={styles['input']}
          layout={'vertical'}
          labelContent={'Частота'}
          disabled
        />
        <p>Гц</p>
      </div>

      <div className={styles['input-container']}>
        <FormInputNumber
          className={styles['input']}
          name={'strobeStart'}
          layout={'vertical'}
          labelContent={'Старт'}
          required
          minValue={0}
        />
        <p>сек</p>
      </div>

      <div className={styles['input-container']}>
        <FormInputNumber
          className={styles['input']}
          layout={'vertical'}
          labelContent={'Период'}
          name={'strobePeriod'}
          required
          minValue={0}
        />
        <p>сек</p>
      </div>

      <div className={styles['input-container']}>
        <FormInputNumber
          className={styles['input']}
          layout={'vertical'}
          labelContent={'Длительность'}
          name={'strobeDuration'}
          required
          minValue={0}
          maxValue={getValidMaxValue()}
        />
        <p>сек</p>
      </div>

      <ButtonPrimary className={styles['button-submit']} htmlType={'submit'} title={'Применить'} />

      <ul className={styles.list}>
        {expressAnalysisItems.map((result, i) => (
          <StrobeResultItem
            currentIndex={i}
            onClickExpressStrobeButton={() => onClickExpressStrobeButton(i)}
            refStrobeListItem={itemStrobesRefs?.current && itemStrobesRefs?.current[i]}
            key={result.title + i}
            results={result.results}
            title={result.title}
            strobeData={{
              start: result.strobeOptions.start,
              duration: result.strobeOptions.duration,
              period: result.strobeOptions.period,
              offset: null
            }}
          />
        ))}
      </ul>
    </Form>
  )
}

export default ExpressStrobe
