import type { FC } from 'react'

import styles from './text-lable.module.css'

interface IProps {
  manualValue?: string | JSX.Element
  value?: string
}

const TextLabel: FC<IProps> = ({ manualValue, value }) => (
  <div className={styles['text-label']}>
    {manualValue ? <p>{manualValue}</p> : <p>{value}</p>}
  </div>
)

export default TextLabel
