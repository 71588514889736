import SwitchIndicator from '@/components/UI/elements/switch-indicator/switch-indicator'
import FormInformation from '@/components/UI/form-controls/form-information/form-information'
import FormSelect from '@/components/UI/form-controls/form-select/form-select'
import PanelTitle from '@/components/UI/form-controls/form-title/panel-title'
import {
  ConnectionsLabel,
  ConnectionsValue,
  vibroAccelerations
} from '@/constants/connections/connections-form.constant'
import { temperatures } from '@/constants/measurement/measurements-form.constant'
import { PointLabel } from '@/constants/point/point-form.constant'
import { Vp3701ChannelTypeMap, pointTypes } from '@/constants/point/point.constant'
import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'
import type { IConnection } from '@/types/connection.type'
import type { IVp3701 } from '@/types/device/vp3701.interface'
import type { IGenerator } from '@/types/devices.type'
import { DeviceType } from '@/types/devices.type'
import type { IMeasuringPoint } from '@/types/point/point.type'
import { type FC, useEffect, useState } from 'react'

import styles from './devices-connections.module.css'

type DevicesOptionType = {
  label: string
  value: string
  type: DeviceType
}

type DevicesConnectionsProps = {
  currentPoint?: IMeasuringPoint
  vp3701Devices?: IVp3701[]
  generators?: IGenerator[]
  currentConnection?: IConnection
  isLoadingDate: boolean
}

const DevicesConnections: FC<DevicesConnectionsProps> = ({
  currentPoint,
  vp3701Devices,
  generators,
  currentConnection,
  isLoadingDate
}) => {
  const [isVP3701, setIsVP3701] = useState(false)

  const handleSelectDevice = (value: string) => {
    const foundedVP3701 = vp3701Devices?.find((device) => device.vp3701Id === value)

    if (foundedVP3701) {
      setIsVP3701(true)
    } else {
      setIsVP3701(false)
    }
  }

  const getDevicesOptions = (): DevicesOptionType[] | undefined => {
    if (generators && vp3701Devices) {
      const optionsVp3701 = vp3701Devices.map<DevicesOptionType>((device) => ({
        label: device.name,
        value: device.vp3701Id,
        type: DeviceType.VP3701
      }))
      const optionsGenerators = generators.map<DevicesOptionType>((device) => ({
        label: device.name,
        value: device.generatorId,
        type: DeviceType.GENERATOR
      }))
      return [...optionsVp3701, ...optionsGenerators]
    }
  }

  const devices = getDevicesOptions()

  const currentDevices = devices?.find(
    (device) => device.value === currentConnection?.vp3701Id || device.value === currentConnection?.generatorId
  )
  const labelPointType = pointTypes.find(
    (item) => currentPoint?.physicalQuantityType && item.value === currentPoint.physicalQuantityType
  )

  const renderDeviceComponent = () => {
    if (currentDevices) {
      return <FormInformation label='Установленное устройство' value={currentDevices.label} isLoading={isLoadingDate} />
    }
    return (
      <FormSelect
        labelContent={ConnectionsLabel.Device}
        name={ConnectionsValue.Device}
        onSelect={handleSelectDevice}
        options={devices}
        isLoading={isLoadingDate}
        placeholder='Выберите доступное устройство'
      />
    )
  }

  const getOptionsChannelType = (physicalQuantityType: EPhysicalQuantityType) => {
    switch (physicalQuantityType) {
      case EPhysicalQuantityType.TEMPERATURE:
        return temperatures
      case EPhysicalQuantityType.VIBRO_ACCELERATION:
        return vibroAccelerations
      default:
        return undefined
    }
  }

  const renderChannelTypeComponent = () => {
    if (currentPoint) {
      if (currentConnection && currentConnection?.vp3701ChannelType) {
        return (
          <FormInformation
            label={ConnectionsLabel.Vp3701ChannelTypeLabel}
            value={Vp3701ChannelTypeMap[currentConnection?.vp3701ChannelType]}
            isLoading={isLoadingDate}
          />
        )
      }

      return (
        <FormSelect
          labelContent={ConnectionsLabel.Vp3701ChannelTypeLabel}
          name={ConnectionsValue.Vp3701ChannelTypeLabel}
          required={isVP3701}
          options={getOptionsChannelType(currentPoint.physicalQuantityType)}
          isLoading={isLoadingDate}
          placeholder='Выберите тип канала VP-3701'
        />
      )
    }
    return null
  }

  useEffect(() => {
    if (currentConnection && currentConnection.deviceType === DeviceType.VP3701) {
      setIsVP3701(true)
    } else {
      setIsVP3701(false)
    }
  }, [currentConnection])

  return (
    <>
      <PanelTitle>
        <div className={styles['title-form']}>
          Настройка связи
          <SwitchIndicator
            sourceBoolean={Boolean(currentConnection)}
            trueContent='Подключение установлено'
            falseContent='Подключение не установлено'
          />
        </div>
      </PanelTitle>
      <FormInformation label='Выбранная точка измерения' value={currentPoint?.name} isLoading={isLoadingDate} />
      <FormInformation
        label={PointLabel.PhysicalQuantityType}
        value={labelPointType?.label}
        isLoading={isLoadingDate}
      />
      <>
        {renderDeviceComponent()}
        {isVP3701 ? renderChannelTypeComponent() : null}
      </>
    </>
  )
}

export default DevicesConnections
