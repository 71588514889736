const calculateEx = (strobeValuesData: number[]) => {
  const strobesSum = strobeValuesData.reduce((prev, curr) => prev + curr, 0)

  const middleValue = strobesSum / strobeValuesData.length

  const topValue =
    strobeValuesData
      .map((value) => Math.pow(value - middleValue, 4))
      .reduce((prev, curr) => prev + curr, 0) / strobeValuesData.length

  const bottomValue = Math.pow(
    strobeValuesData
      .map((value) => Math.pow(value - middleValue, 2))
      .reduce((prev, curr) => prev + curr, 0) / strobeValuesData.length,
    2
  )

  return topValue / bottomValue - 3
}

export default calculateEx
