import { EThresholdLevel } from '@/enums/threshold/threshold-level.enum'
import type { INodesState } from '@/types/nodes/nodes.type'
import cn from 'classnames'
import type { FC } from 'react'

import styles from './danger-state-Indicator.module.css'

interface IProps {
  nodeState?: INodesState
}

const DangerStateIndicator: FC<IProps> = ({ nodeState }) => (
  <div
    className={cn(styles['circle-indicator'], {
      [styles['circle-indicator-level1']]:
        nodeState?.currentLevel === EThresholdLevel.LEVEL_1,
      [styles['circle-indicator-level2']]:
        nodeState?.currentLevel === EThresholdLevel.LEVEL_2,
      [styles['circle-indicator-level3']]:
        nodeState?.currentLevel === EThresholdLevel.LEVEL_3,
      [styles['circle-indicator-level4']]:
        nodeState?.currentLevel === EThresholdLevel.LEVEL_4
    })}
  />
)

export default DangerStateIndicator
