import NotImplemented from '@/components/UI/elements/not-implemented/not-implemented'
import Submenu from '@/components/UI/elements/submenu/submenu'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import ToolBar from '@/components/UI/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/UI/layouts/work-space/work-space'
import { machineConditionLinks } from '@/constants/navigation/links.constant'
import type { FC } from 'react'
import React from 'react'

const EmptyComponent: FC = () => (
  <Container>
    <LeftBar />
    <WorkSpace>
      <Submenu links={machineConditionLinks} />
      <NotImplemented />
    </WorkSpace>
    <ToolBar />
  </Container>
)

export default EmptyComponent
