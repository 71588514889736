import { Input, Select, Skeleton, Tooltip } from 'antd'
import type { DefaultOptionType } from 'antd/lib/select'
import type { FC } from 'react'

import styles from '@/components/UI/elements/select-list/select-list.module.css'

type TProps = {
  placeholder: string
  handleChange: (id: string, options: DefaultOptionType) => void
  options: DefaultOptionType[] | undefined
  value?: string
  isDisabled?: boolean
  isLoading?: boolean
  label?: string
  emptyValue?: string
  emptyTooltipTitle?: string
}

const SelectList: FC<TProps> = ({
  placeholder,
  handleChange,
  options,
  value,
  isLoading,
  isDisabled,
  label,
  emptyValue = 'Нет устройств',
  emptyTooltipTitle
}) => {
  const isEmptyList = options?.length === 0

  return (
    <Tooltip title={isEmptyList ? emptyTooltipTitle : null} placement='bottom'>
      <div className={styles.container}>
        {label ? <h2 className={styles.title}>{label}</h2> : null}
        {isLoading ? (
          <Skeleton.Input
            className={styles['selection-input']}
            active={isLoading}
          />
        ) : options?.length ? (
          <Select
            className={styles.select}
            variant={'borderless'}
            value={value || null}
            placeholder={placeholder}
            onSelect={handleChange}
            options={options}
            disabled={isDisabled}
          />
        ) : (
          <Input value={emptyValue} className={styles.input} disabled />
        )}
      </div>
    </Tooltip>
  )
}

export default SelectList
