import cn from 'classnames'
import type {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  PropsWithChildren
} from 'react'

import styles from './chart-tools.module.css'

type ChartsToolsProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

const ChartsTools: FC<PropsWithChildren<ChartsToolsProps>> = ({
  className,
  children,
  ...props
}) => (
  <div className={cn(styles['tools-container'], className)} {...props}>
    {children}
  </div>
)

export default ChartsTools
