import { SliceName } from '@/constants/store/slices.constant'
import type { DeviceType } from '@/types/devices.type'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface ConfigurationState {
  displayMode: string
  addedObject: string
  selectedDevice: deviceType
  borderDate: borderDateType
  modalWindowIsOpen: boolean
}

interface deviceType {
  type: DeviceType | ''
  id: string
  autoUpdate?: boolean
}

export interface borderDateType {
  coef: number
  riftValue: number
  hysteresisCoef: number
}

const initialState: ConfigurationState = {
  displayMode: '',
  addedObject: '',
  selectedDevice: { type: '', id: '' },
  borderDate: {} as borderDateType,
  modalWindowIsOpen: false
}

export const configurationSlice = createSlice({
  name: SliceName.Configuration,
  initialState,
  reducers: {
    setDisplayMode(state, action: PayloadAction<string>) {
      state.displayMode = action.payload
      state.selectedDevice = { ...state.selectedDevice, type: '', id: '' }
      state.addedObject = ''
    },
    setAddedObject(state, action: PayloadAction<string>) {
      state.addedObject = action.payload
      state.selectedDevice = { ...state.selectedDevice, id: '' }
    },

    clearSelectedEquipment(state) {
      state.addedObject = ''
      state.selectedDevice = { id: '', type: '' }
    },
    setBorder(state, action: PayloadAction<borderDateType>) {
      state.borderDate = action.payload
    },
    setModalWindow(state, action: PayloadAction<boolean>) {
      state.modalWindowIsOpen = action.payload
    }
  }
})
