import { Skeleton } from 'antd'
import type { FC } from 'react'
import React from 'react'

import styles from './skeleton-form-item.module.css'

export const SkeletonFormItem: FC = () => (
  <div className={styles['skeleton-item']}>
    <Skeleton.Input active={true} />
    <Skeleton.Input active={true} />
  </div>
)

export default SkeletonFormItem
