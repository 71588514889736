import { SliceName } from '@/constants/store/slices.constant'
import type { IGlobalUiState } from '@/store/reducers/global-states/global.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: IGlobalUiState = {
  selectedMachineId: undefined,
  selectedMeasurementId: undefined,
  selectedPointId: undefined
}

export const globalSlice = createSlice({
  name: SliceName.Global,
  initialState,
  reducers: {
    setSelectedMachineId(state, action: PayloadAction<string | undefined>) {
      state.selectedMachineId = action.payload
      state.selectedPointId = undefined
      state.selectedMeasurementId = undefined
    },
    setSelectedPointId(state, action: PayloadAction<string | undefined>) {
      state.selectedPointId = action.payload
      state.selectedMeasurementId = undefined
    },
    setSelectedMeasurementId(state, action: PayloadAction<string | undefined>) {
      state.selectedMeasurementId = action.payload
    },
    resetSelectedForMachine(state) {
      state.selectedMachineId = undefined
      state.selectedPointId = undefined
      state.selectedMeasurementId = undefined
    },
    resetSelectedIdsForPoint(state) {
      state.selectedPointId = undefined
      state.selectedMeasurementId = undefined
    }
  }
})
