import SelectList from '@/components/UI/elements/select-list/select-list'
import { CancelOption } from '@/constants/equipment/cancel-option.constant'
import { EViewMode } from '@/enums/common/view-mode.enum'
import { EEquipmentContent } from '@/enums/equipments/equipment-view.enum'
import useActions from '@/hooks/use-actions'
import { useGetMeasurementsQuery } from '@/store/api/measurements.api'
import { type FC } from 'react'

interface IProps {
  pointId: string | null
  measurementId: string | null
}

const MeasurementsList: FC<IProps> = ({ pointId, measurementId }) => {
  const { setSelectedMeasurementId, setEquipmentView } = useActions()

  const { data: measurementsData, isLoading } = useGetMeasurementsQuery(pointId, {
    skip: !pointId
  })

  const getPointsOptions = () => {
    if (measurementsData?.content) {
      return [
        {
          label: CancelOption.Label,
          value: CancelOption.Value
        },
        ...measurementsData.content.map((mappedMachine) => ({
          label: mappedMachine?.name,
          value: mappedMachine?.measurementId
        }))
      ]
    }
    return undefined
  }

  const handleChangeMeasurements = (value: string) => {
    if (value === CancelOption.Value) {
      setSelectedMeasurementId(null)
      setEquipmentView({
        content: EEquipmentContent.POINT,
        mode: EViewMode.EDITING
      })
      return
    }

    setSelectedMeasurementId(value)
    setEquipmentView({
      content: EEquipmentContent.MEASUREMENT,
      mode: EViewMode.EDITING
    })
  }

  return (
    <SelectList
      label={'Вид измерения'}
      isLoading={isLoading}
      value={measurementId}
      handleChange={handleChangeMeasurements}
      options={getPointsOptions()}
      placeholder='Выберите вид измерения'
    />
  )
}
export default MeasurementsList
