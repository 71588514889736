import CollapseHarmonic from '@/app/reports/components/collapse-generator/components/collapse-harmonic/collapse-harmonic'
import CollapseSquare from '@/app/reports/components/collapse-generator/components/collapse-square/collapse-square'
import Spinner from '@/components/UI/elements/spinner/spinner'
import { ReportKey } from '@/constants/reports.constant'
import useActions from '@/hooks/use-actions'
import type { IDeviceVP3701, IGenerator } from '@/types/devices.type'
import type { IReport } from '@/types/reports/reports.type'
import { findGenerator } from '@/utils/reports'
import { Checkbox } from 'antd'
import type { FC, MouseEvent } from 'react'

type CollapseHarmonicType = {
  selectedReport: IReport
  dataGenerator?: IGenerator[]
  isLoading: boolean
  checkedData: IGenerator[] | IDeviceVP3701[]
}

const CollapseGenerator: FC<CollapseHarmonicType> = ({
  selectedReport,
  dataGenerator,
  isLoading,
  checkedData
}) => {
  const checkedGenerator = checkedData as IGenerator[]
  const { setCheckedData } = useActions()

  if (isLoading) {
    return <Spinner />
  }

  if (!dataGenerator) {
    return null
  }

  const filteredDataGenerator = dataGenerator.filter(
    (generator) => generator.type === selectedReport.key.toUpperCase()
  )

  const handleClickCheckbox = (evt: MouseEvent<HTMLInputElement>) => {
    evt.stopPropagation()

    const currentElement = findGenerator(
      dataGenerator,
      evt.currentTarget.value
    ) as IGenerator

    const isChecked = checkedGenerator.some((value) => value === currentElement)

    if (isChecked) {
      setCheckedData(
        checkedGenerator.filter((value) => value !== currentElement)
      )
      return
    }

    setCheckedData([...checkedGenerator, currentElement])
  }

  const ExtraCheckbox: FC<{
    idItem: IGenerator['generatorId']
    checked: boolean
  }> = ({ idItem, checked }) => (
    <Checkbox value={idItem} onClick={handleClickCheckbox} checked={checked} />
  )

  const isChecked = (generatorId: IGenerator['generatorId']) =>
    checkedGenerator.some((value) => value.generatorId === generatorId)

  return (
    <>
      {selectedReport.key === ReportKey.Harmonic && (
        <CollapseHarmonic
          dataGenerator={filteredDataGenerator}
          isChecked={isChecked}
          ExtraCheckbox={ExtraCheckbox}
        />
      )}
      {selectedReport.key === ReportKey.Square && (
        <CollapseSquare
          dataGenerator={filteredDataGenerator}
          isChecked={isChecked}
          ExtraCheckbox={ExtraCheckbox}
        />
      )}
    </>
  )
}

export default CollapseGenerator
