import { SliceName } from '@/constants/store/slices.constant'
import type { TStrobeOptions } from '@/types/express-strobe.type'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type TStrobeIndexes = [number | undefined, number | undefined][]

type TStrobe = {
  strobeIndexes: TStrobeIndexes
  title: string
  strobeData: Omit<TStrobeOptions, 'offset'>
}

interface IInitialExpressData {
  strobes: TStrobe[]
  isStrobeExpressBarOpen: boolean
}

const initialState: IInitialExpressData = {
  strobes: [],
  isStrobeExpressBarOpen: false
}

export const expressStrobeSlice = createSlice({
  name: SliceName.ExpressStrobe,
  initialState,
  reducers: {
    setIsExpressBarStrobeOpen(state, action: PayloadAction<boolean>) {
      state.isStrobeExpressBarOpen = action.payload
    },

    setStrobeData(
      state,
      action: PayloadAction<{
        index: number
        strobeData: Partial<TStrobeOptions>
      }>
    ) {
      const { index, strobeData } = action.payload
      state.strobes[index].strobeData = {
        ...state.strobes[index].strobeData,
        ...strobeData
      }
    },

    pushStrobeIndexes(state, action: PayloadAction<TStrobe>) {
      const foundIndex = state.strobes.findIndex((strobe) => strobe.title === action.payload.title)

      if (foundIndex === -1) {
        state.strobes.push(action.payload)
      } else {
        state.strobes[foundIndex] = {
          ...state.strobes[foundIndex],
          ...action.payload
        }
      }
    },

    resetStrobeIndexes(state) {
      state.strobes = []
    }
  }
})
