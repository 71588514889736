import type { CSSProperties, FC, LegacyRef, PropsWithChildren } from 'react'
import React from 'react'

import styles from './form-wrapper.module.css'

type ConfigurationWrapperType = {
  ref?: LegacyRef<HTMLDivElement>
  style?: CSSProperties
  className?: string
}

const FormWrapper: FC<PropsWithChildren<ConfigurationWrapperType>> = ({
  children,
  ref,
  style,
  className = ''
}) => (
  <div
    style={style}
    ref={ref}
    className={`${styles['form-wrapper']} ${className}`}
  >
    {children}
  </div>
)

export default FormWrapper
