import KinematicCondition from '@/components/kinematic-space/components/kinematic-condition/kinematic-condition'
import KinematicConfiguration from '@/components/kinematic-space/components/kinematic-configuration/kinematic-configuration'
import KinematicToolbar from '@/components/kinematic-space/components/kinematic-toolbar/kinematic-toolbar'
import { EKinematicWorkMode } from '@/enums/kinematic/work-mode.enum'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetKinematicElementsQuery } from '@/store/api/kinematic.api'
import type { IKinematicScheme } from '@/types/kinematic.type'
import type { FC } from 'react'
import { useRef } from 'react'

import styles from './kinematic-space.module.css'

type TKinematicMain = {
  kinematicScheme: IKinematicScheme
}

const KinematicSpace: FC<TKinematicMain> = ({ kinematicScheme }) => {
  const { data: kinematicElements } = useGetKinematicElementsQuery(kinematicScheme?.kinematicSchemeId || '', {
    skip: !kinematicScheme?.kinematicSchemeId
  })
  const { selectedKinematic } = useTypedSelector((state) => state.kinematicUiReducer)
  const { workMode } = useTypedSelector((state) => state.kinematicUiReducer)
  const mainRef = useRef<HTMLDivElement>(null)

  const totalFilteredElements = kinematicElements?.totalElements ? kinematicElements.totalElements + 1 : 1

  return (
    <div ref={mainRef} className={styles.main}>
      <KinematicToolbar
        totalFilteredElements={totalFilteredElements.toString()}
        selectedElement={selectedKinematic}
        kinematicSchemeId={kinematicScheme?.kinematicSchemeId}
      />
      {workMode === EKinematicWorkMode.Configuration && (
        <KinematicConfiguration kinematicElements={kinematicElements?.content} kinematicScheme={kinematicScheme} />
      )}
      {workMode === EKinematicWorkMode.Condition && (
        <KinematicCondition kinematicElements={kinematicElements?.content} kinematicScheme={kinematicScheme} />
      )}
    </div>
  )
}

export default KinematicSpace
