import { useGetMnemonicsQuery } from '@/store/api/mnemo.api'

import { useTypedSelector } from '../use-typed-selector'

export const useGetMnemonics = () => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)

  const dataQuery = useGetMnemonicsQuery(selectedMachineId, {
    skip: !selectedMachineId
  })

  const isHaveMnemo = dataQuery.isSuccess
    ? !!dataQuery.data?.content.find(
        (mnemo) => mnemo.nodeId === selectedMachineId
      )
    : false

  return { ...dataQuery, isHaveMnemo }
}
