import {
  unitBaseAccelerationOptions,
  unitOptionsAcceleration,
  unitOptionsByTemperature
} from '@/constants/measurement/unit.constant'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'
import type { IOptions } from '@/types/common/options.interface'
import type { IMeasuringPoint } from '@/types/point/point.type'

export const getUnitTypeOptions = (point?: IMeasuringPoint) => {
  if (point) {
    const unitByPointTypeOptions: {
      [key in EPhysicalQuantityType]?: IOptions<EUnitType>[]
    } = {
      [EPhysicalQuantityType.TEMPERATURE]: unitOptionsByTemperature,
      [EPhysicalQuantityType.VIBRO_ACCELERATION]: unitOptionsAcceleration,
      [EPhysicalQuantityType.VIBRO_VELOCITY]: unitOptionsAcceleration,
      [EPhysicalQuantityType.VIBRO_DISPLACEMENT]: unitOptionsAcceleration,
      [EPhysicalQuantityType.VOLTAGE]: undefined,
      [EPhysicalQuantityType.RPM]: undefined,
      [EPhysicalQuantityType.DRPM]: undefined,
      [EPhysicalQuantityType.AMPERAGE]: undefined,
      [EPhysicalQuantityType.PHASE]: undefined,
      [EPhysicalQuantityType.NULL]: undefined
    }

    return unitByPointTypeOptions[point.physicalQuantityType]
  }
}

export const getUnitVibroBaseOptions = () => unitBaseAccelerationOptions
