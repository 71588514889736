import cn from 'classnames'
import type { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from 'react'
import React from 'react'

import styles from '@/components/UI/form-controls/form-title/form-title.module.css'

type FormTitleProps = {
  className?: string
  size?: 'S' | 'M' | 'L'
} & DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>

const PanelTitle: FC<PropsWithChildren<FormTitleProps>> = ({ children, className, size = 'M', ...props }) => (
  <h4
    className={cn(styles['form-title'], className || '', {
      [styles['form-title-small']]: size === 'S',
      [styles['form-title--medium']]: size === 'M',
      [styles['form-title-large']]: size === 'L'
    })}
    {...props}
  >
    {children}
  </h4>
)

export default PanelTitle
