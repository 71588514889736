import {
  Vp3701ChannelType,
  Vp3701ChannelTypeMap
} from '@/constants/point/point.constant'

export const vibroAccelerations = [
  {
    label: Vp3701ChannelTypeMap[Vp3701ChannelType.VibroAccelerationX],
    value: Vp3701ChannelType.VibroAccelerationX
  },
  {
    label: Vp3701ChannelTypeMap[Vp3701ChannelType.VibroAccelerationY],
    value: Vp3701ChannelType.VibroAccelerationY
  },
  {
    label: Vp3701ChannelTypeMap[Vp3701ChannelType.VibroAccelerationZ],
    value: Vp3701ChannelType.VibroAccelerationZ
  }
]

export enum ConnectionsLabel {
  Device = 'Устройство',
  Vp3701ChannelTypeLabel = 'Тип канала VP-3701'
}

export enum ConnectionsValue {
  Device = 'device',
  Vp3701ChannelTypeLabel = 'vp3701ChannelType'
}
