export const validateMinValue =
  (minValue?: number) => (_: unknown, value: string) => {
    if (minValue !== undefined && value !== null && Number(value) <= minValue) {
      return Promise.reject(
        new Error(`Значение должно быть больше ${minValue}`)
      )
    }

    return Promise.resolve()
  }

export const validateMaxValue =
  (maxValue?: number) => (_: unknown, value: string) => {
    if (maxValue !== undefined && Number(value) >= maxValue) {
      return Promise.reject(
        new Error(`Значение должно быть меньше ${maxValue}`)
      )
    }
    return Promise.resolve()
  }

/**
 * Валидатор для проверки, что значение не совпадает с заданным исключаемым значением.
 */
export const validateExcludedValue =
  <ValueType>({
    excludedValue,
    errorMessage
  }: {
    excludedValue: ValueType
    errorMessage: string
  }) =>
  (_: unknown, value: ValueType) => {
    if (value !== excludedValue) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(errorMessage))
  }
