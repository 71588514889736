import type { TMnemoSchemeItem } from '@/types/mnemonic.type'
import type { INodesState } from '@/types/nodes/nodes.type'
import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Cell, Pie, PieChart } from 'recharts'

import styles from './styles-statistical-indicator.module.css'

interface IZone {
  value: number
  color: string
}

interface IProps {
  element?: TMnemoSchemeItem
  nodeState?: INodesState
}

const DEFAULT_ZONES: IZone[] = [
  { value: 1, color: 'var(--fine-color)' },
  { value: 1, color: 'var(--good-color)' },
  { value: 1, color: 'var(--warning-color)' },
  { value: 1, color: 'var(--dangerous-color)' },
  { value: 1, color: 'var(--undefined-color)' }
]

const StatisticAlIIndicator: FC<IProps> = ({ element, nodeState }) => {
  const [zones, setZones] = useState(DEFAULT_ZONES)

  const isEmpty = useCallback(() => {
    if (nodeState) {
      for (const key in nodeState) {
        if (
          key.startsWith('level') &&
          nodeState[key as keyof typeof nodeState] !== 0
        ) {
          return false
        }
      }
    }

    return true
  }, [nodeState])

  useEffect(() => {
    if (nodeState) {
      const zoneConfigs = [
        { value: nodeState.level1Count, color: 'var(--fine-color)' },
        { value: nodeState.level2Count, color: 'var(--good-color)' },
        { value: nodeState.level3Count, color: 'var(--warning-color)' },
        { value: nodeState.level4Count, color: 'var(--dangerous-color)' },
        {
          value: isEmpty() ? 1 : nodeState.levelNoneCount,
          color: 'var(--undefined-color)'
        }
      ]

      const filteredZones = zoneConfigs.filter((item) => item.value !== 0)
      setZones(filteredZones)
    }
  }, [isEmpty, nodeState])

  const filteredZones = zones.filter((zone) => zone.value !== 0)
  const onlyOneNonZero = filteredZones.length === 1

  return !onlyOneNonZero ? (
    <PieChart className={styles.container} width={30} height={30}>
      <Pie
        stroke={'black'}
        className={styles['pie']}
        strokeWidth={0.5}
        isAnimationActive={false}
        dataKey='value'
        data={zones}
        fill='var(--undefined-color)'
        minAngle={10}
        cursor={element ? '' : 'pointer'}
      >
        {zones.map((zone, index) => (
          <Cell
            width={'100%'}
            height={'100%'}
            className={styles.cell}
            key={`cellStat-${zone.color}${zone.value}${index}`}
            fill={zone.color}
          />
        ))}
      </Pie>
    </PieChart>
  ) : (
    <div
      className={styles['full-pie']}
      style={{ backgroundColor: zones[0].color }}
    />
  )
}

export default StatisticAlIIndicator
