import DevicesTree from '@/app/configuration/components/devices/devices-tree/devices-tree'
import Generator from '@/app/configuration/components/devices/generator/generator'
import DevicesTools from '@/app/configuration/components/devices/generators-tools/devices-tools'
import PopupGenerator from '@/app/configuration/components/devices/popup-generator/popup-generator'
import Vp3701Panel from '@/app/configuration/components/devices/vp3701-panel/vp3701-panel'
import Submenu from '@/components/UI/elements/submenu/submenu'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import ToolBar from '@/components/UI/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/UI/layouts/work-space/work-space'
import { configurationLinks } from '@/constants/navigation/links.constant'
import { EDeviceTreePosition } from '@/enums/device/device-tree-position.enum'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { parsePosition } from '@/utils/tree/parse-position.util'
import type { FC } from 'react'
import React from 'react'

const LEVEL_DEVICE = 3

const Devices: FC = () => {
  const { selectedDevice } = useTypedSelector((state) => state.devicesReducer)
  const collectionPosition = parsePosition(selectedDevice.position)

  // The tree does not have a single root, so the groups start from the second element in the array
  const isVp3701Content = collectionPosition?.[1] === EDeviceTreePosition.Vp3701
  const isGeneratorContent = collectionPosition?.[1] === EDeviceTreePosition.Generator

  const isVp3701PanelContent = isVp3701Content && collectionPosition?.length === LEVEL_DEVICE
  const isGeneratorPanelContent = isGeneratorContent && collectionPosition?.length === LEVEL_DEVICE

  return (
    <Container>
      <ToolBar>
        <DevicesTools isGeneratorContent={isGeneratorContent} isGeneratorPanelContent={isGeneratorPanelContent} />
      </ToolBar>
      <LeftBar>
        <DevicesTree />
      </LeftBar>
      <WorkSpace>
        <Submenu links={configurationLinks} />
        {isVp3701PanelContent && <Vp3701Panel selectedDevice={selectedDevice} />}
        {isGeneratorPanelContent && <Generator selectedDevice={selectedDevice} />}
      </WorkSpace>
      <PopupGenerator />
    </Container>
  )
}

export default Devices
