import type { IAttributesKinematicBlocks } from '@/components/kinematic-space/kinematic-space.type'
import { attributesKinematicBlocks, kbsWithoutIns } from '@/components/kinematic-space/kinematic.constant'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IKinematicElement } from '@/types/kinematic.type'
import type { KonvaEventObject } from 'konva/lib/Node'
import type { FC } from 'react'
import { Image } from 'react-konva'
import type { KonvaNodeEvents } from 'react-konva/ReactKonvaCore'
import useImage from 'use-image'

type TProps = {
  kinematicElement: IKinematicElement
  onDblClick: KonvaNodeEvents['onDblClick']
  onDragMove: (e: KonvaEventObject<DragEvent>) => void
  onDragEnd: (element: IKinematicElement) => void
  onSelect: (evt: KonvaEventObject<MouseEvent>, element: IKinematicElement) => void
}

const ConfigurationBlock: FC<TProps> = ({ onDblClick, onDragEnd, onDragMove, kinematicElement, onSelect }) => {
  const { stageOptions } = useTypedSelector((state) => state.kinematicReducer)
  const { selectedKinematic } = useTypedSelector((state) => state.kinematicUiReducer)

  const handleOnDragEnd = (evt: KonvaEventObject<DragEvent>) => {
    const absPos = evt.target.absolutePosition()

    onDragEnd({
      ...kinematicElement,
      x: absPos.x / stageOptions.scale,
      y: absPos.y / stageOptions.scale
    })
  }

  const getStatusColor = (element: IKinematicElement): string | undefined => {
    const { kinematicBlockType, ins } = element

    const selected = selectedKinematic?.kinematicBlockId === kinematicElement.kinematicBlockId

    if (selected) {
      return 'rgb(68,137,243)'
    }

    return kbsWithoutIns.every((item) => kinematicBlockType !== item) && !ins ? 'red' : undefined
  }

  const currentAttributeKinematicBlock = attributesKinematicBlocks.find(
    (element) => element.type === kinematicElement.kinematicBlockType
  ) as IAttributesKinematicBlocks

  const [image] = useImage(currentAttributeKinematicBlock.iconSrc)

  return (
    <Image
      draggable={true}
      y={kinematicElement.y}
      x={kinematicElement.x}
      width={kinematicElement.width}
      height={kinematicElement.height}
      image={image}
      onDragEnd={handleOnDragEnd}
      onDragMove={onDragMove}
      onDblClick={onDblClick}
      onClick={(evt) => onSelect(evt, kinematicElement)}
      stroke={getStatusColor(kinematicElement)}
      strokeWidth={2}
    />
  )
}

export default ConfigurationBlock
