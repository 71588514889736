import type { FC, PropsWithChildren } from 'react'
import React from 'react'

import styles from '@/components/UI/layouts/tool-bar/tool-bar.module.css'

const ToolBar: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <div className={styles.container}>{children}</div>
)

export default ToolBar
