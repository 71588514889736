import ButtonStatus from '@/components/UI/buttons/button-status/button-status'
import AppModal from '@/components/UI/elements/app-modal/app-modal'
import useActions from '@/hooks/use-actions'
import type { FC } from 'react'
import { useState } from 'react'

const AnalysisModal: FC = () => {
  const [openedModal, setOpenedModal] = useState(false)
  const { setExpressAnalysisActivated } = useActions()
  const handleToggleAnalysisModalClick = () => {
    setOpenedModal(!openedModal)
  }

  return (
    <>
      <ButtonStatus
        icon={<span className='material-symbols-outlined'>biotech</span>}
        onClick={handleToggleAnalysisModalClick}
      >
        Анализ
      </ButtonStatus>
      <AppModal open={openedModal} onCancel={handleToggleAnalysisModalClick} />
    </>
  )
}

export default AnalysisModal
