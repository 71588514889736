export const enum CdParamName {
  Z1 = 'z1',
  Z2 = 'z2',
  Z3 = 'z3'
}

export enum CdTypeOut {
  Frcd = 'frcd',
  Fo2 = 'fo2',
  Fz = 'fz'
}
