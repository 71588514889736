import GeneratorHarmonic from '@/app/configuration/components/devices/generator/generator-harmonic/generator-harmonic'
import GeneratorSquare from '@/app/configuration/components/devices/generator/generator-square/generator-square'
import Spinner from '@/components/UI/elements/spinner/spinner'
import { EGeneratorType } from '@/enums/device/generator/generator-type.enum'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetGeneratorQuery } from '@/store/api/devices.api'
import type { ISelectedDevice } from '@/types/device/selected-device.interface'
import type { FC } from 'react'
import React from 'react'

type TProps = {
  selectedDevice: ISelectedDevice
}

const Generator: FC<TProps> = ({ selectedDevice }) => {
  const { id: generatorId } = selectedDevice
  const { data: generator, isFetching } = useGetGeneratorQuery(generatorId, {
    skip: !generatorId
  })
  const { isEditGenerator } = useTypedSelector((state) => state.devicesReducer)
  const isSquareGenerator = generator?.type === EGeneratorType.SQUARE
  const isHarmonicGenerator = generator?.type === EGeneratorType.HARMONIC

  if (isFetching) {
    return <Spinner />
  }

  if (!generator || !generatorId) {
    return null
  }

  return (
    <>
      {isSquareGenerator ? (
        <GeneratorSquare
          generatorSquare={generator}
          isEditGenerator={isEditGenerator}
          currentGeneratorID={generatorId}
        />
      ) : null}
      {isHarmonicGenerator ? (
        <GeneratorHarmonic
          generatorHarmonic={generator}
          isEditGenerator={isEditGenerator}
          currentGeneratorID={generatorId}
        />
      ) : null}
    </>
  )
}
export default Generator
