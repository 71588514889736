import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasurementQuery } from '@/store/api/measurements.api'

const useGetMeasurement = () => {
  const { selectedMeasurementId } = useTypedSelector(
    (state) => state.globalReducer
  )

  return useGetMeasurementQuery(selectedMeasurementId, {
    skip: !selectedMeasurementId
  })
}
export default useGetMeasurement
