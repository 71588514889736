import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IChartSpectrum } from '@/types/chart/chart-spectrun.type'
import { useCallback, useEffect } from 'react'

import { binarySearch } from '../helpers/calculates.helper'

interface IProps {
  isDb: boolean
  chartData: IChartSpectrum[]
  title: string
}

const useMarkersEffects = ({ isDb, chartData, title }: IProps) => {
  const { setResultsMarker, setInitialExpressMarkerOptions } = useActions()

  const { isMarkersExpressBarOpen, markers } = useTypedSelector(
    (state) => state.expressMarkersReducer
  )

  const getInitialMarkersOptions = useCallback(() => {
    const firstMarkerIndex = Math.round((chartData.length * 10) / 100) - 1
    const secondMarkerIndex = Math.round((chartData.length * 30) / 100) - 1
    const thirdMarkerIndex = Math.round((chartData.length * 50) / 100) - 1

    const firstMarker = chartData[firstMarkerIndex]?.frequency
    const secondMarker = chartData[secondMarkerIndex]?.frequency
    const thirdMarker = chartData[thirdMarkerIndex]?.frequency

    setInitialExpressMarkerOptions([
      { freq: firstMarker, index: firstMarkerIndex },
      { freq: secondMarker, index: secondMarkerIndex },
      { freq: thirdMarker, index: thirdMarkerIndex }
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMarkersExpressBarOpen])

  const calculateExpressMarkersResults = useCallback(() => {
    const results = markers.map((markerValue) => {
      let index = Number(markerValue.index)

      if (index === 0) {
        index = binarySearch(
          chartData,
          +markerValue.freq,
          0,
          chartData.length - 1
        )?.index
      }

      return {
        freq: Number(chartData[index]?.frequency.toPrecision(6)),
        value: Number(Number(chartData[index]?.amplitude).toPrecision(6))
      }
    })

    const firstOptionFreq = results[0]?.freq
    const secondOptionFreq = results[1]?.freq
    const thirdOptionFreq = results[2]?.freq

    const firstOptionValue = results[0]?.value
    const secondOptionValue = results[1]?.value
    const thirdOptionValue = results[2]?.value

    const d12Freq = Number(
      Math.abs(firstOptionFreq - secondOptionFreq).toPrecision(6)
    )

    const d13Freq = Number(
      Math.abs(firstOptionFreq - thirdOptionFreq).toPrecision(6)
    )

    const d23Freq = Number(
      Math.abs(secondOptionFreq - thirdOptionFreq).toPrecision(6)
    )

    const d12Value = Number(
      Math.abs(firstOptionValue - secondOptionValue).toPrecision(6)
    )

    const d13Value = Number(
      Math.abs(firstOptionValue - thirdOptionValue).toPrecision(6)
    )

    const d23Value = Number(
      Math.abs(secondOptionValue - thirdOptionValue).toPrecision(6)
    )

    setResultsMarker({
      results,
      d12Freq,
      d13Freq,
      d23Freq,
      d12Value,
      d13Value,
      d23Value,
      isDb,
      title
    })
  }, [markers, setResultsMarker, isDb, title, chartData])

  useEffect(() => {
    if (isMarkersExpressBarOpen) {
      calculateExpressMarkersResults()
    }
  }, [calculateExpressMarkersResults, isMarkersExpressBarOpen])

  useEffect(() => {
    if (isMarkersExpressBarOpen) {
      getInitialMarkersOptions()
    }
  }, [getInitialMarkersOptions, isMarkersExpressBarOpen])
}
export default useMarkersEffects
