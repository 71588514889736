export const enum ESpecterLineCount {
  COUNT_0_25600_32768 = 'COUNT_0_25600_32768',
  COUNT_1_12800_16384 = 'COUNT_1_12800_16384',
  COUNT_2_6400_8192 = 'COUNT_2_6400_8192',
  COUNT_3_3200_4096 = 'COUNT_3_3200_4096',
  COUNT_4_1600_2048 = 'COUNT_4_1600_2048',
  COUNT_5_800_1024 = 'COUNT_5_800_1024',
  COUNT_6_400_512 = 'COUNT_6_400_512',
  COUNT_7_200_256 = 'COUNT_7_200_256',
  COUNT_8_100_128 = 'COUNT_8_100_128'
}
