const DECIBEL_COEFFICIENT = 20
const REFERENCE = 1e-6
const POWER_BASE = 10

export const calculateDecibel = (value: number): number => DECIBEL_COEFFICIENT * Math.log10(value / REFERENCE)

export const calculateAmplitude = (value: number): number =>
  Math.pow(POWER_BASE, value / DECIBEL_COEFFICIENT) * REFERENCE

/**
 * Рассчитывает логарифмическое значение с использованием переданных параметров.
 *
 * @param value - Значение.
 * @param index - Индекс спектра.
 * @param listMax - Максимальное значение в списке.
 * @returns Результат вычисления.
 */
export const calculateLogarithmic = (value: number, index: number, listMax: number): number => {
  const argument = value * (index + 1)
  const base = listMax / value
  return Math.log10(argument) / Math.log10(base)
}

/**
 * Вычисляет амплитуду гармоники для расчета истинной линии по 3-ём линиям спектра.
 * @param {number[]} amplitudes - Массив амплитуд гармоник.
 * @returns {number} Амплитуда гармоники.
 */
export const calculateAmplitudeHarmonic = (amplitudes: number[]): number => {
  // Вычисляем произведение квадратов амплитуд
  const product = amplitudes.reduce((acc, amplitude) => acc + amplitude ** 2, 0)
  // Возвращаем квадратный корень произведения
  return Math.sqrt(product)
}

/**
 * Вычисляет частоту гармоники для расчета истинной линии по 3-ём линиям спектра.
 * @param {number[]} frequencies - Массив частот гармоник.
 * @param {number[]} amplitudes - Массив амплитуд гармоник.
 * @param {number} amplitudeHarmonic - Амплитуда гармонического сигнала.
 * @returns {number} Частота гармонического сигнала.
 */
export const calculateFrequencyHarmonic = (
  frequencies: number[],
  amplitudes: number[],
  amplitudeHarmonic: number
): number => {
  // Вычисляем числитель формулы
  const numerator = frequencies.reduce((acc, frequency, index) => acc + frequency * amplitudes[index] ** 2, 0)
  // Вычисляем частоту гармонического сигнала
  return numerator / amplitudeHarmonic ** 2
}
