export const enum GbParam {
  Z1 = 'z1',
  Z2 = 'z2'
}

export enum GbOut {
  Frgb = 'frgb',
  Fo2 = 'fo2',
  Fz = 'fz'
}
