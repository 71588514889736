import {
  useGetCurrentMnemonicQuery,
  useGetMnemonicsQuery
} from '@/store/api/mnemo.api'

import { useTypedSelector } from '../use-typed-selector'

export const useGetCurrentMnemoScheme = () => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)

  const { data: mnemonicSchemeData } = useGetMnemonicsQuery(selectedMachineId, {
    skip: !selectedMachineId
  })

  const currentMnemonicData = useGetCurrentMnemonicQuery(
    mnemonicSchemeData?.content[0]?.mnemoSchemeId,
    {
      skip: !mnemonicSchemeData?.content[0]?.mnemoSchemeId
    }
  )

  return { ...currentMnemonicData, mnemonicSchemeData }
}
