import { useGetMnemonicElement } from '@/hooks/api/use-get-mnemonic-element'
import { useChangeMnemonicElementMutation } from '@/store/api/mnemo.api'
import type { TMnemoSchemeItem } from '@/types/mnemonic.type'
import { errorNotificationCreate } from '@/utils/notification-creators'
import type { MenuProps } from 'antd'
import { Button, Dropdown } from 'antd'
import type { FC } from 'react'

import styles from '@/components/mnemonic/indicator-settings/indicator-settings.module.css'

import TableCell from './table-cell'

const colors: MenuProps['items'] = [
  {
    label: (
      <div
        className={`${styles.fill}`}
        style={{ backgroundColor: 'transparent' }}
      />
    ),
    key: 'transparent'
  },
  {
    label: (
      <div className={`${styles.fill}`} style={{ backgroundColor: 'red' }} />
    ),
    key: 'red'
  },
  {
    label: (
      <div className={`${styles.fill}`} style={{ backgroundColor: 'green' }} />
    ),
    key: 'green'
  },
  {
    label: (
      <div className={`${styles.fill}`} style={{ backgroundColor: 'yellow' }} />
    ),
    key: 'yellow'
  },
  {
    label: (
      <div className={`${styles.fill}`} style={{ backgroundColor: 'blue' }} />
    ),
    key: 'blue'
  }
]

const FillItem: FC = () => {
  const { data: selectedMnemoItem } = useGetMnemonicElement()
  const [changeMnemonicElement] = useChangeMnemonicElementMutation()

  const updateMnemonicElement = async (mnemonicElement: TMnemoSchemeItem) => {
    if (mnemonicElement?.mnemoElementId) {
      try {
        const data = await changeMnemonicElement(mnemonicElement).unwrap()

        if (data !== null) throw new Error('Ошибка соединения')
      } catch (error) {
        errorNotificationCreate(error)
      }
    }
  }

  const changeFillColor: MenuProps['onClick'] = ({ key: color }) => {
    if (selectedMnemoItem) {
      updateMnemonicElement({
        ...selectedMnemoItem,
        staticLabelElement: {
          ...selectedMnemoItem?.staticLabelElement,
          fontStyle: {
            ...selectedMnemoItem?.staticLabelElement?.fontStyle,
            backgroundColor: color
          }
        }
      })
    }
  }

  return (
    <TableCell title={'Заливка'}>
      <Dropdown
        trigger={['click']}
        menu={{ items: colors, onClick: changeFillColor }}
        placement='bottomLeft'
      >
        <Button style={{ width: '100%' }}>
          <div
            className={`${styles.fill}`}
            style={{
              backgroundColor:
                selectedMnemoItem?.staticLabelElement?.fontStyle
                  ?.backgroundColor
            }}
          />
        </Button>
      </Dropdown>
    </TableCell>
  )
}

export default FillItem
