import Charts from '@/app/machine-condition/components/results/components/charts/charts'
import ResultsSidebar from '@/app/machine-condition/components/results/components/results-sidebar/results-sidebar'
import ResultsTools from '@/app/machine-condition/components/results/components/results-tools/results-tools'
import Submenu from '@/components/UI/elements/submenu/submenu'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import WorkSpace from '@/components/UI/layouts/work-space/work-space'
import GroupList from '@/components/group-list/group-list'
import { machineConditionLinks } from '@/constants/navigation/links.constant'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'

import styles from './results.module.css'

const Results: FC = () => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)

  const isSelectedMachine = Boolean(selectedMachineId)

  return (
    <>
      <Container>
        <LeftBar>
          <GroupList />
        </LeftBar>
        <WorkSpace>
          <Submenu links={machineConditionLinks} />

          {isSelectedMachine && (
            <>
              <ResultsTools />

              <div className={styles.content}>
                <ResultsSidebar />
                <Charts />
              </div>
            </>
          )}
        </WorkSpace>
      </Container>
    </>
  )
}

export default Results
