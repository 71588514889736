import { useGetMeasuringPointsQuery } from '@/store/api/points.api'

import { useTypedSelector } from '../use-typed-selector'

export const useGetPoints = () => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)

  const pointsQueryData = useGetMeasuringPointsQuery(selectedMachineId, {
    skip: !selectedMachineId
  })

  return pointsQueryData
}
