/* eslint-disable no-var */
import { SliceName } from '@/constants/store/slices.constant'
import { EGeneratorType } from '@/enums/device/generator/generator-type.enum'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface IGeneratorAnalogSignalSettings {
  nameSignal: string
  dclevel: number
  baseFreq: number
  baseAmp: number
  nharm: number
  normAmp?: number
  lastAmp: number
  phaseSet: number
  phaseShift: number
  whiteNoise: number
  ampSet: number
}

interface IGeneratorSquareSignalSettings {
  squareDuty: number
  squarePhase: number
  squareBaseFreq: number
  squareLowLevel: number
  squareHighToLow: number
}

interface IGeneratorGeneralSettings {
  nameGenerator: string
  normAmp: number
  fs: string
  tsig: string
  type: EGeneratorType
}

interface IModuleStates {
  module1State: boolean
  module2State: boolean
}

interface IM1Settings {
  m1_Type: number
  m1_BaseFreq: number
  m1_DeepT: number
  m1_Nharm: number
  m1_AmpSet: number
  m1_LastAmp: number
  m1_PhaseSet: number
  m1_PhaseShift: number
}

interface IM2Settings {
  m2_Type: number
  m2_BaseFreq: number
  m2_DeepT: number
  m2_Nharm: number
  m2_AmpSet: number
  m2_LastAmp: number
  m2_PhaseSet: number
  m2_PhaseShift: number
}

interface IInitialGenerator {
  generatorType: string
  generatorTypeChannel: EGeneratorType
  generatorGeneralSettings: IGeneratorGeneralSettings
  generatorAnalogSignalSettings: IGeneratorAnalogSignalSettings
  generatorSquareSignalSettings: IGeneratorSquareSignalSettings
  modulesState: IModuleStates
  m1Settings: IM1Settings
  m2Settings: IM2Settings
}

const initialState: IInitialGenerator = {
  generatorType: '',
  generatorTypeChannel: EGeneratorType.HARMONIC,
  generatorGeneralSettings: {
    nameGenerator: '',
    fs: '65536',
    normAmp: 0.1,
    tsig: '0.1',
    type: EGeneratorType.SQUARE
  },
  generatorAnalogSignalSettings: {
    nameSignal: '',
    dclevel: 0.0,
    baseFreq: 1.0,
    baseAmp: 1.0,
    nharm: 1,
    lastAmp: 0,
    phaseSet: 0,
    phaseShift: 0,
    whiteNoise: 0,
    ampSet: 0
  },
  generatorSquareSignalSettings: {
    squareBaseFreq: 1.0,
    squareLowLevel: 1.0,
    squareDuty: 1,
    squareHighToLow: 50,
    squarePhase: 0
  },
  m1Settings: {
    m1_Type: 0,
    m1_BaseFreq: 1,
    m1_DeepT: 20,
    m1_Nharm: 1,
    m1_AmpSet: 0,
    m1_LastAmp: 10,
    m1_PhaseSet: 0,
    m1_PhaseShift: 0
  },
  m2Settings: {
    m2_Type: 0,
    m2_BaseFreq: 1,
    m2_DeepT: 20,
    m2_Nharm: 1,
    m2_AmpSet: 0,
    m2_LastAmp: 10,
    m2_PhaseSet: 0,
    m2_PhaseShift: 0
  },
  modulesState: {
    module1State: false,
    module2State: false
  }
}

export const generatorSlice = createSlice({
  name: SliceName.Generator,
  initialState,
  reducers: {
    setGeneratorType(state, action: PayloadAction<{ generatorType: string }>) {
      state.generatorType = action.payload.generatorType
    },

    setGeneratorGeneralSettings(
      state,
      action: PayloadAction<{
        generatorGeneralSettings: IGeneratorGeneralSettings
      }>
    ) {
      state.generatorGeneralSettings = action.payload.generatorGeneralSettings
    },

    setGeneratorTypeChannel(
      state,
      action: PayloadAction<{
        generatorTypeChannel: EGeneratorType
      }>
    ) {
      state.generatorTypeChannel = action.payload.generatorTypeChannel
    },

    setGeneratorAnalogSignalSettings(
      state,
      action: PayloadAction<{
        generatorAnalogSignalSettings: IGeneratorAnalogSignalSettings
      }>
    ) {
      state.generatorAnalogSignalSettings = action.payload.generatorAnalogSignalSettings
    },

    setGeneratorSquareSignalSettings(
      state,
      action: PayloadAction<{
        generatorSquareSignalSettings: IGeneratorSquareSignalSettings
      }>
    ) {
      state.generatorSquareSignalSettings = action.payload.generatorSquareSignalSettings
    },

    setModuleStates(
      state,
      action: PayloadAction<{
        modulesState: IModuleStates
      }>
    ) {
      state.modulesState = action.payload.modulesState
    },

    setM1Settings(
      state,
      action: PayloadAction<{
        m1Settings: IM1Settings
      }>
    ) {
      state.m1Settings = action.payload.m1Settings
    },

    setM2Settings(
      state,
      action: PayloadAction<{
        m2Settings: IM2Settings
      }>
    ) {
      state.m2Settings = action.payload.m2Settings
    }
  }
})
