import type { FC, PropsWithChildren } from 'react'
import React from 'react'

import styles from '@/components/UI/layouts/container/container.module.css'

const Container: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <main className={styles.main}>{children}</main>
)

export default Container
