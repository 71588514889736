import { EDefviceStatus } from '@/enums/device/defvice-staatus.enum'
import cn from 'classnames'
import type { FC } from 'react'

import styles from './status-indicator.module.css'

interface IProps {
  status: EDefviceStatus
}

const StatusIndicator: FC<IProps> = ({ status }) => (
  <div
    className={cn(styles['indicator'], {
      [styles['indicator-online']]: status === EDefviceStatus.ONLINE,
      [styles['indicator-offline']]: status === EDefviceStatus.OFFLINE
    })}
  />
)

export default StatusIndicator
