import type { FC } from 'react'
import React from 'react'

import styles from './vertical-indicator.module.css'

import type { IToolProps } from '../tool.interface'

const VerticalIndicator: FC<IToolProps> = ({ handleDoubleClick }) => (
  <button className={styles.button} onDoubleClick={handleDoubleClick}>
    <div
      style={{ backgroundColor: 'var(--undefined-color)' }}
      className={`${styles.cell} ${styles['cell-vs-value']}`}
    >
      <p>Знач.</p>
    </div>
    <div
      style={{ backgroundColor: 'var(--fine-color)' }}
      className={styles.cell}
    />
    <div
      style={{ backgroundColor: 'var(--good-color)' }}
      className={styles.cell}
    />
    <div
      style={{ backgroundColor: 'var(--warning-color)' }}
      className={styles.cell}
    />
    <div
      style={{ backgroundColor: 'var(--dangerous-color)' }}
      className={styles.cell}
    />
  </button>
)

export default VerticalIndicator
