import type { TooltipProps } from 'antd'
import { Tooltip } from 'antd'
import cn from 'classnames'
import type {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  PropsWithChildren
} from 'react'

import styles from './button-wrapper.module.css'

type ButtonWrapperType = {
  activated?: boolean
}

type TypeProps = PropsWithChildren<
  DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > &
    TooltipProps &
    ButtonWrapperType
>

const ButtonWrapper: FC<TypeProps> = ({
  title,
  placement = 'right',
  children,
  activated,
  disabled,
  ...props
}) => (
  <Tooltip placement={placement} title={title}>
    <div
      className={cn(styles['button-wrapper'], {
        [styles['button-wrapper-activated']]: activated,
        [styles['button-wrapper-disabled']]: disabled
      })}
    >
      <button type={'button'} disabled={disabled} {...props}>
        {children}
      </button>
    </div>
  </Tooltip>
)

export default ButtonWrapper
