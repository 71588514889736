import { ApiResource, ApiTag } from '@/constants/store/api.constants'

import { baseApi } from './api-base-query'

interface IGetImagesResponse {
  content: IUploadImageResponse[]
}

interface IUploadImageResponse {
  id?: string
  createdDate?: string
  name?: string
  gallery?: string
  size?: number
  status?: string
  contentType?: string
  ext?: string
}

export const imagesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getImages: builder.query<IGetImagesResponse, void>({
      query: () => ({ url: ApiResource.Images, method: 'GET' }),
      providesTags: [ApiTag.Images]
    }),

    uploadImage: builder.mutation<IUploadImageResponse, FormData>({
      query: (formData) => ({
        url: `${ApiResource.Images}/upload`,
        method: 'POST',
        body: formData
      }),
      invalidatesTags: [ApiTag.Images]
    })
  })
})

export const { useGetImagesQuery, useUploadImageMutation } = imagesApi
