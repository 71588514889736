import type { FC } from 'react'
import React from 'react'

import styles from '@/components/UI/elements/switch-indicator/switch-indicator.module.css'

type SwitchIndicatorProps = {
  sourceBoolean?: boolean
  trueContent?: string
  falseContent?: string
  content?: string
}

const SwitchIndicator: FC<SwitchIndicatorProps> = ({
  sourceBoolean,
  trueContent,
  falseContent,
  content
}) =>
  sourceBoolean ? (
    <span className={`${styles['text']} ${styles['text-mark-true']}`}>
      {trueContent || content}
    </span>
  ) : (
    <span className={`${styles['text']} ${styles['text-mark-false']}`}>
      {falseContent || content}
    </span>
  )

export default SwitchIndicator
