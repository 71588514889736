import type { FC } from 'react'
import React from 'react'

import styles from './circleIndicator.module.css'

import type { IToolProps } from '../tool.interface'

const CircleIndicator: FC<IToolProps> = ({ handleDoubleClick }) => (
  <button
    type={'button'}
    onDoubleClick={handleDoubleClick}
    className={styles['circle-indicator']}
  />
)

export default CircleIndicator
