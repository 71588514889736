import { ERiftCalcType } from '@/enums/threshold/rift-calc-type.enum'
import type { IRift } from '@/types/threshold/threshold.type'
import { orderBy } from 'lodash'

export const calculateRiftsValues = (
  rifts: IRift[],
  riftCalcType: ERiftCalcType,
  baseRiftValue: number
) => {
  const isIncrement = riftCalcType === ERiftCalcType.INCREMENT

  return orderBy(
    rifts.map((rift) => ({
      ...rift,
      riftValue: isIncrement
        ? rift.coef + baseRiftValue
        : rift.coef * baseRiftValue
    })),
    'riftValue',
    'desc'
  )
}
