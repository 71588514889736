export enum MachineLabel {
  ShortName = 'Краткое наименование машины:',
  FullName = 'Полное наименование машины:',
  Location = 'Расположение машины:',
  Comment = 'Комментарий:',
  OperationalControl = 'Оперативный, сек:',
  CyclicControl = 'Циклический, мин:',
  PeriodicControl = 'Периодический, дней:',
  isActivated = 'Активация машины:',
  RotationSpeedMin = 'Частота вращения Мин, Гц:',
  RotationSpeedMax = 'Частота вращения Макс, Гц:'
}

export enum MachineName {
  ShortName = 'name',
  FullName = 'fullName',
  Location = 'location',
  Comment = 'comment',
  OperationalControl = 'operationalControlIntervalMillis',
  CyclicControl = 'cyclicControlIntervalMillis',
  PeriodicControl = 'periodicControlIntervalMillis',
  isActivated = 'isActivated',
  RotationSpeedMin = 'rotationSpeedMin',
  RotationSpeedMax = 'rotationSpeedMax'
}
