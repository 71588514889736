import { EAmplitudeMode } from '@/enums/charts/chart-value-mode.enum'

export const chartViewModeAmplitudeOptions = [
  { value: EAmplitudeMode.LINER, label: 'Lin' },
  { value: EAmplitudeMode.DB, label: 'дБ' },
  { value: EAmplitudeMode.LOG, label: 'log' }
]

export const chartViewModeFrequencyOptions = [
  { value: EAmplitudeMode.LINER, label: 'Lin' },
  { value: EAmplitudeMode.LOG, label: 'log' }
]
