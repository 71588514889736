import type {
  ISourceError,
  TErrorTable
} from '@/app/configuration/components/devices/vp3701-panel/components/vp3701-erros/vp3701-errors.type'
import { vp3701Errors } from '@/constants/device/vp3701/vp3701-erros.constant'
import { EWeightError } from '@/enums/device/vp3701/weight-error.enum'
import type { TableColumnsType } from 'antd'
import { Table } from 'antd'
import type { FC } from 'react'

import styles from './vp3701-errors.module.css'

const mapWeightColorError = {
  [EWeightError.MINIMUM]: styles['row-minimum'],
  [EWeightError.AVERAGE]: styles['row-average'],
  [EWeightError.CRITICAL]: styles['row-critical']
}

const columns: TableColumnsType<TErrorTable> = [
  {
    title: 'Код ошибки',
    dataIndex: 'name'
  },
  {
    title: 'Описание',
    dataIndex: 'description'
  },
  {
    title: 'Процесс',
    dataIndex: 'process'
  },
  {
    title: 'Количество',
    dataIndex: 'count'
  }
]

type TProps = {
  errors?: ISourceError[]
}

const Vp3701Errors: FC<TProps> = ({ errors }) => {
  const hasErrorsWithoutName = errors?.some((error) => !vp3701Errors[error.name])

  if (hasErrorsWithoutName) {
    return <p>Ошибка при отображении ошибок. Обратитесь к администратору.</p>
  }

  const dataSource =
    errors &&
    errors.map<TErrorTable>((error, index) => {
      const { count, name } = error
      const { weight, process, description } = vp3701Errors[name]

      return {
        key: index,
        name,
        process,
        weight,
        description,
        count
      }
    })

  const getRowClassName = (record: TErrorTable) => mapWeightColorError[record.weight]

  return (
    <Table
      rowClassName={getRowClassName}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      rowHoverable={false}
    />
  )
}

export default Vp3701Errors
