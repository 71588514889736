import type { ButtonProps } from 'antd'
import { Button } from 'antd'
import type { FC, PropsWithChildren } from 'react'

import styles from './panel-button.module.css'

type TProps = PropsWithChildren<ButtonProps>

const PanelButton: FC<TProps> = ({ children, ...props }) => (
  <Button {...props} htmlType='button' type='default' className={styles['panel-button']}>
    {children}
  </Button>
)

export default PanelButton
