import { GroupTree } from '@/app/configuration/components/menu-tree/menu-tree.constant'
import type { IMachineInfo } from '@/types/machine.type'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import type { ISelectedMeasuringPoint } from '@/types/point/point.type'
import type { ITreeNode } from '@/types/tree/tree-node.interface'
import type { FC, Key } from 'react'

export const mapMachinesToTree = (machines: IMachineInfo[], icon: FC): ITreeNode[] =>
  machines.map((machine) => ({
    key: machine.machineId,
    title: machine.name,
    group: GroupTree.Machine,
    icon: icon
  }))

export const mapPointsToTree = (points: ISelectedMeasuringPoint[], icon: FC): ITreeNode[] =>
  points.map((point) => ({
    key: point.pointId,
    title: point.name,
    group: GroupTree.Points,
    icon: icon
  }))

export const mapMeasurementToTree = (measurements: IMeasurement[], icon: FC): ITreeNode[] =>
  measurements.map((measurement) => ({
    key: measurement.measurementId,
    title: measurement.name,
    group: GroupTree.Measurement,
    isLeaf: true,
    icon: icon
  }))

export const findParentKeys = (key: string, nodes: ITreeNode[], parentKey?: Key): Key[] => {
  for (const node of nodes) {
    if (node.key === key) {
      return parentKey ? [parentKey] : []
    }
    if (node.children) {
      const result = findParentKeys(key, node.children, node.key)
      if (result.length > 0) {
        return parentKey ? [parentKey, ...result] : result
      }
    }
  }
  return []
}
