import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import type { IConfigCharts } from '@/types/chart/charts.type'

export const enum ETypeChart {
  SCALAR = 'scalar',
  SPECTRUM = 'spectrum',
  TIMING = 'timing'
}

export const configCharts: IConfigCharts = {
  [ETypeMeasurement.TemperatureDescription]: {
    typeChart: ETypeChart.SCALAR,
    size: 50
  },
  [ETypeMeasurement.CommonLevelDescription]: {
    typeChart: ETypeChart.SCALAR,
    size: 50
  },
  [ETypeMeasurement.ProgramSpecterDescription]: {
    typeChart: ETypeChart.SPECTRUM,
    size: 5
  },
  [ETypeMeasurement.SignalDescription]: {
    typeChart: ETypeChart.TIMING,
    size: 5
  }
}

export const PADDING_CHART = 5
