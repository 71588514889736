import { SliceName } from '@/constants/store/slices.constant'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type TStageOptions = {
  width: number
  height: number
  scale: number
}

const initialState: { stageOptions: TStageOptions } = {
  stageOptions: { width: 800, height: 600, scale: 1 }
}

export const kinematicSlice = createSlice({
  name: SliceName.Kinematic,
  initialState,
  reducers: {
    incrementScale(state) {
      state.stageOptions.scale = state.stageOptions.scale + 0.1
    },

    decrementScale(state) {
      state.stageOptions.scale =
        state.stageOptions.scale >= 0.2
          ? state.stageOptions.scale - 0.1
          : state.stageOptions.scale
    },

    setStageOptions(state, { payload }: PayloadAction<Partial<TStageOptions>>) {
      state.stageOptions = { ...state.stageOptions, ...payload }
    }
  }
})
