import { BdParamName } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bd-form/bd-form.constant'
import {
  BrOutName,
  BrParamName
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/br-form/br-form.constant'
import type {
  IFieldsBr,
  IOuts,
  TFormBr,
  TKinematicBearing
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/br-form/br-form.type'
import { TypeSource } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type { IOutBr } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.type'
import { REAL_NUMBER } from '@/config/connection.config'
import type { IBearing } from '@/types/bearing/bearing.type'
import type { IKinematicElement } from '@/types/kinematic.type'
import { formatFloat, isNumber } from '@/utils/common'

const multiplyValue = (numberOne: number | null, numberTwo: number | null) =>
  isNumber(numberOne) && isNumber(numberTwo) ? numberOne * numberTwo : null

export const getCalculatedResults = (
  fieldsValue: IFieldsBr,
  bearing: TKinematicBearing | null,
  kinematicBlockName?: string
): { outs: IOuts; blockName: string } | null => {
  const { freqOutSource, freqCoefForIn } = fieldsValue
  if (!bearing || !freqOutSource || !kinematicBlockName) {
    return null
  }

  const { value: freqInValue } = freqOutSource
  const { kftf, kftfo, kbsf, kbpfo, kbpfi, bearingName, manufacturerName } =
    bearing

  const match = kinematicBlockName.match(/BR(\d+)/) as RegExpMatchArray
  const blockName = `BR${match[1]}(${bearingName}: ${manufacturerName})`
  const outName = `BR${match[1]}(${bearingName})`

  const outs: IOuts = {
    [BrOutName.Kftf]: {
      freqOutName: `${outName}_FTF`,
      freqOutCoefKb: kftf,
      freqOutCoefMachine: multiplyValue(kftf, freqCoefForIn),
      freqOutValue: multiplyValue(freqInValue, kftf)
    },
    [BrOutName.Kftfo]: {
      freqOutName: `${outName}_FTFo`,
      freqOutCoefKb: kftfo,
      freqOutCoefMachine: multiplyValue(kftfo, freqCoefForIn),
      freqOutValue: multiplyValue(freqInValue, kftfo)
    },
    [BrOutName.Kbsf]: {
      freqOutName: `${outName}_BSF`,
      freqOutCoefKb: kbsf,
      freqOutCoefMachine: multiplyValue(kbsf, freqCoefForIn),
      freqOutValue: multiplyValue(freqInValue, kbsf)
    },
    [BrOutName.Kbpfo]: {
      freqOutName: `${outName}_BPFO`,
      freqOutCoefKb: kbpfo || 0,
      freqOutCoefMachine: multiplyValue(kbpfo, freqCoefForIn),
      freqOutValue: multiplyValue(freqInValue, kbpfo)
    },
    [BrOutName.Kbpfi]: {
      freqOutName: `${outName}_BPFI`,
      freqOutCoefKb: kbpfi || 0,
      freqOutCoefMachine: multiplyValue(kbpfi, freqCoefForIn),
      freqOutValue: multiplyValue(freqInValue, kbpfi)
    }
  }

  Object.keys(outs).forEach((key) => {
    const typedKey = key as BrOutName
    const { freqOutCoefKb, freqOutCoefMachine, freqOutValue } = outs[typedKey]
    outs[typedKey].freqOutCoefKb = isNumber(freqOutCoefKb)
      ? formatFloat(freqOutCoefKb, REAL_NUMBER)
      : null
    outs[typedKey].freqOutCoefMachine = isNumber(freqOutCoefMachine)
      ? formatFloat(freqOutCoefMachine, REAL_NUMBER)
      : null
    outs[typedKey].freqOutValue = isNumber(freqOutValue)
      ? formatFloat(freqOutValue, REAL_NUMBER)
      : null
  })

  return { outs, blockName }
}

export const parseServerData = (kinematicElement: IKinematicElement) => {
  const { description, ins, outs, kinematicBlockBR } = kinematicElement

  const fieldsData: TFormBr = {
    [BrParamName.Description]: description,
    [BrParamName.TypeSource]: TypeSource.Missing,
    [BrParamName.FreqCoefForIn]: ins ? ins[0].freqCoefForIn : 1,
    [BrParamName.ManufacturerValue]: undefined,
    [BrParamName.BearingOptionValue]: undefined
  }

  let outsData: IOuts | null = null
  let bearingData: TKinematicBearing | null = null

  if (kinematicBlockBR) {
    Object.assign(fieldsData, {
      [BrParamName.ManufacturerValue]: kinematicBlockBR.manufacturerName,
      [BrParamName.BearingOptionValue]: kinematicBlockBR.bearingName
    })

    bearingData = kinematicBlockBR
  }

  if (ins) {
    const typeSource = ins[0].kinematicBlockSourseId
      ? TypeSource.Kb
      : TypeSource.Missing

    const freqOutSource = {
      freqOutCoefIndexSourse: ins[0].freqOutCoefIndexSourse,
      freqOutCoefMachineSourse: ins[0].freqOutCoefMachineSourse,
      freqOutName: ins[0].freqInName,
      key: ins[0].freqOutCoefIndexSourse,
      label: `${ins[0].freqInName}: ${ins[0].freqInValue}`,
      value: ins[0].freqInValue
    }

    const insAdapted = {
      [BdParamName.TypeSource]: typeSource,
      [BdParamName.KinematicBlockSourceId]: ins[0].kinematicBlockSourseId,
      [BdParamName.FreqOutSource]: freqOutSource
    }

    Object.assign(fieldsData, insAdapted)
  }

  if (outs) {
    outsData = {
      [BrOutName.Kftf]: {
        freqOutName: outs[0].freqOutName,
        freqOutCoefKb: outs[0].freqOutCoefKb,
        freqOutCoefMachine: outs[0].freqOutCoefMachine,
        freqOutValue: outs[0].freqOutValue
      },
      [BrOutName.Kftfo]: {
        freqOutName: outs[1].freqOutName,
        freqOutCoefKb: outs[1].freqOutCoefKb,
        freqOutCoefMachine: outs[1].freqOutCoefMachine,
        freqOutValue: outs[1].freqOutValue
      },
      [BrOutName.Kbsf]: {
        freqOutName: outs[2].freqOutName,
        freqOutCoefKb: outs[2].freqOutCoefKb,
        freqOutCoefMachine: outs[2].freqOutCoefMachine,
        freqOutValue: outs[2].freqOutValue
      },
      [BrOutName.Kbpfo]: {
        freqOutName: outs[3].freqOutName,
        freqOutCoefKb: outs[3].freqOutCoefKb,
        freqOutCoefMachine: outs[3].freqOutCoefMachine,
        freqOutValue: outs[3].freqOutValue
      },
      [BrOutName.Kbpfi]: {
        freqOutName: outs[4].freqOutName,
        freqOutCoefKb: outs[4].freqOutCoefKb,
        freqOutCoefMachine: outs[4].freqOutCoefMachine,
        freqOutValue: outs[4].freqOutValue
      }
    }
  }

  return { fieldsData, bearingData, outsData }
}

export const parseDataForChangeBr = (
  kinematicBlockName: string,
  kinematicElement: IKinematicElement,
  fieldValues: TFormBr,
  bearing: TKinematicBearing,
  outs: IOuts
): IKinematicElement => {
  const data = {
    ...kinematicElement,
    kinematicBlockName: kinematicBlockName,
    description: fieldValues[BrParamName.Description],
    kinematicBlockBR: bearing
  }

  const { freqOutSource, freqCoefForIn } = fieldValues

  if (freqOutSource) {
    const ins = [
      {
        freqInName: freqOutSource.freqOutNameSourse,
        freqInValue: freqOutSource.value,
        freqOutCoefMachineSourse: freqOutSource.freqOutCoefMachineSourse ?? 1,
        freqInCoefMachine:
          isNumber(freqOutSource.freqOutCoefMachineSourse) && freqCoefForIn
            ? freqCoefForIn * freqOutSource.freqOutCoefMachineSourse
            : null,
        kinematicBlockSourseId: fieldValues[BrParamName.KinematicBlockSourceId],
        freqOutCoefIndexSourse: freqOutSource.freqOutCoefIndexSourse,
        freqOutNameSourse:
          freqCoefForIn === 1 ? freqOutSource.freqOutNameSourse : 'Fin1',
        freqCoefForIn: freqCoefForIn
      }
    ]

    Object.assign(data, { ins })
  }

  const adaptedOuts = Object.values(outs).map(
    ({
      freqOutName,
      freqOutCoefKb,
      freqOutCoefMachine,
      freqOutValue
    }: IOutBr) => ({
      freqOutName,
      freqOutCoefKb,
      freqOutCoefMachine,
      freqOutValue
    })
  )

  return Object.assign(data, { outs: adaptedOuts })
}

export const getAdaptedBearingForKinematic = (
  bearing: IBearing
): TKinematicBearing => ({
  dout: bearing.dout,
  din: bearing.din,
  alfa: bearing.alfa,
  dtk: bearing.dtk,
  n: bearing.n,
  kftf: bearing.kftf,
  kftfo: bearing.kftfo,
  kbsf: bearing.kbsf,
  kbpfo: bearing.kbpfo,
  kbpfi: bearing.kbpfi,
  bearingName: bearing.modelType,
  manufacturerName: bearing.manufacturer
})
