import type { SelectProps } from 'antd'

export const devices: SelectProps['options'] = [
  {
    label: 'VP3701',
    value: 'VP3701'
  },
  {
    label: 'VP3711',
    value: 'VP3711'
  },
  {
    label: 'GENERATOR',
    value: 'GENERATOR'
  }
]

export const planTypes: SelectProps['options'] = [
  {
    label: 'Оперативный',
    value: 'OPERATIONAL'
  },
  {
    label: 'Циклический',
    value: 'CYCLIC'
  },
  {
    label: 'Периодический',
    value: 'PERIODIC'
  }
]
