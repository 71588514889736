import type { ButtonProps } from 'antd'
import { Button } from 'antd'
import type { FC, PropsWithChildren } from 'react'
import React from 'react'

import styles from '@/components/UI/buttons/button-link/button-link.module.css'

type ButtonLinkProps = {
  typeIcon?: string
}

type TypeProps = ButtonLinkProps & ButtonProps

const ButtonLink: FC<PropsWithChildren<TypeProps>> = ({
  children,
  typeIcon,
  ...props
}) => (
  <Button className={styles.button} {...props} type='link'>
    {typeIcon ? (
      <span className={`material-symbols-sharp`}>{typeIcon}</span>
    ) : null}
    {children}
  </Button>
)

export default ButtonLink
