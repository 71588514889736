import ChartTooltip from '@/app/machine-condition/components/results/components/charts/components/tooltip-wrapper/chart-tooltip'
import type { FC } from 'react'
import type { TooltipProps } from 'recharts'
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

type TooltipScalarProps = TooltipProps<ValueType, NameType>

const TooltipTiming: FC<TooltipScalarProps> = ({ active, payload }) => {
  if (active && payload) {
    const currentData = payload[0]?.payload

    return (
      <ChartTooltip>
        <span>Значение: {currentData?.value.toFixed(2)}</span>
        <span>Время: {Number(currentData?.time?.toPrecision(4))} сек.</span>
      </ChartTooltip>
    )
  }

  return null
}

export default TooltipTiming
