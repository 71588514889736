import cn from 'classnames'
import type { ResizeCallback } from 're-resizable'
import { Resizable } from 're-resizable'
import type { FC, PropsWithChildren } from 'react'
import { useState } from 'react'

import styles from './resizable-sidebar.module.css'

const enum Restrictions {
  MinHeight = '20%',
  MaxHeight = '80%'
}

type ResizableSidebarProps = {
  className?: string
}

const ResizableSidebar: FC<PropsWithChildren<ResizableSidebarProps>> = ({
  className = '',
  children
}) => {
  const [height, setHeight] = useState<number | string>('50%')

  const resizeStopHandler: ResizeCallback = (e, direction, ref) => {
    const currentHeight = ref.offsetHeight
    setHeight(currentHeight)
  }

  return (
    <Resizable
      className={cn(className, styles['resizable-container'])}
      enable={{ bottom: true }}
      size={{ height: height, width: 'auto' }}
      onResizeStop={resizeStopHandler}
      handleClasses={{
        bottom: styles['resize-bottom'],
        right: styles['resize-right']
      }}
      minHeight={Restrictions.MinHeight}
      maxHeight={Restrictions.MaxHeight}
    >
      {children}
    </Resizable>
  )
}

export default ResizableSidebar
