import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import Popup from '@/components/UI/elements/popup/popup'
import { ButtonTitle } from '@/constants/core/common.constant'
import type { FC } from 'react'
import React from 'react'

type PopupDeleteProps = {
  title?: string
  onClose: () => void
  onDelete: () => void
  isOpen: boolean
  isLoading: boolean
}

const PopupDelete: FC<PopupDeleteProps> = (props) => {
  const { title = 'Вы действительно хотите удалить объект?', onClose, onDelete, isOpen, isLoading } = props

  return (
    <Popup title={title} isOpen={isOpen} onCancel={onClose}>
      <ButtonsWrapper>
        <ButtonPrimary title={ButtonTitle.Cancel} htmlType={'button'} onClick={onClose} theme='dark' />
        <ButtonPrimary
          title={ButtonTitle.Remove}
          htmlType={'button'}
          onClick={onDelete}
          theme='dark'
          loading={isLoading}
        />
      </ButtonsWrapper>
    </Popup>
  )
}

export default PopupDelete
