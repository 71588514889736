import { KbType } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type { EKinematicBlockType } from '@/types/kinematic.type'

export const mapTypeBr: { [key in EKinematicBlockType]: string } = {
  [KbType.Br]: 'Подшипник качения',
  [KbType.Cp]: 'Муфта',
  [KbType.Sh]: 'Вал',
  [KbType.Bs]: 'Подшипниковая опора скольжения',
  [KbType.Bf]: 'Подшипниковая опора жидкостного трения (ПЖТ)',
  [KbType.Ad]: 'Асинхронный двигатель',
  [KbType.Sd]: 'Синхронный двигатель',
  [KbType.Sg]: 'Синхронный генератор',
  [KbType.Dcm]: 'Двигатель постоянного тока',
  [KbType.Dcg]: 'Генератор постоянного тока',
  [KbType.Gb]: 'Одноступенчатая зубчатая передача',
  [KbType.Gbd]:
    'Двухступенчатая зубчатая передача со сменой направления вращения',
  [KbType.Gbt]: 'Двухступенчатая зубчатая передача с разделением момента',
  [KbType.Tgb]: 'Двухвходовая двухступенчатая зубчатая передача',
  [KbType.Tw]: 'Рабочее колесо турбины / вентилятора',
  [KbType.Cw]: 'Рабочее колесо компрессор / насос',
  [KbType.Tn]: 'Турбина',
  [KbType.Cn]: 'Компрессор',
  [KbType.Bd]: 'Ременная передача',
  [KbType.Cd]: 'Цепная передача',
  [KbType.Hd]: 'Гидропривод',
  [KbType.Pd]: 'Пневмо / газовый привод'
}
