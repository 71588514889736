export const enum EProcessError {
  TS = 'TS',
  T = 'T',
  FREE_RTOS = 'FREE_RTOS',
  FREE_RTOS_T = 'FREE_RTOS_T',
  FREE_RTOS_TS = 'FREE_RTOS_TS',
  FREE_RTOS_WIFI = 'FREE_RTOS_WIFI',
  WIFI = 'WIFI',
  HTTP = 'HTTP'
}
