import cn from 'classnames'
import type { FC } from 'react'

import styles from './menu-tree.module.css'

const MeasurementIcon: FC = () => (
  <span className={cn('material-symbols-outlined', styles['icon'])}>
    nest_thermostat_gen_3
  </span>
)

export default MeasurementIcon
