import { rootReducer } from '@/store/reducers/root-reducer'
import { configureStore } from '@reduxjs/toolkit'

import { baseApi } from './api/api-base-query'

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
      }).concat([baseApi.middleware])
  })

export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
