import FormItemApp from '@/components/UI/form-controls/form-item-mcm/form-item-app'
import { MeasurementLabel, MeasurementName } from '@/constants/measurement/measurements-form.constant'
import { samplingRateOptions } from '@/constants/measurement/measurements.constant'
import { ESamplingRate } from '@/enums/measurment/sampling-rate.enum'
import { Checkbox, InputNumber, Select } from 'antd'
import type { FC } from 'react'

const SignalForm: FC = () => (
  <>
    <FormItemApp
      label={MeasurementLabel.SamplingRate}
      name={MeasurementName.SamplingRate}
      rules={[{ required: true, message: 'Поле обязательно' }]}
      initialValue={ESamplingRate.RATE_13_6400}
    >
      <Select options={samplingRateOptions} />
    </FormItemApp>
    <FormItemApp
      label={MeasurementLabel.DurationInSec}
      name={MeasurementName.DurationInSec}
      rules={[{ required: true, message: 'Поле обязательно' }]}
      initialValue={10}
    >
      <InputNumber />
    </FormItemApp>
    {/* Not implemented on the backend */}
    <FormItemApp label={MeasurementLabel.IirFilterEnabled} name={MeasurementLabel.IirFilterEnabled}>
      <Checkbox disabled={true} />
    </FormItemApp>
  </>
)

export default SignalForm
