import PlansMainContent from '@/app/plans/components/plans-main-content/plans-main-content'
import PlansToolbar from '@/app/plans/components/plans-toolbar/plans-toolbar'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import GroupList from '@/components/group-list/group-list'
import type { FC } from 'react'

const Plans: FC = () => (
  <Container>
    <PlansToolbar />
    <LeftBar>
      <GroupList />
    </LeftBar>
    <PlansMainContent />
  </Container>
)

export default Plans
