import FormItemApp from '@/components/UI/form-controls/form-item-mcm/form-item-app'
import {
  MeasurementLabel,
  MeasurementName
} from '@/constants/measurement/measurements-form.constant'
import {
  freqLimitOptions,
  specterOptions
} from '@/constants/measurement/measurements.constant'
import { ESpecterLineCount } from '@/enums/measurment/specter-line-count.enum'
import { EVisualFreqLimit } from '@/enums/measurment/visual-freq-limit.enum'
import { Select } from 'antd'
import type { FC } from 'react'

const SpecterForm: FC = () => (
  <>
    <FormItemApp
      name={MeasurementName.VisualFreqLimit}
      label={MeasurementLabel.VisualFreqLimit}
      initialValue={EVisualFreqLimit.RATE_5000}
      rules={[{ required: true, message: 'Поле обязательно' }]}
    >
      <Select options={freqLimitOptions} />
    </FormItemApp>

    <FormItemApp
      name={MeasurementName.SpecterLineCount}
      label={MeasurementLabel.SpecterLineCount}
      initialValue={ESpecterLineCount.COUNT_2_6400_8192}
      rules={[{ required: true, message: 'Поле обязательно' }]}
    >
      <Select options={specterOptions} />
    </FormItemApp>
  </>
)

export default SpecterForm
