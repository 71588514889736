import AboutMain from '@/app/about/components/about-main'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import ToolBar from '@/components/UI/layouts/tool-bar/tool-bar'
import type { FC } from 'react'
import React from 'react'

const About: FC = () => (
  <Container>
    <LeftBar />
    <AboutMain />
    <ToolBar />
  </Container>
)

export default About
