import imageActive from '@/assets/images/icons/icon-select-arrow-active.svg'
import imageDisabled from '@/assets/images/icons/icon-select-arrow-inactive.svg'
import type { FC } from 'react'

interface IProps {
  checked: boolean | undefined
}

const SwitcherIconElement: FC<IProps> = ({ checked }) => (
  <img src={checked ? imageDisabled : imageActive} />
)

export default SwitcherIconElement
