import { SliceName } from '@/constants/store/slices.constant'
import type { IExpressAnalysisState } from '@/types/express-analysis/express-analysis'
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: IExpressAnalysisState = {
  expressAnalysisActivated: false
}

export const expressAnalysisSlice = createSlice({
  name: SliceName.ExpressAnalysis,
  initialState,
  reducers: {
    setExpressAnalysisActivated(state, action: PayloadAction<boolean>) {
      state.expressAnalysisActivated = action.payload
    }
  }
})
