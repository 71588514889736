import type { FC } from 'react'
import React from 'react'

export const Control: FC = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 76.35 75.93'>
    <rect
      x='.5'
      y='.5'
      width='75.35'
      height='25.22'
      fill='#f39475'
      stroke='#676766'
      strokeMiterlimit='10'
    />
    <rect
      x='.5'
      y='25.72'
      width='75.35'
      height='25.22'
      fill='#ffe995'
      stroke='#676766'
      strokeMiterlimit='10'
    />
    <rect
      x='.5'
      y='50.21'
      width='75.35'
      height='25.22'
      fill='#79c192'
      stroke='#676766'
      strokeMiterlimit='10'
    />
  </svg>
)

export default Control
