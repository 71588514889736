import type { FC } from 'react'

import styles from '../../chart-spectrum.module.css'

interface IProps {
  skz: number
  am: number
  fm: number
}

const LegendSection: FC<IProps> = ({ skz, am, fm }) => (
  <div className={styles['legend-block']}>
    <p>СКЗ = {skz.toPrecision(6)}</p>
    <p>Ам = {am.toPrecision(6)}</p>
    <p>Fm = {fm.toPrecision(6)}</p>
  </div>
)

export default LegendSection
