import Spinner from '@/components/UI/elements/spinner/spinner'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMachinesQuery } from '@/store/api/machines.api'
import { Tree } from 'antd'
import type { FC, Key } from 'react'
import React from 'react'
import { Droppable } from 'react-beautiful-dnd'

import styles from './group-list.module.css'

import NodesTreeData from './components/nodes-tree-data/nodes-tree-data'

const GroupList: FC = () => {
  const { setSelectedMachineId } = useActions()
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)
  const { data: machinesResponse, isFetching } = useGetMachinesQuery()
  const machines = machinesResponse?.content

  // const { refetch: refetchGetAllNodesQuery } = useGetAllNodesQuery()
  //
  // useEffect(() => {
  //   const intervalRefetch = setInterval(refetchGetAllNodesQuery, 3000)
  //
  //   return () => {
  //     clearInterval(intervalRefetch)
  //   }
  // }, [refetchGetAllNodesQuery])

  if (isFetching) {
    return <Spinner />
  }

  if (!machines) {
    return null
  }

  const handleMachineSelect = (machineId: Key) => {
    if (machineId) {
      setSelectedMachineId(machineId.toString())
    }
  }

  return (
    <Droppable droppableId='machines' direction='vertical'>
      {(droppableProvided, snapshot) => (
        <div
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
          className={styles.container}
        >
          <Tree
            selectedKeys={selectedMachineId ? [selectedMachineId] : undefined}
            onSelect={([key]) => handleMachineSelect(key)}
            showLine={true}
            defaultExpandAll
            className={styles['tree']}
            treeData={NodesTreeData({
              machines: machines
            })}
          />
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default GroupList
