import { ELevel } from '@/enums/node-state/level.enum'
import type { ICommonStatePoints } from '@/hooks/measurement-point/common-state-points.interface'
import type { INodesState } from '@/types/nodes/nodes.type'

export const getReducePointState = (
  pointsIds?: string[],
  dataAllNodesState?: INodesState[]
): ICommonStatePoints | null => {
  if (!pointsIds || !dataAllNodesState) {
    return null
  }

  const filteredMeasuringPoints = pointsIds.map((pointId) =>
    dataAllNodesState.find((item) => item.measuringPointId === pointId)
  )

  return filteredMeasuringPoints.reduce(
    (acc, cur) => {
      if (cur) {
        acc.level4Count += cur.level4Count
        acc.level3Count += cur.level3Count
        acc.level2Count += cur.level2Count
        acc.level1Count += cur.level1Count
      }
      return acc
    },
    {
      [ELevel.Level4Count]: 0,
      [ELevel.Level3Count]: 0,
      [ELevel.Level2Count]: 0,
      [ELevel.Level1Count]: 0
    }
  )
}
