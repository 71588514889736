import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMachineQuery } from '@/store/api/machines.api'

const useGetMachine = () => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)

  return useGetMachineQuery(selectedMachineId, {
    skip: !selectedMachineId
  })
}
export default useGetMachine
