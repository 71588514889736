import FormRadioGroup from '@/components/UI/form-controls/form-radio-group/form-radio-group'
import useFade from '@/hooks/use-fade'
import type { FC } from 'react'
import React from 'react'

const FormItemsType: FC = () => {
  const { ref, style } = useFade()

  return (
    <div ref={ref} style={style}>
      <FormRadioGroup
        name={'generatorType'}
        initialValue='generator'
        options={[
          { label: 'Генератор', value: 'generator' },
          { label: 'WAV - файл', value: 'wav', disabled: true },
          { label: 'DSC - файл', value: 'dsc', disabled: true }
        ]}
      />
    </div>
  )
}

export default FormItemsType
