import type {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  PropsWithChildren
} from 'react'

import styles from './text-info.module.css'

const TextInfo: FC<
  PropsWithChildren<
    DetailedHTMLProps<
      HTMLAttributes<HTMLParagraphElement>,
      HTMLParagraphElement
    >
  >
> = ({ children, ...props }) => (
  <p className={styles['text-info']} {...props}>
    {children}
  </p>
)

export default TextInfo
