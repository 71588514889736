import {
  GbOut,
  GbParam
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/gb-form/gb-form.constant'
import type {
  IFormGb,
  IGbOuts
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/gb-form/gb-form.type'
import {
  KbParam,
  TypeSource
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type { ITableDataOuts } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.type'
import {
  calculateValue,
  handleName,
  handleValue
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.util'
import { REAL_NUMBER } from '@/config/connection.config'
import type { IIn, IKinematicElement, IOut } from '@/types/kinematic.type'
import { formatFloat, isNumber } from '@/utils/common'

export const parseServerData = (kinematicElement: IKinematicElement) => {
  const { description, ins, kinematicBlockGB, outs } = kinematicElement

  interface IResult {
    fields: IFormGb
    ins: IIn[] | null
    outs: IGbOuts | null
  }

  const result: IResult = {
    fields: {
      [KbParam.Description]: description,
      [KbParam.FreqCoefForIn]:
        ins && ins[0].freqCoefForIn ? ins[0].freqCoefForIn : 1,
      [KbParam.TypeSource]: TypeSource.Missing,
      [KbParam.KinematicBlockSourceId]: null,
      [KbParam.FreqOutSource]: null,
      [GbParam.Z1]: null,
      [GbParam.Z2]: null
    },
    ins: null,
    outs: null
  }

  if (kinematicBlockGB) {
    const { z1, z2 } = kinematicBlockGB
    result.fields[GbParam.Z1] = z1
    result.fields[GbParam.Z2] = z2
  }

  if (ins) {
    result.fields[KbParam.TypeSource] = ins[0].kinematicBlockSourseId
      ? TypeSource.Kb
      : TypeSource.Missing
    result.fields[KbParam.KinematicBlockSourceId] =
      ins[0].kinematicBlockSourseId
    result.fields[KbParam.FreqOutSource] = ins[0].freqInValue
    result.ins = ins
  }

  if (outs) {
    result.outs = Object.values(GbOut).reduce((acc, value, index) => {
      acc[value] = {
        freqOutName: outs[index].freqOutName,
        freqOutCoefKb: outs[index].freqOutCoefKb,
        freqOutCoefMachine: outs[index].freqOutCoefMachine,
        freqOutValue: outs[index].freqOutValue
      }

      return acc
    }, {} as Record<GbOut, IOut>)
  }
  return result
}

export const getCalculatedResults = (
  fieldsValue: IFormGb,
  ins: IIn[],
  kinematicBlockName: string
) => {
  const { freqOutSource, freqCoefForIn, z1, z2 } = fieldsValue
  const { freqOutCoefMachineSourse, freqOutNameSourse } = ins[0]

  const freqInValue = calculateValue(freqOutSource, freqCoefForIn)
  const freqInCoefMachine = calculateValue(
    freqOutCoefMachineSourse,
    freqCoefForIn
  )

  const fo2CoefKb = isNumber(z1) && isNumber(z2) ? z1 / z2 : null

  const result: IGbOuts = {
    [GbOut.Frgb]: {
      freqOutName: freqOutNameSourse || null,
      freqOutCoefKb: freqCoefForIn ?? null,
      freqOutCoefMachine: freqInCoefMachine,
      freqOutValue: freqInValue
    },
    [GbOut.Fo2]: {
      freqOutName: kinematicBlockName ? `${kinematicBlockName}_Fo2` : null,
      freqOutCoefKb: fo2CoefKb,
      freqOutCoefMachine: calculateValue(freqInCoefMachine, fo2CoefKb),
      freqOutValue: calculateValue(freqInValue, fo2CoefKb)
    },
    [GbOut.Fz]: {
      freqOutName: kinematicBlockName ? `${kinematicBlockName}_Fz` : null,
      freqOutCoefKb: z1,
      freqOutCoefMachine: calculateValue(freqInCoefMachine, z1),
      freqOutValue: calculateValue(freqInValue, z1)
    }
  }

  Object.keys(result).forEach((key) => {
    const keyType = key as keyof IGbOuts
    const { freqOutValue, freqOutCoefMachine, freqOutCoefKb } = result[keyType]

    result[keyType].freqOutValue = freqOutValue
      ? formatFloat(freqOutValue, REAL_NUMBER)
      : null
    result[keyType].freqOutCoefKb = freqOutCoefKb
      ? formatFloat(freqOutCoefKb, REAL_NUMBER)
      : null
    result[keyType].freqOutCoefMachine = freqOutCoefMachine
      ? formatFloat(freqOutCoefMachine, REAL_NUMBER)
      : null
  })

  return result
}

export const parseDataForChangeGb = (
  kinematicElement: IKinematicElement,
  fields: IFormGb,
  ins: IIn[],
  gbOuts: IGbOuts
): IKinematicElement => {
  const { description, kinematicBlockSourseId, freqCoefForIn, z1, z2 } = fields

  return {
    ...kinematicElement,
    description: description,
    kinematicBlockGB: {
      z1: z1 || 0,
      z2: z2 || 0
    },
    ins: [
      {
        freqInName: ins[0].freqInName,
        freqInValue: ins[0].freqInValue,
        freqOutCoefMachineSourse: ins[0].freqOutCoefMachineSourse,
        freqInCoefMachine:
          isNumber(freqCoefForIn) && isNumber(ins[0].freqOutCoefMachineSourse)
            ? ins[0].freqOutCoefMachineSourse * freqCoefForIn
            : 1,
        kinematicBlockSourseId: kinematicBlockSourseId,
        freqOutCoefIndexSourse: ins[0].freqOutCoefIndexSourse || 0,
        freqOutNameSourse: ins[0].freqOutNameSourse || 'Fin1',
        freqCoefForIn: isNumber(freqCoefForIn) ? freqCoefForIn : 1,
        measurementSourseId: null
      }
    ],
    outs: Object.values(gbOuts)
  }
}

export const formatDataForTable = (outs: IGbOuts): ITableDataOuts[] => {
  const { frgb, fo2, fz } = outs
  const data: Omit<ITableDataOuts, 'key'>[] = [
    {
      label: 'Частота вращения',
      freqOutName: handleName(frgb.freqOutName),
      freqOutCoefKb: handleValue(frgb.freqOutCoefKb),
      freqOutCoefMachine: handleValue(frgb.freqOutCoefMachine),
      freqOutValue: handleValue(frgb.freqOutValue)
    },
    {
      label: 'Частота вращения второго (выходного) вала',
      freqOutName: handleName(fo2.freqOutName),
      freqOutCoefKb: handleValue(fo2.freqOutCoefKb),
      freqOutCoefMachine: handleValue(fo2.freqOutCoefMachine),
      freqOutValue: handleValue(fo2.freqOutValue)
    },
    {
      label: 'Зубцовая частота',
      freqOutName: handleName(fz.freqOutName),
      freqOutCoefKb: handleValue(fz.freqOutCoefKb),
      freqOutCoefMachine: handleValue(fz.freqOutCoefMachine),
      freqOutValue: handleValue(fz.freqOutValue)
    }
  ]

  return data.map<ITableDataOuts>((item, key) => ({ ...item, key }))
}
