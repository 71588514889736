import {
  useGetCurrentKinematicQuery,
  useGetKinematicsQuery
} from '@/store/api/kinematic.api'

export const useGetCurrentKinematicScheme = (machineId?: string) => {
  const { data: kinematicsResponse } = useGetKinematicsQuery(machineId, {
    skip: !machineId
  })

  const kinematicId = kinematicsResponse?.content[0]?.kinematicSchemeId
  const result = useGetCurrentKinematicQuery(kinematicId, {
    skip: !kinematicId
  })

  if (!kinematicId) {
    result.data = undefined
  }
  return result
}
