import SelectList from '@/components/UI/elements/select-list/select-list'
import { CancelOption } from '@/constants/equipment/cancel-option.constant'
import { EViewMode } from '@/enums/common/view-mode.enum'
import { EEquipmentContent } from '@/enums/equipments/equipment-view.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMachinesQuery } from '@/store/api/machines.api'
import { type FC } from 'react'

const MachinesList: FC = () => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)
  const { setSelectedMachineId, setEquipmentView, setSelectedPointId, setSelectedMeasurementId } = useActions()

  const { data: machinesData, isLoading: isLoadingMachines } = useGetMachinesQuery()

  const getMachineOptions = () => {
    if (machinesData?.content) {
      return [
        {
          label: CancelOption.Label,
          value: CancelOption.Value
        },
        ...machinesData.content.map((mappedMachine) => ({
          label: mappedMachine?.name,
          value: mappedMachine?.machineId
        }))
      ]
    }
    return undefined
  }

  const handleChangeMachines = (value: string) => {
    setSelectedPointId(undefined)
    setSelectedMeasurementId(undefined)
    if (value === CancelOption.Value) {
      setSelectedMachineId(undefined)
      return
    }

    setEquipmentView({
      content: EEquipmentContent.MACHINE,
      mode: EViewMode.EDITING
    })
    setSelectedMachineId(value)
  }

  return (
    <SelectList
      label={'Машина'}
      isLoading={isLoadingMachines}
      value={selectedMachineId}
      handleChange={handleChangeMachines}
      options={getMachineOptions()}
      placeholder='Выберите машину'
    />
  )
}
export default MachinesList
