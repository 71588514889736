import type { TStrobeOptions } from '@/types/express-strobe.type'
import type { FC } from 'react'

import styles from '../../express-sidebar.module.css'

interface IProps {
  skz: number
  pk: number
  pf: number
  ex: number
  as: number
  title: string
  strobeData: TStrobeOptions | undefined
}

const StrobeListItem: FC<IProps> = ({
  skz,
  pk,
  pf,
  ex,
  as,
  strobeData,
  title
}) => (
  <li className={styles['list-item']}>
    {strobeData ? (
      <>
        <p>
          <span>Частота</span> ={' '}
          {Number((1 / strobeData?.period).toPrecision(2))} Гц
        </p>
        <p>
          <span>Старт</span> = {strobeData?.start.toPrecision(3)} сек
        </p>
        <p>
          <span>Период</span> = {strobeData?.period.toPrecision(3)} сек
        </p>
        <p>
          <span>Длительность</span> = {strobeData?.duration.toPrecision(3)} сек
        </p>
      </>
    ) : null}
    <h4 className={styles['list-header']}>{title}</h4>
    <p>СКЗ = {skz.toPrecision(3)}</p>
    <p>ПК = {pk.toPrecision(3)}</p>
    <p>ПФ = {pf.toPrecision(3)}</p>
    <p>Ех = {ex.toPrecision(3)}</p>
    <p>Ас = {as.toPrecision(3)}</p>
  </li>
)

export default StrobeListItem
