import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { TBarOptions } from '@/store/reducers/charts/express-bars.slice'
import type { IChartSpectrum } from '@/types/chart/chart-spectrun.type'
import type { Dispatch, MutableRefObject, SetStateAction } from 'react'
import { useCallback, useEffect, useRef } from 'react'
import { shallowEqual } from 'react-redux'

import { binarySearch } from '../helpers/calculates.helper'

const useBarsKeyHandlers = ({
  refIndexBar,
  chartData,
  barOptions,
  resultId,
  setBarOptions
}: {
  refIndexBar: MutableRefObject<number>
  chartData: IChartSpectrum[]
  barOptions: TBarOptions
  resultId: string
  setBarOptions: Dispatch<SetStateAction<TBarOptions>>
}) => {
  const refThrottlingTimer = useRef<null | ReturnType<typeof setTimeout>>(null)

  const { isBarsExpressBarOpen } = useTypedSelector((state) => state.expressBarsReducer, {
    equalityFn: shallowEqual
  })
  const { selectedResultId } = useTypedSelector((state) => state.chartsUiReducer)
  const { setExpressBarOptions } = useActions()

  const handleKey = useCallback(
    (evt: KeyboardEvent) => {
      if (Number(refIndexBar.current) === 0 || selectedResultId !== resultId) return

      if (barOptions.mainIndex === null) {
        barOptions.mainIndex = binarySearch(chartData, barOptions.mainFreq, 0, chartData.length - 1).index
      }

      if (evt.code === 'ArrowUp') {
        evt.preventDefault()

        let step = 1

        if (evt.altKey) {
          if (evt.ctrlKey) {
            step = 10
          }

          const nextModulate = barOptions.modulateFreq + step

          setBarOptions((prev) => ({
            ...prev,
            modulateFreq: nextModulate
          }))

          return
        }

        const currentIndex = binarySearch(
          chartData,
          barOptions.mainFreq * refIndexBar.current,
          0,
          chartData.length - 1
        )?.index

        const [max] = [chartData[currentIndex - step], chartData[currentIndex], chartData[currentIndex + step]].sort(
          (a, b) => b?.amplitude - a?.amplitude
        )

        setBarOptions((prev) => ({
          ...prev,
          mainFreq: max.frequency / refIndexBar.current,
          mainIndex: max.index / refIndexBar.current
        }))
      } else if (evt.code === 'ArrowDown') {
        evt.preventDefault()

        let step = 1

        if (evt.altKey) {
          if (evt.ctrlKey) {
            step = 10
          }

          const nextModulate = barOptions.modulateFreq - step <= 0 ? 0.1 : barOptions.modulateFreq - step

          setBarOptions((prev) => ({
            ...prev,
            modulateFreq: nextModulate
          }))

          return
        }
      } else if (evt.code === 'ArrowLeft') {
        evt.preventDefault()

        let step = 1

        if (evt.ctrlKey && evt.shiftKey) {
          step = 50
        } else if (evt.shiftKey) {
          step = 10
        }

        const currentIndex = binarySearch(
          chartData,
          barOptions.mainFreq * refIndexBar.current,
          0,
          chartData.length - 1
        )?.index

        const nextIndex = currentIndex - step > step ? currentIndex - step : 0

        setBarOptions((prev) => ({
          ...prev,
          mainFreq: chartData[nextIndex]?.frequency / refIndexBar.current,
          mainIndex: Math.round(nextIndex / refIndexBar.current)
        }))
      } else if (evt.code === 'ArrowRight') {
        evt.preventDefault()

        let step = 1

        if (evt.ctrlKey && evt.shiftKey) {
          step = 50
        } else if (evt.shiftKey) {
          step = 10
        }

        const maxBar = chartData.length - 1

        const currentIndex = binarySearch(
          chartData,
          barOptions.mainFreq * refIndexBar.current,
          0,
          chartData.length - 1
        )?.index

        const nextIndex = currentIndex + step < maxBar ? currentIndex + step : maxBar

        setBarOptions((prev) => ({
          ...prev,
          mainFreq: chartData[nextIndex]?.frequency / refIndexBar.current,
          mainIndex: Math.round(nextIndex / refIndexBar.current)
        }))
      }
    },
    [barOptions, chartData, refIndexBar, resultId, selectedResultId, setBarOptions]
  )

  const handleKeyUp = useCallback(
    (evt: KeyboardEvent) => {
      if (selectedResultId !== resultId || refIndexBar.current === null) return

      if (evt.code === 'ArrowRight' || evt.code === 'ArrowLeft' || evt.code === 'ArrowDown' || evt.code === 'ArrowUp') {
        if (refThrottlingTimer.current !== null) {
          clearTimeout(refThrottlingTimer.current)
          refThrottlingTimer.current = null
        }

        refThrottlingTimer.current = setTimeout(() => {
          setExpressBarOptions({ ...barOptions })
        }, 500)
      }
    },
    [barOptions, refIndexBar, resultId, selectedResultId, setExpressBarOptions]
  )

  useEffect(() => {
    if (isBarsExpressBarOpen && selectedResultId === resultId) {
      document.addEventListener('keydown', handleKey)
      document.addEventListener('keyup', handleKeyUp)

      return () => {
        document.removeEventListener('keydown', handleKey)
        document.removeEventListener('keyup', handleKeyUp)
      }
    }
  }, [handleKey, handleKeyUp, isBarsExpressBarOpen, resultId, selectedResultId])
}
export default useBarsKeyHandlers
