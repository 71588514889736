import { DATE_TEMPLATE } from '@/constants/core/common.constant'
import { GeneratorLabel, Vp3701Label } from '@/constants/device/deviceOptions'
import { ErrorData } from '@/constants/errors.constant'
import {
  mapAmpSet,
  mapConnectionStatus,
  mapPhaseSet,
  mapPowerType,
  mapStatus,
  mapType
} from '@/constants/reports.constant'
import type { IDeviceVP3701, IGenerator, THarmonic } from '@/types/devices.type'
import { formatDate } from '@/utils/format-date'
import { generationTable, setSettingsPDF } from '@/utils/reports'
import { createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { jsPDF } from 'jspdf'
import type { RowInput } from 'jspdf-autotable'

const currentDate = formatDate(String(dayjs()), 'YYYY-MM-DD_HH-mm-ss')

export const generationPdfVp3701 = createAsyncThunk(
  'reports/generationPdfVp3701',
  async (checkedData: IGenerator[] | IDeviceVP3701[]) => {
    const vp3701Data = checkedData as IDeviceVP3701[]
    const vp3701Document = new jsPDF()
    setSettingsPDF(vp3701Document)

    vp3701Data.forEach((vp3701Item, index, array) => {
      const title = vp3701Item.name || ErrorData.Null
      vp3701Document.text(title, 105, 20, { align: 'center' })

      const buildHeadVp3701 = [['Параметр', 'Значение']]

      const buildBodyVp3701 = [
        [Vp3701Label.Vp3701Id, vp3701Item.vp3701Id],
        [Vp3701Label.Name, vp3701Item.name],
        [Vp3701Label.SerialNumber, vp3701Item.serialNumber],
        [Vp3701Label.IP, vp3701Item.ip],
        [Vp3701Label.PortSetting, vp3701Item.portSetting],
        [Vp3701Label.PortData, vp3701Item.portData],
        [Vp3701Label.PowerType, mapPowerType[vp3701Item.powerType]],
        [Vp3701Label.Version, vp3701Item.version],
        [Vp3701Label.Status, mapStatus[vp3701Item.status]],
        [Vp3701Label.StatusUpdatingTimestamp, formatDate(vp3701Item.statusUpdatingTimestamp, DATE_TEMPLATE)],
        [Vp3701Label.ConnectionStatus, mapConnectionStatus[vp3701Item.connectionStatus]]
      ]
      generationTable(vp3701Document, buildHeadVp3701, buildBodyVp3701)

      // Условие для устранения конечной пустой страницы
      if (!(array[array.length - 1] === vp3701Item)) {
        vp3701Document.addPage()
      }
    })

    await vp3701Document.save(`VP-3701_${currentDate}.pdf`, {
      returnPromise: true
    })
  }
)

export const generationPdfHarmonic = createAsyncThunk(
  'reports/generationPdfHarmonic',
  async (checkedData: IGenerator[] | IDeviceVP3701[]) => {
    const dataGenerators = checkedData as IGenerator[]
    const generatorDocument = new jsPDF()
    setSettingsPDF(generatorDocument)

    dataGenerators.forEach((generator, _, array) => {
      const title = generator.name || ErrorData.Null
      generatorDocument.text(title, 105, 20, { align: 'center' })
      const harmonics = generator.harmonicCommonSettings?.harmonics[0] as THarmonic

      const tablesData: { head: RowInput[]; body: RowInput[] }[] = [
        {
          head: [
            [
              {
                content: 'Параметры сигнала',
                colSpan: 2,
                styles: {
                  halign: 'center'
                }
              }
            ],
            ['Параметр', 'Значение']
          ],
          body: [
            [GeneratorLabel.Dclevel, harmonics.dclevel],
            [GeneratorLabel.BaseFreq, harmonics.baseFreq],
            [GeneratorLabel.BaseAmp, harmonics.baseAmp],
            [GeneratorLabel.Nharm, harmonics.nharm],
            [GeneratorLabel.AmpSet, mapAmpSet[harmonics.ampSet]],
            [GeneratorLabel.LastAmp, harmonics.lastAmp || '0'],
            [GeneratorLabel.PhaseSet, mapPhaseSet[harmonics.phaseSet]],
            [GeneratorLabel.PhaseShift, harmonics.phaseShift],
            [GeneratorLabel.WhiteNoise, harmonics.whiteNoise]
          ]
        },
        {
          head: [
            [
              {
                content: 'Параметры модулирующего процесса 1',
                colSpan: 2,
                styles: {
                  halign: 'center'
                }
              }
            ],
            ['Параметр', 'Значение']
          ],
          body: [
            [GeneratorLabel.M1_Type, mapType[harmonics.m1_Type]],
            [GeneratorLabel.M1_BaseFreq, harmonics.m1_BaseFreq],
            [GeneratorLabel.M1_DeepA, harmonics.m1_DeepA],
            [GeneratorLabel.M1_DeepT, harmonics.m1_DeepT],
            [GeneratorLabel.M1_Nharm, harmonics.m1_Nharm],
            [GeneratorLabel.M1_AmpSet, mapAmpSet[harmonics.m1_AmpSet]],
            [GeneratorLabel.M1_LastAmp, harmonics.m1_LastAmp],
            [GeneratorLabel.M1_PhaseSet, mapPhaseSet[harmonics.m1_PhaseSet]],
            [GeneratorLabel.M1_PhaseShift, harmonics.m1_PhaseShift]
          ]
        },
        {
          head: [
            [
              {
                content: 'Параметры модулирующего процесса 2',
                colSpan: 2,
                styles: {
                  halign: 'center'
                }
              }
            ],
            ['Параметр', 'Значение']
          ],
          body: [
            [GeneratorLabel.M2_Type, mapType[harmonics.m2_Type]],
            [GeneratorLabel.M2_BaseFreq, harmonics.m2_BaseFreq],
            [GeneratorLabel.M2_DeepA, harmonics.m2_DeepA],
            [GeneratorLabel.M2_DeepT, harmonics.m2_DeepT],
            [GeneratorLabel.M2_Nharm, harmonics.m2_Nharm],
            [GeneratorLabel.M2_AmpSet, mapAmpSet[harmonics.m2_AmpSet]],
            [GeneratorLabel.M2_LastAmp, harmonics.m1_LastAmp],
            [GeneratorLabel.M2_PhaseSet, mapPhaseSet[harmonics.m2_PhaseSet]],
            [GeneratorLabel.M2_PhaseShift, harmonics.m2_PhaseShift]
          ]
        }
      ]

      tablesData.forEach((dataItem) => {
        generationTable(generatorDocument, dataItem.head, dataItem.body)
      })

      // Условие для устранения конечной пустой страницы
      if (!(array[array.length - 1] === generator)) {
        generatorDocument.addPage()
      }
    })
    await generatorDocument.save(`Гармонический_${currentDate}.pdf`, {
      returnPromise: true
    })
  }
)

export const generationPdfSquare = createAsyncThunk(
  'reports/generationPdfSquare',
  async (checkedData: IGenerator[] | IDeviceVP3701[]) => {
    const dataGenerators = checkedData as IGenerator[]
    const generatorDocument = new jsPDF()
    setSettingsPDF(generatorDocument)

    dataGenerators.forEach((generator, _, array) => {
      const title = generator.name || ErrorData.Null
      generatorDocument.text(title, 105, 20, { align: 'center' })
      const square = generator.squareSettings

      const tablesData: { head: RowInput[]; body: RowInput[] }[] = [
        {
          head: [
            [
              {
                content: 'Параметры сигнала',
                colSpan: 2,
                styles: {
                  halign: 'center'
                }
              }
            ],
            ['Параметр', 'Значение']
          ],
          body: [
            [GeneratorLabel.BaseFreq, square?.squareBaseFreq || 0],
            [GeneratorLabel.SquareLowLevel, square?.squareLowLevel || 0],
            [GeneratorLabel.SquareDuty, square?.squareDuty || 0],
            [GeneratorLabel.SquareHighToLow, square?.squareHighToLow || 0],
            [GeneratorLabel.SquarePhase, square?.squarePhase || 0]
          ]
        }
      ]

      tablesData.forEach((dataItem) => {
        generationTable(generatorDocument, dataItem.head, dataItem.body)
      })

      // Условие для устранения конечной пустой страницы
      if (!(array[array.length - 1] === generator)) {
        generatorDocument.addPage()
      }
    })

    await generatorDocument.save(`Дискретный_${currentDate}.pdf`, {
      returnPromise: true
    })
  }
)
