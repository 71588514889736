import type { IKinematicElement } from '@/types/kinematic.type'
import { maxBy } from 'lodash'

export const getMaxWidthScheme = (
  kinematicElements: IKinematicElement[],
  currentElement: IKinematicElement
) => {
  const currentElementWidth = currentElement.x
  const { x: maxWidth } = maxBy(
    kinematicElements,
    (obj) => obj.x
  ) as IKinematicElement

  return currentElementWidth > maxWidth ? currentElementWidth : maxWidth
}

export const getMaxHeightScheme = (
  kinematicElements: IKinematicElement[],
  currentElement: IKinematicElement
) => {
  const currentElementHeight = currentElement.y
  const { y: maxHeight } = maxBy(
    kinematicElements,
    (obj) => obj.y
  ) as IKinematicElement

  return currentElementHeight > maxHeight ? currentElementHeight : maxHeight
}
