import DevicesConnections from '@/app/configuration/components/connections/devices-connections/devices-connections'
import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/UI/elements/controls-wrapper/controls-wrapper'
import HeaderConfiguration from '@/components/UI/elements/header-work-space/header-configuration'
import Spinner from '@/components/UI/elements/spinner/spinner'
import FormUi from '@/components/UI/form-controls/form-ui/form-ui'
import DocTooltip from '@/components/doc-tooltip/doc-tooltip'
import { ButtonTitle } from '@/constants/core/common.constant'
import { FormKey } from '@/constants/doc-tooltip.constant'
import type { Vp3701ChannelType } from '@/constants/point/point.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import {
  useCreateConnectionsMutation,
  useDeleteConnectionsMutation,
  useGetConnectionsQuery
} from '@/store/api/connections.api'
import { useGetGeneratorsQuery, useGetVp3701ListQuery } from '@/store/api/devices.api'
import { useGetMachineQuery } from '@/store/api/machines.api'
import { useGetMeasuringPointQuery } from '@/store/api/points.api'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { useForm } from 'antd/lib/form/Form'
import type { FC } from 'react'
import { useEffect } from 'react'

import styles from './params-connections.module.css'

export const ParamsConnections: FC = () => {
  const [formConnection] = useForm<{
    device: string
    vp3701ChannelType?: Vp3701ChannelType
  }>()
  const { setSelectedPointId } = useActions()
  const { selectedPointId, selectedMachineId } = useTypedSelector((state) => state.globalReducer)

  const { data: currentMachine, isLoading: isLoadingMachine } = useGetMachineQuery(selectedMachineId, {
    skip: !selectedMachineId
  })
  const { data: currentPoint, isLoading: isLoadingPoint } = useGetMeasuringPointQuery(selectedPointId, {
    skip: !selectedPointId
  })

  const { data: vp3701Devices, isLoading: isLoadingVp3701 } = useGetVp3701ListQuery()
  const { data: generators, isLoading: isLoadingGenerator } = useGetGeneratorsQuery()
  const {
    data: connectionData,
    isLoading: isLoadingConnection,
    isFetching: isFetchingConnection,
    refetch: refetchConnections
  } = useGetConnectionsQuery(
    {
      measuringPoint: selectedPointId,
      deviceId: ''
    },
    { skip: !selectedPointId }
  )
  const currentConnection = connectionData?.content?.find(
    (connection) => connection.measuringPointId === selectedPointId
  )

  // Сброс полей при переключении точки измерения
  useEffect(() => {
    if (currentConnection) {
      formConnection.resetFields()
    }
  }, [currentConnection, formConnection])

  const isLoadingDate = isLoadingVp3701 || isLoadingGenerator || isLoadingConnection || isFetchingConnection

  const isLoadingPage = isLoadingMachine || isLoadingPoint

  const [createConnection] = useCreateConnectionsMutation()
  const [deleteConnection] = useDeleteConnectionsMutation()

  if (isLoadingPage) {
    return <Spinner />
  }

  const handleConnectionByGenerator = async () => {
    const fields = formConnection.getFieldsValue()
    if (selectedPointId) {
      try {
        await createConnection({
          generatorId: fields.device,
          vp3701ChannelType: null,
          measuringPointId: selectedPointId,
          vp3701Id: null
        }).unwrap()

        successNotificationCreate('Связь создана')
      } catch (error) {
        errorNotificationCreate(error)
      }
    }
  }

  const handleConnectionByVp3701 = async () => {
    const fields = formConnection.getFieldsValue()
    console.log(fields)
    if (selectedPointId && fields.vp3701ChannelType) {
      try {
        await createConnection({
          generatorId: null,
          vp3701ChannelType: fields.vp3701ChannelType,
          measuringPointId: selectedPointId,
          vp3701Id: fields.device
        }).unwrap()

        successNotificationCreate('Связь создана')
      } catch (error) {
        errorNotificationCreate(error)
      }
    }
  }

  const handleDeleteConnection = async () => {
    try {
      if (currentConnection) {
        await deleteConnection(currentConnection.connectionId).unwrap()
        refetchConnections()
        successNotificationCreate('Связь удалена')
      }
    } catch (error) {
      errorNotificationCreate(error)
    }
  }

  const handleFinishFormConnection = async () => {
    if (currentConnection) {
      await handleDeleteConnection()
      return
    }

    const fields = formConnection.getFieldsValue()

    const isVp3701 = vp3701Devices?.content.find((device) => device.vp3701Id === fields.device)

    if (isVp3701) {
      await handleConnectionByVp3701()
      return
    }

    const isGenerator = generators?.content.find((generator) => generator.generatorId === fields.device)

    if (isGenerator) {
      await handleConnectionByGenerator()
    }
  }

  const handleClosePanelClick = () => {
    setSelectedPointId(undefined)
  }

  return (
    <>
      <HeaderConfiguration title={`[${currentMachine?.name}]: Параметры связей`} description={currentPoint?.name} />

      <FormUi onFinish={handleFinishFormConnection} className={styles.container} form={formConnection}>
        <ControlsWrapper toolTip={<DocTooltip theme='light' formKey={FormKey.FormOne} />}>
          <DevicesConnections
            currentPoint={currentPoint}
            vp3701Devices={vp3701Devices?.content}
            generators={generators?.content}
            isLoadingDate={isLoadingDate}
            currentConnection={currentConnection}
          />
        </ControlsWrapper>

        <ControlsWrapper>
          <ButtonsWrapper>
            <ButtonPrimary
              title={ButtonTitle.Exit}
              htmlType={'button'}
              isLoadingSkeleton={isLoadingDate}
              onClick={handleClosePanelClick}
            />
            <ButtonPrimary
              title={!currentConnection ? ButtonTitle.SetConnection : ButtonTitle.RemoveConnection}
              htmlType={'submit'}
              isLoadingSkeleton={isLoadingDate}
            />
          </ButtonsWrapper>
        </ControlsWrapper>
      </FormUi>
    </>
  )
}

export default ParamsConnections
