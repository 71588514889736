export const enum BrParamName {
  KinematicBlockName = 'kinematicBlockName',
  Description = 'description',
  TypeSource = 'typeSource',
  KinematicBlockSourceId = 'kinematicBlockSourseId',
  FreqOutSource = 'freqOutSource',
  FreqCoefForIn = 'freqCoefForIn',
  ManufacturerValue = 'manufacturerOptionValue',
  BearingOptionValue = 'bearingOptionValue'
}

export const enum BrOutName {
  Kftf = 'kftf',
  Kftfo = 'kftfo',
  Kbsf = 'kbsf',
  Kbpfo = 'kbpfo',
  Kbpfi = 'kbpfi'
}

export const enum TypeSourceSelect {
  Kb = 'KB',
  Mp = 'MP'
}

export const optionsTypeSource = [
  { label: 'Кинематический блок', value: TypeSourceSelect.Kb },
  { label: 'Точка измерения', value: TypeSourceSelect.Mp, disabled: true }
]

export const bearingCoefficients = [
  {
    name: 'kFTF',
    tooltip: 'Коэффициент частоты вращения сепаратора',
    type: 'kftf'
  },
  {
    name: 'kFTFo',
    tooltip: 'Коэффициент обратной частоты сепаратора',
    type: 'kftfo'
  },
  {
    name: 'kBSF',
    tooltip: 'Коэффициент частоты контакта тел качения',
    type: 'kbsf'
  },
  {
    name: 'kBPFO',
    tooltip:
      'Коэффициент частоты перекатывания тел качения по наружному кольцу',
    type: 'kbpfo'
  },
  {
    name: 'kBPFI',
    tooltip:
      'Коэффициент частоты перекатывания тел качения по внутреннему кольцу',
    type: 'kbpfi'
  }
]
