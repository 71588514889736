import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { type FC, type LegacyRef, type MutableRefObject, useState } from 'react'

import styles from '../../express-sidebar.module.css'

import ExpressMarkerListItem from './components/express-marker-list-item/express-marker-list-item'
import ExpressMarkerPopup from './components/express-marker-popup/express-marker-popup'

interface IExpressMarkers {
  onClickExpressMarkerButton: (index: number) => void
  itemMarkersRefs: MutableRefObject<LegacyRef<HTMLLIElement>[] | null>
}

const ExpressMarkers: FC<IExpressMarkers> = ({
  onClickExpressMarkerButton,
  itemMarkersRefs
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const { resultsMarkers, isMarkersExpressBarOpen } = useTypedSelector(
    (state) => state.expressMarkersReducer
  )

  return (
    <div className={styles.container}>
      <ButtonPrimary
        onClick={() => setIsPopupOpen(true)}
        htmlType={'button'}
        title={'Маркеры'}
      />
      <div className={styles['container-express']}>
        <ExpressMarkerPopup
          setIsPopupOpen={setIsPopupOpen}
          isPopupOpen={isPopupOpen}
        />

        <ul className={styles.list}>
          {isMarkersExpressBarOpen
            ? resultsMarkers.map((result, i) => (
                <ExpressMarkerListItem
                  key={result.title}
                  onClickExpressMarkerButton={() =>
                    onClickExpressMarkerButton(i)
                  }
                  refMarkerListItem={
                    itemMarkersRefs?.current && itemMarkersRefs?.current[i]
                  }
                  result={result}
                  currentIndex={i}
                />
              ))
            : null}
        </ul>
      </div>
    </div>
  )
}

export default ExpressMarkers
