import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { TExpressSection } from '@/store/reducers/charts/express-section.slice'
import type { IChartSpectrum } from '@/types/chart/chart-spectrun.type'
import { useEffect, useRef, useState } from 'react'
import type { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'

const useExpressSections = (chartData: IChartSpectrum[]) => {
  const { setExpressSection } = useActions()
  const { sections } = useTypedSelector((state) => state.expressSectionReducer)
  const [sectionOptions, setSectionOptions] = useState<TExpressSection[]>(sections)
  const refIndexSection = useRef(0)

  const [isAreaDrag, setIsAreaDrag] = useState(false)
  const [isResizeRight, setIsResizeRight] = useState(false)
  const [isResizeLeft, setIsResizeLeft] = useState(false)

  const resetDefaultSettings = () => {
    if (isAreaDrag || isResizeLeft || isResizeRight) {
      setExpressSection({
        section: sectionOptions[refIndexSection.current],
        index: refIndexSection.current
      })
      setIsAreaDrag(false)
      setIsResizeLeft(false)
      setIsResizeRight(false)
    }
  }

  const onMouseMove = (e: CategoricalChartState) => {
    if (isAreaDrag || isResizeRight || isResizeLeft) {
      setSectionOptions((prev) => {
        if (e?.activePayload !== undefined) {
          const newPrev = [...prev]
          const targetFreq = e?.activePayload[0]?.payload?.frequency
          let fl = newPrev[refIndexSection.current].fl
          let fh = newPrev[refIndexSection.current].fh

          if (isAreaDrag) {
            const width = Math.abs(fh - fl)
            const valueIndex = chartData[chartData.length - 1].frequency

            let start = targetFreq - width / 2
            let end = start + width

            if (start < 0) {
              start = 0
              end = start + width
            }

            if (end > valueIndex) {
              end = valueIndex
              start = end - width
            }

            fl = start
            fh = end
          } else if (isResizeRight) {
            fh = targetFreq > fl ? targetFreq : fl + 1
          } else if (isResizeLeft) {
            fl = targetFreq < fh ? targetFreq : fh - 1
          }

          newPrev[refIndexSection.current] = {
            ...newPrev[refIndexSection.current],
            fl,
            fh
          }

          return [...newPrev]
        }

        return prev
      })
    }
  }

  const onMouseUp = () => {
    resetDefaultSettings()
  }

  const onMouseLeave = () => {
    resetDefaultSettings()
  }

  useEffect(() => {
    setSectionOptions(sections)
  }, [sections])

  return {
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    setIsAreaDrag,
    setIsResizeLeft,
    setIsResizeRight,
    sectionOptions,
    refIndexSection
  }
}

export default useExpressSections
