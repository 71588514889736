// Old solution
// const calculatePK = (longChartData: number[]) => {
//   const dataModulo = longChartData.map(Math.abs)
//
//   return Math.max.apply(Math, dataModulo)
// }

const calculatePK = (longChartData: number[]): number | null => {
  let maxValue = -Infinity

  for (const value of longChartData) {
    const absValue = Math.abs(value)
    if (absValue > maxValue) {
      maxValue = absValue
    }
  }

  return maxValue
}

export default calculatePK
