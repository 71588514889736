import type { EditableCellProps } from '@/app/configuration/components/devices/generator/generator.type'
import {
  GeneratorKey,
  STEP_COUNT_FREQ,
  ampSetModule,
  distrAmplitudes,
  phaseSetModule,
  phases,
  typeModule,
  typeSetModule
} from '@/constants/core/common.constant'
import type { InputRef } from 'antd'
import { Form, Input, InputNumber, Select } from 'antd'
import React, { useEffect, useRef } from 'react'

const CellEditableHarmonic: React.FC<EditableCellProps> = ({ editing, children, record }) => {
  const inputRef = useRef<InputRef>(null)

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [editing])

  const getInputComponent = (key: GeneratorKey) => {
    switch (key) {
      case GeneratorKey.Name:
        return <Input ref={inputRef} />
      case GeneratorKey.Fs:
        return <InputNumber step={0.01} disabled={true} />
      case GeneratorKey.TSig:
        return <InputNumber step={0.01} disabled={true} />
      case GeneratorKey.NormAmp:
        return <InputNumber step={0.01} disabled={true} />
      case GeneratorKey.Dclevel:
        return <InputNumber />
      case GeneratorKey.BaseFreq:
        return <InputNumber step={0.01} />
      case GeneratorKey.BaseAmp:
        return <InputNumber step={1} />
      case GeneratorKey.Nharm:
        return <InputNumber step={1} />
      case GeneratorKey.AmpSet:
        return <Select placeholder={'Выберите амплитуду распределения'} options={distrAmplitudes} />
      case GeneratorKey.LastAmp:
        return <InputNumber step={1} />
      case GeneratorKey.PhaseSet:
        return <Select placeholder={'Выберите фазовое распределение'} options={phases} />
      case GeneratorKey.PhaseShift:
        return <InputNumber step={5} min={-180} max={180} />
      case GeneratorKey.WhiteNoise:
        return <InputNumber step={10} min={0} max={100} />
      case GeneratorKey.M1_Type:
        return <Select placeholder={'Выберите амплитуду распределения'} options={typeModule} />
      case GeneratorKey.M1_DeepT:
        return <InputNumber step={10} min={0} max={100} />
      case GeneratorKey.M1_BaseFreq:
        return <InputNumber step={STEP_COUNT_FREQ} />
      case GeneratorKey.M1_DeepA:
        return <InputNumber step={10} min={0} max={100} />
      case GeneratorKey.M1_Nharm:
        return <InputNumber step={1} />
      case GeneratorKey.M1_AmpSet:
        return <Select placeholder={'Выберите закон изменения ряда'} options={ampSetModule} />
      case GeneratorKey.M1_LastAmp:
        return <InputNumber step={1} />
      case GeneratorKey.M1_PhaseSet:
        return <Select placeholder={'Выберите фазовое распределение'} options={phaseSetModule} />
      case GeneratorKey.M1_PhaseShift:
        return <InputNumber step={5} min={-180} max={180} />
      case GeneratorKey.M2_Type:
        return <Select placeholder={'Выберите сдвиг по фазе'} options={typeSetModule} />
      case GeneratorKey.M2_DeepT:
        return <InputNumber step={10} min={0} max={100} />
      case GeneratorKey.M2_BaseFreq:
        return <InputNumber step={STEP_COUNT_FREQ} />
      case GeneratorKey.M2_DeepA:
        return <InputNumber step={10} min={0} max={100} />
      case GeneratorKey.M2_Nharm:
        return <InputNumber step={1} />
      case GeneratorKey.M2_AmpSet:
        return <Select placeholder={'Выберите закон изменения ряда'} options={ampSetModule} />
      case GeneratorKey.M2_LastAmp:
        return <InputNumber step={1} />
      case GeneratorKey.M2_PhaseSet:
        return <Select placeholder={'Выберите фазовое распределение'} options={phaseSetModule} />
      case GeneratorKey.M2_PhaseShift:
        return <InputNumber step={5} min={-180} max={180} />
      default:
        return <Input />
    }
  }

  return (
    <td>
      {editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={record.key}
          rules={[
            {
              required: true,
              message: `Значение поля «${record.label}» необходимо заполнить`
            }
          ]}
        >
          {getInputComponent(record.key)}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

export default CellEditableHarmonic
