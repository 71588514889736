import { EMachineDroppable } from '@/enums/machines/machine-droppable.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMachinesQuery } from '@/store/api/machines.api'
import { useChangePlanMutation } from '@/store/api/plans.api'
import type { IMachineInfo } from '@/types/machine.type'
import {
  errorNotificationCreate,
  successNotificationCreate
} from '@/utils/notification-creators'
import { notification } from 'antd'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext } from 'react-beautiful-dnd'

// HOC для DragDropContext
const withDragDropContextHoc = (WrappedComponent: FC) => {
  const HOC: FC = (props) => {
    const { data: machinesResponse } = useGetMachinesQuery()
    const { plans, selectedPlanId } = useTypedSelector(
      (state) => state.planReducer
    )
    const [machinesForDnd, setMachinesForDnd] = useState<IMachineInfo[]>([])
    const machines = machinesResponse?.content

    useEffect(() => {
      if (machines) {
        setMachinesForDnd(machines)
      }
    }, [machines])

    const { addMachineInPlan } = useActions()
    const [changePlan] = useChangePlanMutation()

    const handleDragEnd = async (result: DropResult) => {
      if (!result.destination) {
        return
      }

      const newMachines = [...machinesForDnd]
      const [removed] = newMachines.splice(result.source.index, 1)
      newMachines.splice(result.destination.index, 0, removed)

      setMachinesForDnd(newMachines)

      const isNotPlansDroppable =
        result.destination.droppableId !== EMachineDroppable.PLANS

      if (isNotPlansDroppable) {
        return
      }

      try {
        const isNotSelectedPlan = Boolean(!selectedPlanId)
        if (isNotSelectedPlan) {
          notification.error({
            message: 'Операция невыполнима',
            description: 'Маршрут для добавления машины не выбран'
          })

          return
        }
        const selectedPlan = plans.find(
          (plan) => plan.planId === selectedPlanId
        )

        const foundMachine = machinesForDnd.find(
          (m) => m.machineId === result.draggableId
        )

        if (foundMachine && selectedPlan?.machineIds) {
          const hasAlradyPlan = selectedPlan.machineIds.includes(
            foundMachine.machineId
          )

          if (hasAlradyPlan) {
            notification.error({
              message: 'Операция невыполнима',
              description: 'Машина уже добавлена в маршрут',
              role: 'alert'
            })

            return
          }
          const changedPlan = {
            ...selectedPlan,
            machineIds: [...selectedPlan.machineIds, foundMachine.machineId]
          }

          await changePlan(changedPlan).unwrap()

          addMachineInPlan(foundMachine)

          successNotificationCreate('Машина добавлена в маршрут')
        }
      } catch (error) {
        console.error(error)
        errorNotificationCreate(error)
      }
    }

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <WrappedComponent {...props} />
      </DragDropContext>
    )
  }

  return HOC
}

export default withDragDropContextHoc
