import { ApiResource, ApiTag } from '@/constants/store/api.constants'

import { baseApi } from './api-base-query'

interface IGetInfoResponse {
  gitHash: string
  version: string
}

export const plansApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getInfo: builder.query<IGetInfoResponse, void>({
      query: () => ({ url: ApiResource.Info, method: 'GET' }),
      providesTags: [ApiTag.Info]
    })
  })
})

export const { useGetInfoQuery, useLazyGetInfoQuery } = plansApi
