export const enum SynchMechParamName {
  Fu = 'fu',
  N2p = 'n2p',
  Nr = 'nr',
  Ns = 'ns'
}

export enum SynchMechOut {
  Fu = 'fu',
  Fr = 'fr',
  Fe = 'fe',
  Fnl = 'fnl',
  Fsz = 'fsz',
  Fsy = 'fsy'
}
