import {
  ConfigurationRoute,
  MachineConditionRoute,
  MainRoute,
  SettingsRoute
} from '@/constants/navigation/routes.constant'
import { SettingsTitle } from '@/constants/settings/settings.constant'
import type { ILink } from '@/types/navigation/links.type'

export const menuLinks: Readonly<ILink[]> = [
  {
    to: `/${MainRoute.MachineCondition}/${MachineConditionRoute.Results}`,
    icon: 'network_check',
    title: 'Состояние'
  },
  {
    to: `/${MainRoute.ExpertAnalysis}`,
    icon: 'multiline_chart',
    title: 'Экспертный анализ',
    disabled: true
  },
  {
    to: `/${MainRoute.Plans}`,
    icon: 'timeline',
    title: 'Маршруты'
  },
  {
    to: `/${MainRoute.OffRoute}`,
    icon: 'exit_to_app',
    title: 'Вне маршрута',
    disabled: true
  },
  {
    to: `/${MainRoute.Reports}`,
    icon: 'library_books',
    title: 'Отчёты'
  },
  {
    to: `/${MainRoute.Configuration}/${ConfigurationRoute.Equipments}`,
    icon: 'developer_board',
    title: 'Конфигурация'
  },
  {
    to: `/${MainRoute.Settings}/${SettingsRoute.Server}`,
    icon: 'settings',
    title: 'Настройка'
  },
  {
    to: `${MainRoute.Support}`,
    icon: 'support',
    title: 'Поддержка',
    disabled: true
  },
  {
    to: `${MainRoute.About}`,
    icon: 'help_outline',
    title: 'О системе'
  },
  {
    to: `${MainRoute.Exit}`,
    icon: 'directions_run',
    title: 'Выход',
    disabled: true
  }
] as const

export const settingsLinks: Readonly<ILink[]> = [
  {
    to: `../${SettingsRoute.Main}`,
    title: SettingsTitle.Main
  },
  {
    to: `../${SettingsRoute.Profile}`,
    title: SettingsTitle.Profile
  },
  {
    to: `../${SettingsRoute.Server}`,
    title: SettingsTitle.Server
  },
  {
    to: `../${SettingsRoute.License}`,
    title: SettingsTitle.License
  }
] as const

export const configurationLinks: Readonly<ILink[]> = [
  {
    to: `../${ConfigurationRoute.Equipments}`,
    title: 'оборудование',
    icon: 'memory'
  },
  {
    to: `../${ConfigurationRoute.MnemonicMap}`,
    title: 'мнемосхема',
    icon: 'schema'
  },
  {
    to: `../${ConfigurationRoute.KinematicsConfig}`,
    title: 'кинематика',
    icon: 'account_tree'
  },
  {
    to: `../${ConfigurationRoute.Devices}`,
    title: 'устройства',
    icon: 'device_hub'
  },
  {
    to: `../${ConfigurationRoute.Connections}`,
    title: 'связи',
    icon: 'join_inner'
  }
] as const

export const machineConditionLinks: Readonly<ILink[]> = [
  {
    to: `../${MachineConditionRoute.DescriptionCondition}`,
    title: 'описание',
    icon: 'description'
  },
  {
    to: `../${MachineConditionRoute.MnemonicCondition}`,
    title: 'мнемосхема',
    icon: 'schema'
  },
  {
    to: `../${MachineConditionRoute.KinematicsCondition}`,
    title: 'кинематика',
    icon: 'account_tree'
  },
  {
    to: `../${MachineConditionRoute.Results}`,
    title: 'Результаты',
    icon: 'fact_check'
  },
  {
    to: `../${MachineConditionRoute.WorkCondition}`,
    title: 'раб. режим',
    icon: 'home_repair_service'
  }
] as const
