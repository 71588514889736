import CollapseTables from '@/app/reports/components/collapse-tables/collapse-tables'
import CollapseWrapper from '@/app/reports/components/collapse-wrapper/collapse-wrapper'
import { GeneratorLabel } from '@/constants/device/deviceOptions'
import { ErrorData } from '@/constants/errors.constant'
import type { IGenerator } from '@/types/devices.type'
import type { TableType } from '@/types/reports/reports.type'
import { Collapse } from 'antd'
import type { FC } from 'react'

type CollapseSquareType = {
  dataGenerator: IGenerator[]
  isChecked: (generatorId: IGenerator['generatorId']) => boolean
  ExtraCheckbox: FC<{
    idItem: IGenerator['generatorId']
    checked: boolean
  }>
}

const CollapseSquare: FC<CollapseSquareType> = ({
  dataGenerator,
  isChecked,
  ExtraCheckbox
}) => {
  const buildTables = (dataItem: IGenerator): null | TableType[] => {
    const square = dataItem.squareSettings
    if (!square) {
      return null
    }

    return [
      {
        columns: [
          {
            title: 'Параметры сигнала',
            children: [
              {
                title: 'Параметр',
                dataIndex: 'name',
                key: 'name'
              },
              {
                title: 'Значение',
                dataIndex: 'value',
                key: 'value'
              }
            ]
          }
        ],
        data: [
          {
            key: '1',
            name: GeneratorLabel.BaseFreq,
            value: square.squareBaseFreq
          },
          {
            key: '2',
            name: GeneratorLabel.SquareLowLevel,
            value: square.squareLowLevel
          },
          {
            key: '3',
            name: GeneratorLabel.SquareDuty,
            value: square.squareDuty
          },
          {
            key: '4',
            name: GeneratorLabel.SquareHighToLow,
            value: square.squareHighToLow
          },
          {
            key: '5',
            name: GeneratorLabel.SquarePhase,
            value: square.squarePhase
          }
        ]
      }
    ]
  }

  const generators = dataGenerator.map((generator) => ({
    key: generator.generatorId,
    label: generator.name || ErrorData.Null,
    children: <CollapseTables tables={buildTables(generator)} />,
    extra: (
      <ExtraCheckbox
        idItem={generator.generatorId}
        checked={isChecked(generator.generatorId)}
      />
    )
  }))

  return (
    <CollapseWrapper data={dataGenerator}>
      <Collapse
        accordion
        bordered={false}
        items={generators}
        collapsible='header'
      />
    </CollapseWrapper>
  )
}

export default CollapseSquare
