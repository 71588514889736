import EquipmentItem from '@/components/UI/elements/equipment-item/equipment-item'
import DangerStateIndicator from '@/components/UI/elements/indicators/danger-state-indicator/danger-state-indicator'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetAllNodesQuery } from '@/store/api/nodes.api'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import cn from 'classnames'
import { type FC } from 'react'

import styles from '../measurements-list.module.css'

interface IProps {
  measurement: IMeasurement
  onSelectMeasurementClick: (pointId: IMeasurement['measuringPointId']) => void
}

const MeasurementItem: FC<IProps> = ({
  measurement,
  onSelectMeasurementClick
}) => {
  const { data: dataAllNodesState } = useGetAllNodesQuery()
  const { selectedMeasurementId } = useTypedSelector(
    (state) => state.globalReducer
  )
  const foundNodeState = dataAllNodesState?.find(
    (node) => node.measurementId === measurement.measurementId
  )

  return (
    <div
      className={cn(styles['measurement-button'], {
        [styles['measurement-button-active']]:
          measurement.measurementId === selectedMeasurementId
      })}
      onClick={() => onSelectMeasurementClick(measurement.measurementId)}
    >
      <EquipmentItem
        indicator={
          foundNodeState?.measurementId ? (
            <DangerStateIndicator nodeState={foundNodeState} />
          ) : null
        }
        name={measurement.name}
        paused={measurement.paused}
      />
    </div>
  )
}

export default MeasurementItem
