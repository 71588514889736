export const enum ReportKey {
  Vp3701 = 'vp3701',
  Square = 'square',
  Harmonic = 'harmonic'
}

export const enum ReportValue {
  Vp3701 = 'VP-3701',
  Square = 'Дискретный',
  Harmonic = 'Гармонический'
}

export const enum Vp3701Key {
  Vp3701Id = 'vp3701Id',
  SerialNumber = 'serialNumber',
  PowerType = 'powerType',
  Version = 'version',
  IP = 'ip',
  Name = 'name',
  ConnectionStatus = 'connectionStatus',
  StatusUpdatingTimestamp = 'statusUpdatingTimestamp',
  Status = 'status',
  PortData = 'portData',
  PortSetting = 'portSetting'
}

export const mapPowerType = {
  ['BATTERY']: 'Аккумулятор',
  ['EXTERNAL']: 'Внешний источник',
  ['NONE']: 'Не определён'
}

export const mapConnectionStatus = {
  ['MEASURING_POINT_CONNECTED']: 'Подключение к точке измерения установлено',
  ['NO_ONE_MEASURING_POINT_CONNECTED']:
    'Подключение к точке измерения отсутствует'
}

export const mapStatus = {
  ['ONLINE']: 'Соединение с сервером установлено',
  ['OFFLINE']: 'Соединение с сервером отсутствует'
}

export const readableVp3701Key: Readonly<{ [key in Vp3701Key]: string }> = {
  [Vp3701Key.Vp3701Id]: 'VP-3701 ID',
  [Vp3701Key.SerialNumber]: 'Серийный номер',
  [Vp3701Key.PowerType]: 'Источник питания',
  [Vp3701Key.Version]: 'Версия',
  [Vp3701Key.IP]: 'IP-адрес',
  [Vp3701Key.Name]: 'Наименование',
  [Vp3701Key.ConnectionStatus]: 'Статус соединения',
  [Vp3701Key.StatusUpdatingTimestamp]: 'Время обновления статуса',
  [Vp3701Key.Status]: 'Статус',
  [Vp3701Key.PortData]: 'Порт данных',
  [Vp3701Key.PortSetting]: 'Порт потока'
}

export const readableGenerator = {
  generatorId: 'GeneratorHarmonic ID',
  name: 'Наименование',
  fs: 'FS',
  tsig: 'TSIG',
  type: 'Тип',
  squareSettingsNormAmp: '[Square Settings] Norm Amp',
  squareSettingsSquareBaseFreq: '[Square Settings] Settings Square Base Freq',
  squareSettingsSquareLowLevel: '[Square Settings] Settings Square Low Level',
  squareSettingsSquareHighToLow:
    '[Square Settings] Settings Square High To Low',
  squareSettingsSquareDuty: '[Square Settings] SettingsSquareDuty',
  squareSettingsSquarePhase: '[Square Settings] SettingsSquarePhase',
  harmonicsNormAmp: 'Norm AMP',
  harmonicsDclevel: 'DC Level',
  harmonicsBaseFreq: 'Base Freq',
  harmonicsBaseAmp: 'Base Amp',
  harmonicsNharm: 'Nharm',
  harmonicsAmpSet: 'AMP Set',
  harmonicsLastAmp: 'Last AMP',
  harmonicsPhaseSet: 'Phase Set',
  harmonicsPhaseShift: 'Phase Shift',
  harmonicsWhiteNoise: 'White Noise',
  harmonicsM1_Type: 'M1 Type',
  harmonicsM1_BaseFreq: 'M1 Base Freq',
  harmonicsM1_DeepT: 'M1 Deep T',
  harmonicsM1_Nharm: 'M1 Nharm',
  harmonicsM1_AmpSet: 'M1 Type',
  harmonicsM1_LastAmp: 'M1 Last Amp',
  harmonicsM1_PhaseSet: 'M1 Phase Set',
  harmonicsM1_PhaseShift: 'M1 Phase Shift',
  harmonicsM2_Type: 'M2 Type',
  harmonicsM2_BaseFreq: 'M2 Base Freq',
  harmonicsM2_DeepA: 'M2 Deep A',
  harmonicsM2_DeepT: 'M2 Deep T',
  harmonicsM2_Nharm: 'M2 Nharm',
  harmonicsM2_AmpSet: 'M2 AMP Set',
  harmonicsM2_LastAmp: 'M2 Last Amp',
  harmonicsM2_PhaseSet: 'M2 Phase Set',
  harmonicsM2_PhaseShift: 'M2 Phase Shift',
  harmonicsM1_DeepA: 'M1 Phase Shift',
  harmonicCommonSettingsNormAmp: '[Harmonic]: Norm AMP',
  wavSettingsFileName: '[Wav settings]: File Name',
  wavSettingsFileLength: '[Wav settings]: File Length',
  wavSettingsMd5Hash: '[Wav settings]: Md 5 Hash',
  wavSettingsFileId: '[Wav settings]: File ID',
  dskSettingsFileName: '[Dsk Settings]: File Name',
  dskSettingsFileLength: '[Dsk Settings]: File Length',
  dskSettingsMd5Hash: '[Dsk Settings]: Md 5 Hash',
  dskSettingsFileId: '[Dsk Settings]: File ID',
  dskSettingsDatFileId: '[Dsk Settings]: DatFileId'
}

export enum Status {
  Offline = 'OFFLINE',
  Online = 'Online'
}

export enum CollapseSquareValue {
  Null = '0'
}

export const mapAmpSet: Record<number, string> = {
  [0]: 'Лин. спад',
  [1]: 'Эксп. спад',
  [2]: 'Четные х1,5',
  [3]: 'Четные х2',
  [4]: 'Четные х4'
}

export const mapPhaseSet: Record<number, string> = {
  [0]: 'Равномерно',
  [1]: 'Случайно'
}

export const mapType: Record<number, string> = {
  [0]: 'Амплитудная',
  [1]: 'Фазовая'
}
