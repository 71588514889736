import ChartScalar from '@/app/machine-condition/components/results/components/charts/components/chart-scalar/chart-scalar'
import ChartSpectrum from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/chart-spectrum'
import ChartTiming from '@/app/machine-condition/components/results/components/charts/components/chart-timing/chart-timing'
import Spinner from '@/components/UI/elements/spinner/spinner'
import TextInfo from '@/components/UI/elements/text-info/text-info'
import { ETypeChart, configCharts } from '@/constants/chart/charts.constant'
import { DATE_TEMPLATE } from '@/constants/core/common.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasurementQuery, useGetMeasurementResultsQuery } from '@/store/api/measurements.api'
import type { IMeasurementScalarResult } from '@/types/chart/charts.type'
import type { TStrobeOptions } from '@/types/express-strobe.type'
import { formatDate } from '@/utils/format-date'
import { getTypeMeasurement } from '@/utils/measurement/get-type-measurement'
import type { LegacyRef, RefObject } from 'react'
import React, { type FC, useCallback, useEffect, useRef } from 'react'

import styles from './charts.module.css'

import ExpressSidebar from './components/express-sidebar/express-sidebar'
import calculateAs from './components/express-sidebar/helpers/calculate-as.helper'
import calculateEx from './components/express-sidebar/helpers/calculate-ex.helper'
import calculatePK from './components/express-sidebar/helpers/calculate-pk.helper'
import calculateSKZ from './components/express-sidebar/helpers/calculate-skz.helper'

export type TExpressAnalysis = {
  title: string
  strobeOptions: Omit<TStrobeOptions, 'offset'>

  results: {
    skz: number
    pk: number
    pf: number
    ex: number
    as: number
  }[]
}[]

const Charts: FC = () => {
  const { selectedMeasurementId } = useTypedSelector((state) => state.globalReducer)

  const { strobes } = useTypedSelector((state) => state.expressStrobeReducer)
  const { configChart } = useTypedSelector((state) => state.chartsUiReducer)
  const { setSelectedResultId, setSelectedResultIndex, setConfigChart } = useActions()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { data: measurement } = useGetMeasurementQuery(selectedMeasurementId, {
    skip: !selectedMeasurementId
  })
  const {
    data: measurementResultsResponse,
    isLoading: isLoadingMeasurementResults,
    isFetching: isFetchingMeasurementResults
  } = useGetMeasurementResultsQuery(
    {
      measurementId: measurement?.measurementId,
      size: configChart?.size,
      sortDir: 'DESC'
    },
    { skip: !measurement?.measurementId && !configChart }
  )

  useEffect(() => {
    const typeMeasurement = getTypeMeasurement(measurement)
    if (typeMeasurement) {
      setConfigChart(configCharts[typeMeasurement])
    }
  }, [measurement, setConfigChart])

  const isTypeSpectrum = configChart?.typeChart === ETypeChart.SPECTRUM
  const isTypeScalar = configChart?.typeChart === ETypeChart.SCALAR
  const isTypeTiming = configChart?.typeChart === ETypeChart.TIMING
  const measurementResults = measurementResultsResponse?.content

  const calculateExpressStrobeAnalysis = useCallback(() => {
    const mappedResults =
      measurementResults?.map((result) => ({
        values: result.signalValue?.values
      })) || []

    return strobes.map((strobe, indx) => ({
      title: strobe.title,
      strobeOptions: strobe.strobeData,
      results: strobe.strobeIndexes.map((indexes) => {
        const resultArray = mappedResults[indx]?.values?.slice(
          indexes[0] || 0,
          indexes[1] || (mappedResults[indx]?.values?.length || 1) - 1
        )

        const skz = calculateSKZ(resultArray || [])
        const pk = calculatePK(resultArray || [])
        const pf = pk / skz
        const ex = calculateEx(resultArray || [])
        const as = calculateAs(resultArray || [])

        return {
          skz,
          pk,
          pf,
          ex,
          as
        }
      })
    }))
  }, [measurementResults, strobes])

  const itemStrobesRefs = useRef<Array<LegacyRef<HTMLLIElement>> | null>(null)
  const chartStrobesRefs = useRef<Array<LegacyRef<HTMLButtonElement>> | null>(null)

  const itemSectionsRefs = useRef<Array<LegacyRef<HTMLLIElement>> | null>(null)
  const chartSpecterRefs = useRef<Array<LegacyRef<HTMLButtonElement>> | null>(null)

  const itemMarkersRefs = useRef<Array<LegacyRef<HTMLLIElement>> | null>(null)
  const itemBarsRefs = useRef<Array<LegacyRef<HTMLLIElement>> | null>(null)

  useEffect(() => {
    if (measurementResults?.length) {
      itemStrobesRefs.current = Array.from(measurementResults, () => React.createRef())
      chartStrobesRefs.current = Array.from(measurementResults, () => React.createRef())

      itemSectionsRefs.current = Array.from(measurementResults, () => React.createRef())
      itemMarkersRefs.current = Array.from(measurementResults, () => React.createRef())
      itemBarsRefs.current = Array.from(measurementResults, () => React.createRef())
      chartSpecterRefs.current = Array.from(measurementResults, () => React.createRef())
    }
  }, [measurementResults])

  useEffect(() => {
    if (measurementResults?.length) {
      setSelectedResultId(measurementResults[0].resultId)
      setSelectedResultIndex(0)
    }
  }, [measurementResults, setSelectedResultId, setSelectedResultIndex])

  const handleClickOnChartForStrobe = (index: number, resultId: string) => {
    const target = itemStrobesRefs?.current
    if (!target) return

    const node = target[index] as RefObject<HTMLLIElement>

    if (node?.current) {
      node.current.scrollIntoView({ behavior: 'smooth' })
    }

    setSelectedResultId(resultId)
    setSelectedResultIndex(index)
  }

  const handleClickOnChartSpectrum = (index: number, resultId: string) => {
    const targetSections = itemSectionsRefs?.current
    const targetMarkers = itemMarkersRefs?.current
    const targetBars = itemBarsRefs?.current
    if (!targetSections || !targetMarkers || !targetBars) return

    const nodeSection = targetSections[index] as RefObject<HTMLLIElement>
    const nodeMarker = targetMarkers[index] as RefObject<HTMLLIElement>
    const nodeBar = targetBars[index] as RefObject<HTMLLIElement>

    if (nodeSection?.current) {
      nodeSection.current.scrollIntoView({ behavior: 'smooth' })
    }

    if (nodeMarker?.current) {
      nodeMarker.current.scrollIntoView({ behavior: 'smooth' })
    }

    if (nodeBar?.current) {
      nodeBar.current.scrollIntoView({ behavior: 'smooth' })
    }

    setSelectedResultId(resultId)
    setSelectedResultIndex(index)
  }

  const handleClickExpressStrobeButton = (index: number) => {
    const target = chartStrobesRefs?.current
    if (!target) return

    const node = target[index] as RefObject<HTMLButtonElement>

    if (node?.current) {
      node.current.scrollIntoView({ behavior: 'smooth' })

      if (measurementResults !== undefined) {
        setSelectedResultId(measurementResults[index].resultId)
        setSelectedResultIndex(index)
      }
    }
  }

  const handleClickExpressSectionButton = (index: number) => {
    const target = chartSpecterRefs?.current
    if (!target) return

    const node = target[index] as RefObject<HTMLButtonElement>

    if (node?.current) {
      node.current.scrollIntoView({ behavior: 'smooth' })

      if (measurementResults !== undefined) {
        setSelectedResultId(measurementResults[index].resultId)
        setSelectedResultIndex(index)
      }
    }
  }

  const handleClickExpressMarkerButton = useCallback(
    (index: number) => {
      const target = chartSpecterRefs?.current
      if (!target) return

      const node = target[index] as RefObject<HTMLButtonElement>

      if (node?.current) {
        node.current.scrollIntoView({ behavior: 'smooth' })

        if (measurementResults !== undefined) {
          setSelectedResultId(measurementResults[index].resultId)
          setSelectedResultIndex(index)
        }
      }
    },
    [measurementResults, setSelectedResultId, setSelectedResultIndex]
  )

  const handleClickExpressBarButton = (index: number) => {
    const target = chartSpecterRefs?.current
    if (!target) return

    const node = target[index] as RefObject<HTMLButtonElement>

    if (node?.current) {
      node.current.scrollIntoView({ behavior: 'smooth' })

      if (measurementResults !== undefined) {
        setSelectedResultId(measurementResults[index].resultId)
        setSelectedResultIndex(index)
      }
    }
  }

  if (isLoadingMeasurementResults || isFetchingMeasurementResults) {
    return <Spinner />
  }

  if (!measurementResults) {
    return null
  }

  const buildScalarChart = () => {
    if (isTypeScalar) {
      const results = measurementResults
        .map<IMeasurementScalarResult>((result) => ({
          value: Number(result.scalarValue?.value),
          time: result.timestamp
        }))
        .reverse()

      return <ChartScalar scalarData={results} title={`${measurement?.name}`} />
    }
  }

  const buildTimingCharts = () => {
    if (isTypeTiming) {
      return measurementResults?.map((result, index) => {
        const data = result?.signalValue?.values || []

        return (
          <ChartTiming
            handleClickOnChart={() => handleClickOnChartForStrobe(index, result.resultId)}
            key={result.resultId}
            resultId={result.resultId}
            index={index}
            title={`${measurement?.name} ${formatDate(result.timestamp, DATE_TEMPLATE)}`}
            data={data}
            chartButtonRef={chartStrobesRefs.current && chartStrobesRefs.current[index]}
            durationInSec={measurement?.signalDescription?.durationInSec}
          />
        )
      })
    }
  }

  if (measurementResults.length === 0) {
    return <TextInfo>Результаты отсутствуют</TextInfo>
  }

  return (
    selectedMeasurementId &&
    measurement && (
      <>
        <div className={styles['wrapper-charts']} ref={wrapperRef}>
          {buildScalarChart()}
          {isTypeSpectrum && (
            <ChartSpectrum
              measurementResults={measurementResults}
              selectedMeasurement={measurement}
              onChartClick={(index, resultId) => handleClickOnChartSpectrum(index, resultId)}
              chartStrobesRefs={chartStrobesRefs}
            />
          )}
          {buildTimingCharts()}
        </div>

        <ExpressSidebar
          onClickExpressStrobeButton={handleClickExpressStrobeButton}
          onClickExpressSectionButton={handleClickExpressSectionButton}
          onClickExpressMarkerButton={handleClickExpressMarkerButton}
          onClickExpressBarButton={handleClickExpressBarButton}
          expressStrobeAnalysisItems={calculateExpressStrobeAnalysis()}
          itemStrobesRefs={itemStrobesRefs}
          itemSectionsRefs={itemSectionsRefs}
          itemMarkersRefs={itemMarkersRefs}
          itemBarsRefs={itemBarsRefs}
          durationInSec={measurement?.signalDescription?.durationInSec}
          dataLength={measurementResults[0]?.signalValue?.values.length || 1}
          isTypeSpectrum={isTypeSpectrum}
          isTypeScalar={isTypeScalar}
          isTypeTiming={isTypeTiming}
        />
      </>
    )
  )
}

export default Charts
