import cn from 'classnames'
import type {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  PropsWithChildren
} from 'react'

import styles from './table-wrapper.module.css'

type TableWrapperProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

const TableWrapper: FC<PropsWithChildren<TableWrapperProps>> = ({
  children,
  className,
  ...props
}) => (
  <div className={cn(styles.table, className)} {...props}>
    {children}
  </div>
)

export default TableWrapper
