import type { IChartTiming } from '@/types/chart/charts.type'
import { maxBy, minBy } from 'lodash'
import type { RefObject } from 'react'

export const getDiapason = (chartDomBlock: RefObject<HTMLDivElement>) => {
  if (chartDomBlock.current) {
    return Math.round(chartDomBlock.current.getBoundingClientRect().width / 2)
  }
}

export const getThinnedData = (
  sourceData: number[],
  diapason: number,
  rangeData: number[],
  durationInSec: number
): IChartTiming[] => {
  const [minValue, maxValue] = rangeData

  // Формируем данные для графика с дополнительными полями
  const chartTiming = sourceData.map((item, index, array) => ({
    value: item,
    sourceIndex: index,
    index: index,
    time: index / (array.length / durationInSec)
  }))

  const slicedChartTiming = chartTiming.slice(minValue, maxValue)

  if (slicedChartTiming.length <= diapason * 2) {
    return slicedChartTiming
  }

  const interval = Math.round((maxValue - minValue) / diapason)

  const sampleData: IChartTiming[] = []
  for (let i = 0; i < slicedChartTiming.length; i += interval) {
    const segment = slicedChartTiming.slice(i, i + interval)

    const min = minBy(segment, 'value') as IChartTiming
    const max = maxBy(segment, 'value')

    if (min && max) {
      if (min.sourceIndex < max.sourceIndex) {
        sampleData.push(min, max)
      } else {
        sampleData.push(max, min)
      }
    }
  }

  return sampleData.map((item, index) => ({ ...item, index }))
}
