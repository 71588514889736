import { ApiResource, ApiTag } from '@/constants/store/api.constants'
import type { TPlan } from '@/types/plan.type'

import { baseApi } from './api-base-query'

interface IGetPlanResponse {
  content: TPlan[]
}

interface ICreatePlanResponse {
  id: string
}

export const plansApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlans: builder.query<IGetPlanResponse, void>({
      query: () => ({ url: ApiResource.Plans, method: 'GET' }),
      providesTags: [ApiTag.Plans]
    }),

    createPlan: builder.mutation<ICreatePlanResponse, TPlan>({
      query: (data) => ({ url: ApiResource.Plans, method: 'POST', body: data }),
      invalidatesTags: [ApiTag.Plans]
    }),

    executePlan: builder.mutation<null, string>({
      query: (planId) => ({
        url: `${ApiResource.Plans}/${planId}/task`,
        method: 'POST',
        body: { type: 'EXECUTE_NOW' }
      }),
      invalidatesTags: [ApiTag.Plans]
    }),

    changePlan: builder.mutation<null, TPlan>({
      query: (data) => ({
        url: `${ApiResource.Plans}/${data.planId}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: [ApiTag.Plans]
    }),

    removePlan: builder.mutation<null, string>({
      query: (planId) => ({
        url: `${ApiResource.Plans}/${planId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [ApiTag.Plans]
    })
  })
})

export const { useGetPlansQuery, useExecutePlanMutation, useCreatePlanMutation, useChangePlanMutation } = plansApi
