import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasuringPointQuery } from '@/store/api/points.api'

const useGetMeasuringPoint = () => {
  const { selectedPointId } = useTypedSelector((state) => state.globalReducer)

  return useGetMeasuringPointQuery(selectedPointId, {
    skip: !selectedPointId
  })
}
export default useGetMeasuringPoint
