import type { FormItemProps } from 'antd'
import { Form } from 'antd'
import type { FC, PropsWithChildren } from 'react'

import styles from './form-item-mcm.module.css'

const FormItemApp: FC<PropsWithChildren<FormItemProps>> = ({
  children,
  ...props
}) => (
  <Form.Item {...props} className={styles['form-input']}>
    {children}
  </Form.Item>
)

export default FormItemApp
