export const enum EAmplitudeMode {
  DB = 'DB',
  LOG = 'LOG',
  LINER = 'LINER'
}

export const enum EFrequencyMode {
  LOG = 'LOG',
  Liner = 'LINER'
}
