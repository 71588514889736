import type { IToolProps } from '@/components/mnemonic/ui/tool.interface'
import { useGetMnemoStats } from '@/hooks/api/use-get-mnemo-stats'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type Konva from 'konva'
import type { FC } from 'react'
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { Circle, Group, Line, Transformer, Wedge } from 'react-konva'

const StatisticalIndicatorElement: FC<IToolProps> = ({
  element,
  updateMnemonicElement,
  draggable,
  parentSize,
  setContextMenuOptions
}) => {
  const circleRef = useRef<Konva.Wedge>(null)
  const groupRef = useRef<Konva.Group>(null)
  const pointRef = useRef<Konva.Circle>(null)
  const lineRef = useRef<Konva.Line>(null)
  const transformerRef = useRef<Konva.Transformer>(null)

  const { setMnemoSchemeItem } = useActions()

  const { mnemoSchemeItem } = useTypedSelector((state) => state.mnemonicReducer)

  const { data: dataMnemoStats } = useGetMnemoStats()

  const dangerState = dataMnemoStats?.mnemoElementState?.find(
    (indicator) => indicator.mnemoElementId === element?.mnemoElementId
  )

  const getCellAngle = useCallback(
    (
      cellName:
        | 'levelNoneCount'
        | 'level1Count'
        | 'level2Count'
        | 'level3Count'
        | 'level4Count'
    ) => {
      let sum = 0
      let angle = 360
      let percent = 100

      if (!dangerState?.statisticalNodeStateDto) return angle / 5

      for (const key in dangerState.statisticalNodeStateDto) {
        sum +=
          dangerState.statisticalNodeStateDto[
            key as keyof typeof dangerState.statisticalNodeStateDto
          ]
      }

      if (sum === 0) return angle / 5

      percent = (dangerState.statisticalNodeStateDto[cellName] / sum) * 100

      angle = (360 * percent) / 100

      return angle
    },
    [dangerState]
  )

  useEffect(() => {
    if (!transformerRef?.current || !groupRef?.current) return

    transformerRef.current.nodes([groupRef?.current])
    transformerRef.current.getLayer()?.batchDraw()
  }, [])

  //для того чтобы не прыгало при ресайзе
  useLayoutEffect(() => {
    if (!groupRef?.current) return

    groupRef.current.scaleX(1)
    groupRef.current.scaleY(1)
  }, [element])

  return (
    <>
      {element?.pointerPosition ? (
        <Line
          ref={lineRef}
          points={[
            pointRef?.current?.attrs.x || element?.pointerPosition?.x || 0,
            pointRef?.current?.attrs.y || element?.pointerPosition?.y || 0,
            groupRef?.current?.attrs.x || element?.x || 0,
            groupRef?.current?.attrs.y || element?.y || 0
          ]}
          stroke={'#000'}
          strokeWidth={1}
        />
      ) : null}

      <Group
        ref={groupRef}
        onClick={
          draggable
            ? () => {
                if (element) {
                  setMnemoSchemeItem(element)
                }
              }
            : undefined
        }
        onContextMenu={(e) => {
          e.evt.preventDefault()

          const pointerPosition = e.target.getStage()?.getPointerPosition()

          if (!pointerPosition || !setContextMenuOptions) return

          setContextMenuOptions({
            isOpen: true,
            position: {
              x: pointerPosition.x,
              y: pointerPosition.y
            }
          })
        }}
        onMouseEnter={(e) => {
          // style stage container:
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'grab'
        }}
        onMouseDown={(e) => {
          // style stage container:
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'grabbing'
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'default'
        }}
        onTransformEnd={() => {
          const node = groupRef.current

          if (
            !node ||
            !updateMnemonicElement ||
            !element?.statisticalIndicatorElement
          )
            return

          const scaleX = node.scaleX()
          const scaleY = node.scaleY()

          updateMnemonicElement({
            ...element,
            x: node.x(),
            y: node.y(),
            statisticalIndicatorElement: {
              ...element.statisticalIndicatorElement,
              width: node.width() * scaleX,
              height: node.height() * scaleY
            }
          })
        }}
        draggable={draggable}
        onDragEnd={(e) => {
          if (
            !updateMnemonicElement ||
            !element?.mnemoSchemeId ||
            e.target.attrs.x < 0 ||
            e.target.attrs.y < 0
          )
            return

          updateMnemonicElement({
            ...element,
            x: e.target.attrs.x,
            y: e.target.attrs.y
          })
        }}
        width={Math.abs(element?.statisticalIndicatorElement?.width || 15)}
        height={Math.abs(element?.statisticalIndicatorElement?.height || 15)}
        x={element?.x}
        y={element?.y}
      >
        <Wedge
          ref={circleRef}
          stroke={
            mnemoSchemeItem?.mnemoElementId === element?.mnemoElementId
              ? '#40a9ff'
              : undefined
          }
          fill={'rgb(132, 208, 149)'}
          angle={getCellAngle('level1Count')}
          radius={Math.abs(element?.statisticalIndicatorElement?.width || 15)}
          rotation={
            getCellAngle('level4Count') +
            getCellAngle('levelNoneCount') * 2 +
            getCellAngle('level3Count') +
            getCellAngle('level2Count')
          }
        />
        <Wedge
          ref={circleRef}
          stroke={
            mnemoSchemeItem?.mnemoElementId === element?.mnemoElementId
              ? '#40a9ff'
              : undefined
          }
          fill={'rgb(212, 234, 136)'}
          angle={getCellAngle('level2Count')}
          radius={Math.abs(element?.statisticalIndicatorElement?.width || 15)}
          rotation={
            getCellAngle('level4Count') +
            getCellAngle('levelNoneCount') * 2 +
            getCellAngle('level3Count')
          }
        />
        <Wedge
          ref={circleRef}
          stroke={
            mnemoSchemeItem?.mnemoElementId === element?.mnemoElementId
              ? '#40a9ff'
              : undefined
          }
          fill={'rgb(253, 233, 167)'}
          angle={getCellAngle('level3Count')}
          radius={Math.abs(element?.statisticalIndicatorElement?.width || 15)}
          rotation={
            getCellAngle('level4Count') + getCellAngle('levelNoneCount') * 2
          }
        />
        <Wedge
          ref={circleRef}
          stroke={
            mnemoSchemeItem?.mnemoElementId === element?.mnemoElementId
              ? '#40a9ff'
              : undefined
          }
          fill={'#ff9774'}
          angle={getCellAngle('level4Count')}
          radius={Math.abs(element?.statisticalIndicatorElement?.width || 15)}
          rotation={getCellAngle('levelNoneCount') * 2}
        />
        <Wedge
          ref={circleRef}
          stroke={
            mnemoSchemeItem?.mnemoElementId === element?.mnemoElementId
              ? '#40a9ff'
              : undefined
          }
          fill={'rgb(205, 209, 239)'}
          angle={getCellAngle('levelNoneCount')}
          radius={Math.abs(element?.statisticalIndicatorElement?.width || 15)}
          rotation={getCellAngle('levelNoneCount')}
        />
      </Group>

      <Transformer
        flipEnabled={false}
        resizeEnabled={draggable}
        borderEnabled={draggable}
        ref={transformerRef}
        rotateEnabled={false}
        boundBoxFunc={(oldBox, newBox) => {
          const node = groupRef.current
          const line = lineRef.current

          if (!node) return oldBox

          /* const isOut =
            circle.x() < 0 ||
            circle.y() < 0 ||
            circle.x() + circle.width() * circle.getAbsoluteScale().x >=
              (parentSize?.width || 1) ||
            circle.y() + circle.height() * circle.getAbsoluteScale().y >=
              (parentSize?.height || 1);

          if (isOut)
            return {
              ...oldBox,
              height: oldBox.height - 1,
              width: oldBox.width - 1,
            }; */

          if (line) {
            line.attrs.points = [
              line.attrs.points[0],
              line.attrs.points[1],
              node.x(),
              node.y()
            ]
          }

          return newBox
        }}
        onDragMove={() => {
          const node = groupRef.current
          const newPosition = { x: node?.attrs.x, y: node?.attrs.y }

          if (!newPosition?.x || !newPosition?.y || !node) return

          if (newPosition.x < node.width() / 2) {
            newPosition.x = node.width() / 2
          }

          if (newPosition.x > Number(parentSize?.width) - node.width() / 2) {
            newPosition.x = Number(parentSize?.width) - node.width() / 2
          }

          if (newPosition.y < node.height() / 2) {
            newPosition.y = node.height() / 2
          }

          if (newPosition.y > Number(parentSize?.height) - node.height() / 2) {
            newPosition.y = Number(parentSize?.height) - node.height() / 2
          }

          node?.setPosition(newPosition)

          if (!lineRef.current) return

          lineRef.current.attrs.points = [
            lineRef.current.attrs.points[0],
            lineRef.current.attrs.points[1],
            newPosition.x,
            newPosition.y
          ]
        }}
        enabledAnchors={[
          'top-left',
          'top-right',
          'bottom-left',
          'bottom-right'
        ]}
      />

      {element?.pointerPosition ? (
        <Circle
          ref={pointRef}
          draggable={draggable}
          fill={'#000'}
          width={10}
          height={10}
          onDragEnd={(e) => {
            if (
              !updateMnemonicElement ||
              !element?.mnemoSchemeId ||
              e.target.attrs.x < 0 ||
              e.target.attrs.y < 0
            )
              return

            updateMnemonicElement({
              ...element,
              pointerPosition: {
                x: e.target.attrs.x,
                y: e.target.attrs.y
              }
            })
          }}
          onDragMove={(e) => {
            if (!lineRef.current) return

            lineRef.current.attrs.points = [
              e.target.attrs.x,
              e.target.attrs.y,
              lineRef.current.attrs.points[2],
              lineRef.current.attrs.points[3]
            ]
          }}
          x={element?.pointerPosition?.x}
          y={element?.pointerPosition?.y}
        />
      ) : null}
    </>
  )
}

export default StatisticalIndicatorElement
