import type { IMachineForm } from '@/app/configuration/components/equipments/components/machine/machine.interface'
import { MachineName } from '@/constants/machine/machines-form.constant'
import type { IMachine, TCreatedMachine } from '@/types/machine.type'
import {
  cyclicToMs,
  msToCyclic,
  msToOperational,
  msToPeriodic,
  operationalToMs,
  periodicToMs
} from '@/utils/machine/time converters.util'
import type { FormInstance } from 'antd'

export const mapToRequestMachine = (data: IMachineForm): TCreatedMachine => {
  const {
    operationalControlIntervalMillis,
    cyclicControlIntervalMillis,
    periodicControlIntervalMillis
  } = data
  return {
    ...data,
    operationalControlIntervalMillis: operationalToMs(
      operationalControlIntervalMillis
    ),
    cyclicControlIntervalMillis: cyclicToMs(cyclicControlIntervalMillis),
    periodicControlIntervalMillis: periodicToMs(periodicControlIntervalMillis),
    paused: !data[MachineName.isActivated]
  }
}

export const mapFromResponseMachine = (data: IMachine): IMachineForm => ({
  [MachineName.ShortName]: data.name,
  [MachineName.FullName]: data.fullName,
  [MachineName.Location]: data.location,
  [MachineName.Comment]: data.comment,
  [MachineName.OperationalControl]: msToOperational(
    data.operationalControlIntervalMillis
  ),
  [MachineName.CyclicControl]: msToCyclic(data.cyclicControlIntervalMillis),
  [MachineName.PeriodicControl]: msToPeriodic(
    data.periodicControlIntervalMillis
  ),
  [MachineName.isActivated]: !data.paused,
  [MachineName.RotationSpeedMin]: data.rotationSpeedMin,
  [MachineName.RotationSpeedMax]: data.rotationSpeedMax
})

export const validateNotAllZero =
  (machineForm: FormInstance<IMachineForm>) => async () => {
    const {
      operationalControlIntervalMillis,
      cyclicControlIntervalMillis,
      periodicControlIntervalMillis
    } = machineForm.getFieldsValue()
    if (
      operationalControlIntervalMillis === 0 &&
      cyclicControlIntervalMillis === 0 &&
      periodicControlIntervalMillis === 0
    ) {
      throw new Error('Все Значения ИК не могут быть равны 0')
    }
  }
