import type { FormItemProps, RadioGroupProps } from 'antd'
import { Form, Radio } from 'antd'
import cn from 'classnames'
import type { FC } from 'react'
import React from 'react'

import styles from './form-radio-group.module.css'

type FormRadioGroup = {
  labelContent?: string
  name: string
  message?: string
}

type TProps = RadioGroupProps & FormItemProps & FormRadioGroup

const FormRadioGroup: FC<TProps> = ({
  labelContent,
  name,
  initialValue,
  className,
  message,
  required = true,
  ...props
}) =>
  labelContent ? (
    <Form.Item
      label={<span style={{ fontWeight: 'bold' }}>{labelContent}</span>}
      initialValue={initialValue}
      name={name}
      className={styles['wrapper-radio-group']}
      rules={[
        {
          required: required,
          message: message || `Поле «${labelContent}» обязательно`
        }
      ]}
    >
      <Radio.Group
        className={cn(styles['radio-group'], className)}
        {...props}
      />
    </Form.Item>
  ) : (
    <Form.Item
      initialValue={initialValue}
      name={name}
      className={styles.item}
      rules={[
        {
          required: required,
          message: message || `Поле «${labelContent}» обязательно`
        }
      ]}
    >
      <Radio.Group
        className={cn(styles['radio-group'], className)}
        {...props}
      />
    </Form.Item>
  )

export default FormRadioGroup
