import type { IChartTiming } from '@/types/chart/charts.type'
import type { TStrobeOptions } from '@/types/express-strobe.type'
import type { Dispatch, MutableRefObject, SetStateAction } from 'react'
import React from 'react'
import { ReferenceArea, ReferenceLine } from 'recharts'

import { searchStrobeBinary } from './search-strobe-binary.helper'

const generateStrobeCoordinateX = ({
  time,
  chartData,
  strobeData,
  sliderValuesHorizontal
}: {
  time: number
  chartData: IChartTiming[]
  strobeData: TStrobeOptions
  sliderValuesHorizontal: number[]
}) => {
  const currentIndex = searchStrobeBinary(chartData, time, 0, chartData.length - 1)?.index

  let currentTime: number | undefined = chartData[currentIndex]?.time

  const timeX2 = time + strobeData.duration

  const currentIndexX2 = searchStrobeBinary(chartData, timeX2, 0, chartData.length - 1)?.index
  let currentTimeX2: number | undefined = chartData[currentIndexX2]?.time

  if (chartData[sliderValuesHorizontal[1]]?.time < currentTimeX2) {
    currentTimeX2 = undefined
  }

  if (chartData[sliderValuesHorizontal[0]]?.time > currentTime) {
    currentTime = undefined
  }

  return {
    x1: currentTime,
    x2: currentTimeX2
  }
}

const generateStrobeResizeDurationCoordX = ({
  time,
  strobeData,
  chartData
}: {
  time: number
  chartData: IChartTiming[]
  strobeData: TStrobeOptions
}) => {
  const currentTime = Number(searchStrobeBinary(chartData, time + strobeData.duration, 0, chartData.length - 1)?.time)

  return {
    x: currentTime
  }
}

const generateStrobeResizePeriodCoordX = ({ time, chartData }: { time: number; chartData: IChartTiming[] }) => {
  const currentTime = Number(searchStrobeBinary(chartData, time, 0, chartData.length - 1)?.time)

  return { x: currentTime }
}

export const renderFrontStrobes = ({
  slicedChartData,
  chartData,
  strobeData,
  sliderValuesHorizontal
}: {
  slicedChartData: number[]
  chartData: IChartTiming[]
  strobeData: TStrobeOptions
  sliderValuesHorizontal: number[]
}) =>
  slicedChartData.map((time) => (
    <React.Fragment key={time}>
      <ReferenceArea
        {...generateStrobeCoordinateX({
          time,
          chartData,
          strobeData,
          sliderValuesHorizontal
        })}
        fillOpacity={1}
        fill={'#fff'}
      />
    </React.Fragment>
  ))

export const renderMainStrobes = ({
  slicedChartData,
  chartData,
  strobeData,
  sliderValuesHorizontal,
  refIndexStrobe,
  setIsAreaDrag,
  setIsResizePeriod,
  setIsResizeDuration
}: {
  slicedChartData: number[]
  chartData: IChartTiming[]
  strobeData: TStrobeOptions
  sliderValuesHorizontal: number[]
  refIndexStrobe: MutableRefObject<number>
  setIsAreaDrag: Dispatch<SetStateAction<boolean>>
  setIsResizePeriod: Dispatch<SetStateAction<boolean>>
  setIsResizeDuration: Dispatch<SetStateAction<boolean>>
}) =>
  slicedChartData.map((time, chartIndex) => {
    const coordinates = generateStrobeCoordinateX({
      time,
      chartData,
      strobeData,
      sliderValuesHorizontal
    })

    return (
      <React.Fragment key={time}>
        {coordinates?.x1 !== coordinates?.x2 ? (
          <ReferenceArea
            {...coordinates}
            label={{
              position: 'insideTopLeft',
              value: chartIndex + 1,
              fill: '#000',
              stroke: 'blue'
            }}
            fillOpacity={0}
            cursor={'move'}
            onMouseDown={() => {
              refIndexStrobe.current = chartIndex
              setIsAreaDrag(true)
            }}
          />
        ) : null}

        {chartIndex !== 0 ? (
          <ReferenceLine
            {...generateStrobeResizePeriodCoordX({
              time,
              chartData
            })}
            cursor={'col-resize'}
            strokeOpacity={0}
            strokeWidth={2}
            onMouseDown={() => {
              refIndexStrobe.current = chartIndex
              setIsResizePeriod(true)
            }}
          />
        ) : null}

        <ReferenceLine
          {...generateStrobeResizeDurationCoordX({
            time,
            chartData,
            strobeData
          })}
          strokeOpacity={0}
          strokeWidth={2}
          cursor={'col-resize'}
          onMouseDown={() => {
            refIndexStrobe.current = chartIndex
            setIsResizeDuration(true)
          }}
        />
      </React.Fragment>
    )
  })
