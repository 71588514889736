import { ControlPanel } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/control-panel/control-panel'
import type { IPointsForm } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/kinematic-points/kinematic-points.type'
import { mapTypePoint } from '@/constants/point/point.constant'
import { useGetPoints } from '@/hooks/api/use-get-points'
import { useChangeKinematicElementMutation } from '@/store/api/kinematic.api'
import type { IKinematicElement } from '@/types/kinematic.type'
import {
  errorNotificationCreate,
  successNotificationCreate
} from '@/utils/notification-creators'
import type { SelectProps } from 'antd'
import { Form, Select } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { useForm } from 'antd/lib/form/Form'
import { isEqual } from 'lodash'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import styles from './kinematic-points.module.css'

type TProps = {
  kinematicElement: IKinematicElement
  onClose: () => void
}

const KinematicPoints: FC<TProps> = ({ kinematicElement, onClose }) => {
  const [pointsForm] = useForm<IPointsForm>()
  const [changeKinematicElement, { isLoading: isUpdating }] =
    useChangeKinematicElementMutation()
  const { data: pointsData, isLoading: isLoadingPoints } = useGetPoints()
  const [initialData, setInitialData] = useState<IPointsForm | null>(null)
  const [disabledCancelButton, setDisabledCancelButton] = useState(true)
  const [disabledFinishButton, setDisabledFinishButton] = useState(true)
  const isInitialData = isEqual(initialData, pointsForm.getFieldsValue())
  const fieldsObservable = useWatch([], pointsForm)

  useEffect(() => {
    const { measurementPointIds } = kinematicElement

    pointsForm.setFieldValue('points', measurementPointIds)
    setInitialData({ points: measurementPointIds })
  }, [kinematicElement, pointsForm])

  useEffect(() => {
    const { validateFields } = pointsForm
    setDisabledCancelButton(isInitialData)

    const checkValidateFields = async (): Promise<void> => {
      try {
        await validateFields({
          validateOnly: true,
          recursive: true
        })

        setDisabledFinishButton(isInitialData)
      } catch (e) {
        setDisabledFinishButton(true)
      }
    }

    checkValidateFields()
  }, [disabledCancelButton, pointsForm, fieldsObservable, isInitialData])

  const options: SelectProps['options'] = pointsData?.content.map((point) => ({
    label: `${point.name}: ${mapTypePoint[
      point.physicalQuantityType
    ].toLowerCase()}`,
    value: point.pointId
  }))

  const handleFinish = async () => {
    try {
      try {
        await pointsForm.validateFields()
      } catch (e) {
        errorNotificationCreate(e, {
          message: 'Форма содержит ошибки'
        })
        return
      }
      if (kinematicElement) {
        await changeKinematicElement({
          ...kinematicElement,
          measurementPointIds: pointsForm.getFieldValue('points')
        }).unwrap()
        successNotificationCreate(
          `Список точек измерения ${kinematicElement.kinematicBlockName} обновлён`
        )
      }
      onClose()
      setDisabledFinishButton(true)
    } catch (e) {
      errorNotificationCreate(e)
    }
  }

  const handleCancel = () => {
    if (initialData) {
      const { setFieldsValue, resetFields } = pointsForm
      resetFields()
      setFieldsValue(initialData)
    }
  }

  return (
    <div className={styles['container']}>
      <Form
        className={styles['drawer-kinematic-form']}
        layout='vertical'
        form={pointsForm}
      >
        <Form.Item name='points' label='Список доступных точек измерения'>
          <Select
            mode='multiple'
            loading={isLoadingPoints}
            style={{ width: '100%' }}
            placeholder='Выберите точки измерения'
            options={options}
            showSearch={false}
          />
        </Form.Item>
      </Form>
      <ControlPanel
        onClickCancel={handleCancel}
        onClickSave={handleFinish}
        disabledCancel={disabledCancelButton}
        disabledSave={disabledFinishButton}
        isLoadingSave={isUpdating}
      />
    </div>
  )
}

export default KinematicPoints
