import cn from 'classnames'
import type { FC, PropsWithChildren } from 'react'

import styles from '@/components/UI/layouts/work-space/work-space.module.css'

const WorkSpace: FC<PropsWithChildren> = ({ children }) => (
  <div className={cn(styles.container)}>{children}</div>
)

export default WorkSpace
