import type { CSSProperties } from 'react'
import { useEffect, useRef, useState } from 'react'

const useFade = (duration = 300) => {
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const node = ref.current
    if (node) {
      setIsVisible(true)
    }

    return () => {
      setIsVisible(false)
    }
  }, [])

  const style: CSSProperties = {
    opacity: isVisible ? 1 : 0,
    transition: `opacity ${duration}ms ease-in-out`
  }

  return { ref, style }
}

export default useFade
