export const Vp3701Params = {
  Vp3701Id: 'vp3701Id',
  Name: 'name',
  Comment: 'comment',
  Location: 'location',
  SerialNumber: 'serialNumber',
  Modification: 'modification',
  Status: 'status',
  PowerType: 'powerType',
  Firmware: 'firmware',
  Ssid: 'ssid',
  AccelType: 'accelType',
  ThermoType: 'thermoType',
  BatteryCharge: 'batteryCharge',
  ChannelUnitType1: 'channelUnitType1',
  ChannelUnitType2: 'channelUnitType2',
  ChannelUnitType3: 'channelUnitType3',
  ChannelUnitType4: 'channelUnitType4',
  ChannelUnitType5: 'channelUnitType5',
  ConnectionStatus: 'connectionStatus',
  LastErrors: 'lastErrors',
  StatusUpdatingTimestamp: 'statusUpdatingTimestamp'
} as const
