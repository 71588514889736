import type { TBarOptions } from '@/store/reducers/charts/express-bars.slice'
import type { IChartSpectrum } from '@/types/chart/chart-spectrun.type'
import type { Dispatch, MutableRefObject, SetStateAction } from 'react'
import React from 'react'
import { ReferenceDot, ReferenceLine } from 'recharts'

import { binarySearch } from './calculates.helper'

export const getChartDataForExpressBarsModulates = ({
  barOptions,
  chartData,
  bar,
  separator
}: {
  bar: IChartSpectrum | number
  barOptions: TBarOptions
  chartData: IChartSpectrum[]
  separator: -1 | 1
}): (IChartSpectrum | number)[] => {
  const modulateBars: (IChartSpectrum | number)[] = []

  for (let i = 0; i < barOptions.countOfModulateBars; i++) {
    const offsetFreq = barOptions.modulateFreq * (i + 1) * separator
    const lastFreq = chartData[chartData.length - 1]?.frequency
    const mainFreq = typeof bar === 'number' ? bar : bar?.frequency

    const modulateFreq = mainFreq + offsetFreq

    if (mainFreq + offsetFreq <= lastFreq) {
      const chartIndex = binarySearch(chartData, mainFreq + offsetFreq, 0, chartData.length - 1)?.index

      modulateBars.push(chartData[chartIndex])
    } else {
      modulateBars.push(modulateFreq)
    }
  }

  return modulateBars
}

const renderExpressModulateBarsChart = ({
  bar,
  separator,
  barOptions,
  chartData,
  setIsModulateAreaDrag,
  refIndexModulateBar,
  refIndexBar,
  indexMainBar
}: {
  bar: IChartSpectrum | number
  separator: -1 | 1
  barOptions: TBarOptions
  chartData: IChartSpectrum[]
  setIsModulateAreaDrag: Dispatch<SetStateAction<boolean>>
  refIndexModulateBar: MutableRefObject<number>
  refIndexBar: MutableRefObject<number>
  indexMainBar: number
}) => {
  const modulatesBars = getChartDataForExpressBarsModulates({
    chartData,
    barOptions,
    bar,
    separator
  })

  return modulatesBars.map((modulateBar, i) => (
    <React.Fragment key={i}>
      <ReferenceLine
        x={typeof modulateBar === 'number' ? undefined : modulateBar?.frequency}
        stroke={'#000'}
        strokeWidth={1}
        strokeOpacity={0.7}
        strokeDasharray={'3 10'}
      />
      <ReferenceLine
        x={typeof modulateBar === 'number' ? undefined : modulateBar?.frequency}
        stroke={'#000'}
        strokeWidth={5}
        strokeOpacity={0}
        cursor={'move'}
        onMouseDown={() => {
          setIsModulateAreaDrag(true)
          refIndexModulateBar.current = (i + 1) * separator
          refIndexBar.current = indexMainBar
        }}
      />
      <ReferenceDot
        r={3}
        fill={refIndexBar.current === indexMainBar ? '#0b7ae8' : '#000'}
        x={typeof modulateBar === 'number' ? undefined : modulateBar?.frequency}
        y={typeof modulateBar === 'number' ? undefined : modulateBar?.amplitude}
        cursor={'move'}
        onMouseDown={() => {
          setIsModulateAreaDrag(true)
          refIndexModulateBar.current = (i + 1) * separator
        }}
      />
    </React.Fragment>
  ))
}

export const getChartDataForExpressBars = ({
  mainFreq,
  countOfMainBars,
  chartData
}: TBarOptions & {
  chartData: IChartSpectrum[]
}) => {
  const bars = []

  if (countOfMainBars === 0) return

  for (let i = 1; i <= countOfMainBars; i++) {
    const freq = mainFreq * i
    const lastFreq = chartData[chartData.length - 1]?.frequency

    if (freq <= lastFreq) {
      const foundedIndex = binarySearch(chartData, freq, 0, chartData.length - 1)?.index

      bars.push(chartData[foundedIndex])
    } else {
      bars.push(freq)
    }
  }

  return bars
}

export const renderExpressBarsChart = ({
  expressBars,
  chartData,
  setIsMainAreaDragBar,
  refIndexBar,
  barOptions,
  setIsModulateAreaDrag,
  refIndexModulateBar,
  isModulateVisible
}: {
  expressBars: (IChartSpectrum | number)[] | undefined
  chartData: IChartSpectrum[]
  setIsMainAreaDragBar: Dispatch<SetStateAction<boolean>>
  refIndexBar: MutableRefObject<number>
  barOptions: TBarOptions
  setIsModulateAreaDrag: Dispatch<SetStateAction<boolean>>
  refIndexModulateBar: MutableRefObject<number>
  isModulateVisible: boolean
}) =>
  expressBars?.map((bar, index) => (
    <React.Fragment key={typeof bar === 'number' ? bar : bar?.frequency}>
      {isModulateVisible
        ? renderExpressModulateBarsChart({
            bar,
            separator: -1,
            barOptions,
            chartData,
            setIsModulateAreaDrag,
            refIndexModulateBar,
            refIndexBar,
            indexMainBar: index + 1
          })
        : null}

      <ReferenceLine
        x={typeof bar === 'number' ? undefined : bar?.frequency}
        stroke={'#000'}
        strokeWidth={2}
        strokeOpacity={0.7}
        label={{
          position: 'insideTopLeft',
          value: `${index + 1}A`,
          fill: '#000',
          stroke: 'blue'
        }}
      />
      <ReferenceLine
        x={typeof bar === 'number' ? undefined : bar?.frequency}
        stroke={'#000'}
        strokeWidth={5}
        strokeOpacity={0}
        cursor={'move'}
        onMouseDown={() => {
          refIndexBar.current = index + 1
          setIsMainAreaDragBar(true)
        }}
      />
      <ReferenceDot
        r={3}
        x={typeof bar === 'number' ? undefined : bar?.frequency}
        y={typeof bar === 'number' ? undefined : bar?.amplitude}
        cursor={'move'}
        fill={refIndexBar.current - 1 === index ? '#0b7ae8' : '#000'}
        onMouseDown={() => {
          refIndexBar.current = index + 1
          setIsMainAreaDragBar(true)
        }}
      />

      {isModulateVisible
        ? renderExpressModulateBarsChart({
            bar,
            separator: 1,
            barOptions,
            chartData,
            setIsModulateAreaDrag,
            refIndexModulateBar,
            refIndexBar,
            indexMainBar: index + 1
          })
        : null}
    </React.Fragment>
  ))
