import MachinesList from '@/components/machines-list/machines-list'
import MeasurementsList from '@/components/measurements-list/measurements-list'
import PointsList from '@/components/points-list/points-list'

import MnemonicTools from '../mnemonic-tools/mnemonic-tools'

const MnemonicLeftSidebar = () => (
  <>
    <MachinesList />

    <PointsList />

    <MeasurementsList />

    <MnemonicTools />
  </>
)

export default MnemonicLeftSidebar
