import { ECodeError } from '@/enums/device/vp3701/code-error.enum'
import { EProcessError } from '@/enums/device/vp3701/process-error.enum'
import { EWeightError } from '@/enums/device/vp3701/weight-error.enum'
import type { IVp3701Error } from '@/types/device/vp3701/vp3701-error.interface'

export const vp3701Errors: { [key in ECodeError]: IVp3701Error } = {
  [ECodeError.ISR_1]: {
    description: 'Невозможность запустить обработчик внешних прерываний',
    weight: EWeightError.CRITICAL,
    process: EProcessError.TS
  },
  [ECodeError.ISR_2]: {
    description: 'KX-134 не поднимает флаг прерываний или логическая 1 не доходит до esp32',
    weight: EWeightError.CRITICAL,
    process: EProcessError.TS
  },
  [ECodeError.PSRAM_1]: {
    description: 'Ошибка выделения внешней памяти PSRAM',
    weight: EWeightError.CRITICAL,
    process: EProcessError.TS
  },
  [ECodeError.SPI_1]: {
    description: 'Память переполнилась раньше, чем было набрано необходимое количество фреймов',
    weight: EWeightError.MINIMUM,
    process: EProcessError.TS
  },
  [ECodeError.I2C_1]: {
    description: 'Ошибка отправки информации по I2C',
    weight: EWeightError.CRITICAL,
    process: EProcessError.T
  },
  [ECodeError.I2C_2]: {
    description: 'Ошибка чтения информации по I2C',
    weight: EWeightError.CRITICAL,
    process: EProcessError.T
  },
  [ECodeError.I2C_3]: {
    description: 'Ошибка конфигурации I2C-интерфейса',
    weight: EWeightError.CRITICAL,
    process: EProcessError.T
  },
  [ECodeError.I2C_4]: {
    description: 'Ошибка запуска драйвера-обработчика I2C',
    weight: EWeightError.CRITICAL,
    process: EProcessError.T
  },
  [ECodeError.WDT_1]: {
    description: 'Ошибка реконфигурации сторожевого таймера',
    weight: EWeightError.AVERAGE,
    process: EProcessError.FREE_RTOS
  },
  [ECodeError.TASK_1]: {
    description: 'Ошибка создания задачи GET_TEMP',
    weight: EWeightError.CRITICAL,
    process: EProcessError.FREE_RTOS_T
  },
  [ECodeError.TASK_2]: {
    description: 'Ошибка создания задачи GET_read_angle',
    weight: EWeightError.CRITICAL,
    process: EProcessError.FREE_RTOS_TS
  },
  [ECodeError.TASK_3]: {
    description: 'Ошибка создания задачи wifi_send',
    weight: EWeightError.CRITICAL,
    process: EProcessError.FREE_RTOS_WIFI
  },
  [ECodeError.NVC]: {
    description: 'Ошибка инициализации FLASH',
    weight: EWeightError.CRITICAL,
    process: EProcessError.FREE_RTOS_WIFI
  },
  [ECodeError.WIFI_1]: {
    description: 'В ходе сеанса происходило отключение VP3701 от WIFI',
    weight: EWeightError.AVERAGE,
    process: EProcessError.WIFI
  },
  [ECodeError.WIFI_2]: {
    description: 'Ошибка на одном из этапов конфигурации WIFI/первичного подключения',
    weight: EWeightError.CRITICAL,
    process: EProcessError.WIFI
  },
  [ECodeError.HTTP_1]: {
    description: 'Один из пришедших от сервера JSON-файлов пустой или VP3701 не может его распарсить',
    weight: EWeightError.MINIMUM,
    process: EProcessError.HTTP
  },
  [ECodeError.HTTP_2]: {
    description: 'Произошла ошибка в установленном HTTP-соединении',
    weight: EWeightError.MINIMUM,
    process: EProcessError.HTTP
  },
  [ECodeError.HTTP_3]: {
    description: 'Произошла ошибка при открытии HTTP-соединения',
    weight: EWeightError.AVERAGE,
    process: EProcessError.HTTP
  },
  [ECodeError.HTTP_4]: {
    description: 'Произошла ошибка при отправке HTTP-пакета',
    weight: EWeightError.AVERAGE,
    process: EProcessError.HTTP
  },
  [ECodeError.KX_1]: {
    description: 'Ошибка инициализации KX-134',
    weight: EWeightError.CRITICAL,
    process: EProcessError.TS
  },
  [ECodeError.MLX_1]: {
    description: 'Ошибка инициализации MLX1',
    weight: EWeightError.CRITICAL,
    process: EProcessError.T
  },
  [ECodeError.HTTP_5]: {
    description:
      'Один из фреймов бинарных данных не был отправлен. Процесс измерения виброускорения прерван во избежание разрыва временного сигнала.',
    weight: EWeightError.AVERAGE,
    process: EProcessError.HTTP
  }
}
