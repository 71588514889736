import { settingsLinks } from '@/constants/navigation/links.constant'
import type { FC } from 'react'
import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from '@/app/settings/components/navigation-settings/navigation-settings.module.css'

export const NavigationSettings: FC = () => {
  const isActiveClass = ({ isActive }: { isActive: boolean }): string =>
    isActive
      ? `${styles['nav-list-link']} ${styles['nav-list-link-active']}`
      : styles['nav-list-link']

  return (
    <ul className={styles['nav-list']}>
      {settingsLinks.map((item) => (
        <li key={item.title} className={styles['nav-list__item']}>
          <NavLink to={item.to} className={isActiveClass}>
            {item.title}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}

export default NavigationSettings
