import type { IToolProps } from '@/components/mnemonic/ui/tool.interface'
import { EThresholdLevel } from '@/enums/threshold/threshold-level.enum'
import { useGetMnemoStats } from '@/hooks/api/use-get-mnemo-stats'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type Konva from 'konva'
import type { FC } from 'react'
import { useLayoutEffect, useRef } from 'react'
import { Circle, Group, Line, Rect, Text } from 'react-konva'

const MeasurementLastValueElement: FC<IToolProps> = ({
  element,
  updateMnemonicElement,
  draggable,
  parentSize,
  setContextMenuOptions
}) => {
  const groupRef = useRef<Konva.Group>(null)
  const rectRef = useRef<Konva.Rect>(null)
  const textRef = useRef<Konva.Text>(null)
  const pointRef = useRef<Konva.Circle>(null)
  const lineRef = useRef<Konva.Line>(null)

  const { setMnemoSchemeItem } = useActions()

  const { mnemoSchemeItem } = useTypedSelector((state) => state.mnemonicReducer)

  const { data: dataMnemoStats } = useGetMnemoStats()

  const dangerState = dataMnemoStats?.mnemoElementState?.find(
    (indicator) => indicator.mnemoElementId === element?.mnemoElementId
  )?.measurementLastValueStateDto

  useLayoutEffect(() => {
    if (!rectRef.current) return

    rectRef.current.attrs.width = textRef.current?.width()
    rectRef.current.attrs.height = textRef.current?.height()
  }, [dangerState])

  if (!element || !parentSize) return null

  return (
    <>
      {element.pointerPosition ? (
        <Line
          ref={lineRef}
          points={[
            pointRef.current?.attrs.x || element.pointerPosition?.x || 0,
            pointRef.current?.attrs.y || element.pointerPosition?.y || 0,
            groupRef.current?.attrs.x + 10 || element.x + 10 || 0,
            groupRef.current?.attrs.y + 10 || element.y + 10 || 0
          ]}
          stroke={'#000'}
          strokeWidth={1}
        />
      ) : null}

      <Group
        onClick={
          draggable
            ? () => {
                if (element) {
                  setMnemoSchemeItem(element)
                }
              }
            : undefined
        }
        onContextMenu={(e) => {
          e.evt.preventDefault()

          const pointerPosition = e.target.getStage()?.getPointerPosition()

          if (!pointerPosition || !setContextMenuOptions) return

          setContextMenuOptions({
            isOpen: true,
            position: {
              x: pointerPosition.x,
              y: pointerPosition.y
            }
          })
        }}
        onMouseEnter={(e) => {
          // style stage container:
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'grab'
        }}
        onMouseDown={(e) => {
          // style stage container:
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'grabbing'
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'default'
        }}
        ref={groupRef}
        x={element.x}
        y={element.y}
        draggable={draggable}
        onDragEnd={(e) => {
          if (
            !updateMnemonicElement ||
            !element.mnemoSchemeId ||
            e.target.attrs.x < 0 ||
            e.target.attrs.y < 0
          )
            return

          updateMnemonicElement({
            ...element,
            x: e.target.attrs.x,
            y: e.target.attrs.y
          })
        }}
        onDragMove={(e) => {
          const node = e.target
          const textNode = textRef.current
          const newPosition = { x: node?.attrs.x, y: node?.attrs.y }

          if (!newPosition?.x || !newPosition?.y || !node || !textNode) return

          if (newPosition.x < 0) {
            newPosition.x = 0
          }

          if (newPosition.x > parentSize.width - textNode.width()) {
            newPosition.x = parentSize.width - textNode.width()
          }

          if (newPosition.y < 0) {
            newPosition.y = 0
          }

          if (newPosition.y > parentSize?.height - textNode.height()) {
            newPosition.y = parentSize?.height - textNode.height()
          }

          node?.setPosition(newPosition)

          if (!lineRef.current) return

          lineRef.current.attrs.points = [
            lineRef.current.attrs.points[0],
            lineRef.current.attrs.points[1],
            newPosition.x + 10,
            newPosition.y + 10
          ]
        }}
      >
        <Rect
          ref={rectRef}
          fill={
            dangerState?.dangerLevelType === EThresholdLevel.LEVEL_1
              ? 'rgb(132, 208, 149)'
              : dangerState?.dangerLevelType === EThresholdLevel.LEVEL_2
              ? 'rgb(212, 234, 136)'
              : dangerState?.dangerLevelType === EThresholdLevel.LEVEL_3
              ? 'rgb(253, 233, 167)'
              : dangerState?.dangerLevelType === EThresholdLevel.LEVEL_4
              ? '#ff9774'
              : 'rgb(205, 209, 239)'
          }
          stroke={
            mnemoSchemeItem?.mnemoElementId === element.mnemoElementId
              ? '#40a9ff'
              : '#000'
          }
          strokeWidth={1}
          cornerRadius={8}
        />

        <Text
          ref={textRef}
          onContextMenu={(e) => {
            e.evt.preventDefault()
          }}
          text={dangerState?.lastValue?.toPrecision(5) || 'Значение'}
          padding={10}
          align={'center'}
          fontSize={
            element.measurementLastValueElement?.fontStyle?.fontSize ||
            undefined
          }
          fill={
            dangerState?.dangerLevelType === EThresholdLevel.LEVEL_4 ||
            dangerState?.dangerLevelType === EThresholdLevel.NONE
              ? 'rgb(253, 233, 167)'
              : '#000'
          }
        />
      </Group>

      {element.pointerPosition ? (
        <Circle
          ref={pointRef}
          draggable={draggable}
          fill={'#000'}
          width={10}
          height={10}
          onDragEnd={(e) => {
            if (
              !updateMnemonicElement ||
              !element.mnemoSchemeId ||
              e.target.attrs.x < 0 ||
              e.target.attrs.y < 0
            )
              return

            updateMnemonicElement({
              ...element,
              pointerPosition: {
                x: e.target.attrs.x,
                y: e.target.attrs.y
              }
            })
          }}
          onDragMove={(e) => {
            if (!lineRef.current) return

            lineRef.current.attrs.points = [
              e.target.attrs.x,
              e.target.attrs.y,
              lineRef.current.attrs.points[2],
              lineRef.current.attrs.points[3]
            ]
          }}
          x={element.pointerPosition?.x}
          y={element.pointerPosition?.y}
        />
      ) : null}
    </>
  )
}

export default MeasurementLastValueElement
