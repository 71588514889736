import { useGetMnemonicElementQuery } from '@/store/api/mnemo.api'

import { useTypedSelector } from '../use-typed-selector'
import { useGetCurrentMnemoScheme } from './use-get-current-mnemo-scheme'

export const useGetMnemonicElement = () => {
  const { mnemoSchemeItem } = useTypedSelector((state) => state.mnemonicReducer)

  const { data } = useGetCurrentMnemoScheme()

  const dataQuery = useGetMnemonicElementQuery(
    {
      mnemoSchemeId: data?.mnemoSchemeId,
      mnemoElementId: mnemoSchemeItem?.mnemoElementId
    },
    { skip: !data?.mnemoSchemeId || !mnemoSchemeItem?.mnemoElementId }
  )

  return dataQuery
}
