import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import PanelTitle from '@/components/UI/form-controls/form-title/panel-title'
import { ButtonTitle } from '@/constants/core/common.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import type { FC } from 'react'
import React from 'react'

const FormRemoveToleranceControl: FC = () => {
  const [removeToleranceControlForm] = useForm()
  const { selectedRift, rifts, zones } = useTypedSelector((state) => state.thresholdReducer)
  const { setIsOpenThresholdPopup, setSelectedRift, setRifts, setZones } = useActions()

  const handlePopupClose = () => {
    setIsOpenThresholdPopup(false)
  }

  const handleRemoveSubmit = () => {
    if (rifts.length > 1) {
      const newRifts = [...rifts]
      const newZones = [...zones]
      newRifts.splice(selectedRift, 1)
      newZones.splice(selectedRift + 1, 1)
      setRifts(newRifts)
      setZones(newZones)
      setIsOpenThresholdPopup(false)
      setSelectedRift(selectedRift)
    }
  }

  return (
    <Form form={removeToleranceControlForm} autoFocus={true} onFinish={handleRemoveSubmit}>
      <PanelTitle>Вы действительно хотите удалить границу?</PanelTitle>
      <ButtonsWrapper isSingleElement={true}>
        <ButtonPrimary title={ButtonTitle.Cancel} htmlType={'button'} onClick={handlePopupClose} />
        <ButtonPrimary title={ButtonTitle.Remove} htmlType={'submit'} />
      </ButtonsWrapper>
    </Form>
  )
}

export default FormRemoveToleranceControl
