import { setupStore } from '@/store/store'
import 'material-symbols'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import './assets/styles/index.css'

import App from './app/app'

const store = setupStore()

const mcmApp = createRoot(document.getElementById('app') as HTMLElement)

mcmApp.render(
  <Provider store={store}>
    <App />
  </Provider>
)
