import type { IToolProps } from '@/components/mnemonic/ui/tool.interface'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type Konva from 'konva'
import type { FC } from 'react'
import { useEffect, useLayoutEffect, useRef } from 'react'
import { Image, Transformer } from 'react-konva'
import useImage from 'use-image'

const StaticImageElement: FC<IToolProps> = ({ element, updateMnemonicElement, draggable, parentSize }) => {
  const imageRef = useRef<Konva.Image>(null)
  const transformerRef = useRef<Konva.Transformer>(null)
  const { baseUrl } = useTypedSelector((state) => state.serverSettingsReducer)

  const [image] = useImage(`${baseUrl}/configurator/images/${element?.staticImageElement?.fileId}/image`)

  useEffect(() => {
    if (!transformerRef?.current || !imageRef?.current) return

    transformerRef.current.nodes([imageRef?.current])
    transformerRef.current.getLayer()?.batchDraw()
  }, [])

  //для того чтобы не прыгало при ресайзе
  useLayoutEffect(() => {
    if (!imageRef?.current) return

    imageRef.current.scaleX(1)
    imageRef.current.scaleY(1)
  }, [element])

  if (!element?.staticImageElement) return null

  return (
    <>
      <Image
        ref={imageRef}
        onContextMenu={(e) => {
          e.evt.preventDefault()
        }}
        onMouseEnter={(e) => {
          // style stage container:
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'grab'
        }}
        onMouseDown={(e) => {
          // style stage container:
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'grabbing'
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'default'
        }}
        onTransformEnd={() => {
          const node = imageRef.current

          if (!node || !updateMnemonicElement || !element?.staticImageElement) return

          const scaleX = node.scaleX()
          const scaleY = node.scaleY()

          updateMnemonicElement({
            ...element,
            x: node.x(),
            y: node.y(),
            staticImageElement: {
              ...element.staticImageElement,
              width: node.width() * scaleX,
              height: node.height() * scaleY
            }
          })
        }}
        draggable={draggable}
        onDragEnd={(e) => {
          if (!updateMnemonicElement || !element?.mnemoSchemeId || e.target.attrs.x < 0 || e.target.attrs.y < 0) return

          updateMnemonicElement({
            ...element,
            x: e.target.attrs.x,
            y: e.target.attrs.y
          })
        }}
        width={element?.staticImageElement?.width ? Math.abs(element?.staticImageElement?.width) : undefined}
        height={element?.staticImageElement?.height ? Math.abs(element?.staticImageElement?.height) : undefined}
        x={element.x}
        y={element.y}
        image={image}
      />

      <Transformer
        flipEnabled={false}
        resizeEnabled={draggable}
        borderEnabled={draggable}
        ref={transformerRef}
        rotateEnabled={false}
        onDragMove={() => {
          const node = imageRef.current
          const newPosition = { x: node?.attrs.x, y: node?.attrs.y }

          if (!newPosition?.x || !newPosition?.y || !node) return

          if (newPosition.x < 0) {
            newPosition.x = 0
          }

          if (newPosition.x > Number(parentSize?.width) - node.width()) {
            newPosition.x = Number(parentSize?.width) - node.width()
          }

          if (newPosition.y < 0) {
            newPosition.y = 0
          }

          if (newPosition.y > Number(parentSize?.height) - node.height()) {
            newPosition.y = Number(parentSize?.height) - node.height()
          }

          node?.setPosition(newPosition)
        }}
        enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
      />
    </>
  )
}

export default StaticImageElement
