import svgAD from '@/assets/images/kinematic/AD_KS.svg'
import svgBD from '@/assets/images/kinematic/BD_KS.svg'
import svgBF from '@/assets/images/kinematic/BF_KS.svg'
import svgBR from '@/assets/images/kinematic/BR_KS.svg'
import svgBS from '@/assets/images/kinematic/BS_KS.svg'
import svgCD from '@/assets/images/kinematic/CD_KS.svg'
import svgCN from '@/assets/images/kinematic/CN_KS.svg'
import svgCP from '@/assets/images/kinematic/CP_KS.svg'
import svgCW from '@/assets/images/kinematic/CW_KS.svg'
import svgDCG from '@/assets/images/kinematic/DCG_KS.svg'
import svgDCM from '@/assets/images/kinematic/DCM_KS.svg'
import svgGBD from '@/assets/images/kinematic/GBD_KS.svg'
import svgGBT from '@/assets/images/kinematic/GBT_KS.svg'
import svgGB from '@/assets/images/kinematic/GB_KS.svg'
import svgHD from '@/assets/images/kinematic/HD_KS.svg'
import svgPD from '@/assets/images/kinematic/PD_KS.svg'
import svgSD from '@/assets/images/kinematic/SD_KS.svg'
import svgSG from '@/assets/images/kinematic/SG_KS.svg'
import svgSH from '@/assets/images/kinematic/SH_KS.svg'
import svgTGB from '@/assets/images/kinematic/TGB_KS.svg'
import svgTN from '@/assets/images/kinematic/TN_KS.svg'
import svgTW from '@/assets/images/kinematic/TW_KS.svg'
import type { IAttributesKinematicBlocks } from '@/components/kinematic-space/kinematic-space.type'
import { EKinematicBlockType } from '@/types/kinematic.type'

export const attributesKinematicBlocks: IAttributesKinematicBlocks[] = [
  {
    type: EKinematicBlockType.BR,
    title: 'Подшипник Качения',
    iconSrc: svgBR,
    width: 40,
    height: 80
  },
  {
    type: EKinematicBlockType.CP,
    title: 'Муфта',
    iconSrc: svgCP,
    width: 40,
    height: 80
  },
  {
    type: EKinematicBlockType.SH,
    title: 'Вал',
    iconSrc: svgSH,
    width: 40,
    height: 80
  },
  {
    type: EKinematicBlockType.BS,
    title: 'Подшипниковая опора скольжения',
    iconSrc: svgBS,
    width: 40,
    height: 80
  },
  {
    type: EKinematicBlockType.BF,
    title: 'Подшипниковая опора жидкостного трения (ПЖТ)',
    iconSrc: svgBF,
    width: 40,
    height: 80
  },
  {
    type: EKinematicBlockType.AD,
    title: 'Асинхронный двигатель',
    iconSrc: svgAD,
    width: 120,
    height: 80
  },
  {
    type: EKinematicBlockType.SD,
    title: 'Синхронный двигатель',
    iconSrc: svgSD,
    width: 120,
    height: 80
  },
  {
    type: EKinematicBlockType.SG,
    title: 'Синхронный генератор',
    iconSrc: svgSG,
    width: 120,
    height: 80
  },
  {
    type: EKinematicBlockType.DCM,
    title: 'Двигатель постоянного тока',
    iconSrc: svgDCM,
    width: 120,
    height: 80
  },
  {
    type: EKinematicBlockType.DCG,
    title: 'Генератор постоянного тока',
    iconSrc: svgDCG,
    width: 120,
    height: 80
  },
  {
    type: EKinematicBlockType.GB,
    title: 'Одноступенчатая зубчатая передача',
    iconSrc: svgGB,
    width: 40,
    height: 160
  },
  {
    type: EKinematicBlockType.GBD,
    title: 'Двухступенчатая зубчатая передача со сменой направления вращения',
    iconSrc: svgGBD,
    width: 40,
    height: 240
  },
  {
    type: EKinematicBlockType.GBT,
    title: 'Двухступенчатая зубчатая передача с разделением момента',
    iconSrc: svgGBT,
    width: 40,
    height: 240
  },
  {
    type: EKinematicBlockType.TGB,
    title: 'Двухходовая двухступенчатая зубчатая передача',
    iconSrc: svgTGB,
    width: 40,
    height: 240
  },
  {
    type: EKinematicBlockType.TW,
    title: 'Рабочее колесо турбины / вентилятора',
    iconSrc: svgTW,
    width: 40,
    height: 80
  },
  {
    type: EKinematicBlockType.CW,
    title: 'Рабочее колесо компрессор / насос',
    iconSrc: svgCW,
    width: 40,
    height: 80
  },
  {
    type: EKinematicBlockType.TN,
    title: 'Турбина',
    iconSrc: svgTN,
    width: 120,
    height: 80
  },
  {
    type: EKinematicBlockType.CN,
    title: 'Компрессор',
    iconSrc: svgCN,
    width: 120,
    height: 80
  },
  {
    type: EKinematicBlockType.BD,
    title: 'Ременная передача',
    iconSrc: svgBD,
    width: 40,
    height: 160
  },
  {
    type: EKinematicBlockType.CD,
    title: 'Цепная передача',
    iconSrc: svgCD,
    width: 40,
    height: 160
  },
  {
    type: EKinematicBlockType.HD,
    title: 'Гидропривод',
    iconSrc: svgHD,
    width: 40,
    height: 80
  },
  {
    type: EKinematicBlockType.PD,
    title: 'Пневмо / газовый привод',
    iconSrc: svgPD,
    width: 40,
    height: 80
  }
]

export const kbsWithoutIns: Array<EKinematicBlockType> = [
  EKinematicBlockType.AD,
  EKinematicBlockType.SD,
  EKinematicBlockType.DCM,
  EKinematicBlockType.DCG,
  EKinematicBlockType.TN,
  EKinematicBlockType.CW,
  EKinematicBlockType.CP,
  EKinematicBlockType.SH
]
