import cn from 'classnames'
import type {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  PropsWithChildren
} from 'react'
import React from 'react'

import styles from './buttons-wrapper.module.css'

type ButtonsWrapperProps = {
  isSingleElement?: boolean
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const ButtonsWrapper: FC<PropsWithChildren<ButtonsWrapperProps>> = ({
  isSingleElement,
  children,
  className
}) => (
  <div
    className={cn(styles['buttons-wrapper'], className, {
      [styles['buttons-wrapper-is-single']]: isSingleElement
    })}
  >
    {children}
  </div>
)

export default ButtonsWrapper
