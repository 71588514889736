import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import Spinner from '@/components/UI/elements/spinner/spinner'
import Submenu from '@/components/UI/elements/submenu/submenu'
import FormInformation from '@/components/UI/form-controls/form-information/form-information'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import WorkSpace from '@/components/UI/layouts/work-space/work-space'
import GroupList from '@/components/group-list/group-list'
import { machineConditionLinks } from '@/constants/navigation/links.constant'
import useGetMachine from '@/hooks/api/use-get-machine'
import { Flex } from 'antd'
import type { FC } from 'react'

import styles from './description.module.css'

const Description: FC = () => {
  const { data: machine, isFetching } = useGetMachine()

  return (
    <Container>
      <LeftBar>
        <GroupList />
      </LeftBar>

      <WorkSpace>
        <Submenu links={machineConditionLinks} />
        {isFetching && <Spinner />}
        {machine && (
          <Flex
            className={styles.container}
            vertical
            justify={'space-evenly'}
            gap={6}
          >
            <Flex className={styles.row} flex={1} gap={6}>
              <Flex vertical className={styles.cell} flex={1}>
                <h3 className={styles['cell-header']}>Объект контроля</h3>

                <FormInformation
                  className={styles['cell-info']}
                  label={'Краткое наименование'}
                  value={machine.name}
                />
                <FormInformation
                  className={styles['cell-info']}
                  label={'Тип машины'}
                  value={''}
                />
                <FormInformation
                  className={styles['cell-info']}
                  label={'Полное наименование'}
                  value={machine.fullName}
                />
                <FormInformation
                  className={styles['cell-info']}
                  label={'Расположение машины'}
                  value={machine.location}
                />
                <FormInformation
                  className={styles['cell-info']}
                  label={'Комментарий'}
                  value={machine.comment}
                />
              </Flex>

              <Flex vertical className={styles.cell} flex={1}>
                <h3 className={styles['cell-header']}>
                  Информация по состоянию
                </h3>
              </Flex>
            </Flex>

            <Flex flex={1} gap={6}>
              <Flex vertical className={styles.cell} flex={1}>
                <h3 className={styles['cell-header']}>Текущее состояние</h3>

                <FormInformation
                  className={styles['cell-info']}
                  label={'Текущий режим'}
                  value={
                    machine.paused === undefined
                      ? ''
                      : machine.paused
                      ? 'На паузе'
                      : 'В работе'
                  }
                />
                <FormInformation
                  className={styles['cell-info']}
                  label={'Степень готовности'}
                  value={''}
                />
                <FormInformation
                  className={styles['cell-info']}
                  label={'Текущее состояние'}
                  value={''}
                />
                <FormInformation
                  className={styles['cell-info']}
                  label={'Прогноз состояния'}
                  value={''}
                />
                <FormInformation
                  className={styles['cell-info']}
                  label={'Дата прогноза'}
                  value={''}
                />
              </Flex>
              <Flex vertical className={styles.cell} flex={1}>
                <h3 className={styles['cell-header']}>Информация по ТОиР</h3>

                <FormInformation
                  className={styles['cell-info']}
                  label={'Тип последнего ТОиР'}
                  value={''}
                />
                <FormInformation
                  className={styles['cell-info']}
                  label={'Дата последнего ТОиР'}
                  value={''}
                />

                <ButtonPrimary
                  className={styles.button}
                  htmlType='button'
                  title={'История ТОиР'}
                />
              </Flex>
            </Flex>
          </Flex>
        )}
      </WorkSpace>
    </Container>
  )
}
export default Description
