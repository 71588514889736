import TableWrapper from '@/components/UI/elements/table-wrapper/table-wrapper'
import type { TableType } from '@/types/reports/reports.type'
import { Table } from 'antd'
import type { FC } from 'react'

import styles from './collapse-tables.module.css'

type TablesType = {
  tables: TableType[] | null
}

const CollapseTables: FC<TablesType> = ({ tables }) => {
  if (tables === null) {
    return null
  }

  return (
    <div className={styles['tables-wrapper']}>
      {tables.map((itemTables, index) => (
        <TableWrapper key={index}>
          <Table
            pagination={false}
            bordered={true}
            columns={itemTables.columns}
            dataSource={itemTables.data}
          />
        </TableWrapper>
      ))}
    </div>
  )
}

export default CollapseTables
