import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IDeviceVP3701, IGenerator } from '@/types/devices.type'
import { Checkbox } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import type { FC, PropsWithChildren } from 'react'
import { useEffect, useState } from 'react'

import styles from './collapses-wrapper.module.css'

type CollapseWrapperProps = {
  data?: IGenerator[] | IDeviceVP3701[]
}

const CollapseWrapper: FC<PropsWithChildren<CollapseWrapperProps>> = ({ data, children }) => {
  const { setCheckedData } = useActions()
  const { selectedReport } = useTypedSelector((state) => state.reportsReducer)
  const { checkedData } = useTypedSelector((state) => state.reportsReducer)
  const [checkAll, setCheckAll] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)

  useEffect(() => {
    setIndeterminate(Boolean(checkedData.length) && checkedData.length < data.length)
    setCheckAll(checkedData.length === data.length)
  }, [checkedData.length, data.length])

  const handleChange = (evt: CheckboxChangeEvent) => {
    setCheckedData(evt.target.checked ? data : [])
  }

  return (
    <div className={styles['report-container']}>
      <div className={styles['header-reports']}>
        <h2>{selectedReport?.value}</h2>
        <Checkbox className={styles.checkbox} indeterminate={indeterminate} checked={checkAll} onChange={handleChange}>
          Выделить всё
        </Checkbox>
      </div>
      <div className={styles.accordion}>{children}</div>
    </div>
  )
}

export default CollapseWrapper
