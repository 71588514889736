import FormInputNumber from '@/components/UI/form-controls/form-input-number/form-input-number'
import FormSelect from '@/components/UI/form-controls/form-select/form-select'
import {
  GeneratorKey,
  STEP_COUNT_FREQ,
  STEP_COUNT_PERCENT,
  STEP_COUNT_ROUND,
  distrAmplitudes,
  phases
} from '@/constants/core/common.constant'
import { GeneratorLabel } from '@/constants/device/deviceOptions'
import useActions from '@/hooks/use-actions'
import useFade from '@/hooks/use-fade'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FormInstance } from 'antd'
import type { FC } from 'react'
import React from 'react'

import styles from './form-items-harmonic-signal.module.css'

type TProps = {
  form: FormInstance
}

const FormItemsHarmonicSignal: FC<TProps> = ({ form }) => {
  const { ref, style } = useFade()
  const { moduleOneActive } = useTypedSelector((state) => state.devicesReducer)
  const { setModuleOneActive, setModuleTwoActive } = useActions()
  const handleChangeOneModule = (value: number) => {
    setModuleOneActive(value)
    if (value === 0) {
      setModuleTwoActive(value)
      form.setFieldValue('moduleTwoState', value)
    }
  }

  return (
    <div ref={ref} style={style} className={styles.columns}>
      <div>
        <FormSelect
          labelContent={'Модуль процесс 1'}
          name={'moduleOneState'}
          options={[
            { label: 'Да', value: 1 },
            { label: 'Нет', value: 0 }
          ]}
          placeholder={'Выберите значение'}
          initialValue={moduleOneActive}
          onSelect={handleChangeOneModule}
          autoFocus={true}
          tooltipKey='deviceName'
        />
        <FormInputNumber
          labelContent={GeneratorLabel.Dclevel}
          step={STEP_COUNT_FREQ}
          name={GeneratorKey.Dclevel}
          required={true}
        />
        <FormInputNumber
          labelContent={GeneratorLabel.BaseAmp}
          step={STEP_COUNT_FREQ}
          name={GeneratorKey.BaseAmp}
          initialValue={1}
          required={true}
        />
        <FormSelect
          labelContent={GeneratorLabel.AmpSet}
          name={GeneratorKey.AmpSet}
          options={distrAmplitudes}
          placeholder={'Выберите закон изменения ряда'}
          initialValue={0}
        />
        <FormSelect
          labelContent={GeneratorLabel.M1_PhaseSet}
          name={GeneratorKey.PhaseSet}
          options={phases}
          placeholder={'Выберите фазовое распределение'}
          initialValue={0}
        />
        <FormInputNumber
          labelContent={GeneratorLabel.WhiteNoise}
          step={STEP_COUNT_PERCENT}
          name={GeneratorKey.WhiteNoise}
          min={0}
          max={100}
          required={true}
        />
      </div>
      <div>
        <FormSelect
          labelContent={'Модуль процесс 2'}
          name={'moduleTwoState'}
          options={[
            { label: 'Да', value: 1 },
            { label: 'Нет', value: 0 }
          ]}
          placeholder={'Выберите значение'}
          onSelect={(value: number) => setModuleTwoActive(value)}
          initialValue={moduleOneActive}
          disabled={!moduleOneActive}
        />
        <FormInputNumber
          labelContent={GeneratorLabel.BaseFreq}
          step={STEP_COUNT_FREQ}
          name={GeneratorKey.BaseFreq}
          initialValue={1}
          required={true}
        />
        <FormInputNumber
          labelContent={GeneratorLabel.Nharm}
          step={STEP_COUNT_FREQ}
          name={GeneratorKey.Nharm}
          initialValue={1}
          required={true}
        />
        <FormInputNumber
          labelContent={GeneratorLabel.LastAmp}
          step={STEP_COUNT_PERCENT}
          name={GeneratorKey.LastAmp}
          min={0}
          max={100}
          required={true}
        />
        <FormInputNumber
          labelContent={GeneratorLabel.PhaseShift}
          step={STEP_COUNT_ROUND}
          name={GeneratorKey.PhaseShift}
          min={-180}
          max={180}
          required={true}
        />
      </div>
    </div>
  )
}

export default FormItemsHarmonicSignal
