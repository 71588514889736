import { ECommonLevelType } from '@/enums/measurment/common-level-type.enum'
import { EDetector } from '@/enums/measurment/detector.enum'
import { ESamplingRate } from '@/enums/measurment/sampling-rate.enum'
import { ESpecterLineCount } from '@/enums/measurment/specter-line-count.enum'
import { ETemperatureType } from '@/enums/measurment/temperature-type.enum'
import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import { EVisualFreqLimit } from '@/enums/measurment/visual-freq-limit.enum'
import type { IOptions } from '@/types/common/options.interface'

export const temperatureMeasurementOption: IOptions<ETypeMeasurement>[] = [
  { label: 'Температура', value: ETypeMeasurement.TemperatureDescription }
]

export const vibrationMeasurementOption: IOptions<ETypeMeasurement>[] = [
  { label: 'Сигнал', value: ETypeMeasurement.SignalDescription },
  { label: 'Спектр', value: ETypeMeasurement.ProgramSpecterDescription },
  { label: 'Общий уровень', value: ETypeMeasurement.CommonLevelDescription }
]

export const samplingRateOptions = [
  { label: '25600', value: ESamplingRate.RATE_15_25600 },
  { label: '12800', value: ESamplingRate.RATE_14_12800 },
  { label: '6400', value: ESamplingRate.RATE_13_6400 },
  { label: '3200', value: ESamplingRate.RATE_12_3200 },
  { label: '1600', value: ESamplingRate.RATE_11_1600 },
  { label: '800', value: ESamplingRate.RATE_10_800 }
]

export const freqLimitOptions = [
  { label: '10000', value: EVisualFreqLimit.RATE_10000 },
  { label: '5000', value: 'RATE_5000' },
  { label: '2500', value: 'RATE_2500' },
  { label: '1250', value: 'RATE_1250' },
  { label: '625', value: 'RATE_625' }
]

export const specterOptions = [
  { label: '25600', value: ESpecterLineCount.COUNT_0_25600_32768 },
  { label: '12800', value: ESpecterLineCount.COUNT_1_12800_16384 },
  { label: '6400', value: ESpecterLineCount.COUNT_2_6400_8192 },
  { label: '3200', value: ESpecterLineCount.COUNT_3_3200_4096 },
  { label: '1600', value: ESpecterLineCount.COUNT_4_1600_2048 },
  { label: '800', value: ESpecterLineCount.COUNT_5_800_1024 },
  { label: '400', value: ESpecterLineCount.COUNT_6_400_512 },
  { label: '200', value: ESpecterLineCount.COUNT_7_200_256 },
  { label: '100', value: ESpecterLineCount.COUNT_8_100_128 }
]

export const mapCommonLevels = {
  [ECommonLevelType.LEVEL_2_1000]: '2-1000',
  [ECommonLevelType.LEVEL_10_1000]: '10-1000',
  [ECommonLevelType.LEVEL_10_2000]: '10-2000',
  [ECommonLevelType.LEVEL_05_500]: '05-500',
  [ECommonLevelType.LEVEL_07_300]: '07-300',
  [ECommonLevelType.LEVEL_100_5000]: '100-5000',
  [ECommonLevelType.LEVEL_5_500]: '5-500',
  [ECommonLevelType.LEVEL_10_5000]: '10-5000',
  [ECommonLevelType.LEVEL_5_10000]: '5-10000'
}

export const commonLevels = [
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_2_1000],
    value: ECommonLevelType.LEVEL_2_1000
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_10_1000],
    value: ECommonLevelType.LEVEL_10_1000
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_10_2000],
    value: ECommonLevelType.LEVEL_10_2000
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_05_500],
    value: ECommonLevelType.LEVEL_05_500
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_07_300],
    value: ECommonLevelType.LEVEL_07_300
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_100_5000],
    value: ECommonLevelType.LEVEL_100_5000
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_5_500],
    value: ECommonLevelType.LEVEL_5_500
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_10_5000],
    value: ECommonLevelType.LEVEL_10_5000
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_5_10000],
    value: ECommonLevelType.LEVEL_5_10000
  }
]

export const detectors = [
  { value: EDetector.SKZ, label: 'CK3' },
  { value: EDetector.PEAK, label: 'ПИК', disabled: true },
  { value: EDetector.PEAK_PEAK, label: 'ПИК-ПИК', disabled: true }
]

export const mapMeasurementType: { [key in ETypeMeasurement]: string } = {
  [ETypeMeasurement.SignalDescription]: 'Сигнал',
  [ETypeMeasurement.ProgramSpecterDescription]: 'Спектр',
  [ETypeMeasurement.CommonLevelDescription]: 'Общий уровень',
  [ETypeMeasurement.TemperatureDescription]: 'Температура'
}

export const mapTemperatureType = {
  [ETemperatureType.OBJECT]: 'Объект',
  [ETemperatureType.SENSOR]: 'Датчик'
}

export const temperatureTypeOptions = [
  {
    value: ETemperatureType.OBJECT,
    label: mapTemperatureType[ETemperatureType.OBJECT]
  },
  {
    value: ETemperatureType.SENSOR,
    label: mapTemperatureType[ETemperatureType.SENSOR]
  }
]
