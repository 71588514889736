import {
  ServerType,
  serverTypeMap
} from '@/constants/settings/settings.constant'

export enum ServerLabel {
  ServerName = 'Имя сервера',
  ServerAddress = 'Адрес удалённого сервера',
  Port = 'Порт',
  Type = 'Тип сервера',
  Ssl = 'Безопасное соединение (SSL)'
}

export enum ServerName {
  ServerName = 'nameServer',
  ServerAddress = 'serverAddress',
  Port = 'port',
  Type = 'type',
  Ssl = 'ssl'
}

export const optionsServerType = [
  { label: serverTypeMap[ServerType.Cloud], value: ServerType.Cloud },
  { label: serverTypeMap[ServerType.Local], value: ServerType.Local }
]
