import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { TResultsMarkers } from '@/store/reducers/charts/express-markers.slice'
import type { TableProps } from 'antd'
import { Table } from 'antd'
import classNames from 'classnames'
import type { FC, LegacyRef } from 'react'

import styles from '../../../../express-sidebar.module.css'

const columns: TableProps<ITableData>['columns'] = [
  {
    title: 'N',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'F',
    dataIndex: 'f',
    key: 'f'
  },
  {
    title: 'A',
    dataIndex: 'a',
    key: 'a'
  }
]

const getTableData = (result: TResultsMarkers): ITableData[] => {
  const results: ITableData[] = []

  for (let i = 0; i < result.results.length; i++) {
    const currentResult = result.results[i]
    if (!isNaN(+currentResult.freq)) {
      results.push({
        key: `${i}_${currentResult.freq}`,
        title: `${i + 1}`,
        f: currentResult.freq,
        a: currentResult.value
      })
    }
  }

  if (!isNaN(+result.d12Freq) && !isNaN(+result.d12Value)) {
    results.push({
      key: `${result.d12Freq}_${result.d12Value}`,
      title: 'D12',
      f: result.d12Freq,
      a: result.d12Value
    })
  }

  if (!isNaN(+result.d13Freq) && !isNaN(+result.d13Value)) {
    results.push({
      key: `${result.d13Freq}_${result.d13Value}`,
      title: 'D13',
      f: result.d13Freq,
      a: result.d13Value
    })
  }

  if (!isNaN(+result.d23Freq) && !isNaN(+result.d23Value)) {
    results.push({
      key: `${result.d23Freq}_${result.d23Value}`,
      title: 'D23',
      f: result.d23Freq,
      a: result.d23Value
    })
  }

  return results
}

interface ITableData {
  key: string
  title: string | number
  f: string | number
  a: string | number
}

interface IExpressMarkerListItem {
  onClickExpressMarkerButton: () => void
  refMarkerListItem: LegacyRef<HTMLLIElement> | null
  result: TResultsMarkers
  currentIndex: number
}

const ExpressMarkerListItem: FC<IExpressMarkerListItem> = ({
  onClickExpressMarkerButton,
  refMarkerListItem,
  result,
  currentIndex
}) => {
  const tableData = getTableData(result)
  const { selectedResultIndex } = useTypedSelector(
    (state) => state.chartsUiReducer
  )

  return (
    <li ref={refMarkerListItem} className={styles['list-item']}>
      <button
        onClick={onClickExpressMarkerButton}
        type={'button'}
        className={styles.button}
      >
        <h3
          className={classNames(
            styles['list-header'],
            styles['list-header-bg'],
            {
              [styles['list-header-active']]:
                selectedResultIndex === currentIndex
            }
          )}
        >
          {result.title}
        </h3>

        <Table
          className={styles['custom-table']}
          pagination={false}
          dataSource={tableData}
          columns={columns}
        />
      </button>
    </li>
  )
}

export default ExpressMarkerListItem
