import EquipmentItem from '@/components/UI/elements/equipment-item/equipment-item'
import StatisticAlIIndicator from '@/components/UI/elements/indicators/statistical-indicator/statistic-alI-indicator'
import { useGetMeasuringPointQuery } from '@/store/api/points.api'
import type { INodesState } from '@/types/nodes/nodes.type'
import type { FC } from 'react'

import styles from './kinematic-point.module.css'

type TProps = {
  pointId: string
  dataAllNodesState?: INodesState[]
}

const KinematicPoint: FC<TProps> = ({ pointId, dataAllNodesState }) => {
  const foundNodeState = dataAllNodesState?.find(
    (node) => node.measuringPointId === pointId
  )

  const { data: currentPoint } = useGetMeasuringPointQuery(pointId, {
    skip: !pointId
  })

  if (!currentPoint) {
    return null
  }

  return (
    <div key={pointId} className={styles['kinematic-point']}>
      <EquipmentItem
        indicator={
          foundNodeState?.measuringPointId ? (
            <StatisticAlIIndicator nodeState={foundNodeState} />
          ) : null
        }
        name={currentPoint.name}
        paused={currentPoint.paused}
      />
    </div>
  )
}

export default KinematicPoint
