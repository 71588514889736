import FormInputNumber from '@/components/UI/form-controls/form-input-number/form-input-number'
import { GeneratorKey, STEP_COUNT_FREQ, STEP_COUNT_PERCENT } from '@/constants/core/common.constant'
import { GeneratorLabel } from '@/constants/device/deviceOptions'
import useFade from '@/hooks/use-fade'
import type { FC } from 'react'
import React from 'react'

const FormItemsSquareSignal: FC = () => {
  const { ref, style } = useFade()
  return (
    <div ref={ref} style={style}>
      <FormInputNumber
        labelContent={GeneratorLabel.SquareBaseFreq}
        step={STEP_COUNT_FREQ}
        name={GeneratorKey.SquareBaseFreq}
        required={true}
      />
      <FormInputNumber
        labelContent={GeneratorLabel.SquareLowLevel}
        step={STEP_COUNT_FREQ}
        name={GeneratorKey.SquareLowLevel}
        required={true}
      />
      <FormInputNumber labelContent={GeneratorLabel.SquareDuty} name={GeneratorKey.SquareDuty} required={true} />
      <FormInputNumber
        labelContent={GeneratorLabel.SquareHighToLow}
        step={STEP_COUNT_PERCENT}
        name={GeneratorKey.SquareHighToLow}
        min={0}
        max={100}
        required={true}
      />
      <FormInputNumber
        labelContent={GeneratorLabel.SquarePhase}
        step={STEP_COUNT_PERCENT}
        name={GeneratorKey.SquarePhase}
        min={0}
        max={100}
        required={true}
      />
    </div>
  )
}

export default FormItemsSquareSignal
