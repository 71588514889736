export const PointLabel = {
  ShortName: 'Краткое наименование',
  FullName: 'Полное наименование',
  PhysicalQuantityType: 'Тип точки измерения',
  isActivated: 'Активация точки измерения',
  Place: 'Расположение точки измерения',
  Comment: 'Комментарий'
} as const

export const PointName = {
  ShortName: 'pointName',
  FullName: 'pointFullName',
  PhysicalQuantityType: 'physicalQuantityType',
  isActivated: 'isActivated',
  Place: 'place',
  Comment: 'comment'
} as const
