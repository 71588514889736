import type { ILink } from '@/types/navigation/links.type'
import cn from 'classnames'
import type { FC } from 'react'
import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './submenu-link.module.css'

const SubmenuLink: FC<ILink> = ({ icon, title, to }) => {
  const isActiveClass = ({ isActive }: { isActive: boolean }): string =>
    isActive ? styles.active : ''

  return (
    <li className={styles.link}>
      <NavLink to={to} className={isActiveClass}>
        <p>{title}</p>
        {icon ? (
          <span className={cn('material-symbols-sharp', styles.icon)}>
            {icon}
          </span>
        ) : null}
      </NavLink>
    </li>
  )
}

export default SubmenuLink
