import { EUnitType } from '@/enums/measurment/unit-type.enum'
import type { IOptions } from '@/types/common/options.interface'

export const UnitTypeLabel = {
  [EUnitType.NULL_UNIT_TYPE]: 'Без типа',
  [EUnitType.MS2]: 'м/с²',
  [EUnitType.G]: 'g',
  [EUnitType.MMS2]: 'мм/с²',
  [EUnitType.MMS]: 'мм/с',
  [EUnitType.MS]: 'м/с',
  [EUnitType.MKM]: 'мкм',
  [EUnitType.MM]: 'мм',
  [EUnitType.M]: 'м',
  [EUnitType.A]: 'А',
  [EUnitType.MA]: 'мА',
  [EUnitType.MKA]: 'мкА',
  [EUnitType.VOLT]: 'В',
  [EUnitType.M_VOLT]: 'мВ',
  [EUnitType.U_VOLT]: 'мкВ',
  [EUnitType.DEGREES]: '°C',
  [EUnitType.ROTATION_COUNT_IN_SEC]: 'Гц',
  [EUnitType.ROTATION_COUNT_IN_MINUTE]: 'об/мин',
  [EUnitType.D_ROTATION_COUNT_IN_SEC]: 'Гц',
  [EUnitType.D_ROTATION_COUNT_IN_MINUTE]: 'об/мин',
  [EUnitType.DEGREES_CELSIUS]: '°C',
  [EUnitType.DEGREES_FAHRENHEIT]: '°F',
  [EUnitType.DEGREES_KELVIN]: '°K'
}

export const unitBaseAccelerationOptions: IOptions<EUnitType>[] = [
  {
    value: EUnitType.MS2,
    label: UnitTypeLabel[EUnitType.MS2],
    title: `Виброускорение, ${UnitTypeLabel[EUnitType.MS2]}`
  },
  {
    value: EUnitType.G,
    label: UnitTypeLabel[EUnitType.G],
    title: `Виброускорение, ${UnitTypeLabel[EUnitType.G]}`
  },
  {
    value: EUnitType.MMS2,
    label: UnitTypeLabel[EUnitType.MMS2],
    title: `Виброускорение, ${UnitTypeLabel[EUnitType.MMS2]}`
  }
]

export const unitOptionsAcceleration: IOptions<EUnitType>[] = [
  {
    value: EUnitType.MS2,
    label: UnitTypeLabel[EUnitType.MS2],
    title: `Виброускорение, ${UnitTypeLabel[EUnitType.MS2]}`
  },
  {
    value: EUnitType.MMS,
    label: UnitTypeLabel[EUnitType.MMS],
    title: `Скорость, ${UnitTypeLabel[EUnitType.MMS]}`
  },
  {
    value: EUnitType.MKM,
    label: UnitTypeLabel[EUnitType.MKM],
    title: `Перемещение, ${UnitTypeLabel[EUnitType.MKM]}`
  },
  {
    value: EUnitType.G,
    label: UnitTypeLabel[EUnitType.G],
    title: `Виброускорение, ${UnitTypeLabel[EUnitType.G]}`
  },
  {
    value: EUnitType.MMS2,
    label: UnitTypeLabel[EUnitType.MMS2],
    title: `Виброускорение, ${UnitTypeLabel[EUnitType.MMS2]}`
  },

  {
    value: EUnitType.MS,
    label: UnitTypeLabel[EUnitType.MS],
    title: `Скорость, ${UnitTypeLabel[EUnitType.MS]}`
  },

  {
    value: EUnitType.MM,
    label: UnitTypeLabel[EUnitType.MM],
    title: `Перемещение, ${UnitTypeLabel[EUnitType.MM]}`
  },
  {
    value: EUnitType.M,
    label: UnitTypeLabel[EUnitType.M],
    title: `Перемещение, ${UnitTypeLabel[EUnitType.M]}`
  }
]

export const unitOptionsByTemperature: IOptions<EUnitType>[] = [
  {
    value: EUnitType.DEGREES_CELSIUS,
    label: UnitTypeLabel[EUnitType.DEGREES_CELSIUS]
  },
  {
    value: EUnitType.DEGREES_FAHRENHEIT,
    label: UnitTypeLabel[EUnitType.DEGREES_FAHRENHEIT]
  },
  {
    value: EUnitType.DEGREES_KELVIN,
    label: UnitTypeLabel[EUnitType.DEGREES_KELVIN]
  }
]

type TUnitConverter = {
  [sourceUnitKey in EUnitType]?: {
    [targetUnitKey in EUnitType]?: (value: number) => number
  }
}

type TphysicalQuantityVibrationConverter = {
  [sourceUnitKey in EUnitType]?: {
    [targetUnitKey in EUnitType]?: (value: number, frequency: number) => number
  }
}

export const unitTemperatureConverter: TUnitConverter = {
  [EUnitType.DEGREES_CELSIUS]: {
    [EUnitType.DEGREES_FAHRENHEIT]: (celsius: number) => (celsius * 9) / 5 + 32,
    [EUnitType.DEGREES_KELVIN]: (celsius: number) => celsius + 273.15
  },
  [EUnitType.DEGREES_FAHRENHEIT]: {
    [EUnitType.DEGREES_CELSIUS]: (fahrenheit: number) => ((fahrenheit - 32) * 5) / 9,
    [EUnitType.DEGREES_KELVIN]: (fahrenheit: number) => ((fahrenheit - 32) * 5) / 9 + 273.15
  },
  [EUnitType.DEGREES_KELVIN]: {
    [EUnitType.DEGREES_CELSIUS]: (kelvin: number) => kelvin - 273.15,
    [EUnitType.DEGREES_FAHRENHEIT]: (kelvin: number) => ((kelvin - 273.15) * 9) / 5 + 32
  }
}

export const unitAccelerationConverter: TUnitConverter = {
  [EUnitType.MS2]: {
    [EUnitType.G]: (ms2: number) => ms2 / 9.81,
    [EUnitType.MMS2]: (ms2: number) => ms2 * 1000
  },
  [EUnitType.G]: {
    [EUnitType.MS2]: (g: number) => g * 9.81,
    [EUnitType.MMS2]: (g: number) => g * 9810
  },
  [EUnitType.MMS2]: {
    [EUnitType.G]: (mms2: number) => mms2 / 9810,
    [EUnitType.MS2]: (mms2: number) => mms2 / 1000
  }
}

export const unitVelocityConverter: TUnitConverter = {
  [EUnitType.MMS]: {
    [EUnitType.MS]: (mms: number) => mms / 1000
  },
  [EUnitType.MS]: {
    [EUnitType.MMS]: (ms: number) => ms * 1000
  }
}

export const unitDisplacementConverter: TUnitConverter = {
  [EUnitType.MKM]: {
    [EUnitType.M]: (mkm: number) => mkm / 1000000,
    [EUnitType.MM]: (mkm: number) => mkm / 1000
  },
  [EUnitType.MM]: {
    [EUnitType.MKM]: (mm: number) => mm * 1000,
    [EUnitType.M]: (mm: number) => mm / 1000
  },
  [EUnitType.M]: {
    [EUnitType.MKM]: (mm: number) => mm * 1000000,
    [EUnitType.M]: (mm: number) => mm * 1000
  }
}

export const physicalQuantityVibrationConverter: TphysicalQuantityVibrationConverter = {
  [EUnitType.MS2]: {
    [EUnitType.MMS]: (acceleration: number, frequency: number) => (acceleration * 500) / (frequency * Math.PI),
    [EUnitType.MKM]: (acceleration: number, frequency: number) =>
      acceleration * Math.pow(500 / (frequency * Math.PI), 2)
  },
  [EUnitType.MMS]: {
    [EUnitType.MS2]: (velocity: number, frequency: number) => (velocity * (frequency * Math.PI)) / 500,
    [EUnitType.MKM]: (velocity: number, frequency: number) => (velocity * 500) / (frequency * Math.PI)
  },
  [EUnitType.MKM]: {
    [EUnitType.MS2]: (displacement: number, frequency: number) =>
      displacement * Math.pow((frequency * Math.PI) / 500, 2),
    [EUnitType.MMS]: (displacement: number, frequency: number) => (displacement * (frequency * Math.PI)) / 500
  }
}

export const mapQuantityVibrationConverter: {
  [key in string]: TUnitConverter
} = {
  [EUnitType.MS2]: unitAccelerationConverter,
  [EUnitType.MMS]: unitVelocityConverter,
  [EUnitType.MKM]: unitDisplacementConverter
}

export const mapBaseVibrationUnits: { [key in string]: EUnitType } = {
  [EUnitType.MS2]: EUnitType.MS2,
  [EUnitType.G]: EUnitType.MS2,
  [EUnitType.MMS2]: EUnitType.MS2,
  [EUnitType.MMS]: EUnitType.MMS,
  [EUnitType.MS]: EUnitType.MMS,
  [EUnitType.MKM]: EUnitType.MKM,
  [EUnitType.MM]: EUnitType.MKM,
  [EUnitType.M]: EUnitType.MKM
}
