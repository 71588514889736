import EquipmentItem from '@/components/UI/elements/equipment-item/equipment-item'
import StatisticAlIIndicator from '@/components/UI/elements/indicators/statistical-indicator/statistic-alI-indicator'
import type { IMachineInfo } from '@/types/machine.type'
import type { INodesState } from '@/types/nodes/nodes.type'
import { type FC } from 'react'

interface INodeProps {
  machine: IMachineInfo
  dataAllNodesState: INodesState[] | undefined
}

const NodeItem: FC<INodeProps> = ({ machine, dataAllNodesState }) => {
  const foundNodeState = dataAllNodesState?.find(
    (node) => node.machineId === machine.machineId
  )

  return (
    <EquipmentItem
      paused={machine.paused}
      indicator={
        foundNodeState ? (
          <StatisticAlIIndicator nodeState={foundNodeState} />
        ) : null
      }
      name={machine.name}
    />
  )
}

export default NodeItem
