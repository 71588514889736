import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import { bearingFormFields } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bearing-form/bearing-form.constant'
import type { IBearing } from '@/types/bearing/bearing.type'
import type { FormInstance } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

type TSubmitButton = {
  onFinishAddBearing: () => void
  formAddBearing: FormInstance<IBearing>
}
export const SubmitButton: FC<TSubmitButton> = ({
  onFinishAddBearing,
  formAddBearing
}) => {
  const [isDisabledButton, setIsDisabledButton] = useState(true)
  const watchedModelType = useWatch(
    bearingFormFields.modelType.name,
    formAddBearing
  )
  const watchedManufacturer = useWatch(
    bearingFormFields.manufacturer.name,
    formAddBearing
  )
  const watchedKftf = useWatch(bearingFormFields.kftf.name, formAddBearing)
  const watchedKftfo = useWatch(bearingFormFields.kftfo.name, formAddBearing)
  const watchedKbsf = useWatch(bearingFormFields.kbsf.name, formAddBearing)
  const watchedKbpfo = useWatch(bearingFormFields.kbpfo.name, formAddBearing)
  const watchedKbpfi = useWatch(bearingFormFields.kbpfi.name, formAddBearing)

  useEffect(() => {
    const checkValidateFields = async (): Promise<void> => {
      try {
        await formAddBearing.validateFields(
          [
            bearingFormFields.modelType.name,
            bearingFormFields.manufacturer.name,
            bearingFormFields.kftf.name,
            bearingFormFields.kftfo.name,
            bearingFormFields.kbsf.name,
            bearingFormFields.kbpfo.name,
            bearingFormFields.kbpfi.name
          ],
          { validateOnly: true }
        )
        setIsDisabledButton(false)
      } catch (error) {
        setIsDisabledButton(true)
      }
    }

    checkValidateFields()
  }, [
    formAddBearing,
    watchedModelType,
    watchedManufacturer,
    watchedKftf,
    watchedKftfo,
    watchedKbsf,
    watchedKbpfo,
    watchedKbpfi
  ])

  return (
    <ButtonsWrapper>
      <ButtonPrimary
        title='Добавить'
        htmlType={'button'}
        theme='dark'
        onClick={onFinishAddBearing}
        disabled={isDisabledButton}
      />
    </ButtonsWrapper>
  )
}
