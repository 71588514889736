import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { TResultsBar } from '@/store/reducers/charts/express-bars.slice'
import type { TableProps } from 'antd'
import { Table } from 'antd'
import classNames from 'classnames'
import { uniqBy } from 'lodash'
import type { FC, LegacyRef } from 'react'

import styles from '../../../express-sidebar.module.css'

const getTableData = (resultBar: TResultsBar): ITableData[] => {
  const results: ITableData[] = []

  for (let i = 0; i < resultBar.results.length; i++) {
    const currentResult = resultBar.results[i]

    if (currentResult.isEmpty) {
      continue
    }

    for (let j = 0; j < currentResult.leftModulateBars.length; j++) {
      const currentModulateResult = currentResult.leftModulateBars[j]

      results.push({
        key: `${currentModulateResult.count}m_${currentModulateResult.count}_${currentModulateResult.modulateBarF}`,
        name: currentModulateResult.count + 'm',
        f:
          typeof currentModulateResult.modulateBarF === 'number'
            ? Number(currentModulateResult.modulateBarF?.toPrecision(5))
            : currentModulateResult.modulateBarF,
        a:
          typeof currentModulateResult.modulateBarA === 'number'
            ? Number(currentModulateResult.modulateBarA?.toPrecision(3))
            : currentModulateResult.modulateBarA,
        percent:
          typeof currentModulateResult.percent === 'number'
            ? Math.round(currentModulateResult.percent)
            : currentModulateResult.percent
      })
    }

    results.push({
      key: `${currentResult.count}A_${currentResult.count}_${currentResult.mainBarF}`,
      name: currentResult.count + 'A',
      f:
        typeof currentResult.mainBarF === 'number'
          ? Number(currentResult.mainBarF?.toPrecision(5))
          : currentResult.mainBarF,
      a:
        typeof currentResult.mainBarA === 'number'
          ? Number(currentResult.mainBarA?.toPrecision(3))
          : currentResult.mainBarA,
      percent:
        typeof currentResult.mainBarF === 'number'
          ? Math.round(currentResult.percent)
          : '--',
      isMain: true
    })

    for (let j = 0; j < currentResult.rightModulateBars.length; j++) {
      const currentModulateResult = currentResult.rightModulateBars[j]

      results.push({
        key: `${currentModulateResult.count}m_${currentModulateResult.count}_${currentModulateResult.modulateBarF}`,
        name: currentModulateResult.count + 'm',
        f:
          typeof currentModulateResult.modulateBarF === 'number'
            ? Number(currentModulateResult.modulateBarF?.toPrecision(5))
            : currentModulateResult.modulateBarF,
        a:
          typeof currentModulateResult.modulateBarA === 'number'
            ? Number(currentModulateResult.modulateBarA?.toPrecision(3))
            : currentModulateResult.modulateBarA,
        percent:
          typeof currentModulateResult.percent === 'number'
            ? Math.round(currentModulateResult.percent)
            : currentModulateResult.percent
      })
    }
  }

  return uniqBy(results, 'key')
}

const columns: TableProps<ITableData>['columns'] = [
  {
    title: 'N',
    dataIndex: 'name',
    key: 'name',
    render: (text, { isMain }) =>
      isMain ? <p className={styles.bold}>{text}</p> : text
  },
  {
    title: 'F',
    dataIndex: 'f',
    key: 'f',
    render: (text, { isMain }) =>
      isMain ? <p className={styles.bold}>{text}</p> : text
  },
  {
    title: 'A',
    dataIndex: 'a',
    key: 'a',
    render: (text, { isMain }) =>
      isMain ? <p className={styles.bold}>{text}</p> : text
  },
  {
    title: '%',
    dataIndex: 'percent',
    key: 'percent',
    render: (text, { isMain }) =>
      isMain ? <p className={styles.bold}>{text}</p> : text
  }
]

interface ITableData {
  key: string
  name: string
  f: number | string
  a: number | string
  percent: number | string
  isMain?: true
}

interface IExpressBarListItem {
  onClickExpressBarButton: () => void
  refBarListItem: LegacyRef<HTMLLIElement> | null
  result: TResultsBar
  currentIndex: number
}

const ExpressBarListItem: FC<IExpressBarListItem> = ({
  onClickExpressBarButton,
  refBarListItem,
  result,
  currentIndex
}) => {
  const { selectedResultIndex } = useTypedSelector(
    (state) => state.chartsUiReducer
  )
  const tableData: ITableData[] = getTableData(result)

  return (
    <li ref={refBarListItem} className={styles['list-item']}>
      <button
        onClick={onClickExpressBarButton}
        type={'button'}
        className={styles.button}
      >
        <h3
          className={classNames(
            styles['list-header'],
            styles['list-header-bg'],
            {
              [styles['list-header-active']]:
                selectedResultIndex === currentIndex
            }
          )}
        >
          {result.title}
        </h3>

        <Table
          className={styles['custom-table']}
          pagination={false}
          dataSource={tableData}
          columns={columns}
        />
      </button>
    </li>
  )
}
export default ExpressBarListItem
