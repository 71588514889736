import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { TStrobeOptions } from '@/types/express-strobe.type'
import { useCallback, useEffect, useRef, useState } from 'react'
import { shallowEqual } from 'react-redux'
import type { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'

const useStrobeData = (index: number) => {
  const refIndexStrobe = useRef(0)
  const { strobes } = useTypedSelector((state) => state.expressStrobeReducer, {
    equalityFn: shallowEqual
  })

  const { setStrobeData } = useActions()

  const [currentStrobeData, setCurrentStrobeData] = useState<TStrobeOptions>({
    ...strobes[index]?.strobeData,
    offset: null
  })

  const [isAreaDrag, setIsAreaDrag] = useState(false)
  const [isResizeDuration, setIsResizeDuration] = useState(false)
  const [isResizePeriod, setIsResizePeriod] = useState(false)

  useEffect(() => {
    setCurrentStrobeData({
      ...strobes[index]?.strobeData,
      offset: null
    })
  }, [index, strobes])

  const resetDefaultSettings = useCallback(() => {
    if (isAreaDrag || isResizePeriod || isResizeDuration) {
      setCurrentStrobeData((prev) => ({ ...prev, offset: null }))

      setStrobeData({
        index,
        strobeData: {
          start: currentStrobeData.start,
          duration: currentStrobeData.duration,
          period: currentStrobeData.duration
        }
      })

      setIsAreaDrag(false)
      setIsResizeDuration(false)
      setIsResizePeriod(false)
    }
  }, [isAreaDrag, isResizePeriod, isResizeDuration, setStrobeData, index, currentStrobeData])

  const onMouseMove = useCallback(
    (e: CategoricalChartState) => {
      if (isAreaDrag) {
        setCurrentStrobeData((prev) => {
          if (e?.activeLabel !== undefined) {
            const currentTime = Number(e.activeLabel)

            const halfDuration = prev.duration / 2

            let currentStart = currentTime - halfDuration - prev.period * refIndexStrobe.current

            if (currentStart <= 0) {
              currentStart = 0
            }

            return {
              ...prev,
              start: currentStart
            }
          } else {
            return prev
          }
        })
      } else if (isResizeDuration) {
        setCurrentStrobeData((prev) => {
          if (e?.activeLabel !== undefined) {
            let offset = prev.offset
            const currentTime = Number(e.activeLabel)

            if (prev.offset === null) {
              offset = Math.abs(prev.duration - currentTime)
            }

            let duration = currentTime - Number(offset)

            if (duration < 0) {
              duration = 0
            } else if (duration > prev.period) {
              duration = prev.period
            }

            return {
              ...prev,
              duration,
              offset
            }
          } else {
            return prev
          }
        })
      } else if (isResizePeriod) {
        setCurrentStrobeData((prev) => {
          if (e?.activeLabel !== undefined) {
            let offset = prev.offset
            const currentTime = Number(e.activeLabel) / refIndexStrobe.current

            if (prev.offset === null) {
              offset = Math.abs(prev.period - currentTime)
            }

            let newPeriod = currentTime - Number(offset)

            if (newPeriod <= prev.duration) {
              newPeriod = prev.duration
            }

            return {
              ...prev,
              offset,
              period: newPeriod
            }
          } else {
            return prev
          }
        })
      }
    },
    [isAreaDrag, isResizePeriod, isResizeDuration]
  )

  const onMouseUp = useCallback(() => {
    resetDefaultSettings()
  }, [resetDefaultSettings])

  const onMouseLeave = useCallback(() => {
    resetDefaultSettings()
  }, [resetDefaultSettings])

  return {
    onMouseLeave,
    onMouseUp,
    onMouseMove,
    strobeData: currentStrobeData,
    setIsResizePeriod,
    setIsAreaDrag,
    setIsResizeDuration,
    isAreaDrag,
    isResizeDuration,
    isResizePeriod,
    refIndexStrobe
  }
}

export default useStrobeData
