import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import type { IMeasurement } from '@/types/measurement/measurement.type'

export const getTypeMeasurement = (measurement?: IMeasurement) => {
  const typesMeasurements = {
    signalDescription: ETypeMeasurement.SignalDescription,
    programSpecterDescription: ETypeMeasurement.ProgramSpecterDescription,
    commonLevelDescription: ETypeMeasurement.CommonLevelDescription,
    temperatureDescription: ETypeMeasurement.TemperatureDescription
  }

  if (measurement) {
    for (const key in typesMeasurements) {
      if (measurement[key as keyof IMeasurement]) {
        return typesMeasurements[key as keyof typeof typesMeasurements]
      }
    }
  }

  return undefined
}
