import ButtonToolbar from '@/components/UI/buttons/button-toolbar/button-toolbar'
import ModePanel from '@/components/kinematic-space/components/kinematic-toolbar/components/condition-toolbar/components/mode-panel/mode-panel'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'
import { shallowEqual } from 'react-redux'

import styles from '@/components/kinematic-space/components/kinematic-toolbar/components/condition-toolbar/condition-toolbar.module.css'

const ConditionToolbar: FC = () => {
  const { stageOptions } = useTypedSelector(
    (state) => ({
      ...state.kinematicReducer
    }),
    {
      equalityFn: shallowEqual
    }
  )
  const { setStageOptions, incrementScale, decrementScale } = useActions()
  return (
    <>
      <ButtonToolbar
        placement={'top'}
        onClick={() => {
          incrementScale()
        }}
        title={'Увеличить'}
        icon={'zoom_in'}
      />
      <ButtonToolbar
        disabled={stageOptions.scale <= 0.2}
        placement={'top'}
        onClick={() => {
          decrementScale()
        }}
        title={'Уменьшить'}
        icon={'zoom_out'}
      />
      <ButtonToolbar
        placement={'top'}
        onClick={() => {
          setStageOptions({ scale: 1 })
        }}
        title={'Вернуть размер холста'}
      >
        1:1
      </ButtonToolbar>
      <ModePanel className={styles['mode-panel']} />
    </>
  )
}

export default ConditionToolbar
