import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { TMarker } from '@/store/reducers/charts/express-markers.slice'
import type { IChartSpectrum } from '@/types/chart/charts.type'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useRef } from 'react'
import { shallowEqual } from 'react-redux'

interface IPropsMarkersKeyHandler {
  markerOptions: TMarker[]
  chartData: IChartSpectrum[]
  refIndexMarker: React.MutableRefObject<number>
  resultId: string
  setMarkerOptions: Dispatch<SetStateAction<TMarker[]>>
}

const useMarkersKeyHandler = ({
  markerOptions,
  chartData,
  refIndexMarker,
  setMarkerOptions,
  resultId
}: IPropsMarkersKeyHandler) => {
  const refThrottlingTimer = useRef<null | ReturnType<typeof setTimeout>>(null)

  const { setExpressMarkerOptions } = useActions()
  const { isMarkersExpressBarOpen } = useTypedSelector(
    (state) => state.expressMarkersReducer,
    {
      equalityFn: shallowEqual
    }
  )
  const { selectedResultId } = useTypedSelector(
    (state) => state.chartsUiReducer
  )

  const handleKey = useCallback(
    (evt: KeyboardEvent) => {
      if (refIndexMarker.current === null || selectedResultId !== resultId)
        return

      if (evt.code === 'ArrowUp') {
        evt.preventDefault()

        const currentMarker = markerOptions[refIndexMarker.current]

        const step = 1

        const [max] = [
          chartData[Number(currentMarker.index) - step],
          chartData[Number(currentMarker.index)],
          chartData[Number(currentMarker.index) + step]
        ].sort((a, b) => b?.amplitude - a?.amplitude)

        setMarkerOptions((prev) => {
          const newPrev = [...prev]

          newPrev[refIndexMarker.current] = {
            freq: max?.frequency,
            index: max?.index
          }

          return [...newPrev]
        })
      } else if (evt.code === 'ArrowLeft') {
        evt.preventDefault()

        let step = 1

        if (evt.ctrlKey && evt.shiftKey) {
          step = 50
        } else if (evt.shiftKey) {
          step = 10
        }

        const currentMarker = markerOptions[refIndexMarker.current]

        const nextIndex =
          Number(currentMarker.index) - step > step
            ? Number(currentMarker.index) - step
            : 0

        setMarkerOptions((prev) => {
          const newPrev = [...prev]

          newPrev[refIndexMarker.current] = {
            freq: chartData[nextIndex]?.frequency,
            index: nextIndex
          }

          return [...newPrev]
        })
      } else if (evt.code === 'ArrowRight') {
        evt.preventDefault()

        let step = 1

        if (evt.ctrlKey && evt.shiftKey) {
          step = 50
        } else if (evt.shiftKey) {
          step = 10
        }

        const currentMarker = markerOptions[refIndexMarker.current]

        const maxMarker = chartData.length - 1

        const nextIndex =
          Number(currentMarker.index) + step < maxMarker
            ? Number(currentMarker.index) + step
            : maxMarker

        setMarkerOptions((prev) => {
          const newPrev = [...prev]

          newPrev[refIndexMarker.current] = {
            freq: chartData[nextIndex]?.frequency,
            index: nextIndex
          }
          return [...newPrev]
        })
      }
    },
    [
      chartData,
      markerOptions,
      refIndexMarker,
      resultId,
      selectedResultId,
      setMarkerOptions
    ]
  )

  const handleKeyUp = useCallback(
    (evt: KeyboardEvent) => {
      if (refIndexMarker.current === null || selectedResultId !== resultId)
        return

      if (
        evt.code === 'ArrowRight' ||
        evt.code === 'ArrowLeft' ||
        evt.code === 'ArrowDown' ||
        evt.code === 'ArrowUp'
      ) {
        if (refThrottlingTimer.current !== null) {
          clearTimeout(refThrottlingTimer.current)
          refThrottlingTimer.current = null
        }

        refThrottlingTimer.current = setTimeout(() => {
          setExpressMarkerOptions({
            markerOption: markerOptions[refIndexMarker.current],
            index: refIndexMarker.current
          })
        }, 500)
      }
    },
    [
      markerOptions,
      refIndexMarker,
      resultId,
      selectedResultId,
      setExpressMarkerOptions
    ]
  )

  useEffect(() => {
    if (isMarkersExpressBarOpen && selectedResultId === resultId) {
      document.addEventListener('keydown', handleKey)
      document.addEventListener('keyup', handleKeyUp)

      return () => {
        document.removeEventListener('keydown', handleKey)
        document.removeEventListener('keyup', handleKeyUp)
      }
    }
  }, [
    handleKey,
    handleKeyUp,
    isMarkersExpressBarOpen,
    resultId,
    selectedResultId
  ])
}
export default useMarkersKeyHandler
