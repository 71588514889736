import Spinner from '@/components/UI/elements/spinner/spinner'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasurementsQuery } from '@/store/api/measurements.api'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import type { OnDragEndResponder } from 'react-beautiful-dnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import styles from './measurements-list.module.css'

import MeasurementItem from './components/measurement-item'

const MeasurementsList = () => {
  const { setSelectedMeasurementId } = useActions()
  // const [searchParams, setSearchParams] = useSearchParams()
  const { selectedMachineId, selectedPointId } = useTypedSelector(
    (state) => state.globalReducer
  )
  const [measurementsDnd, setMeasurementsDnd] = useState<IMeasurement[]>([])
  const { data: dataMeasurements, isFetching } = useGetMeasurementsQuery(
    selectedPointId,
    {
      skip: !selectedPointId
    }
  )

  useEffect(() => {
    if (dataMeasurements?.content) {
      setMeasurementsDnd(dataMeasurements?.content)
    }
  }, [dataMeasurements?.content])

  if (isFetching) {
    return <Spinner />
  }

  const handleMeasurementSelect = (
    measurementId: IMeasurement['measuringPointId']
  ) => {
    // searchParams.set(QueryParam.MeasurementId, measurementId)
    // setSearchParams(searchParams)
    setSelectedMeasurementId(measurementId)
  }

  const handleDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) return

    const newMeasurements = [...measurementsDnd]
    const [removed] = newMeasurements.splice(result.source.index, 1)
    newMeasurements.splice(result.destination.index, 0, removed)

    setMeasurementsDnd(newMeasurements)
  }

  return selectedPointId && selectedMachineId ? (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='measurements' direction='vertical'>
        {(droppableProvided) => (
          <div
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            className={styles.container}
          >
            {measurementsDnd.map((measurementItem, index) => (
              <Draggable
                key={measurementItem.measurementId}
                draggableId={measurementItem.measurementId}
                index={index}
              >
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    className={cn(styles['draggable'], {
                      [styles['dragging']]: draggableSnapshot.isDragging
                    })}
                  >
                    <MeasurementItem
                      key={measurementItem.measurementId}
                      measurement={measurementItem}
                      onSelectMeasurementClick={handleMeasurementSelect}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {measurementsDnd.length === 0 && (
              <p style={{ textAlign: 'center', fontSize: '12px', margin: 0 }}>
                Виды измерения отсутствуют
              </p>
            )}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : null
}

export default MeasurementsList
