import {
  getDiapason,
  getThinnedData
} from '@/app/machine-condition/components/results/components/charts/components/chart-timing/chart-timing.service'
import TooltipTiming from '@/app/machine-condition/components/results/components/charts/components/chart-timing/components/tooltip-timing/tooltip-timing'
import { searchStrobeBinary } from '@/app/machine-condition/components/results/components/charts/components/chart-timing/helpers/search-strobe-binary.helper'
import ChartWrapper from '@/app/machine-condition/components/results/components/charts/components/chart-wrapper/chart-wrapper'
import SliderApp from '@/app/machine-condition/components/results/components/charts/components/slider-app/slider-app'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasurementQuery } from '@/store/api/measurements.api'
import type { TStrobeIndexes } from '@/store/reducers/charts/express-strobe.slice'
import type { IChartTiming } from '@/types/chart/charts.type'
import type { TStrobeOptions } from '@/types/express-strobe.type'
import { chartFormatValue } from '@/utils/chart/chart-format-value'
import { generateGraphTicks } from '@/utils/chart/generate-graph-ticks'
import { getStepSlider } from '@/utils/chart/get-step-slider'
import { Flex } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { max, min } from 'lodash'
import type { FC, LegacyRef } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

import calculateAs from '../express-sidebar/helpers/calculate-as.helper'
import calculateEx from '../express-sidebar/helpers/calculate-ex.helper'
import calculatePK from '../express-sidebar/helpers/calculate-pk.helper'
import calculateSKZ from '../express-sidebar/helpers/calculate-skz.helper'
import LegendStrobe from './components/legend-strobe/legend-strobe'
import { renderFrontStrobes, renderMainStrobes } from './helpers/render-strobes.helper'
import useStrobeData from './hooks/use-strobe-data'

type ChartTimingProps = {
  title: string
  data: number[]
  durationInSec?: number
  resultId: string
  index: number
  handleClickOnChart: () => void
  chartButtonRef: LegacyRef<HTMLButtonElement> | null
}

export const sliceArrayByPeriod = (lastValue: number, period: number, start: number) => {
  const result = []

  for (let i = start; i < lastValue; i += period) {
    result.push(i)
  }

  return result
}

const ChartTiming: FC<ChartTimingProps> = ({
  title,
  data,
  durationInSec,
  resultId,
  index,
  handleClickOnChart,
  chartButtonRef
}) => {
  const { pushStrobeIndexes } = useActions()
  const { selectedMeasurementId } = useTypedSelector((state) => state.globalReducer)
  const { data: measurement } = useGetMeasurementQuery(selectedMeasurementId, {
    skip: !selectedMeasurementId
  })
  const { isStrobeExpressBarOpen } = useTypedSelector((state) => state.expressStrobeReducer)
  const refChartBlock = useRef<HTMLDivElement>(null)

  const {
    setIsResizeDuration,
    setIsAreaDrag,
    setIsResizePeriod,
    strobeData,
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    refIndexStrobe,
    isAreaDrag,
    isResizeDuration,
    isResizePeriod
  } = useStrobeData(index)

  const isHandlerActive = isAreaDrag || isResizeDuration || isResizePeriod
  const [sliderDisabled, setSliderDisabled] = useState(true)

  const [sliderData, setSliderData] = useState<number[]>([0, 0])
  const [sliderValues, setSliderValues] = useState<number[]>([0, 0])
  const [sliderIndexes, setSliderIndexes] = useState<number[]>([0, 0])
  const diapason = getDiapason(refChartBlock) as number

  const chartData = useMemo<IChartTiming[]>(() => {
    const duration = durationInSec as number
    return getThinnedData(data, diapason, sliderData, duration)
  }, [data, diapason, durationInSec, sliderData])

  const rangeValues = useMemo<number[]>(() => {
    let values = chartData.map((element) => element.value)
    if (sliderDisabled) {
      values = values?.splice(sliderIndexes[0], sliderIndexes[1])
    }
    return [min(values), max(values)] as [number, number]
  }, [chartData, sliderDisabled, sliderIndexes])

  const rangeIndexes = useMemo(() => (chartData ? [0, chartData.length - 1] : [0, 0]), [chartData])

  const rangeTimeValues = useMemo<number[]>(
    () => [chartData[sliderIndexes[0]]?.time, chartData[sliderIndexes[1]]?.time],
    [chartData, sliderIndexes]
  )
  const rangeData = useMemo<number[]>(() => (data ? [0, data.length] : [0, 0]), [data])

  useEffect(() => {
    setSliderValues(rangeValues)
  }, [rangeValues])

  useEffect(() => {
    setSliderIndexes(rangeIndexes)
  }, [chartData, rangeIndexes])

  useEffect(() => {
    setSliderData(rangeData)
  }, [rangeData])

  const stepY = getStepSlider(20, rangeValues)
  const stepX = getStepSlider(20, rangeTimeValues)
  const ticksTimeValues = generateGraphTicks(rangeTimeValues, 14)
  const ticksValues = generateGraphTicks(sliderValues, 8)

  const slicedChartData = useMemo(
    () => sliceArrayByPeriod(Number(chartData[chartData.length - 1]?.time), strobeData.period, strobeData.start),
    [chartData, strobeData.period, strobeData.start]
  )

  const skz = calculateSKZ(data)
  const pk = calculatePK(data)
  const as = calculateAs(data)
  const ex = calculateEx(data)

  // Функция для работы стробов написанная Денисом Ложкиным, в будущем необходимо будет провести рефакторинг кода
  const calculateStrobeIndexes = useCallback(
    (strobeDataCurrent?: TStrobeOptions) => {
      const discrete = Number(measurement?.signalDescription?.samplingRate?.split('_')[2])
      let strobeDataTemp: Omit<TStrobeOptions, 'offset'> = {
        start: (1 / discrete) * 1000,
        period: (1 / discrete) * 1000,
        duration: ((1 / discrete) * 1000) / 2
      }

      if (strobeDataCurrent !== undefined) {
        strobeDataTemp = strobeDataCurrent
      }

      const lastTime = Number(chartData[chartData.length - 1]?.time)

      const slicedIndexes = sliceArrayByPeriod(lastTime, strobeDataTemp?.period, strobeDataTemp?.start)

      const strobeIndexes: TStrobeIndexes = []

      for (let i = 0; i < slicedIndexes.length; i++) {
        const time = slicedIndexes[i]

        const startIndexStrobe = searchStrobeBinary(chartData, time, 0, chartData.length - 1)?.sourceIndex

        const endIndexStrobe = searchStrobeBinary(
          chartData,
          time + strobeDataTemp.duration,
          0,
          chartData.length - 1
        )?.sourceIndex

        if (startIndexStrobe === undefined || endIndexStrobe === undefined) {
          continue
        }

        strobeIndexes.push([startIndexStrobe, endIndexStrobe])
      }

      pushStrobeIndexes({
        strobeData: strobeDataTemp,
        title: title,
        strobeIndexes
      })
    },
    [measurement?.signalDescription?.samplingRate, chartData, pushStrobeIndexes, title]
  )

  useEffect(() => {
    if (isStrobeExpressBarOpen) {
      calculateStrobeIndexes()
    }
  }, [calculateStrobeIndexes, isStrobeExpressBarOpen])

  const handleResetIndexesDoubleClick = () => {
    if (rangeIndexes) {
      setSliderIndexes(rangeIndexes)
    }
  }

  const handleResetDataDoubleClick = () => {
    setSliderData(rangeData)
  }

  const handleResetValueDoubleClick = () => {
    if (rangeValues) {
      setSliderValues(rangeValues)
    }
  }

  const handleSliderDisableCheckboxChange = (evt: CheckboxChangeEvent) => {
    const notChecked = !evt.target.checked
    setSliderDisabled(notChecked)
  }

  const handleSliderDataChange = (range: number[]) => {
    const [minValue, maxValue] = range
    const limitRange = maxValue - minValue
    if (limitRange <= diapason * 2) {
      return
    }
    setSliderData(range)
  }

  return (
    <ChartWrapper
      onChartClick={handleClickOnChart}
      title={title}
      chartRef={refChartBlock}
      resultId={resultId}
      chartButtonRef={chartButtonRef}
    >
      <Flex>
        <SliderApp
          range={{
            draggableTrack: true
          }}
          min={rangeValues ? rangeValues[0] : 0}
          max={rangeValues ? rangeValues[1] : 0}
          value={sliderValues}
          vertical={true}
          step={stepY}
          tooltip={{ formatter: chartFormatValue }}
          onChange={setSliderValues}
          onDoubleClick={handleResetValueDoubleClick}
          onCheckboxChange={handleSliderDisableCheckboxChange}
          disabled={sliderDisabled}
        />
        <Flex flex={1} vertical={true} gap={5}>
          <SliderApp
            range={{ draggableTrack: true }}
            min={rangeData ? rangeData[0] : 0}
            max={rangeData ? rangeData[1] : 0}
            value={sliderData}
            onChange={handleSliderDataChange}
            onDoubleClick={handleResetDataDoubleClick}
            borderBottom={true}
          />
          <ResponsiveContainer height={500}>
            <ComposedChart
              onMouseLeave={
                isHandlerActive
                  ? () => {
                      onMouseLeave()
                      calculateStrobeIndexes(strobeData)
                    }
                  : undefined
              }
              onMouseUp={
                isHandlerActive
                  ? () => {
                      onMouseUp && onMouseUp()
                      calculateStrobeIndexes(strobeData)
                    }
                  : undefined
              }
              onMouseMove={isHandlerActive ? onMouseMove : undefined}
              data={chartData}
            >
              <XAxis
                dataKey='time'
                type='number'
                domain={rangeTimeValues}
                axisLine={true}
                ticks={ticksTimeValues}
                tick={{ fill: 'black' }}
                tickFormatter={(time) => String(Number(Number(time)?.toFixed(2)))}
                allowDataOverflow={true}
              />
              <YAxis
                dataKey='value'
                axisLine={true}
                ticks={ticksValues}
                tickMargin={5}
                tickFormatter={(value) => String(Number(value.toFixed(2)))}
                tickLine={true}
                allowDataOverflow={true}
                tick={{ fill: 'black' }}
                domain={sliderValues}
              />

              {isStrobeExpressBarOpen ? (
                <ReferenceArea
                  x1={chartData[sliderIndexes[0]]?.time}
                  x2={chartData[sliderIndexes[1]]?.time}
                  ifOverflow='visible'
                  fillOpacity={0.8}
                  fill={'#444444'}
                />
              ) : null}

              <CartesianGrid strokeDasharray='3 3' />

              {isStrobeExpressBarOpen
                ? renderFrontStrobes({
                    chartData,
                    slicedChartData,
                    strobeData,
                    sliderValuesHorizontal: sliderIndexes
                  })
                : null}

              <Line isAnimationActive={false} dot={false} dataKey='value' stroke='#444444' strokeWidth={1} />

              {isStrobeExpressBarOpen
                ? renderMainStrobes({
                    slicedChartData,
                    chartData,
                    strobeData,
                    sliderValuesHorizontal: sliderIndexes,
                    refIndexStrobe,
                    setIsAreaDrag,
                    setIsResizeDuration,
                    setIsResizePeriod
                  })
                : null}

              {isStrobeExpressBarOpen ? (
                <Legend
                  align='right'
                  verticalAlign='top'
                  content={<LegendStrobe skz={skz} pk={pk} ex={ex} as={as} />}
                />
              ) : null}

              <Tooltip content={<TooltipTiming />} isAnimationActive={false} />
            </ComposedChart>
          </ResponsiveContainer>
          <SliderApp
            onDoubleClick={handleResetIndexesDoubleClick}
            min={rangeIndexes[0]}
            max={rangeIndexes[1]}
            value={sliderIndexes}
            onChange={setSliderIndexes}
            range={{ draggableTrack: true }}
          />
        </Flex>
      </Flex>
    </ChartWrapper>
  )
}

export default ChartTiming
