export const enum TgbParam {
  FreqCoefForInTwo = 'freqCoefForInTwo',
  KinematicBlockSourceTwo = 'kinematicBlockSourceIdTwo',
  FreqOutSourceTwo = 'freqOutSourceTwo',
  Z1 = 'z1',
  Z3 = 'z3'
}

export enum TgbOut {
  Frtgb1 = 'frtgb1',
  Frtgb2 = 'frtgb2',
  Fo = 'fo',
  Fz = 'fz'
}
