import {
  EAmplitudeMode,
  type EFrequencyMode
} from '@/enums/charts/chart-value-mode.enum'
import {
  generateGraphTicks,
  generateGraphTicksLog
} from '@/utils/chart/generate-graph-ticks'

export const generateTicks = (
  rangeData: number[] | undefined,
  valueMode: EAmplitudeMode | EFrequencyMode,
  maxCountLines: number
) => {
  if (!rangeData) {
    return undefined
  }

  const [minValue, maxValue] = rangeData

  if (valueMode === EAmplitudeMode.LINER) {
    return generateGraphTicks([minValue, maxValue], maxCountLines)
  }

  if (valueMode === EAmplitudeMode.LOG) {
    return generateGraphTicksLog(minValue, maxValue)
  }
}
