import type { IMeasurementForm } from '@/app/configuration/components/equipments/components/measurements/measurement.interface'
import { MeasurementName } from '@/constants/measurement/measurements-form.constant'
import { temperatureMeasurementOption, vibrationMeasurementOption } from '@/constants/measurement/measurements.constant'
import { UnitTypeLabel } from '@/constants/measurement/unit.constant'
import type { ECommonLevelType } from '@/enums/measurment/common-level-type.enum'
import type { EDetector } from '@/enums/measurment/detector.enum'
import type { ESamplingRate } from '@/enums/measurment/sampling-rate.enum'
import type { ESpecterLineCount } from '@/enums/measurment/specter-line-count.enum'
import { ETemperatureType } from '@/enums/measurment/temperature-type.enum'
import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import type { EVisualFreqLimit } from '@/enums/measurment/visual-freq-limit.enum'
import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'
import type { IOptions } from '@/types/common/options.interface'
import type {
  ICommonLevelDescription,
  IMeasurement,
  IProgramSpecterDescription,
  ISignalDescription,
  ITemperatureDescription,
  TCreatedMeasurement
} from '@/types/measurement/measurement.type'
import type { IMeasuringPoint } from '@/types/point/point.type'
import { getTypeMeasurement } from '@/utils/measurement/get-type-measurement'
import { isUndefined } from 'lodash'

export const getMeasurementTypeOptions = (point?: IMeasuringPoint) => {
  if (point) {
    //
    const measurementTypes: {
      [key in EPhysicalQuantityType]?: IOptions<ETypeMeasurement>[]
    } = {
      // Set to undefined for future features
      [EPhysicalQuantityType.TEMPERATURE]: temperatureMeasurementOption,
      [EPhysicalQuantityType.VIBRO_ACCELERATION]: vibrationMeasurementOption,
      [EPhysicalQuantityType.VIBRO_VELOCITY]: undefined,
      [EPhysicalQuantityType.VOLTAGE]: undefined,
      [EPhysicalQuantityType.VIBRO_DISPLACEMENT]: undefined,
      [EPhysicalQuantityType.RPM]: undefined,
      [EPhysicalQuantityType.DRPM]: undefined,
      [EPhysicalQuantityType.AMPERAGE]: undefined,
      [EPhysicalQuantityType.PHASE]: undefined,
      [EPhysicalQuantityType.NULL]: undefined
    }

    return measurementTypes[point.physicalQuantityType]
  }

  return undefined
}

export const mapFromResponseMeasurement = (measurement: IMeasurement, point?: IMeasuringPoint): IMeasurementForm => ({
  [MeasurementName.MeasurementName]: measurement.name,
  [MeasurementName.TypeControl]: point?.physicalQuantityType,
  [MeasurementName.DeviceType]: measurement.deviceType,
  [MeasurementName.MeasurementType]: getTypeMeasurement(measurement),
  [MeasurementName.isActivated]: !measurement.paused,
  [MeasurementName.UnitType]: measurement.unitType,
  [MeasurementName.VisualFreqLimit]: measurement.programSpecterDescription?.visualFreqLimit,
  [MeasurementName.SpecterLineCount]: measurement.programSpecterDescription?.specterLineCount,
  [MeasurementName.CommonLevelType]: measurement.commonLevelDescription?.commonLevelType,
  [MeasurementName.SamplingRate]: measurement?.signalDescription?.samplingRate,
  [MeasurementName.DurationInSec]: measurement?.signalDescription?.durationInSec,
  [MeasurementName.IirFilterEnabled]: measurement?.signalDescription?.iirFilterEnabled,
  [MeasurementName.Coef]: measurement?.temperatureDescription?.coef,
  [MeasurementName.NumberOfAverages]: measurement?.temperatureDescription?.numberOfAverages,
  [MeasurementName.TemperatureType]: measurement?.temperatureDescription?.temperatureType
})

const filterMeasurementByType = (measurements: IMeasurement[], typeMeasurement: ETypeMeasurement): IMeasurement[] =>
  measurements.filter((measurement) => getTypeMeasurement(measurement) === typeMeasurement)

const abbreviateToThousands = (currentNumber: string) => {
  if (currentNumber?.length < 4) {
    return currentNumber
  }

  const integerPart = currentNumber.slice(0, -3)
  const fractionalPart = currentNumber.slice(-3)
  const floatNumber = parseFloat(fractionalPart) / 100

  const lineEnding = Math.round(floatNumber) || ''

  return `${integerPart}k${lineEnding}`
}

const formatSpecterMeasurement = (firstSpecterIndication: string, secondSpecterIndication: string) => {
  const visualFreqLimit = firstSpecterIndication.slice(5)
  const [, , specterLineCount] = secondSpecterIndication.split('_')

  return `${abbreviateToThousands(visualFreqLimit)}_${specterLineCount}`
}

const formatCommonLevelMeasurement = (commonLevelIndication: string) => {
  const [, firstValue, secondValue] = commonLevelIndication.split('_')

  return `${firstValue}-${abbreviateToThousands(secondValue)}`
}

const generateNameByCommonLevelDescription = (
  measurements: IMeasurement[],
  unit: EUnitType,
  detector?: EDetector,
  commonLevelType?: ECommonLevelType
) => {
  if (detector && commonLevelType) {
    const filteredCommonLevelDescription = filterMeasurementByType(
      measurements,
      ETypeMeasurement.ProgramSpecterDescription
    )
    const numberCommonLevelMeasurement = ++filteredCommonLevelDescription.length
    const formattedCommonLevelMeasurement = formatCommonLevelMeasurement(commonLevelType)
    const unitCommonLevelDescription = UnitTypeLabel[unit]
    return `BBLa_${numberCommonLevelMeasurement}_${detector}_${formattedCommonLevelMeasurement}_${unitCommonLevelDescription}`
  }
}

const generateNameByProgramSpecterDescription = (
  measurements: IMeasurement[],
  unit: EUnitType,
  visualFreqLimit?: EVisualFreqLimit,
  specterLineCount?: ESpecterLineCount
) => {
  if (visualFreqLimit && specterLineCount) {
    const filteredMeasurementsByProgramSpecterDescription = filterMeasurementByType(
      measurements,
      ETypeMeasurement.ProgramSpecterDescription
    )
    const numberProgramSpecterMeasurement = ++filteredMeasurementsByProgramSpecterDescription.length
    const formattedSpecterMeasurement = formatSpecterMeasurement(visualFreqLimit, specterLineCount)
    const unitProgramSpecterMeasurement = UnitTypeLabel[unit]
    return `ASRa_${numberProgramSpecterMeasurement}_${formattedSpecterMeasurement}_${unitProgramSpecterMeasurement}`
  }
}

const generateNameBySignalDescription = (
  measurements: IMeasurement[],
  unit: EUnitType,
  samplingRate?: ESamplingRate,
  durationInSec?: number
) => {
  if (durationInSec && samplingRate) {
    const filteredMeasurementsBySignalDescription = filterMeasurementByType(
      measurements,
      ETypeMeasurement.SignalDescription
    )

    const numberSignalMeasurement = ++filteredMeasurementsBySignalDescription.length
    const rateSignalMeasurement = abbreviateToThousands(samplingRate.substring(8))
    const unitSignalMeasurement = UnitTypeLabel[unit]

    return `TSa_${numberSignalMeasurement}_${rateSignalMeasurement}_${rateSignalMeasurement}_${durationInSec}_${unitSignalMeasurement}`
  }
}

const generateNameByTemperatureDescription = (
  measurements: IMeasurement[],
  unit: EUnitType,
  coef?: number,
  temperatureType?: ETemperatureType
) => {
  if (!isUndefined(temperatureType)) {
    const temperatureTypeNames = {
      [ETemperatureType.OBJECT]: 'o',
      [ETemperatureType.SENSOR]: 'a'
    }

    const temperatureTypeForName = temperatureTypeNames[temperatureType]

    const filteredMeasurementsByTemperatureDescription = filterMeasurementByType(
      measurements,
      ETypeMeasurement.TemperatureDescription
    )
    const numberTemperatureMeasurementForName = ++filteredMeasurementsByTemperatureDescription.length

    const coefficientForName = coef ? `${coef.toString().split('.').join('')}_` : ''

    const unitSignalMeasurement = UnitTypeLabel[unit]

    return `TR${temperatureTypeForName}_${numberTemperatureMeasurementForName}_${coefficientForName}${unitSignalMeasurement}`
  }
}

export const generateMeasurementName = (formMeasurement: IMeasurementForm, measurements?: IMeasurement[]) => {
  const { measurementType } = formMeasurement

  if (!isUndefined(measurementType) && !isUndefined(measurements)) {
    const nameGenerationPatterns: {
      [key in ETypeMeasurement]?: string
    } = {
      [ETypeMeasurement.TemperatureDescription]: generateNameByTemperatureDescription(
        measurements,
        formMeasurement.unitType,
        formMeasurement.coef,
        formMeasurement.temperatureType
      ),
      [ETypeMeasurement.SignalDescription]: generateNameBySignalDescription(
        measurements,
        formMeasurement.unitType,
        formMeasurement.samplingRate,
        formMeasurement.durationInSec
      ),
      [ETypeMeasurement.ProgramSpecterDescription]: generateNameByProgramSpecterDescription(
        measurements,
        formMeasurement.unitType,
        formMeasurement.visualFreqLimit,
        formMeasurement.specterLineCount
      ),
      [ETypeMeasurement.CommonLevelDescription]: generateNameByCommonLevelDescription(
        measurements,
        formMeasurement.unitType,
        formMeasurement.detector,
        formMeasurement.commonLevelType
      )
    }

    return nameGenerationPatterns[measurementType]
  }
}

export const mapToRequestMeasurement = (
  formMeasurement: IMeasurementForm,
  measurementName?: string,
  pointId?: string,
  typeMeasurement?: ETypeMeasurement
): TCreatedMeasurement | undefined => {
  console.log(measurementName)
  if (isUndefined(measurementName) || isUndefined(pointId) || isUndefined(typeMeasurement)) {
    return undefined
  }

  const isSignalDescription = typeMeasurement === ETypeMeasurement.SignalDescription
  const isProgramSpecterDescription = typeMeasurement === ETypeMeasurement.ProgramSpecterDescription
  const isCommonLevelDescription = typeMeasurement === ETypeMeasurement.CommonLevelDescription
  const isTemperatureDescription = typeMeasurement === ETypeMeasurement.TemperatureDescription

  if (formMeasurement.deviceType) {
    const requestMeasurement: TCreatedMeasurement = {
      measuringPointId: pointId,
      measurementName: measurementName,
      deviceType: formMeasurement.deviceType,
      unitType: formMeasurement.unitType,
      paused: !formMeasurement.isActivated
    }

    if (isTemperatureDescription) {
      const temperatureDescription: ITemperatureDescription = {
        temperatureType: formMeasurement.temperatureType,
        coef: formMeasurement.coef,
        numberOfAverages: formMeasurement.numberOfAverages
      }

      return { ...requestMeasurement, temperatureDescription }
    }

    if (isCommonLevelDescription) {
      const commonLevelDescription: ICommonLevelDescription = {
        commonLevelType: formMeasurement.commonLevelType
      }

      return { ...requestMeasurement, commonLevelDescription }
    }

    if (isSignalDescription) {
      const signalDescription: ISignalDescription = {
        iirFilterEnabled: formMeasurement.iirFilterEnabled,
        durationInSec: formMeasurement.durationInSec,
        samplingRate: formMeasurement.samplingRate
      }

      return { ...requestMeasurement, signalDescription }
    }

    if (isProgramSpecterDescription) {
      const programSpecterDescription: IProgramSpecterDescription = {
        specterLineCount: formMeasurement.specterLineCount,
        visualFreqLimit: formMeasurement.visualFreqLimit
      }

      return { ...requestMeasurement, programSpecterDescription }
    }
  }
}
