export const SliceName = {
  KinematicUi: 'kinematic-ui',
  Kinematic: 'kinematic',
  ChartsUi: 'chart-ui',
  ExpressAnalysis: 'express-analysis',
  Configuration: 'configuration',
  Devices: 'devices',
  ExpressBars: 'express-bars',
  ExpressMarkers: 'express-markers',
  ExpressSection: 'express-section',
  ExpressStrobe: 'express-strobe',
  Generator: 'generator',
  MeasurementResults: 'measurement-results',
  Plan: 'plan',
  Reports: 'reports',
  ServerSettings: 'server-settings',
  Threshold: 'threshold',
  CommonUi: 'core-ui',
  Global: 'global',
  EquipmentsUi: 'equipment-ui',
  Mnemonic: 'mnemonic',
  Connection: 'connection'
}
