export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const isDevelopmentMode = (): boolean =>
  process.env.NODE_ENV === 'development'

export const addLeadingZeros = (number: number, length: number) => {
  let numString = String(number)
  while (numString.length < length) {
    numString = '0' + numString
  }
  return numString
}

/**
 * Determine whether the array is empty.
 * @param {unknown[]} array the array.
 * @returns {boolean} result of checking.
 * */
export const isEmpty = (array: unknown[]): boolean => !array.length

export const convertDegToRad = (deg: number): number => (Math.PI * deg) / 180

export const formatFloat = (value: number, digits: number): string => {
  const result = parseFloat(value.toFixed(digits))
  if (result < 0.0001) {
    return result.toExponential()
  }

  return result.toString()
}

export const isNumber = (value: unknown): value is number =>
  typeof value === 'number' && !isNaN(value)
export const replaceDecimalPoint = (inputString?: string) => {
  if (inputString) {
    return inputString.replace(/\d+\.\d+/g, function (match) {
      return match.replace('.', ',')
    })
  }
}
