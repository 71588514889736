import type { KeyMap } from 'react-hotkeys'

export const KEY_MAP: KeyMap = {
  StepLeft: 'left',
  StepRight: 'right',
  MiddleStepLeft: 'shift+left',
  MiddleStepRight: 'shift+right',
  HighStepLeft: 'ctrl+left',
  HighStepRight: 'ctrl+right',
  IncreaseCursor: 'ctrl+shift+right',
  DecreaseCursor: 'ctrl+shift+left'
}

export const StepSize = {
  SmallStep: 1,
  MediumStep: 50,
  LargeStep: 100,
  ExpandableStep: 10
}

export const ColorLine = {
  DeepCarminePink: '#E82F2F',
  MediumGreen: '#3CB556',
  FrenchBlue: '#0076BF',
  CadmiumOrange: '#FF801F',
  WarmPurple: '#853892',
  RedBeech: '#803F01',
  ArtyClickSkyBlue: '#2AC4EF',
  PistachioGreen: '#9FC000',
  BlueHosta: '#6EC0C6'
} as const
