import Submenu from '@/components/UI/elements/submenu/submenu'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import ToolBar from '@/components/UI/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/UI/layouts/work-space/work-space'
import MnemonicLeftSidebar from '@/components/mnemonic/mnemonic-left-sidebar/mnemonic-left-sidebar'
import MnemonicMain from '@/components/mnemonic/mnemonic-main/mnemonic-main'
import MnemonicRightTools from '@/components/mnemonic/mnemonic-right-tools/mnemonic-right-tools'
import { configurationLinks } from '@/constants/navigation/links.constant'
import type { FC } from 'react'

const MnemonicMap: FC = () => (
  <Container>
    <ToolBar>
      <MnemonicRightTools />
    </ToolBar>
    <LeftBar>
      <MnemonicLeftSidebar />
    </LeftBar>
    <WorkSpace>
      <Submenu links={configurationLinks} />
      <MnemonicMain />
    </WorkSpace>
  </Container>
)

export default MnemonicMap
