import FormRadioGroup from '@/components/UI/form-controls/form-radio-group/form-radio-group'
import { GeneratorKey, TypeGeneratorLabel, TypeGeneratorValue } from '@/constants/core/common.constant'
import useFade from '@/hooks/use-fade'
import type { FC } from 'react'
import React from 'react'

const optionsType = [
  { label: TypeGeneratorLabel.Harmonic, value: TypeGeneratorValue.Harmonic },
  { label: TypeGeneratorLabel.Square, value: TypeGeneratorValue.Square }
]

const FormItemsTypeChannel: FC = () => {
  const { ref, style } = useFade()
  return (
    <div ref={ref} style={style}>
      <FormRadioGroup
        style={{ display: 'flex', flexDirection: 'column' }}
        name={GeneratorKey.Type}
        options={optionsType}
        initialValue={TypeGeneratorValue.Harmonic}
      />
    </div>
  )
}

export default FormItemsTypeChannel
