import { Tooltip } from 'antd'
import cn from 'classnames'
import type { FC, ReactNode } from 'react'

import styles from './equipment-item.module.css'

interface IProps {
  name?: string
  paused?: boolean
  indicator?: ReactNode | null
}

const EquipmentItem: FC<IProps> = ({ name, paused, indicator }) => (
  <div className={styles['three-item']}>
    {!paused ? (
      indicator ? (
        indicator
      ) : (
        <p className={styles.indicator}>Cm</p>
      )
    ) : (
      <p className={styles.indicator}>
        <span className={cn('material-symbols-sharp', styles['span-pause'])}>
          pause
        </span>
      </p>
    )}

    <p className={styles.indicator}>lm</p>

    <Tooltip
      title={name}
      trigger='hover'
      mouseEnterDelay={0.6}
      placement='right'
    >
      <p className={styles.title}>{name}</p>
    </Tooltip>
  </div>
)

export default EquipmentItem
