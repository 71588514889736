import avatar from '@/assets/images/engineer-avatar.svg'
import MenuLink from '@/components/UI/elements/menu-link/menu-link'
import type { ILink } from '@/types/navigation/links.type'
import cn from 'classnames'
import type { FC, MouseEvent } from 'react'
import React from 'react'

import styles from './menu.module.css'

type stateProps = {
  isMenuOpen: boolean
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  links: Readonly<ILink[]>
}

const Menu: FC<stateProps> = ({ isMenuOpen, setIsMenuOpen, links }) => {
  const handleOverlayClick = (evt: MouseEvent<HTMLElement>): void => {
    if (evt.target === evt.currentTarget) {
      setIsMenuOpen((prevState) => !prevState)
    }
  }

  const handleLinkClick = () => {
    setIsMenuOpen((prevState) => !prevState)
  }

  return (
    <div
      onClick={handleOverlayClick}
      className={cn(styles.overlay, {
        [styles['overlay-hidden']]: !isMenuOpen
      })}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.avatar}>
            <img src={avatar} alt='Avatar' />
          </div>
          <div className={styles.profile}>
            <p className={styles['user-name']}>Иван Иванов</p>
            <p className={styles['user-email']}>some-email@domain.ru</p>
          </div>
        </div>

        <nav className={styles.navigation}>
          <ul className={styles['navigation-list']}>
            {links.map((link, index) => (
              <MenuLink
                key={index}
                disabled={link.disabled}
                to={link.to}
                icon={link.icon}
                title={link.title}
                onClick={handleLinkClick}
              />
            ))}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Menu
