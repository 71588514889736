import type { FC } from 'react'

import styles from '../../../../express-sidebar.module.css'

interface IProps {
  skz: number
  am: number
  fm: number
  fl: number
  fh: number
  index: number
}

const ExpressSectionResultItem: FC<IProps> = ({
  skz,
  am,
  fm,
  fl,
  fh,
  index
}) => (
  <li className={styles['list-item']}>
    <h4 className={styles['list-header']}>{index + 1}.</h4>

    <p>
      <span>Fl</span> = {fl} Гц,
    </p>
    <p>
      <span>Fh</span> = {fh} Гц,
    </p>
    <p>СКЗ = {isFinite(skz) ? skz.toPrecision(6) : 0},</p>
    <p>Am = {isFinite(am) ? am.toPrecision(6) : 0}</p>
    <p>Fm = {fm.toPrecision(6)} Гц,</p>
  </li>
)

export default ExpressSectionResultItem
