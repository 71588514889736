import { Checkbox, Slider } from 'antd'
import type { SliderRangeProps } from 'antd/es/slider'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import cn from 'classnames'
import type { FC } from 'react'
import React from 'react'

import styles from './slider-app.module.css'

interface IProps extends SliderRangeProps {
  borderBottom?: boolean
  onDoubleClick?: () => void
  onCheckboxChange?: ((e: CheckboxChangeEvent) => void) | undefined
  onChange: (range: number[]) => void
}

const SliderApp: FC<IProps> = ({ onDoubleClick, onCheckboxChange, vertical, borderBottom, onChange, ...props }) => (
  <div
    className={cn(styles['slider-container'], {
      [styles['horizontal-slider-container']]: !vertical,
      [styles['vertical-slider-container']]: vertical,
      [styles['border-bottom']]: borderBottom
    })}
    onDoubleClick={onDoubleClick}
  >
    {vertical && <Checkbox className={styles['checkbox']} onChange={onCheckboxChange} />}
    <Slider
      className={cn({
        [styles['horizontal-slider']]: !vertical,
        [styles['vertical-slider']]: vertical
      })}
      vertical={vertical}
      {...props}
      onChange={onChange}
    />
  </div>
)
export default SliderApp
