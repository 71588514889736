import EquipmentItem from '@/components/UI/elements/equipment-item/equipment-item'
import DangerStateIndicator from '@/components/UI/elements/indicators/danger-state-indicator/danger-state-indicator'
import type { INodesState } from '@/types/nodes/nodes.type'
import type { FC } from 'react'

interface IProps {
  name: string
  paused: boolean
  id: string
  dataAllNodesState?: INodesState[]
}

const PlanMeasurementItem: FC<IProps> = ({
  name,
  paused,
  dataAllNodesState,
  id
}) => {
  const foundNodeState = dataAllNodesState?.find(
    (node) => node.measurementId === id
  )

  return (
    <EquipmentItem
      indicator={
        foundNodeState ? (
          <DangerStateIndicator nodeState={foundNodeState} />
        ) : null
      }
      name={name}
      paused={paused}
    />
  )
}

export default PlanMeasurementItem
