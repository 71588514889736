import CommonLevelForm from '@/app/configuration/components/equipments/components/measurements/components/common-level-form/common-level-form'
import SignalForm from '@/app/configuration/components/equipments/components/measurements/components/signal-form/signal-form'
import SpecterForm from '@/app/configuration/components/equipments/components/measurements/components/specter-form/specter-form'
import TemperatureForm from '@/app/configuration/components/equipments/components/measurements/components/temperature-form/temperature-form'
import type { IMeasurementForm } from '@/app/configuration/components/equipments/components/measurements/measurement.interface'
import {
  generateMeasurementName,
  getMeasurementTypeOptions,
  mapFromResponseMeasurement,
  mapToRequestMeasurement
} from '@/app/configuration/components/equipments/components/measurements/measurement.service'
import { getTypePoint } from '@/app/configuration/components/equipments/components/point/point.service'
import { GroupTree } from '@/app/configuration/components/menu-tree/menu-tree.constant'
import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import ContentWrapper from '@/components/UI/elements/content-wrapper/content-wrapper'
import ControlsWrapper from '@/components/UI/elements/controls-wrapper/controls-wrapper'
import HeaderConfiguration from '@/components/UI/elements/header-work-space/header-configuration'
import Spinner from '@/components/UI/elements/spinner/spinner'
import FormItemApp from '@/components/UI/form-controls/form-item-mcm/form-item-app'
import TextLabel from '@/components/UI/form-controls/text-label/text-label'
import DocTooltip from '@/components/doc-tooltip/doc-tooltip'
import measurementIcon from '@/components/icons/equipments/measurement-icon'
import { ButtonTitle } from '@/constants/core/common.constant'
import { deviceOptions, mapDeviceType } from '@/constants/device/deviceOptions'
import { FormKey } from '@/constants/doc-tooltip.constant'
import { MeasurementLabel, MeasurementName } from '@/constants/measurement/measurements-form.constant'
import { mapCommonLevels, mapMeasurementType, mapTemperatureType } from '@/constants/measurement/measurements.constant'
import { UnitTypeLabel } from '@/constants/measurement/unit.constant'
import { EViewMode } from '@/enums/common/view-mode.enum'
import { EEquipmentContent } from '@/enums/equipments/equipment-view.enum'
import type { ECommonLevelType } from '@/enums/measurment/common-level-type.enum'
import type { ETemperatureType } from '@/enums/measurment/temperature-type.enum'
import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import useActions from '@/hooks/use-actions'
import {
  useCreateMeasurementMutation,
  useGetMeasurementQuery,
  useGetMeasurementsQuery,
  useUpdateMeasurementsStatusMutation
} from '@/store/api/measurements.api'
import { useGetMeasuringPointQuery } from '@/store/api/points.api'
import { replaceDecimalPoint } from '@/utils/common'
import { getTypeMeasurement } from '@/utils/measurement/get-type-measurement'
import { getUnitTypeOptions } from '@/utils/measurement/get-unit-type-options'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Form, Select, Switch } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { useForm } from 'antd/lib/form/Form'
import { isUndefined } from 'lodash'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import styles from '@/app/configuration/components/equipments/components/measurements/measurement.module.css'

interface IProps {
  measurementId?: string
  pointId?: string
  machineId?: string
  isAddMode: boolean
  isEditMode: boolean
}

const Measurement: FC<IProps> = ({ measurementId, pointId, isAddMode, isEditMode }) => {
  const { setSelectedMeasurementId, setEquipmentView, updateTreeMenuItem, setSelectedTreeItems } = useActions()
  const [measurementForm] = useForm<IMeasurementForm>()
  const { data: point } = useGetMeasuringPointQuery(pointId, {
    skip: !pointId
  })
  const { data: measurement, isLoading: isLoadingMeasurement } = useGetMeasurementQuery(measurementId, {
    skip: !measurementId
  })
  const { data: measurements } = useGetMeasurementsQuery(pointId, {
    skip: !pointId
  })
  const [createMeasurement, { isLoading: isLoadingCreateMeasurement }] = useCreateMeasurementMutation()
  const [updateMeasurementsStatus, { isLoading: isLoadingUpdateMeasurementStatus }] =
    useUpdateMeasurementsStatusMutation()
  const [typeMeasurement, setTypeMeasurement] = useState<ETypeMeasurement | undefined>(undefined)
  const measurementNameWatched = useWatch(MeasurementName.MeasurementName, measurementForm)

  const deviceTypeWatched = useWatch(MeasurementName.DeviceType, measurementForm)
  const measurementTypeWatched = useWatch(MeasurementName.MeasurementType, measurementForm)

  const typePoint = getTypePoint(point)
  const unitOptions = getUnitTypeOptions(point)
  const measurementTypeOptions = getMeasurementTypeOptions(point)
  const isSignalDescription = typeMeasurement === ETypeMeasurement.SignalDescription
  const isProgramSpecterDescription = typeMeasurement === ETypeMeasurement.ProgramSpecterDescription
  const isCommonLevelDescription = typeMeasurement === ETypeMeasurement.CommonLevelDescription
  const isTemperatureDescription = typeMeasurement === ETypeMeasurement.TemperatureDescription

  useEffect(() => {
    if (isAddMode) {
      setTypeMeasurement(measurementTypeWatched)
      return
    }

    if (isEditMode) {
      setTypeMeasurement(getTypeMeasurement(measurement))
      return
    }
  }, [isAddMode, isEditMode, measurement, measurementTypeWatched])

  useEffect(() => {
    if (measurement && point && isEditMode) {
      const formData = mapFromResponseMeasurement(measurement, point)
      measurementForm.setFieldsValue(formData)
    }
  }, [isEditMode, measurement, measurementForm, point])

  useEffect(() => {
    if (isAddMode) {
      measurementForm.resetFields()
    }
  }, [isAddMode, measurementForm])

  const handleMeasurementFinish = async () => {
    const { getFieldsValue, validateFields } = measurementForm
    const measurementFormValues = getFieldsValue()
    try {
      await validateFields({ recursive: true })
    } catch (e) {
      errorNotificationCreate(e)
      return
    }

    try {
      if (isAddMode && pointId) {
        const measurementName = generateMeasurementName(measurementFormValues, measurements?.content)
        const measurementForRequest = mapToRequestMeasurement(
          measurementFormValues,
          measurementName,
          pointId,
          typeMeasurement
        )
        console.log(measurementForRequest)
        if (measurementForRequest && measurementName) {
          const { id } = await createMeasurement(measurementForRequest).unwrap()
          setEquipmentView({
            mode: EViewMode.EDITING,
            content: EEquipmentContent.MEASUREMENT
          })
          setSelectedMeasurementId(id)
          updateTreeMenuItem({
            id: id,
            name: measurementName,
            parentKey: pointId,
            group: GroupTree.Measurement,
            icon: measurementIcon
          })
          setSelectedTreeItems([id])
          successNotificationCreate('Вид измерения успешно добавлен')
        }
      }
    } catch (err) {
      errorNotificationCreate(err)
    }
  }

  const handleChangePaused = async () => {
    if (measurementId && isEditMode) {
      const isActivated = measurementForm.getFieldValue(MeasurementName.isActivated)
      try {
        await updateMeasurementsStatus({
          updateData: {
            paused: !isActivated
          },
          measurementId
        }).unwrap()

        if (isActivated) {
          successNotificationCreate('Вид измерения активирован')
        } else {
          successNotificationCreate('Вид измерения отключён')
        }
      } catch (err) {
        errorNotificationCreate(err)
      }
    }
  }

  const getTitle = () => {
    if (isAddMode) {
      return 'Добавление нового вида измерения'
    }

    return `Вид измерения ${measurement?.name}`
  }

  const getMeasurementType = () => {
    if (!isUndefined(measurementTypeWatched)) {
      return mapMeasurementType[measurementTypeWatched]
    }
  }

  const getMeasurementUnit = () => {
    const unit = measurementForm.getFieldValue(MeasurementName.UnitType) as EUnitType
    return UnitTypeLabel[unit]
  }

  const getCommonLevelMappedValue = (commonLevelType?: ECommonLevelType) => {
    if (commonLevelType) {
      return mapCommonLevels[commonLevelType]
    }
  }

  const getTemperatureTypeMappedValue = () => {
    const temperatureType = measurementForm.getFieldValue(MeasurementName.TemperatureType) as ETemperatureType
    if (temperatureType) {
      return mapTemperatureType[temperatureType]
    }
  }

  const getCoefMappedValue = () => {
    const coef = measurementForm.getFieldValue(MeasurementName.Coef)
    if (coef) {
      return replaceDecimalPoint(String(coef))
    }
  }

  const resetMeasurementForm = () => {
    setSelectedMeasurementId(undefined)
    setEquipmentView({
      content: EEquipmentContent.NULL,
      mode: EViewMode.EDITING
    })
  }

  if (isLoadingMeasurement) {
    return <Spinner />
  }

  return (
    <>
      <HeaderConfiguration title={getTitle()} />
      <ContentWrapper>
        <Form
          form={measurementForm}
          className={styles.container}
          layout='horizontal'
          onFinish={handleMeasurementFinish}
        >
          <ControlsWrapper toolTip={<DocTooltip theme='light' formKey={FormKey.FormOne} />}>
            {isEditMode && (
              <FormItemApp name={MeasurementName.MeasurementName} label={MeasurementLabel.Name}>
                <TextLabel manualValue={measurementNameWatched} />
              </FormItemApp>
            )}
            <FormItemApp name={MeasurementName.TypeControl} label={MeasurementLabel.TypeControl}>
              <TextLabel manualValue={typePoint} />
            </FormItemApp>
            <FormItemApp name={MeasurementName.DeviceType} label={MeasurementLabel.DeviceType}>
              {isAddMode && <Select options={deviceOptions} placeholder='Выберите вид измерения' />}
              {isEditMode && deviceTypeWatched && <TextLabel manualValue={mapDeviceType[deviceTypeWatched]} />}
            </FormItemApp>
          </ControlsWrapper>
          <ControlsWrapper toolTip={<DocTooltip theme='light' formKey={FormKey.FormOne} />}>
            <FormItemApp
              name={MeasurementName.MeasurementType}
              label={MeasurementLabel.MeasurementType}
              rules={[{ required: isAddMode, message: 'Выберите вид измерения' }]}
            >
              {isAddMode && <Select options={measurementTypeOptions} placeholder='Выберите вид измерения' />}
              {isEditMode && <TextLabel manualValue={getMeasurementType()} />}
            </FormItemApp>
            <FormItemApp
              name={MeasurementName.UnitType}
              label={MeasurementLabel.UnitType}
              rules={[{ required: isAddMode, message: 'Пое обязательно' }]}
            >
              {isAddMode && <Select placeholder='Выберите единицу измерения' options={unitOptions} />}
              {isEditMode && <TextLabel manualValue={getMeasurementUnit()} />}
            </FormItemApp>
            {isEditMode && (
              <FormItemApp
                name={MeasurementName.isActivated}
                label={MeasurementLabel.isActivated}
                rules={[{ required: isAddMode, message: 'Поле обязательно' }]}
              >
                <Switch onChange={handleChangePaused} loading={isLoadingUpdateMeasurementStatus} />
              </FormItemApp>
            )}
          </ControlsWrapper>
          {typeMeasurement && (
            <ControlsWrapper toolTip={<DocTooltip theme='light' formKey={FormKey.FormOne} />}>
              <h4 className={styles.title}>Параметры измерения</h4>
              {isSignalDescription && <SignalForm />}
              {isProgramSpecterDescription && <SpecterForm />}
              {isCommonLevelDescription && (
                <CommonLevelForm
                  isWriterMode={isAddMode}
                  isReaderMode={isEditMode}
                  commonLevelType={getCommonLevelMappedValue()}
                />
              )}
              {isTemperatureDescription && (
                <TemperatureForm
                  isWriterMode={isAddMode}
                  isReaderMode={isEditMode}
                  manualCoef={getCoefMappedValue()}
                  manualTemperatureType={getTemperatureTypeMappedValue()}
                  measurementForm={measurementForm}
                />
              )}
            </ControlsWrapper>
          )}
          <ControlsWrapper>
            <ButtonsWrapper>
              <ButtonPrimary htmlType={'button'} title={ButtonTitle.Exit} onClick={resetMeasurementForm} />
              <ButtonPrimary
                htmlType={'submit'}
                title={ButtonTitle.Save}
                disabled={isEditMode}
                loading={isLoadingCreateMeasurement}
              />
            </ButtonsWrapper>
          </ControlsWrapper>
        </Form>
      </ContentWrapper>
    </>
  )
}
export default Measurement
