import {
  mapBaseVibrationUnits,
  mapQuantityVibrationConverter,
  physicalQuantityVibrationConverter,
  unitAccelerationConverter,
  unitTemperatureConverter
} from '@/constants/measurement/unit.constant'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'

export const convertTemperature = (sourceUnit: EUnitType, targetUnit: EUnitType, value: number): number | undefined =>
  unitTemperatureConverter[sourceUnit]?.[targetUnit]?.(value)

export const convertVibration = (
  sourceUnit: EUnitType,
  targetUnit: EUnitType,
  amplitude: number,
  frequency: number
) => {
  if (sourceUnit === targetUnit) {
    return amplitude
  }

  const baseVibrationSourceUnit = mapBaseVibrationUnits[sourceUnit]
  const baseVibrationTargetUnit = mapBaseVibrationUnits[targetUnit]

  const quantityVibrationSourceConverter = mapQuantityVibrationConverter[baseVibrationSourceUnit]
  const quantityVibrationTargetConverter = mapQuantityVibrationConverter[baseVibrationTargetUnit]

  if (baseVibrationSourceUnit === baseVibrationTargetUnit) {
    return quantityVibrationTargetConverter[sourceUnit]?.[targetUnit]?.(amplitude)
  }

  const baseSourceValue = quantityVibrationSourceConverter[sourceUnit]?.[baseVibrationSourceUnit]?.(amplitude)
  if (!baseSourceValue) {
    return undefined
  }

  const targetSourceValue = physicalQuantityVibrationConverter[baseVibrationSourceUnit]?.[baseVibrationTargetUnit]?.(
    baseSourceValue,
    frequency
  )

  if (!targetSourceValue) {
    return undefined
  }
  return (
    quantityVibrationTargetConverter[baseVibrationTargetUnit]?.[targetUnit]?.(targetSourceValue) || targetSourceValue
  )
}

export const convertBaseAcceleration = (sourceUnit: EUnitType, targetUnit: EUnitType, value: number) =>
  unitAccelerationConverter[sourceUnit]?.[targetUnit]?.(value)
