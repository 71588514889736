export const enum DcmParamName {
  Description = 'description',
  TypeSource = 'typeSource',
  FreqCoefForIn = 'freqCoefForIn',
  FreqOutSource = 'freqOutSource',
  Fu = 'fu',
  N2p = 'n2p',
  Nr = 'nr',
  Nc = 'nc'
}

export enum DcmOutName {
  Fu = 'fu',
  Frsd = 'frsd',
  Fe = 'fe',
  Fnl = 'fnl',
  Fdcmz = 'fdcmz',
  Fdcmc = 'fdcmc'
}
