import adSvg from '@/assets/images/kinematic/AD_CW.svg'
import bdSvg from '@/assets/images/kinematic/BD_CW.svg'
import brSvg from '@/assets/images/kinematic/BR_CW.svg'
import cdSvg from '@/assets/images/kinematic/CD_CW.svg'
import cnSvg from '@/assets/images/kinematic/CN_CW.svg'
import cpSvg from '@/assets/images/kinematic/CP_CW.svg'
import cwSvg from '@/assets/images/kinematic/CW_CW.svg'
import dcgSvg from '@/assets/images/kinematic/DCG_CW.svg'
import dcmSvg from '@/assets/images/kinematic/DCM_CW.svg'
import gbdKsSvg from '@/assets/images/kinematic/GBD_CW.svg'
import gbtKsSvg from '@/assets/images/kinematic/GBT_CW.svg'
import gbSvg from '@/assets/images/kinematic/GB_CW.svg'
import hdSvg from '@/assets/images/kinematic/HD_CW.svg'
import pdSvg from '@/assets/images/kinematic/PD_CW.svg'
import sdSvg from '@/assets/images/kinematic/SD_CW.svg'
import sgSvg from '@/assets/images/kinematic/SG_CW.svg'
import shSvg from '@/assets/images/kinematic/SH_CW.svg'
import tgbSvg from '@/assets/images/kinematic/TGB_CW.svg'
import tnSvg from '@/assets/images/kinematic/TN_CW.svg'
import twSvg from '@/assets/images/kinematic/TW_CW.svg'
import type { IKbType } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.type'

export enum KbType {
  Br = 'BR',
  Cp = 'CP',
  Sh = 'SH',
  Bs = 'BS',
  Bf = 'BF',
  Ad = 'AD',
  Sd = 'SD',
  Sg = 'SG',
  Dcm = 'DCM',
  Dcg = 'DCG',
  Gb = 'GB',
  Gbd = 'GBD',
  Gbt = 'GBT',
  Tgb = 'TGB',
  Tw = 'TW',
  Cw = 'CW',
  Tn = 'TN',
  Cn = 'CN',
  Bd = 'BD',
  Cd = 'CD',
  Hd = 'HD',
  Pd = 'PD'
}

export const additionalInformationKb: { [key in string]: IKbType } = {
  [KbType.Br]: {
    image: brSvg,
    label: 'Подшипник качения'
  },
  [KbType.Ad]: {
    image: adSvg,
    label: 'Асинхронный двигатель'
  },
  [KbType.Gbd]: {
    image: gbdKsSvg,
    label: 'Двухступенчатая зубчатая передача со сменой направления вращения'
  },
  [KbType.Gbt]: {
    image: gbtKsSvg,
    label: 'Двухступенчатая зубчатая передача с разделением момента'
  },
  [KbType.Tw]: {
    image: twSvg,
    label: 'Рабочее колесо турбины / вентилятора'
  },
  [KbType.Cw]: {
    image: cwSvg,
    label: 'Рабочее колесо компрессор / насос'
  },
  [KbType.Hd]: {
    image: hdSvg,
    label: 'Гидропривод'
  },
  [KbType.Pd]: {
    image: pdSvg,
    label: 'Пневмо / газовый привод'
  },
  [KbType.Sd]: {
    image: sdSvg,
    label: 'Синхронный двигатель'
  },
  [KbType.Sg]: {
    image: sgSvg,
    label: 'Синхронный генератор'
  },
  [KbType.Sh]: {
    image: shSvg,
    label: 'Вал'
  },
  [KbType.Cp]: {
    image: cpSvg,
    label: 'Муфта'
  },
  [KbType.Dcm]: {
    image: dcmSvg,
    label: 'Двигатель постоянного тока'
  },
  [KbType.Dcg]: {
    image: dcgSvg,
    label: 'Генератор постоянного тока'
  },
  [KbType.Gb]: {
    image: gbSvg,
    label: 'Одноступенчатая зубчатая передача'
  },
  [KbType.Bd]: {
    image: bdSvg,
    label: 'Ременная передача'
  },
  [KbType.Tgb]: {
    image: tgbSvg,
    label: 'Двухвходовая двухступенчатая зубчатая передача'
  },
  [KbType.Cd]: {
    image: cdSvg,
    label: 'Цепная передача'
  },
  [KbType.Cn]: {
    image: cnSvg,
    label: 'Компрессор'
  },
  [KbType.Tn]: {
    image: tnSvg,
    label: 'Турбина'
  }
}

export const enum KbParam {
  Description = 'description',
  TypeSource = 'typeSource',
  KinematicBlockSourceId = 'kinematicBlockSourseId',
  FreqOutSource = 'freqOutSource',
  FreqCoefForIn = 'freqCoefForIn'
}

export const enum TypeSource {
  Kb = 'KB',
  Mp = 'MP',
  Manual = 'manual',
  Missing = 'Отсутствует'
}

export const optionsTypeSourceWithKb = [
  { label: 'Кинематический блок', value: TypeSource.Kb },
  { label: 'Точка измерения', value: TypeSource.Mp, disabled: true },
  { label: 'Отсутствует', value: TypeSource.Missing }
]

export const optionsTypeSourceWithoutKb = [
  { label: 'Точка измерения', value: TypeSource.Mp, disabled: true },
  { label: 'Отсутствует', value: TypeSource.Missing }
]

export const optionsTypeSourceWithManual = [
  { label: 'Ручной ввод', value: TypeSource.Manual },
  { label: 'Точка измерения', value: TypeSource.Mp, disabled: true },
  { label: 'Отсутствует', value: TypeSource.Missing }
]
