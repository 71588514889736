import {
  MachineConditionRoute,
  MainRoute
} from '@/constants/navigation/routes.constant'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Home: FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(
      `${MainRoute.MachineCondition}/${MachineConditionRoute.DescriptionCondition}`
    )
  }, [navigate])
  return <></>
}

export default Home
