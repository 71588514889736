import ParamsConnections from '@/app/configuration/components/connections/params-connection/params-connections'
import Submenu from '@/components/UI/elements/submenu/submenu'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import ToolBar from '@/components/UI/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/UI/layouts/work-space/work-space'
import MachinesList from '@/components/machines-list/machines-list'
import PointsList from '@/components/points-list/points-list'
import { configurationLinks } from '@/constants/navigation/links.constant'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'

const Connections: FC = () => {
  const { selectedPointId, selectedMachineId } = useTypedSelector((state) => state.globalReducer)

  return (
    <Container>
      <ToolBar />
      <LeftBar>
        <MachinesList />

        {selectedMachineId && <PointsList />}
      </LeftBar>
      <WorkSpace>
        <Submenu links={configurationLinks} />

        {selectedPointId && <ParamsConnections />}
      </WorkSpace>
    </Container>
  )
}

export default Connections
