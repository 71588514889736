import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import Popup from '@/components/UI/elements/popup/popup'
import { DATE_TEMPLATE } from '@/constants/core/common.constant'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useCreatePlanMutation } from '@/store/api/plans.api'
import type { TPlan } from '@/types/plan.type'
import { formatDate } from '@/utils/format-date'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Form, Input, Select } from 'antd'
import type { FC } from 'react'
import React, { useCallback } from 'react'

import styles from '@/components/UI/elements/popup/popup.module.css'

import { devices, planTypes } from './plans.constant'

type TProps = {
  setIsPopupOpen: (value: React.SetStateAction<boolean>) => void
  isPopupOpen: boolean
  onSubmitHandler: (object: TPlan) => void
}

const PopupPlans: FC<TProps> = ({ isPopupOpen, setIsPopupOpen, onSubmitHandler }) => {
  const [form] = Form.useForm()

  const { plans } = useTypedSelector((state) => state.planReducer)

  const [createPlan] = useCreatePlanMutation()

  const onResetPlan = useCallback(() => {
    setIsPopupOpen(false)
    form.resetFields()
  }, [form, setIsPopupOpen])

  const onFinishAddPlan = useCallback(async () => {
    try {
      const fieldValues = form.getFieldsValue()

      if (!plans.find((currentPlan) => currentPlan.name === fieldValues.namePlan.trim())) {
        const planResult = await createPlan({
          name: fieldValues.namePlan.trim(),
          planType: fieldValues.planType,
          deviceType: fieldValues.deviceType
        }).unwrap()

        if (!planResult) throw new Error('Маршрут не создан')

        const plan: TPlan = {
          name: fieldValues.namePlan.trim(),
          planType: fieldValues.planType,
          deviceType: fieldValues.deviceType,
          machineIds: [],
          excludedMachineIds: [],
          excludedMeasurementIds: [],
          excludedMeasuringPointIds: [],
          planId: planResult?.id,
          status: 'SENT',
          modified: formatDate(String(Date.now()), DATE_TEMPLATE),
          lastExecutionTimestamp: '---------- --:--:--',
          nextExecutionTimestamp: '---------- --:--:--',
          machines: []
        }

        onSubmitHandler(plan)

        successNotificationCreate('Маршрут добавлен')

        onResetPlan()
      } else {
        errorNotificationCreate({
          status: 'Маршрут уже существует!'
        })
      }
    } catch (error) {
      errorNotificationCreate(error)
    }
  }, [createPlan, form, onResetPlan, onSubmitHandler, plans])

  return (
    <Popup title={'Добавить маршрут'} isOpen={isPopupOpen}>
      <Form form={form} name={'plan'} onReset={onResetPlan} onFinish={onFinishAddPlan} className={styles.form}>
        <div className={styles['dropdown-container']}>
          <p className={styles['dropdown-title']}>Устройство: </p>

          <Form.Item
            name={'deviceType'}
            rules={[{ required: true, message: 'Нужно выбрать устройство' }]}
            className={styles['form-item']}
          >
            <Select placeholder={'------'} className={styles.select} options={devices} />
          </Form.Item>
        </div>

        <div className={styles['dropdown-container']}>
          <p className={styles['dropdown-title']}>Тип маршрута: </p>

          <Form.Item
            name={'planType'}
            rules={[
              {
                required: true,
                message: 'Нужно выбрать тип маршрута'
              }
            ]}
            className={styles['form-item']}
          >
            <Select placeholder={'------'} className={styles.select} options={planTypes} />
          </Form.Item>
        </div>

        <Form.Item
          name={'namePlan'}
          rules={[
            {
              validator: (_, value) =>
                value?.trim() ? Promise.resolve() : Promise.reject(new Error('Нужно ввести имя маршрута'))
            }
          ]}
        >
          <Input placeholder={'Введите имя маршрута*'} />
        </Form.Item>

        <div className={styles.buttons}>
          <ButtonPrimary htmlType={'reset'} title={'ОТМЕНА'} />
          <ButtonPrimary htmlType={'submit'} title={'ДОБАВИТЬ'} />
        </div>
      </Form>
    </Popup>
  )
}

export default PopupPlans
