import { SliceName } from '@/constants/store/slices.constant'
import { EDeviceType } from '@/enums/device/device-type.enum'
import type { IConnectionInitialState } from '@/types/connection/connection-initial-state.interface'
import type { ISelectedConnection } from '@/types/connection/selected-connection.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: IConnectionInitialState = {
  selectedConnection: { typeDevice: EDeviceType.NULL, isOpenPanel: false }
}

export const connectionUiSlice = createSlice({
  name: SliceName.Connection,
  initialState,
  reducers: {
    setSelectedConnection(state, action: PayloadAction<ISelectedConnection>) {
      state.selectedConnection = action.payload
    }
  }
})
