import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import { ButtonTitle } from '@/constants/core/common.constant'
import type { FC } from 'react'

import styles from './control-panel.module.css'

type TControlPanel = {
  onClickCancel: () => void
  onClickCalculation?: () => void
  onClickSave: () => void
  disabledCancel: boolean
  disabledCalculation?: boolean
  disabledSave: boolean
  isLoadingSave: boolean
}
export const ControlPanel: FC<TControlPanel> = ({
  onClickCancel,
  onClickCalculation,
  onClickSave,
  disabledCancel,
  disabledCalculation,
  disabledSave,
  isLoadingSave
}) => (
  <ButtonsWrapper className={styles['buttons-wrapper']}>
    <ButtonPrimary
      title={ButtonTitle.Cancel}
      htmlType={'button'}
      theme='dark'
      onClick={onClickCancel}
      disabled={disabledCancel}
    />
    {onClickCalculation && (
      <ButtonPrimary
        title='Рассчитать'
        htmlType={'button'}
        theme='dark'
        onClick={onClickCalculation}
        disabled={disabledCalculation}
      />
    )}
    <ButtonPrimary
      title='Сохранить'
      htmlType={'button'}
      theme='dark'
      onClick={onClickSave}
      disabled={disabledSave}
      isLoading={isLoadingSave}
    />
  </ButtonsWrapper>
)
