export enum FormBranch {
  Common = 'common',
  Harmonic = 'harmonic',
  Square = 'square'
}

export enum FormTitleGenerator {
  Type = 'Тип генератора',
  Parameters = 'Общие параметры генератора',
  TypeChannel = 'Тип канала',
  SignalParameters = 'Параметры сигнала',
  ParamsModulatingOne = 'Параметры модулирующего процесса 1',
  ParamsModulatingTwo = 'Параметры модулирующего процесса 2'
}
