import type { InputRef } from 'antd'
import type { DependencyList } from 'react'
import { useEffect, useRef } from 'react'

export const useFocusInputAd = (dependency: DependencyList = []) => {
  const inputRef = useRef<InputRef>(null)

  useEffect(() => {
    const inputElement = inputRef.current

    if (inputElement) {
      inputRef.current.focus()
    }
  }, [dependency])

  return inputRef
}
