import type { FC } from 'react'
import React from 'react'

import styles from './textIndicator.module.css'

import type { IToolProps } from '../tool.interface'

const TextIndicator: FC<IToolProps> = ({ handleDoubleClick }) => (
  <button
    type={'button'}
    onDoubleClick={handleDoubleClick}
    className={styles.button}
  >
    <p> Текст </p>
  </button>
)

export default TextIndicator
