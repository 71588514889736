import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'

export enum Vp3701ChannelType {
  VibroAccelerationX = 'VIBRO_ACCELERATION_X',
  VibroAccelerationY = 'VIBRO_ACCELERATION_Y',
  VibroAccelerationZ = 'VIBRO_ACCELERATION_Z',
  ObjectTemperature = 'OBJECT_TEMPERATURE',
  InternalSensorTemperature = 'INTERNAL_SENSOR_TEMPERATURE'
}

export const Vp3701ChannelTypeMap: Record<Vp3701ChannelType, string> = {
  [Vp3701ChannelType.VibroAccelerationX]: 'Виброускорение по оси X',
  [Vp3701ChannelType.VibroAccelerationY]: 'Виброускорение по оси Y',
  [Vp3701ChannelType.VibroAccelerationZ]: 'Виброускорение по оси Z',
  [Vp3701ChannelType.ObjectTemperature]: 'Объект',
  [Vp3701ChannelType.InternalSensorTemperature]: 'Датчик'
}

export const pointTypes = [
  { label: 'Виброускорение', value: EPhysicalQuantityType.VIBRO_ACCELERATION },
  {
    label: 'Виброскорость',
    value: EPhysicalQuantityType.VIBRO_VELOCITY,
    disabled: true
  },
  {
    label: 'Виброперемещение',
    value: EPhysicalQuantityType.VIBRO_DISPLACEMENT,
    disabled: true
  },
  {
    label: 'Температура',
    value: EPhysicalQuantityType.TEMPERATURE
  },
  {
    label: 'Напряжение',
    value: EPhysicalQuantityType.VOLTAGE,
    disabled: true
  },
  { label: 'Ток', value: EPhysicalQuantityType.AMPERAGE, disabled: true },
  { label: 'Фаза', value: EPhysicalQuantityType.PHASE, disabled: true },
  {
    label: 'Частота вращения',
    value: EPhysicalQuantityType.RPM,
    disabled: true
  },
  {
    label: 'Изменение частоты вращения',
    value: EPhysicalQuantityType.DRPM,
    disabled: true
  },
  { label: 'Не определен', value: EPhysicalQuantityType.NULL, disabled: true }
]

export const mapTypePoint: Record<EPhysicalQuantityType, string> = {
  [EPhysicalQuantityType.VIBRO_ACCELERATION]: 'Виброускорение',
  [EPhysicalQuantityType.TEMPERATURE]: 'Температура',
  [EPhysicalQuantityType.RPM]: 'rpm',
  [EPhysicalQuantityType.AMPERAGE]: 'Сила тока',
  [EPhysicalQuantityType.DRPM]: 'drpm',
  [EPhysicalQuantityType.NULL]: 'null',
  [EPhysicalQuantityType.VOLTAGE]: 'Напряжение',
  [EPhysicalQuantityType.VIBRO_VELOCITY]: 'Виброскорость',
  [EPhysicalQuantityType.PHASE]: 'Фаза',
  [EPhysicalQuantityType.VIBRO_DISPLACEMENT]: 'Виброперемещение'
}
