import EquipmentItem from '@/components/UI/elements/equipment-item/equipment-item'
import StatisticAlIIndicator from '@/components/UI/elements/indicators/statistical-indicator/statistic-alI-indicator'
import type { INodesState } from '@/types/nodes/nodes.type'
import type { FC } from 'react'

interface IProps {
  name: string
  paused: boolean
  id: string
  dataAllNodesState?: INodesState[]
}

const PlanPointItem: FC<IProps> = ({ name, paused, dataAllNodesState, id }) => {
  const foundNodeState = dataAllNodesState?.find(
    (node) => node.measuringPointId === id
  )

  return (
    <EquipmentItem
      indicator={
        foundNodeState ? (
          <StatisticAlIIndicator nodeState={foundNodeState} />
        ) : null
      }
      name={name}
      paused={paused}
    />
  )
}

export default PlanPointItem
