import cn from 'classnames'
import type { FC } from 'react'

import styles from './menu-tree.module.css'

const PointIcon: FC = () => (
  <span className={cn('material-symbols-outlined', styles['icon'])}>
    radio_button_checked
  </span>
)

export default PointIcon
