import type { FC, ReactNode } from 'react'

import styles from './text-static.module.css'

interface IProps {
  label: string
  value?: string | ReactNode
}

const TextStatic: FC<IProps> = ({ label, value }) => (
  <div className={styles['text-static']}>
    <span className={styles['label']} title={label}>
      {label}
    </span>
    <span className={styles['value']}>{value && value}</span>
  </div>
)

export default TextStatic
