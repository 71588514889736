import { DeviceLabel, DeviceValue } from '@/constants/device/devices-form'

export enum ParameterDevices {
  Type = 'type',
  VP3701ID = 'vp3701id',
  TypeGenerator = 'type-generator',
  GeneratorID = 'generatorid'
}

export const devices = [
  { label: DeviceLabel.VP3701, value: DeviceValue.VP3701 },
  { label: DeviceLabel.Generator, value: DeviceValue.Generator }
]
