import { ReportKey, ReportValue } from '@/constants/reports.constant'
import useActions from '@/hooks/use-actions'
import type { IReport, IReportMenu } from '@/types/reports/reports.type'
import { FileOutlined, FolderOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Menu } from 'antd'
import type { FC, ReactInstance } from 'react'
import React from 'react'

import styles from './menu-collapsed.module.css'

const reportsMenu: IReportMenu[] = [
  {
    key: 'sub1',
    label: 'Типы устройств',
    icon: <FolderOutlined />,
    children: [
      {
        key: ReportKey.Vp3701,
        label: ReportValue.Vp3701,
        icon: <FileOutlined />
      },
      {
        key: ReportKey.Harmonic,
        label: ReportValue.Harmonic,
        icon: <FileOutlined />
      },
      {
        key: ReportKey.Square,
        label: ReportValue.Square,
        icon: <FileOutlined />
      }
    ]
  }
]

const reports: IReport[] = [
  { key: ReportKey.Vp3701, value: ReportValue.Vp3701 },
  { key: ReportKey.Square, value: ReportValue.Square },
  { key: ReportKey.Harmonic, value: ReportValue.Harmonic }
]

interface MenuInfo {
  key: string
  keyPath: string[]
  item: ReactInstance
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
}

const MenuCollapsed: FC<MenuProps> = ({ ...props }) => {
  const { setSelectedReport, setCheckedData } = useActions()

  const handleClickReportMenu = (info: MenuInfo) => {
    const foundReport = reports.find(
      (report) => report.key === info.key
    ) as IReport
    setSelectedReport(foundReport)
    setCheckedData([])
  }

  return (
    <Menu
      {...props}
      className={styles['menu-collapsed']}
      mode='inline'
      defaultOpenKeys={['sub1']}
      items={reportsMenu}
      onClick={handleClickReportMenu}
    />
  )
}

export default MenuCollapsed
