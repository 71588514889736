import { uniqueId } from 'lodash'

export const DeviceTypeLabel = {
  Vp3701: 'Устройства VP-3701',
  Generator: 'Генераторы'
} as const

export const DeviceTypeId = {
  Vp3701: uniqueId('device_type_'),
  Generator: uniqueId('device_type_')
} as const
