/**
 * Функция для расчёта коэффициента частоты вращения сепаратора: kFTF
 * @constructor
 * @param {number} dTk - Диаметр тел качения
 * @param {number} dIn - Диаметр внутреннего кольца
 * @param {number} dOut - Диаметр наружного кольца
 * @param {number} alfa - Угол контакта, радианы
 */
export const calculateKFTF = (
  dTk: number,
  dIn: number,
  dOut: number,
  alfa: number
): number => 0.5 * (1 - (dTk / ((dIn + dOut) / 2)) * Math.cos(alfa))

/**
 * Функция для расчёта коэффициент обратной частоты сепаратора: kFTFo
 * @constructor
 * @param {number} kFTF - Коэффициент частоты вращения сепаратора
 */
export const calculateKFTFo = (kFTF: number): number => 1 - kFTF

/**
 * Функция для расчёта коэффициента частоты контакта тел качения: kBSF
 * @constructor
 * @param {number} dTk - Диаметр тел качения
 * @param {number} dIn - Диаметр внутреннего кольца
 * @param {number} dOut - Диаметр наружного кольца
 * @param {number} alfa - Угол контакта, радианы
 */
export const calculateKBSF = (
  dTk: number,
  dIn: number,
  dOut: number,
  alfa: number
): number => {
  const dC = (dIn + dOut) / 2
  return (
    0.5 * (dC / dTk) * (1 - Math.pow(dTk / dC, 2) * Math.pow(Math.cos(alfa), 2))
  )
}

/*0.5 *
  ((dIn + dOut) / 2 / dTk) *
  (1 - (dTk / (dIn + dOut) / 2) * (Math.cos(alfa) * Math.cos(alfa)))*/

/**
 * Функция для расчёта коэффициента частоты перекатывания тел качения по наружному кольцу: kBPFO
 * @constructor
 * @param {number} dTk - Диаметр тел качения
 * @param {number} dIn - Диаметр внутреннего кольца
 * @param {number} dOut - Диаметр наружного кольца
 * @param {number} alfaRadians - Угол контакта, радианы
 * @param {number} n - Количество тел качения
 */
export const calculateKBPFO = (
  dTk: number,
  dIn: number,
  dOut: number,
  alfaRadians: number,
  n: number
): number => 0.5 * n * (1 - (dTk / ((dIn + dOut) / 2)) * Math.cos(alfaRadians))

/**
 * Функция для расчёта коэффициента частоты перекатывания тел качения по внутреннему кольцу: kBPFI
 * @constructor
 * @param {number} dTk - Диаметр тел качения
 * @param {number} dIn - Диаметр внутреннего кольца
 * @param {number} dOut - Диаметр наружного кольца
 * @param {number} alfaRadians - Угол контакта, радианы
 * @param {number} n - Количество тел качения
 */
export const calculateKBPFI = (
  dTk: number,
  dIn: number,
  dOut: number,
  alfaRadians: number,
  n: number
): number => 0.5 * n * (1 + (dTk / ((dIn + dOut) / 2)) * Math.cos(alfaRadians))
