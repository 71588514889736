import { Spin } from 'antd'
import type { FC } from 'react'
import React from 'react'

import styles from '@/components/UI/elements/loader/loader.module.css'

type TProps = {
  isLoading: boolean
}

const Loader: FC<TProps> = ({ isLoading }) =>
  isLoading ? (
    <div className={styles.overlay}>
      <Spin size={'large'} />
    </div>
  ) : null

export default Loader
