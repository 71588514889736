export const enum ECommonLevelType {
  LEVEL_2_1000 = 'LEVEL_2_1000',
  LEVEL_10_1000 = 'LEVEL_10_1000',
  LEVEL_10_2000 = 'LEVEL_10_2000',
  LEVEL_05_500 = 'LEVEL_05_500',
  LEVEL_07_300 = 'LEVEL_07_300',
  LEVEL_100_5000 = 'LEVEL_100_5000',
  LEVEL_5_500 = 'LEVEL_5_500',
  LEVEL_10_5000 = 'LEVEL_10_5000',
  LEVEL_5_10000 = 'LEVEL_5_10000'
}
