import { SliceName } from '@/constants/store/slices.constant'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type TBarOptions = {
  mainIndex: number | null
  mainFreq: number
  modulateIndex: number | null
  modulateFreq: number
  countOfMainBars: number
  countOfModulateBars: number
}

export type TModulateBar = {
  count: number
  modulateBarF: number | string
  modulateBarA: number | string
  percent: number | string
}

export type TResultsBar = {
  title: string
  isDb: boolean
  results: {
    isEmpty: boolean
    count: number
    percent: number
    leftModulateBars: TModulateBar[]
    mainBarF: number | string
    mainBarA: number | string
    rightModulateBars: TModulateBar[]
  }[]
}

interface IExpressDataInitial {
  barOptions: TBarOptions
  isBarsExpressBarOpen: boolean
  resultsBar: TResultsBar[]
}

const initialState: IExpressDataInitial = {
  barOptions: {
    mainIndex: 0,
    mainFreq: 0,
    countOfMainBars: 0,
    modulateFreq: 0,
    modulateIndex: 0,
    countOfModulateBars: 0
  },

  isBarsExpressBarOpen: false,

  resultsBar: []
}

export const expressBarsSlice = createSlice({
  name: SliceName.ExpressBars,
  initialState,
  reducers: {
    setIsBarsExpressOpen(state, action: PayloadAction<boolean>) {
      state.isBarsExpressBarOpen = action.payload
    },

    setExpressBarOptions(state, actions: PayloadAction<TBarOptions>) {
      state.barOptions = actions.payload
    },

    setResultsBar(state, action: PayloadAction<TResultsBar>) {
      const foundIndex = state.resultsBar.findIndex(
        (result) => result.title === action.payload.title
      )

      if (foundIndex === -1) {
        state.resultsBar.push(action.payload)
      } else {
        state.resultsBar[foundIndex] = {
          ...state.resultsBar[foundIndex],
          ...action.payload
        }
      }
    },

    resetResultsBar(state) {
      state.resultsBar = []
    }
  }
})
