import useEscHandler from '@/hooks/use-esc-handler'
import cn from 'classnames'
import type { FC, PropsWithChildren } from 'react'
import React from 'react'

import styles from '@/components/UI/elements/popup/popup.module.css'

type IProps = {
  isOpen: boolean
  title: string
  heightS?: boolean
  onCancel?: () => void
  isError?: boolean
}

const Popup: FC<PropsWithChildren<IProps>> = ({
  isOpen,
  title,
  children,
  onCancel,
  isError
}) => {
  useEscHandler(onCancel)

  return (
    <div
      className={cn(styles.overlay, {
        [styles['overlay-hidden']]: !isOpen
      })}
    >
      <div
        className={cn(styles.container, {
          [styles['shake-container']]: isError
        })}
      >
        <p className={styles.title}>{title}</p>
        <div className={styles.content}>{children}</div>
        {onCancel ? (
          <button
            type='reset'
            className={styles['button-close']}
            onClick={onCancel}
          />
        ) : null}
      </div>
    </div>
  )
}

export default Popup
