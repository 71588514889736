import type { FC } from 'react'

import styles from './drawer-title.module.css'

type TDrawerTitle = {
  title?: string
  description: string
}

const DrawerTitle: FC<TDrawerTitle> = ({ title, description }) => (
  <>
    <h1 className={styles.title}>{title}</h1>
    <p className={styles.description}>{description}</p>
  </>
)

export default DrawerTitle
