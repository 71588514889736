import cn from 'classnames'
import type { FC } from 'react'

import styles from './menu-tree.module.css'

const MachineIcon: FC = () => (
  <span className={cn('material-symbols-outlined', styles['icon'])}>
    settings
  </span>
)

export default MachineIcon
