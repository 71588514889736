export const enum BdParamName {
  Description = 'description',
  TypeSource = 'typeSource',
  KinematicBlockSourceId = 'kinematicBlockSourseId',
  FreqOutSource = 'freqOutSource',
  FreqCoefForIn = 'freqCoefForIn',
  D1 = 'd1',
  D2 = 'd2',
  L = 'l'
}
