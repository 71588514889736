import Submenu from '@/components/UI/elements/submenu/submenu'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import WorkSpace from '@/components/UI/layouts/work-space/work-space'
import GroupList from '@/components/group-list/group-list'
import KinematicSpace from '@/components/kinematic-space/kinematic-space'
import { machineConditionLinks } from '@/constants/navigation/links.constant'
import { EKinematicWorkMode } from '@/enums/kinematic/work-mode.enum'
import { useGetCurrentKinematicScheme } from '@/hooks/api/use-get-current-kinematic-scheme'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'
import { useEffect } from 'react'

import styles from './kinematics-condition.module.css'

const KinematicsCondition: FC = () => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)
  const { data: currentKinematic } =
    useGetCurrentKinematicScheme(selectedMachineId)
  const { setWorkMode, setSelectedKinematic } = useActions()

  // Resetting the UI states of the kinematic scheme
  useEffect(() => {
    setWorkMode(EKinematicWorkMode.Condition)
    setSelectedKinematic(null)
  }, [setSelectedKinematic, setWorkMode])

  return (
    <Container>
      <LeftBar>
        <GroupList />
      </LeftBar>
      <WorkSpace>
        <Submenu links={machineConditionLinks} />
        {currentKinematic ? (
          <KinematicSpace kinematicScheme={currentKinematic} />
        ) : (
          <p className={styles['text-information']}>
            Кинематическая схема отсутствует
          </p>
        )}
      </WorkSpace>
    </Container>
  )
}

export default KinematicsCondition
