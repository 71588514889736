import ButtonWrapper from '@/components/UI/elements/button-wrapper/button-wrapper'
import type { ButtonProps, DropdownProps } from 'antd'
import { Dropdown } from 'antd'
import type { TooltipPlacement } from 'antd/es/tooltip'
import type { FC, MouseEventHandler } from 'react'
import React from 'react'

type ButtonDropdownProps = {
  icon: string
  title: string
  onClick?: MouseEventHandler<HTMLElement>
  sizeS?: boolean
  disabled?: boolean
  activated?: boolean
  placementTooltip?: TooltipPlacement
}

type PropsType = ButtonDropdownProps & ButtonProps & DropdownProps

const ButtonDropdown: FC<PropsType> = ({
  icon,
  title,
  menu,
  placement,
  activated,
  disabled,
  trigger,
  placementTooltip
}) => (
  <Dropdown menu={menu} placement={placement} trigger={trigger}>
    <ButtonWrapper
      title={title}
      activated={activated}
      disabled={disabled}
      placement={placementTooltip}
    >
      <span className={`material-symbols-sharp`}>{icon}</span>
    </ButtonWrapper>
  </Dropdown>
)

export default ButtonDropdown
