import { useTypedSelector } from '@/hooks/use-typed-selector'
import classNames from 'classnames'
import type { FC, LegacyRef } from 'react'
import { shallowEqual } from 'react-redux'

import styles from '../../../../express-sidebar.module.css'

import ExpressSectionResultItem from '../express-section-result-item/express-section-result-item'

interface IProps {
  title: string
  isDb: boolean
  data: { skz: number; am: number; fm: number }[]
  refSectionListItem: LegacyRef<HTMLLIElement> | null
  onClickExpressSectionButton: () => void
  currentIndex: number
}

const ExpressSectionListItem: FC<IProps> = ({
  title,
  data,
  isDb,
  refSectionListItem,
  onClickExpressSectionButton,
  currentIndex
}) => {
  const { sections, selectedResultIndex } = useTypedSelector(
    (state) => ({
      ...state.expressSectionReducer,
      ...state.chartsUiReducer
    }),
    {
      equalityFn: shallowEqual
    }
  )

  return (
    <li ref={refSectionListItem} className={styles['list-item']}>
      <button
        onClick={onClickExpressSectionButton}
        type={'button'}
        className={styles.button}
      >
        <h3
          className={classNames(
            styles['list-header'],
            styles['list-header-bg'],
            {
              [styles['list-header-active']]:
                selectedResultIndex === currentIndex
            }
          )}
        >
          {title}
          {isDb ? ' дБ' : ''}
        </h3>

        <ul className={styles.list}>
          {data.map((dataItem, index) => (
            <ExpressSectionResultItem
              key={index}
              skz={dataItem.skz}
              am={dataItem.am}
              fm={dataItem.fm}
              index={index}
              fl={sections[index].fl}
              fh={sections[index].fh}
            />
          ))}
        </ul>
      </button>
    </li>
  )
}

export default ExpressSectionListItem
