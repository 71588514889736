const calculateAs = (strobeValuesData: number[]) => {
  const strobesSum = strobeValuesData.reduce((prev, curr) => prev + curr, 0)

  const middleValue = strobesSum / strobeValuesData.length

  const topValue =
    strobeValuesData
      .map((value) => Math.pow(value - middleValue, 3))
      .reduce((prev, curr) => prev + curr, 0) / strobeValuesData.length

  const bottomValue = Math.sqrt(
    Math.pow(
      strobeValuesData
        .map((value) => Math.pow(value - middleValue, 2))
        .reduce((prev, curr) => prev + curr, 0) / strobeValuesData.length,
      3
    )
  )

  return topValue / bottomValue
}

export default calculateAs
