import FormItemApp from '@/components/UI/form-controls/form-item-mcm/form-item-app'
import TextLabel from '@/components/UI/form-controls/text-label/text-label'
import {
  MeasurementLabel,
  MeasurementName
} from '@/constants/measurement/measurements-form.constant'
import {
  commonLevels,
  detectors
} from '@/constants/measurement/measurements.constant'
import { ECommonLevelType } from '@/enums/measurment/common-level-type.enum'
import { EDetector } from '@/enums/measurment/detector.enum'
import { Select } from 'antd'
import type { FC } from 'react'

interface IProps {
  isReaderMode?: boolean
  isWriterMode?: boolean
  detector?: string
  commonLevelType?: string
}

const CommonLevelForm: FC<IProps> = ({
  isReaderMode,
  isWriterMode,
  detector,
  commonLevelType
}) => (
  <>
    <FormItemApp
      label={MeasurementLabel.Detector}
      name={MeasurementName.Detector}
      initialValue={EDetector.SKZ}
      rules={[{ required: true, message: 'Поле обязательно' }]}
    >
      {isWriterMode && <Select options={detectors} />}
      {isReaderMode && <TextLabel manualValue={detector} />}
    </FormItemApp>

    <FormItemApp
      label={MeasurementLabel.CommonLevelType}
      name={MeasurementName.CommonLevelType}
      initialValue={ECommonLevelType.LEVEL_2_1000}
      rules={[{ required: true, message: 'Поле обязательно' }]}
    >
      {isWriterMode && <Select options={commonLevels} />}
      {isReaderMode && <TextLabel manualValue={commonLevelType} />}
    </FormItemApp>
  </>
)

export default CommonLevelForm
