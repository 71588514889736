import FormAddToleranceControl from '@/app/configuration/components/equipments/components/measurements/components/threshold/zones-table/components/popup-zones/components/form-add-tolerance-control/form-add-tolerance-control'
import FormRemoveToleranceControl from '@/app/configuration/components/equipments/components/measurements/components/threshold/zones-table/components/popup-zones/components/form-delete-tolerance-control/form-remove-tolerance-control'
import FormEditToleranceControl from '@/app/configuration/components/equipments/components/measurements/components/threshold/zones-table/components/popup-zones/components/form-edit-tolerance-control/form-edit-tolerance-control'
import Popup from '@/components/UI/elements/popup/popup'
import { EFormMode } from '@/enums/threshold/form-mode.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IRift } from '@/types/threshold/threshold.type'
import type { FC } from 'react'

const PopupToleranceControl: FC = () => {
  const { setIsOpenThresholdPopup } = useActions()
  const { formMode, isOpenThresholdPopup, rifts } = useTypedSelector(
    (state) => state.thresholdReducer
  )
  const isAddFormMode = formMode === EFormMode.ADD
  const isEditFormMode = formMode === EFormMode.EDIT
  const isRemoveFormMode = formMode === EFormMode.REMOVE

  // const handleEditZone = () => {
  //   const fieldsValue = form.getFieldsValue()
  //   updateZone({
  //     rift: {
  //       coef: Number(fieldsValue.coef) || 0,
  //       hysteresisCoef: Number(fieldsValue.hysteresisCoef),
  //       riftValue: Number(fieldsValue.riftValue) || 0
  //     }
  //   })
  //   if (thresholdForm.riftCalcEnabled) {
  //     reCalcRifts()
  //   }
  //   setIsOpenPopup(false)
  // }

  // const handleFinishForm = () => {
  //   switch (formMode) {
  //     case FormMode.Add:
  //       handleAddZone()
  //       return
  //     case FormMode.Remove:
  //       removeZone()
  //       setIsOpenPopup(false)
  //       return
  //     case FormMode.Edit:
  //       handleEditZone()
  //       return
  //   }
  // }

  const handlePopupCancel = () => {
    setIsOpenThresholdPopup(false)
  }

  const getTitlePopup = (): string => {
    switch (formMode) {
      case EFormMode.EDIT:
        return 'Редактирование зоны состояния'
      case EFormMode.REMOVE:
        return 'Удаление зоны состояния'
      default:
        return 'Добавление зоны состояния'
    }
  }

  const checkDuplicateRiftValue = () => (test: unknown, value: string) => {
    if (rifts.find((rift: IRift) => rift.riftValue === Number(value))) {
      return Promise.reject(new Error(`Значение уже существует`))
    }
    return Promise.resolve()
  }

  return (
    <Popup
      isOpen={isOpenThresholdPopup}
      title={getTitlePopup()}
      onCancel={handlePopupCancel}
    >
      {isAddFormMode && (
        <FormAddToleranceControl
          checkDuplicateRiftValue={checkDuplicateRiftValue}
        />
      )}
      {isEditFormMode && (
        <FormEditToleranceControl
          checkDuplicateRiftValue={checkDuplicateRiftValue}
        />
      )}
      {isRemoveFormMode && <FormRemoveToleranceControl />}
    </Popup>
  )
}

export default PopupToleranceControl
