import type { FC } from 'react'
import React from 'react'

const NotImplemented: FC = () => (
  <p style={{ margin: '20px 0', display: 'block', textAlign: 'center' }}>
    Не реализовано
  </p>
)

export default NotImplemented
