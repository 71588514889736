import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { TBarOptions } from '@/store/reducers/charts/express-bars.slice'
import { useEffect, useRef, useState } from 'react'
import type { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'

const useExpressBars = () => {
  const { setExpressBarOptions } = useActions()
  const { barOptions: barOptionsReducer } = useTypedSelector((state) => state.expressBarsReducer)
  const [barOptions, setBarOptions] = useState<TBarOptions>({
    mainFreq: 0,
    mainIndex: null,
    countOfMainBars: 0,
    modulateFreq: 0,
    modulateIndex: null,
    countOfModulateBars: 0
  })

  const refIndexBar = useRef(0)
  const refIndexModulateBar = useRef(0)

  const [isMainAreaDrag, setIsMainAreaDrag] = useState(false)
  const [isModulateAreaDrag, setIsModulateAreaDrag] = useState(false)

  const resetDefaultSettings = () => {
    if (isMainAreaDrag || isModulateAreaDrag) {
      setExpressBarOptions({
        ...barOptions,
        modulateFreq: Math.abs(barOptions.modulateFreq)
      })

      setIsMainAreaDrag(false)
      setIsModulateAreaDrag(false)
    }
  }

  const onMouseMove = (e: CategoricalChartState) => {
    if (isMainAreaDrag || isModulateAreaDrag) {
      setBarOptions((prev) => {
        if (e?.activePayload !== undefined) {
          const target = e.activePayload[0]?.payload
          const { frequency: targetFreq, index: targetIndex } = target

          if (isMainAreaDrag) {
            prev.mainIndex = Math.round(targetIndex / refIndexBar.current)

            prev.mainFreq = targetFreq / refIndexBar.current
          } else if (isModulateAreaDrag) {
            prev.modulateFreq = (prev.mainFreq * refIndexBar.current - targetFreq) / refIndexModulateBar.current
          }

          return {
            ...prev
          }
        }

        return prev
      })
    }
  }

  const onMouseUp = () => {
    resetDefaultSettings()
  }

  const onMouseLeave = () => {
    resetDefaultSettings()
  }

  useEffect(() => {
    setBarOptions({
      ...barOptionsReducer
    })
  }, [barOptionsReducer])

  return {
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    setIsMainAreaDrag,
    setIsModulateAreaDrag,
    setBarOptions,
    barOptions,
    refIndexBar,
    refIndexModulateBar
  }
}
export default useExpressBars
