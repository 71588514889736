import ConditionToolbar from '@/components/kinematic-space/components/kinematic-toolbar/components/condition-toolbar/condition-toolbar'
import ConfigurationToolbar from '@/components/kinematic-space/components/kinematic-toolbar/components/configuration-toolbar/configuration-toolbar'
import { EKinematicWorkMode } from '@/enums/kinematic/work-mode.enum'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IKinematicElement } from '@/types/kinematic.type'
import type { FC } from 'react'

import styles from '@/components/kinematic-space/kinematic-space.module.css'

type TProps = {
  totalFilteredElements: string
  selectedElement: IKinematicElement | null
  kinematicSchemeId?: string
}

const KinematicToolbar: FC<TProps> = ({ totalFilteredElements, selectedElement, kinematicSchemeId }) => {
  const { workMode } = useTypedSelector((state) => state.kinematicUiReducer)
  return (
    <div className={styles.tools}>
      {workMode === EKinematicWorkMode.Configuration && (
        <ConfigurationToolbar
          selectedElement={selectedElement}
          totalFilteredElements={totalFilteredElements}
          kinematicSchemeId={kinematicSchemeId}
        />
      )}
      {workMode === EKinematicWorkMode.Condition && <ConditionToolbar />}
    </div>
  )
}

export default KinematicToolbar
