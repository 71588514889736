import { ELevel } from '@/enums/node-state/level.enum'
import type { ICommonStatePoints } from '@/hooks/measurement-point/common-state-points.interface'

export const getQualifierPointState = (
  commonStatePoints?: ICommonStatePoints | null
): ELevel | null => {
  if (!commonStatePoints) {
    return null
  }

  const levels = [
    ELevel.Level4Count,
    ELevel.Level3Count,
    ELevel.Level2Count,
    ELevel.Level1Count
  ]

  for (const level of levels) {
    if (commonStatePoints[level] > 0) {
      return level
    }
  }

  return null
}
