export const enum ECodeError {
  ISR_1 = 'isr1',
  ISR_2 = 'isr2',
  PSRAM_1 = 'psram1',
  SPI_1 = 'spi1',
  I2C_1 = 'i2c1',
  I2C_2 = 'i2c2',
  I2C_3 = 'i2c3',
  I2C_4 = 'i2c4',
  WDT_1 = 'wdt1',
  TASK_1 = 'task1',
  TASK_2 = 'task2',
  TASK_3 = 'task3',
  NVC = 'nvc',
  WIFI_1 = 'wifi1',
  WIFI_2 = 'wifi2',
  HTTP_1 = 'http1',
  HTTP_2 = 'http2',
  HTTP_3 = 'http3',
  HTTP_4 = 'http4',
  KX_1 = 'kx1',
  MLX_1 = 'mlx1',
  HTTP_5 = 'http5'
}
