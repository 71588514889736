import type { IMeasurementData } from '@/app/configuration/components/equipments/components/measurements/components/threshold/threshold.interface'
import { mapDeviceType } from '@/constants/device/devices-form'
import { MeasurementName } from '@/constants/measurement/measurements-form.constant'
import { mapCommonLevels, mapTemperatureType } from '@/constants/measurement/measurements.constant'
import { ThresholdName, initializedRiftZoneData } from '@/constants/threshold/threshold.constant'
import { ERiftCalcType } from '@/enums/threshold/rift-calc-type.enum'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import type { IThreshold, IThresholdForm } from '@/types/threshold/threshold.type'
import { replaceDecimalPoint } from '@/utils/common'
import { getTypeMeasurement } from '@/utils/measurement/get-type-measurement'

export const mapFromRequestMeasurement = (measurement: IMeasurement): IMeasurementData => ({
  [MeasurementName.MeasurementName]: measurement.name,
  [MeasurementName.DeviceType]: mapDeviceType[measurement.deviceType],
  [MeasurementName.MeasurementType]: getTypeMeasurement(measurement),
  [MeasurementName.isActivated]: !measurement.paused,
  [MeasurementName.UnitType]: measurement.unitType,
  [MeasurementName.VisualFreqLimit]: measurement.programSpecterDescription?.visualFreqLimit,
  [MeasurementName.SpecterLineCount]: measurement.programSpecterDescription?.specterLineCount,
  [MeasurementName.CommonLevelType]: measurement.commonLevelDescription?.commonLevelType
    ? mapCommonLevels[measurement.commonLevelDescription.commonLevelType]
    : undefined,
  [MeasurementName.SamplingRate]: measurement?.signalDescription?.samplingRate,
  [MeasurementName.DurationInSec]: measurement?.signalDescription?.durationInSec?.toString(),
  [MeasurementName.IirFilterEnabled]: measurement?.signalDescription?.iirFilterEnabled,
  [MeasurementName.Coef]: replaceDecimalPoint(measurement?.temperatureDescription?.coef?.toString()),
  [MeasurementName.NumberOfAverages]: replaceDecimalPoint(
    measurement?.temperatureDescription?.numberOfAverages?.toString()
  ),
  [MeasurementName.TemperatureType]: measurement.temperatureDescription?.temperatureType
    ? mapTemperatureType[measurement.temperatureDescription.temperatureType]
    : undefined
})

export const mapFromRequestThreshold = (threshold: IThreshold): IThresholdForm => ({
  [ThresholdName.ControlEnabled]: threshold.controlEnabled,
  [ThresholdName.RiftCalcEnabled]: threshold.riftCalcEnabled,
  [ThresholdName.RiftCalcType]: threshold.riftCalcType || ERiftCalcType.INCREMENT,
  [ThresholdName.BaseRiftValue]: threshold.baseRiftValue,
  [ThresholdName.BaseRiftValueAutoCorrectEnabled]: threshold.baseRiftValueAutoCorrectEnabled,
  [ThresholdName.SkipLimit]: threshold.skipLimit,
  [ThresholdName.Rifts]: threshold.rifts,
  [ThresholdName.Zones]: threshold.zones
})

export const mapFromInitializationThreshold = (): IThresholdForm => ({
  [ThresholdName.ControlEnabled]: true,
  [ThresholdName.RiftCalcEnabled]: false,
  [ThresholdName.RiftCalcType]: ERiftCalcType.INCREMENT,
  [ThresholdName.BaseRiftValue]: 0,
  [ThresholdName.BaseRiftValueAutoCorrectEnabled]: false,
  [ThresholdName.SkipLimit]: 0,
  [ThresholdName.Rifts]: initializedRiftZoneData[ThresholdName.Rifts],
  [ThresholdName.Zones]: initializedRiftZoneData[ThresholdName.Zones]
})
