import type { IChartTiming } from '@/types/chart/charts.type'

export const searchStrobeBinary = (
  data: IChartTiming[],
  target: number,
  start: number,
  end: number
): IChartTiming => {
  if (end < 1) return data[0]

  const middle = Math.floor(start + (end - start) / 2)

  const middleFreq = data[middle].time
  const startFreq = data[start].time
  const endFreq = data[end].time

  if (target === middleFreq) {
    return data[middle]
  }

  if (end - 1 === start) {
    return Math.abs(startFreq - target) > Math.abs(endFreq - target)
      ? data[end]
      : data[start]
  }

  if (target > middleFreq) return searchStrobeBinary(data, target, middle, end)

  if (target < middleFreq)
    return searchStrobeBinary(data, target, start, middle)

  return data[0]
}
