import type { ButtonProps } from 'antd'
import { Button } from 'antd'
import type { FC, PropsWithChildren } from 'react'

import styles from './button-status.module.css'

type TProps = PropsWithChildren<ButtonProps>

const ButtonStatus: FC<TProps> = ({ children, ...props }) => (
  <Button htmlType='button' type='text' className={styles['button']} {...props}>
    {children}
  </Button>
)

export default ButtonStatus
