import { BdParamName } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bd-form/bd-form.constant'
import type {
  IFormBd,
  IOutData,
  IOuts
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bd-form/bd-form.type'
import { TypeSource } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type { IOptionOutSource } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.type'
import {
  handleName,
  handleValue
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.util'
import { REAL_NUMBER } from '@/config/connection.config'
import type { IKinematicElement, IOut } from '@/types/kinematic.type'
import { formatFloat, isNumber } from '@/utils/common'

// Расчёт частоты вращения второго (выходного) вала
export const calculateBdxfo2Coef = (d1?: number | null, d2?: number | null) =>
  isNumber(d1) && isNumber(d2) ? d1 / d2 : null

// Расчёт частоты вращения ремня
export const calculateBdxfbCoef = (d1?: number | null, l?: number | null) =>
  isNumber(d1) && isNumber(l) ? (d1 * Math.PI) / l : null

const multiplyValue = (numberOne?: number | null, numberTwo?: number | null) =>
  isNumber(numberOne) && isNumber(numberTwo) ? numberOne * numberTwo : null

export const parseServerData = (kinematicElement: IKinematicElement) => {
  const { description, ins, kinematicBlockBD, outs } = kinematicElement

  const fieldsData = {
    [BdParamName.Description]: description,
    [BdParamName.FreqCoefForIn]: ins ? ins[0].freqCoefForIn : 1,
    [BdParamName.TypeSource]: TypeSource.Missing,
    [BdParamName.D1]: null,
    [BdParamName.D2]: null,
    [BdParamName.L]: null
  }

  let outsData: IOuts | null = null

  if (kinematicBlockBD) {
    const { d1, d2, l } = kinematicBlockBD

    Object.assign(fieldsData, {
      [BdParamName.D1]: d1,
      [BdParamName.D2]: d2,
      [BdParamName.L]: l
    })
  }

  if (ins) {
    const typeSource = ins[0].kinematicBlockSourseId
      ? TypeSource.Kb
      : TypeSource.Mp

    const freqOutSource = {
      freqOutCoefIndexSourse: ins[0].freqOutCoefIndexSourse,
      freqOutCoefMachineSourse: ins[0].freqOutCoefMachineSourse,
      freqOutName: ins[0].freqInName,
      key: ins[0].freqOutCoefIndexSourse,
      label: `${ins[0].freqInName}: ${ins[0].freqInValue}`,
      value: ins[0].freqInValue
    }

    const insAdapted = {
      [BdParamName.TypeSource]: typeSource,
      [BdParamName.KinematicBlockSourceId]: ins[0].kinematicBlockSourseId,
      [BdParamName.FreqOutSource]: freqOutSource
    }

    Object.assign(fieldsData, insAdapted)
  }

  if (outs) {
    outsData = {
      bdxfrbd: {
        freqOutName: outs[0].freqOutName,
        freqOutCoefKb: outs[0].freqOutCoefKb,
        freqOutCoefMachine: outs[0].freqOutCoefMachine,
        freqOutValue: outs[0].freqOutValue
      },
      bdxfo2: {
        freqOutName: outs[1].freqOutName,
        freqOutCoefKb: outs[1].freqOutCoefKb,
        freqOutCoefMachine: outs[1].freqOutCoefMachine,
        freqOutValue: outs[1].freqOutValue
      },
      bdxfb: {
        freqOutName: outs[2].freqOutName,
        freqOutCoefKb: outs[2].freqOutCoefKb,
        freqOutCoefMachine: outs[2].freqOutCoefMachine,
        freqOutValue: outs[2].freqOutValue
      }
    }
  }

  return { fieldsData, outsData }
}

export const getCalculatedResults = (
  fieldsValue: IFormBd,
  kinematicBlockName?: string
) => {
  const { freqCoefForIn, d1, d2, l, freqOutSource } = fieldsValue
  const {
    value: freqOutValueSource,
    freqOutCoefMachineSourse,
    freqOutNameSourse
  } = freqOutSource as IOptionOutSource

  const freqInValue = multiplyValue(freqOutValueSource, freqCoefForIn)
  const freqInCoefMachine = multiplyValue(
    freqOutCoefMachineSourse,
    freqCoefForIn
  )
  const bdxfo2CoefKb = calculateBdxfo2Coef(d1, d2)
  const bdxfbCoefKb = calculateBdxfbCoef(d1, l)

  const result: IOuts = {
    bdxfrbd: {
      freqOutName: freqOutNameSourse,
      freqOutCoefMachine: freqInCoefMachine,
      freqOutCoefKb: freqCoefForIn,
      freqOutValue: freqInValue
    },
    bdxfo2: {
      freqOutName: kinematicBlockName ? `${kinematicBlockName}_Frbd` : null,
      freqOutCoefMachine: multiplyValue(freqInCoefMachine, bdxfo2CoefKb),
      freqOutCoefKb: bdxfo2CoefKb,
      freqOutValue: multiplyValue(freqInValue, bdxfo2CoefKb)
    },
    bdxfb: {
      freqOutName: kinematicBlockName ? `${kinematicBlockName}_Fb` : null,
      freqOutCoefMachine: multiplyValue(freqInCoefMachine, bdxfbCoefKb),
      freqOutCoefKb: bdxfbCoefKb,
      freqOutValue: multiplyValue(freqInValue, bdxfbCoefKb)
    }
  }

  Object.keys(result).forEach((key) => {
    const keyType = key as keyof IOuts
    const { freqOutValue, freqOutCoefMachine, freqOutCoefKb } = result[keyType]

    result[keyType].freqOutValue = freqOutValue
      ? Number(formatFloat(freqOutValue, REAL_NUMBER))
      : null
    result[keyType].freqOutCoefKb = freqOutCoefKb
      ? Number(formatFloat(freqOutCoefKb, REAL_NUMBER))
      : null
    result[keyType].freqOutCoefMachine = freqOutCoefMachine
      ? Number(formatFloat(freqOutCoefMachine, REAL_NUMBER))
      : null
  })

  return result
}

export const parseDataForChangeBd = (
  kinematicElement: IKinematicElement,
  fieldValues: IFormBd,
  outsData: IOuts
): IKinematicElement => {
  const {
    description,
    kinematicBlockSourseId,
    freqOutSource,
    freqCoefForIn,
    d1,
    d2,
    l
  } = fieldValues

  const data = {
    ...kinematicElement,
    description: description,
    kinematicBlockBD: {
      d1: d1,
      d2: d2,
      l: l
    }
  }

  if (freqOutSource) {
    const {
      freqOutNameSourse,
      value,
      freqOutCoefMachineSourse,
      freqOutCoefIndexSourse
    } = freqOutSource

    const ins = [
      {
        freqInName: freqOutNameSourse,
        freqInValue: value,
        freqOutCoefMachineSourse: freqOutCoefMachineSourse ?? 1,
        freqInCoefMachine:
          isNumber(freqCoefForIn) && isNumber(freqOutCoefMachineSourse)
            ? freqOutCoefMachineSourse * freqCoefForIn
            : 1,
        kinematicBlockSourseId: kinematicBlockSourseId,
        freqOutCoefIndexSourse: freqOutCoefIndexSourse ?? 0,
        freqOutNameSourse: freqCoefForIn === 1 ? freqOutNameSourse : 'Fin1',
        freqCoefForIn: freqCoefForIn
      }
    ]

    Object.assign(data, { ins })
  }

  const { bdxfrbd, bdxfo2, bdxfb } = outsData

  const outs: IOut[] = [
    {
      freqOutName: bdxfrbd.freqOutName,
      freqOutCoefKb: bdxfrbd.freqOutCoefKb,
      freqOutCoefMachine: bdxfrbd.freqOutCoefMachine,
      freqOutValue: bdxfrbd.freqOutValue
    },
    {
      freqOutName: bdxfo2.freqOutName,
      freqOutCoefKb: bdxfo2.freqOutCoefKb,
      freqOutCoefMachine: bdxfo2.freqOutCoefMachine,
      freqOutValue: bdxfo2.freqOutValue
    },
    {
      freqOutName: bdxfb.freqOutName,
      freqOutCoefKb: bdxfb.freqOutCoefKb,
      freqOutCoefMachine: bdxfb.freqOutCoefMachine,
      freqOutValue: bdxfb.freqOutValue
    }
  ]

  return Object.assign(data, { outs })
}

export const formatDataForTable = (outs: IOuts): IOutData[] => {
  const { bdxfrbd, bdxfo2, bdxfb } = outs
  const data: Omit<IOutData, 'key'>[] = [
    {
      label: 'Частота вращения',
      freqOutName: handleName(bdxfrbd.freqOutName),
      freqOutCoefKb: handleValue(bdxfrbd.freqOutCoefKb),
      freqOutCoefMachine: handleValue(bdxfrbd.freqOutCoefMachine),
      freqOutValue: handleValue(bdxfrbd.freqOutValue)
    },
    {
      label: 'Частота вращения второго (выходного) вала',
      freqOutName: handleName(bdxfo2.freqOutName),
      freqOutCoefKb: handleValue(bdxfo2.freqOutCoefKb),
      freqOutCoefMachine: handleValue(bdxfo2.freqOutCoefMachine),
      freqOutValue: handleValue(bdxfo2.freqOutValue)
    },
    {
      label: 'Частота вращения ремня',
      freqOutName: handleName(bdxfb.freqOutName),
      freqOutCoefKb: handleValue(bdxfb.freqOutCoefKb),
      freqOutCoefMachine: handleValue(bdxfb.freqOutCoefMachine),
      freqOutValue: handleValue(bdxfb.freqOutValue)
    }
  ]

  return data.map<IOutData>((item, key) => ({ ...item, key }))
}
