import {
  MAX_COUNT_LINES_AMPLITUDE,
  MAX_COUNT_LINES_FREQUENCIES
} from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.constant'
import { generateTicks } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.service'
import { calculateDecibel } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.util'
import ChartSpectrumPanel from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/components/chart-spectrum-panel/chart-spectrum-panel'
import TimeSliceChart from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/components/spectrum-overlay/components/time-slice-chart/time-slice-chart'
import {
  KEY_MAP,
  StepSize
} from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/components/spectrum-overlay/spectrum-overlay.constant'
import {
  assignColorsToDataLines,
  convertAmplitudeByCb,
  convertTimeSliceAmplitudeToDecibel,
  defineRangeBoundsForOverlayData,
  extractTimeSliceData,
  getFilteredDataByMaxAmplitude,
  getTimeSliceDataByCursor,
  transformDataForCursor,
  transformDataForSpectrumOverlay
} from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/components/spectrum-overlay/spectrum-overlay.service'
import { calculateDeltaFrequency } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/helpers/calculates.helper'
import type { ICursor } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/interfaces/cursor.interface'
import type { IDataLines } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/interfaces/data-lines.interface'
import type { ISpectrumOverlayData } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/interfaces/spectrum-overlay-data.interface'
import type { ITimeSliceData } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/interfaces/time-slice-data.interface'
import ChartsTools from '@/app/machine-condition/components/results/components/charts/components/chart-tools/charts-tools'
import ChartUnits from '@/app/machine-condition/components/results/components/charts/components/chart-units/chart-units'
import ChartWrapper from '@/app/machine-condition/components/results/components/charts/components/chart-wrapper/chart-wrapper'
import SliderApp from '@/app/machine-condition/components/results/components/charts/components/slider-app/slider-app'
import { DATE_TEMPLATE } from '@/constants/core/common.constant'
import { EAmplitudeMode, EFrequencyMode } from '@/enums/charts/chart-value-mode.enum'
import { EUnitType } from '@/enums/measurment/unit-type.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { TMeasurementResult } from '@/store/api/measurements.api'
import type { IValuesMode } from '@/types/chart/chart-spectrun.type'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import { chartFormatValue } from '@/utils/chart/chart-format-value'
import { getStepSlider } from '@/utils/chart/get-step-slider'
import { formatDate } from '@/utils/format-date'
import { Flex } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { max, min } from 'lodash'
import type { FC } from 'react'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { HotKeys, configure } from 'react-hotkeys'
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts'

import styles from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.module.css'

type TProps = {
  resultId: string
  title: string
  measurementResults: TMeasurementResult[]
  selectedMeasurement: IMeasurement
}

configure({
  ignoreRepeatedEventsWhenKeyHeldDown: false
})

const initialValuesMode: IValuesMode = {
  amplitudeMode: EAmplitudeMode.DB,
  frequencyMode: EFrequencyMode.Liner
}

const SpectrumOverlay: FC<TProps> = ({ resultId, title, measurementResults, selectedMeasurement }) => {
  const { cursorSettings } = useTypedSelector((state) => state.chartsUiReducer)
  const { setWidthCursor, setActiveCursor, setRefForFocusKeys } = useActions()
  const [valuesMode, setValuesMode] = useState<IValuesMode>(initialValuesMode)
  const [overlayData, setOverlayData] = useState<ISpectrumOverlayData[] | undefined>(undefined)
  const [sourceOverlayData, setSourceOverlayData] = useState<ISpectrumOverlayData[] | undefined>(undefined)
  const [lines, setLines] = useState<IDataLines[]>([])
  const [sliderIndexes, setSliderIndexes] = useState<number[]>([0, 0])
  const [sliderAmplitude, setSliderAmplitude] = useState<number[]>([0, 0])
  const [cursor, setCursor] = useState<ICursor | null>(null)
  const [timeSlice, setTimeSlice] = useState<ITimeSliceData[]>([])
  const [name, setName] = useState<string>(title)
  const [hoveredLine, setHoveredLine] = useState<string | null>(null)
  const [selectedFrequency, setSelectedFrequency] = useState<number | undefined>(undefined)
  const [amplitudeSliderDisabled, setAmplitudeSliderDisabled] = useState(true)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [targetUnit, setTargetUnit] = useState<EUnitType>(EUnitType.G)
  const chartRef = useRef<HTMLElement | null>(null)

  const isAmplitudeModeLog = valuesMode.amplitudeMode === EAmplitudeMode.LOG
  const isFrequencyModeLog = valuesMode.frequencyMode === EFrequencyMode.LOG
  const deltaFrequency = calculateDeltaFrequency(selectedMeasurement)

  const rangeIndexes = useMemo(() => (overlayData ? [0, overlayData.length - 1] : [0, 0]), [overlayData])

  const rangeFrequency = useMemo<number[] | undefined>(() => {
    if (!overlayData) {
      return undefined
    }
    const minIndex = sliderIndexes[0]
    const maxIndex = sliderIndexes[1]
    return [overlayData[minIndex]?.frequency, overlayData[maxIndex]?.frequency]
  }, [overlayData, sliderIndexes])

  const rangeAmplitude = useMemo<number[] | undefined>(() => {
    if (!overlayData) {
      return undefined
    }

    let data = [...overlayData]

    if (amplitudeSliderDisabled) {
      data = overlayData.slice(sliderIndexes[0], sliderIndexes[1])
    }

    const amplitudes = data.flatMap((obj) =>
      Object.entries(obj)
        .filter(([key]) => key.startsWith('amplitude'))
        .map(([_, value]) => value)
    )

    const minAmplitude = min(amplitudes) as number
    const maxAmplitude = max(amplitudes) as number
    return [minAmplitude, maxAmplitude]
  }, [amplitudeSliderDisabled, overlayData, sliderIndexes])

  const ticksFrequencies = useMemo(
    () => generateTicks(rangeFrequency, valuesMode.frequencyMode, MAX_COUNT_LINES_FREQUENCIES),
    [rangeFrequency, valuesMode.frequencyMode]
  )

  const ticksAmplitudes = useMemo(
    () => generateTicks(sliderAmplitude, valuesMode.amplitudeMode, MAX_COUNT_LINES_AMPLITUDE),
    [sliderAmplitude, valuesMode.amplitudeMode]
  )

  const renderChart = useCallback(() => {
    const dataLines = assignColorsToDataLines(measurementResults)
    setLines(dataLines)
    const firstTimestamp = formatDate(dataLines[dataLines.length - 1].timestamp, DATE_TEMPLATE)
    const lastTimestamp = formatDate(dataLines[0].timestamp, DATE_TEMPLATE)
    setName(`${title}: ${firstTimestamp} - ${lastTimestamp}`)
  }, [measurementResults, title])

  const renderData = useCallback(() => {
    if (!selectedMeasurement && !measurementResults) {
      return
    }
    const dataForSpectrumOverlay = transformDataForSpectrumOverlay(
      measurementResults,
      deltaFrequency,
      selectedMeasurement.unitType,
      targetUnit
    )
    const mapConvertAmplitude: Record<EAmplitudeMode, () => ISpectrumOverlayData[]> = {
      [EAmplitudeMode.LINER]: () => dataForSpectrumOverlay,
      [EAmplitudeMode.DB]: () => convertAmplitudeByCb(dataForSpectrumOverlay, calculateDecibel),
      [EAmplitudeMode.LOG]: () => dataForSpectrumOverlay
    }

    setSourceOverlayData(dataForSpectrumOverlay)
    setOverlayData(mapConvertAmplitude[valuesMode.amplitudeMode])
  }, [deltaFrequency, measurementResults, selectedMeasurement, targetUnit, valuesMode.amplitudeMode])

  useEffect(() => {
    if (chartRef.current) {
      setRefForFocusKeys(chartRef.current)
    }

    return () => {
      setRefForFocusKeys(null)
    }
  }, [setRefForFocusKeys])

  useEffect(() => {
    renderChart()
  }, [renderChart])

  useEffect(() => {
    renderData()
  }, [renderData, valuesMode.amplitudeMode])

  useEffect(() => {
    if (overlayData) {
      setSliderIndexes(rangeIndexes)
    }
  }, [overlayData])

  useEffect(() => {
    if (rangeAmplitude && amplitudeSliderDisabled) {
      setSliderAmplitude(rangeAmplitude)
    }
  }, [amplitudeSliderDisabled, rangeAmplitude])

  const handleCursorSetting = useCallback(
    (activeIndexState?: number) => {
      if (activeIndexState && sourceOverlayData) {
        if (activeIndexState < 0 || activeIndexState > sourceOverlayData?.length) {
          return
        }

        setActiveIndex(activeIndexState)
        // Получаем и определяем полезную нагрузку по клику на графике
        const selectedLine = sourceOverlayData[activeIndexState]
        const colors = lines.map((item) => item.color) as string[]

        // Формируем массив результатов для временного среза без модификации на курсор
        const timeSliceData = extractTimeSliceData({ ...selectedLine, colors })
        const { frequency } = selectedLine
        setSelectedFrequency(frequency)

        // Проверяем выбранное представление, конвертируем в децибелы при соблюдении условия
        const isAmplitudeDecibelMode = valuesMode.amplitudeMode === EAmplitudeMode.DB

        // Если введена ширина курсора больше 0, то курсор активируется, смена режима
        if (cursorSettings.isActive) {
          // Преобразуем исходный массив данных в удобную структуру для будущих вычислений, данные должны быть в линейном представлении
          const transformedDataForCursor = transformDataForCursor(sourceOverlayData)

          // Определяем кортеж с начальным и конечным порогами
          const rangeBounds = defineRangeBoundsForOverlayData(
            activeIndexState,
            sourceOverlayData,
            deltaFrequency,
            cursorSettings.width
          )

          // Фильтруем данные по каждому спектру, где определяем максимальную амплитуду и две рядом стоящих линии
          const filteredDataByMaxAmplitude = getFilteredDataByMaxAmplitude(rangeBounds, transformedDataForCursor)

          // Преобразуем массив в структуру данных для отрисовки временного среза
          const timeSliceDataByCursor = getTimeSliceDataByCursor(filteredDataByMaxAmplitude, colors)

          // Отрисовка курсора на графике
          if (rangeBounds) {
            const [leftBound, rightBound] = rangeBounds
            const x1 = sourceOverlayData[leftBound].frequency
            const x2 = sourceOverlayData[rightBound].frequency
            setCursor({ line: selectedLine.frequency, areaX1: x1, areaX2: x2 })
          }

          const result =
            isAmplitudeDecibelMode && timeSliceDataByCursor
              ? convertTimeSliceAmplitudeToDecibel(timeSliceDataByCursor)
              : timeSliceDataByCursor

          setTimeSlice(result ? result : timeSliceData)
          return
        }
        // Классическая установка курсора при ширине 0
        setCursor({ line: frequency })
        const result = isAmplitudeDecibelMode ? convertTimeSliceAmplitudeToDecibel(timeSliceData) : timeSliceData
        setTimeSlice(result)
      }
    },
    [valuesMode.amplitudeMode, cursorSettings.isActive, cursorSettings.width, deltaFrequency, lines, sourceOverlayData]
  )

  useEffect(() => {
    handleCursorSetting(activeIndex)
  }, [activeIndex, handleCursorSetting])

  const handleSelectHover = (id: string) => {
    setHoveredLine(id)
  }

  const handleIncreaseOrDecrease = (operation: 'INCREASE' | 'DECREASE') => {
    const preWidth = cursorSettings.width

    if (preWidth <= StepSize.ExpandableStep && operation === 'DECREASE') {
      setWidthCursor(0)
      setActiveCursor(false)
      return
    }

    if (operation === 'INCREASE') {
      const newWidth = preWidth + StepSize.ExpandableStep
      setActiveCursor(true)
      setWidthCursor(newWidth)
      return
    }

    if (operation === 'DECREASE') {
      const newWidth = preWidth - StepSize.ExpandableStep
      setActiveCursor(true)
      setWidthCursor(newWidth)
      return
    }
  }

  const handleHotKeys = {
    StepLeft: () => handleCursorSetting(activeIndex - StepSize.SmallStep),
    StepRight: () => handleCursorSetting(activeIndex + StepSize.SmallStep),
    MiddleStepLeft: () => handleCursorSetting(activeIndex - StepSize.MediumStep),
    MiddleStepRight: () => handleCursorSetting(activeIndex + StepSize.MediumStep),
    HighStepLeft: () => handleCursorSetting(activeIndex - StepSize.LargeStep),
    HighStepRight: () => handleCursorSetting(activeIndex + StepSize.LargeStep),
    IncreaseCursor: () => handleIncreaseOrDecrease('INCREASE'),
    DecreaseCursor: () => handleIncreaseOrDecrease('DECREASE')
  }

  const getCoordinatesForPointer = () => {
    if (hoveredLine) {
      const foundSelectedLine = timeSlice.find((element) => element.resultId === hoveredLine)
      if (foundSelectedLine) {
        return {
          x: foundSelectedLine.frequency,
          y: foundSelectedLine.amplitude
        }
      }
    }

    return null
  }

  const coordinatesForPointer = getCoordinatesForPointer()

  const renderArrow = ({ cx, cy }: { cx: number; cy: number }) => (
    <svg x={cx - 15} y={cy - 40} width={30} height={30} viewBox='0 0 24 24'>
      <path
        d='M12 4V20M12 20L8 16M12 20L16 16'
        stroke='red'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line x1='4' y1='22' x2='20' y2='22' stroke='red' strokeWidth='2' strokeLinecap='round' />
    </svg>
  )

  const stepY = getStepSlider(20, rangeAmplitude)

  const handleResetAmplitudeDoubleClick = () => {
    if (rangeAmplitude) {
      setSliderAmplitude(rangeAmplitude)
    }
  }

  const handleSliderDisableCheckboxChange = (evt: CheckboxChangeEvent) => {
    const notChecked = !evt.target.checked
    setAmplitudeSliderDisabled(notChecked)
  }

  const handleResetIndexesDoubleClick = () => {
    if (rangeIndexes) {
      setSliderIndexes(rangeIndexes)
    }
  }

  const handleAmplitudeModeSelect = (mode: EAmplitudeMode) => {
    setValuesMode({ ...valuesMode, amplitudeMode: mode })
  }

  const handleFrequencyModeSelect = (mode: EFrequencyMode) => {
    setValuesMode({ ...valuesMode, frequencyMode: mode })
  }

  return (
    <HotKeys
      className={styles['overlay-container']}
      keyMap={KEY_MAP}
      handlers={handleHotKeys}
      allowChanges={true}
      innerRef={chartRef}
    >
      <ChartWrapper resultId={resultId} title={name}>
        <Flex>
          <SliderApp
            min={rangeAmplitude ? rangeAmplitude[0] : 0}
            max={rangeAmplitude ? rangeAmplitude[1] : 0}
            value={sliderAmplitude}
            onChange={setSliderAmplitude}
            range={{ draggableTrack: true }}
            step={stepY}
            vertical={true}
            tooltip={{ formatter: chartFormatValue }}
            onDoubleClick={handleResetAmplitudeDoubleClick}
            onCheckboxChange={handleSliderDisableCheckboxChange}
            disabled={amplitudeSliderDisabled}
          />
          <Flex flex={1} vertical={true} gap={5}>
            <ChartsTools>
              <ChartUnits unit={targetUnit} onSetUnitChange={setTargetUnit} />
              <ChartSpectrumPanel
                chartFreqMode={valuesMode.frequencyMode}
                chartAmplitudeMode={valuesMode.amplitudeMode}
                onAmplitudeModeSelect={handleAmplitudeModeSelect}
                onFrequencyModeSelect={handleFrequencyModeSelect}
                selectedFrequency={selectedFrequency}
              />
            </ChartsTools>
            <ResponsiveContainer height={350}>
              <ComposedChart
                data={overlayData}
                onClick={(evtClick) => {
                  handleCursorSetting(evtClick.activeTooltipIndex)
                }}
              >
                <CartesianGrid color={'black'} strokeDasharray='3 3' />
                <XAxis
                  domain={rangeFrequency}
                  allowDataOverflow={true}
                  type='number'
                  dataKey='frequency'
                  tick={{ fill: 'black' }}
                  axisLine={true}
                  includeHidden={true}
                  ticks={ticksFrequencies}
                  scale={isFrequencyModeLog ? 'log' : 'auto'}
                />
                <YAxis
                  type={'number'}
                  tick={{ fill: 'black' }}
                  domain={sliderAmplitude}
                  allowDataOverflow={true}
                  scale={isAmplitudeModeLog ? 'log' : 'linear'}
                  fontSize={12}
                  width={65}
                  ticks={ticksAmplitudes}
                />

                {lines.map((item) => (
                  <Line
                    key={item.resultId}
                    isAnimationActive={false}
                    dot={false}
                    dataKey={item.resultId}
                    stroke={item.color}
                    strokeWidth={hoveredLine === item.resultId ? 2 : 1}
                  />
                ))}
                {cursor !== null && (
                  <>
                    <ReferenceLine strokeWidth={1} x={cursor.line} stroke='black' />
                    {cursor?.areaX1 && cursor?.areaX2 && (
                      <ReferenceArea
                        x1={cursor.areaX1}
                        x2={cursor.areaX2}
                        stroke='black'
                        fill='blue'
                        fillOpacity={0.3}
                        ifOverflow='extendDomain'
                      />
                    )}
                  </>
                )}

                <ReferenceDot
                  shape={renderArrow}
                  r={3}
                  fill={'red'}
                  x={coordinatesForPointer?.x}
                  y={coordinatesForPointer?.y}
                />
              </ComposedChart>
            </ResponsiveContainer>
            <SliderApp
              onDoubleClick={handleResetIndexesDoubleClick}
              min={rangeIndexes[0]}
              max={rangeIndexes[1]}
              value={sliderIndexes}
              onChange={setSliderIndexes}
              range={{ draggableTrack: true }}
            />
          </Flex>
        </Flex>
      </ChartWrapper>
      {cursor && <TimeSliceChart timeSliceData={timeSlice} onSelectHover={handleSelectHover} />}
    </HotKeys>
  )
}

export default memo(SpectrumOverlay)
