import { ApiResource, ApiTag } from '@/constants/store/api.constants'
import { baseApi } from '@/store/api/api-base-query'
import type { INodesState } from '@/types/nodes/nodes.type'

export const nodesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllNodes: builder.query<INodesState[], void>({
      query: () => ({
        url: ApiResource.GetAllNodes,
        method: 'GET'
      }),
      providesTags: [ApiTag.GetAllNodes]
    })
  })
})

export const { useGetAllNodesQuery } = nodesApi
