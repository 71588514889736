import { useEffect } from 'react'

const useEscHandler = (onHandleClose: (() => void) | undefined) => {
  useEffect(() => {
    if (onHandleClose) {
      const handleEsc = (evt: KeyboardEvent) => {
        if (evt.key === 'Escape') {
          onHandleClose()
        }
      }

      document.addEventListener('keydown', handleEsc)

      return () => {
        document.removeEventListener('keydown', handleEsc)
      }
    }
  }, [onHandleClose])
}

export default useEscHandler
