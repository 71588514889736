import type { FC } from 'react'
import React from 'react'

import styles from '@/components/UI/elements/header-work-space/header-configuration.module.css'

type TitleProps = {
  title: string
  description?: string
}

const HeaderConfiguration: FC<TitleProps> = ({ title, description }) => (
  <div className={`${styles.container}`}>
    <h2 className={styles.title}>{title}</h2>
    {description ? <p className={styles.description}>{description}</p> : null}
  </div>
)

export default HeaderConfiguration
