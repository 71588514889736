import InputSearch from '@/app/plans/components/input-search/input-search'
import TablePlansCell from '@/app/plans/components/plans-main-content/components/table-plans-cell/table-plans-cell'
import TablePlansTitle from '@/app/plans/components/plans-main-content/components/table-plans-title/table-plans-title'
import useActions from '@/hooks/use-actions'
import type { TPlan } from '@/types/plan.type'
import cn from 'classnames'
import type { ChangeEvent, FC } from 'react'
import { useState } from 'react'

import styles from './table-plans.module.css'

type TProps = {
  plans: TPlan[]
}

const TablePlans: FC<TProps> = ({ plans }) => {
  const { setSelectedPlan } = useActions()

  const [inputSearchValue, setInputSearchValue] = useState('')
  const [dataThreeFiltered, setDataThreeFiltered] = useState<TPlan[] | []>([])

  const handleClickRoute = async (plan: TPlan) => {
    if (plan.planId) {
      setSelectedPlan(plan.planId)
    }
  }

  const onChangeInputSearch = (evt: ChangeEvent<HTMLInputElement>) => {
    const inputValue = evt.target.value.toLowerCase()
    setInputSearchValue(inputValue)

    setDataThreeFiltered(
      plans.filter(
        (plan) =>
          plan?.modified?.toLowerCase().includes(inputValue.trim()) ||
          plan?.name?.toLowerCase().includes(inputValue.trim()) ||
          plan?.nextExecutionTimestamp
            ?.toLowerCase()
            .includes(inputValue.trim()) ||
          plan?.lastExecutionTimestamp
            ?.toLowerCase()
            .includes(inputValue.trim()) ||
          plan?.deviceType?.toLowerCase().includes(inputValue.trim())
      )
    )
  }

  return (
    <ul className={cn(styles['sorting-container'], styles['plans-container'])}>
      <TablePlansTitle />

      <InputSearch
        inputSearchValue={inputSearchValue}
        onChangeInputSearch={onChangeInputSearch}
      />

      {inputSearchValue === ''
        ? plans?.map((plan) => (
            <TablePlansCell
              key={plan.planId}
              plan={plan}
              handleClickRoute={handleClickRoute}
            />
          ))
        : dataThreeFiltered?.map((plan) => (
            <TablePlansCell
              key={plan.planId}
              plan={plan}
              handleClickRoute={handleClickRoute}
            />
          ))}
    </ul>
  )
}

export default TablePlans
