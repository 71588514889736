import type { FC } from 'react'

import styles from '../../chart-timing.module.css'

interface IProps {
  skz: number
  pk: number
  ex: number
  as: number
}

const LegendStrobe: FC<IProps> = ({ skz, pk, ex, as }) => (
  <div className={styles['legend-block']}>
    <p>СКЗ = {skz.toPrecision(2)}</p>
    <p>ПК = {pk.toPrecision(2)}</p>
    <p>ПФ = {(pk / skz).toPrecision(2)}</p>
    <p>Ех = {ex.toPrecision(2)}</p>
    <p>Ас = {as.toPrecision(2)}</p>
  </div>
)

export default LegendStrobe
