import type { TExpressSection } from '@/store/reducers/charts/express-section.slice'
import type { IChartSpectrum } from '@/types/chart/chart-spectrun.type'
import type { IMeasurement } from '@/types/measurement/measurement.type'

export const calculateDeltaFrequency = (selectedMeasurement: IMeasurement) =>
  Number(selectedMeasurement.programSpecterDescription?.visualFreqLimit?.split('_')[1]) /
  Number(selectedMeasurement.programSpecterDescription?.specterLineCount?.split('_')[2])

export const calculateSKZ = ({
  spectrumData,
  startIndex,
  endIndex,
  isDb
}: {
  spectrumData: number[]
  startIndex: number
  endIndex: number
  isDb: boolean
}) => {
  let result = 0

  if (startIndex < 0) return 0

  if (endIndex < 0) {
    endIndex = spectrumData.length - 1
  }

  for (let i = startIndex; i < endIndex; i++) {
    if (spectrumData[i] === undefined) return 0

    result += spectrumData[i]
  }

  result = isDb
    ? 20 * Math.log10(Math.sqrt(result) / Math.sqrt(2) / Math.pow(10, -6))
    : Math.sqrt(result) / Math.sqrt(2)

  return result
}

export const calculateAmFm = ({
  spectrumData,
  startIndex,
  endIndex,
  isDb,
  selectedMeasurement
}: {
  spectrumData: number[]
  startIndex: number
  endIndex: number
  isDb: boolean
  selectedMeasurement: IMeasurement
}) => {
  let maxIndex = 0
  let am = 0
  let fm = 0
  const dfValue = calculateDeltaFrequency(selectedMeasurement)

  if (startIndex < 0) return { am: 0, fm: 0 }

  if (endIndex < 0) {
    endIndex = spectrumData.length - 1
  }

  for (let i = startIndex; i < endIndex; i++) {
    if (spectrumData[i] === undefined) return { am: 0, fm: 0 }

    if (spectrumData[i] > am) {
      maxIndex = i
      am = spectrumData[i]
    }
  }

  am = isDb ? 20 * Math.log10(Math.sqrt(am) / Math.pow(10, -6)) : Math.sqrt(am)

  fm = dfValue * maxIndex

  return { am, fm }
}

export const binarySearch = (data: IChartSpectrum[], target: number, start: number, end: number): IChartSpectrum => {
  if (end < 1) return data[0]

  const middle = Math.floor(start + (end - start) / 2)

  const middleFreq = data[middle].frequency
  const startFreq = data[start].frequency
  const endFreq = data[end].frequency

  if (target === middleFreq) {
    return data[middle]
  }

  if (end - 1 === start) {
    return Math.abs(startFreq - target) > Math.abs(endFreq - target) ? data[end] : data[start]
  }

  if (target > middleFreq) return binarySearch(data, target, middle, end)

  if (target < middleFreq) return binarySearch(data, target, start, middle)

  return data[0]
}

export const calculateExpressSectionsData = ({
  chartData,
  sections,
  selectedMeasurement,
  isDb,
  spectrumData
}: {
  chartData: IChartSpectrum[]
  sections: TExpressSection[]
  selectedMeasurement: IMeasurement
  isDb: boolean
  spectrumData: number[]
}) =>
  sections
    .map((section) => {
      const startIndex = binarySearch(chartData, section.fl, 0, chartData.length - 1)?.index

      const endIndex = binarySearch(chartData, section.fh, 0, chartData.length - 1)?.index

      return [startIndex, endIndex]
    })
    .map((slicedArray) => {
      const [startIndex, endIndex] = slicedArray

      const calculated = calculateAmFm({
        spectrumData,
        startIndex,
        endIndex,
        isDb,
        selectedMeasurement
      })

      return {
        skz: calculateSKZ({ spectrumData, startIndex, endIndex, isDb }),
        fm: calculated.fm,
        am: calculated.am
      }
    })
