import CollapseTables from '@/app/reports/components/collapse-tables/collapse-tables'
import CollapseWrapper from '@/app/reports/components/collapse-wrapper/collapse-wrapper'
import { GeneratorLabel } from '@/constants/device/deviceOptions'
import { ErrorData } from '@/constants/errors.constant'
import { mapAmpSet, mapPhaseSet, mapType } from '@/constants/reports.constant'
import type { IGenerator } from '@/types/devices.type'
import type { TableType } from '@/types/reports/reports.type'
import { Collapse } from 'antd'
import type { FC } from 'react'

type CollapseHarmonicType = {
  dataGenerator: IGenerator[]
  isChecked: (generatorId: IGenerator['generatorId']) => boolean
  ExtraCheckbox: FC<{
    idItem: IGenerator['generatorId']
    checked: boolean
  }>
}

const CollapseHarmonic: FC<CollapseHarmonicType> = ({
  dataGenerator,
  isChecked,
  ExtraCheckbox
}) => {
  const buildTables = (dataItem: IGenerator): null | TableType[] => {
    if (!dataItem.harmonicCommonSettings) {
      return null
    }
    const harmonics = dataItem.harmonicCommonSettings.harmonics[0]

    return [
      {
        columns: [
          {
            title: 'Параметры сигнала',
            children: [
              {
                title: 'Параметр',
                dataIndex: 'name',
                key: 'name'
              },
              {
                title: 'Значение',
                dataIndex: 'value',
                key: 'value'
              }
            ]
          }
        ],
        data: [
          {
            key: '1',
            name: GeneratorLabel.Dclevel,
            value: harmonics?.dclevel || 0
          },
          {
            key: '2',
            name: GeneratorLabel.BaseFreq,
            value: harmonics?.baseFreq || 0
          },
          {
            key: '3',
            name: GeneratorLabel.BaseAmp,
            value: harmonics?.baseAmp || 0
          },
          {
            key: '4',
            name: GeneratorLabel.Nharm,
            value: harmonics?.nharm || 0
          },
          {
            key: '5',
            name: GeneratorLabel.AmpSet,
            value: mapAmpSet[harmonics.ampSet]
          },
          {
            key: '6',
            name: GeneratorLabel.LastAmp,
            value: harmonics.lastAmp
          },
          {
            key: '7',
            name: GeneratorLabel.PhaseSet,
            value: mapPhaseSet[harmonics.phaseSet]
          },
          {
            key: '8',
            name: GeneratorLabel.PhaseShift,
            value: harmonics.phaseShift
          },
          {
            key: '9',
            name: GeneratorLabel.WhiteNoise,
            value: harmonics.whiteNoise
          }
        ]
      },
      {
        columns: [
          {
            title: 'Параметры модулирующего процесса 1',
            children: [
              {
                title: 'Параметр',
                dataIndex: 'name',
                key: 'name'
              },
              {
                title: 'Значение',
                dataIndex: 'value',
                key: 'value'
              }
            ]
          }
        ],
        data: [
          {
            key: '1',
            name: GeneratorLabel.M1_Type,
            value: mapType[harmonics.m1_Type]
          },
          {
            key: '2',
            name: GeneratorLabel.M1_BaseFreq,
            value: harmonics.m1_BaseFreq
          },
          {
            key: '3',
            name: GeneratorLabel.M1_DeepA,
            value: harmonics.m1_DeepA
          },
          {
            key: '4',
            name: GeneratorLabel.M1_DeepT,
            value: harmonics.m1_DeepT
          },
          {
            key: '5',
            name: GeneratorLabel.M1_Nharm,
            value: harmonics.m1_Nharm
          },
          {
            key: '6',
            name: GeneratorLabel.M1_AmpSet,
            value: mapAmpSet[harmonics.m1_AmpSet]
          },
          {
            key: '7',
            name: GeneratorLabel.M1_LastAmp,
            value: harmonics.m1_LastAmp
          },
          {
            key: '8',
            name: GeneratorLabel.M1_PhaseSet,
            value: mapPhaseSet[harmonics.m1_PhaseSet]
          },
          {
            key: '9',
            name: GeneratorLabel.M1_PhaseShift,
            value: harmonics.m1_PhaseShift
          }
        ]
      },
      {
        columns: [
          {
            title: 'Параметры модулирующего процесса 2',
            children: [
              {
                title: 'Параметр',
                dataIndex: 'name',
                key: 'name'
              },
              {
                title: 'Значение',
                dataIndex: 'value',
                key: 'value'
              }
            ]
          }
        ],
        data: [
          {
            key: '1',
            name: GeneratorLabel.M2_Type,
            value: mapType[harmonics.m2_Type]
          },
          {
            key: '2',
            name: GeneratorLabel.M2_BaseFreq,
            value: harmonics.m2_BaseFreq
          },
          {
            key: '3',
            name: GeneratorLabel.M2_DeepA,
            value: harmonics.m2_DeepA
          },
          {
            key: '4',
            name: GeneratorLabel.M2_DeepT,
            value: harmonics.m2_DeepT
          },
          {
            key: '5',
            name: GeneratorLabel.M2_Nharm,
            value: harmonics.m2_Nharm
          },
          {
            key: '6',
            name: GeneratorLabel.M2_AmpSet,
            value: mapAmpSet[harmonics.m2_AmpSet]
          },
          {
            key: '7',
            name: GeneratorLabel.M2_AmpSet,
            value: harmonics.m2_AmpSet
          },
          {
            key: '8',
            name: GeneratorLabel.M2_PhaseSet,
            value: mapPhaseSet[harmonics.m2_PhaseSet]
          },
          {
            key: '9',
            name: GeneratorLabel.M2_PhaseShift,
            value: harmonics.m2_PhaseShift
          }
        ]
      }
    ]
  }

  const generators = dataGenerator.map((generator) => ({
    key: generator.generatorId,
    label: generator.name || ErrorData.Null,
    children: <CollapseTables tables={buildTables(generator)} />,
    extra: (
      <ExtraCheckbox
        idItem={generator.generatorId}
        checked={isChecked(generator.generatorId)}
      />
    )
  }))

  return (
    <CollapseWrapper data={dataGenerator}>
      <Collapse
        accordion
        bordered={false}
        items={generators}
        collapsible='header'
      />
    </CollapseWrapper>
  )
}

export default CollapseHarmonic
