import SelectList from '@/components/UI/elements/select-list/select-list'
import { CancelOption } from '@/constants/equipment/cancel-option.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasuringPointsQuery } from '@/store/api/points.api'
import { type FC } from 'react'

const PointsList: FC = () => {
  const { setSelectedPointId, setSelectedMeasurementId } = useActions()
  const { selectedPointId, selectedMachineId } = useTypedSelector((state) => state.globalReducer)
  const { data: pointsData, isLoading: isLoadingPoints } = useGetMeasuringPointsQuery(selectedMachineId, {
    skip: !selectedMachineId
  })

  const handleChangePoints = (value: string) => {
    setSelectedMeasurementId(undefined)
    if (value === CancelOption.Value) {
      setSelectedPointId(undefined)
      return
    }
    setSelectedPointId(value)
  }

  const getPointsOptions = () => {
    if (pointsData?.content) {
      return [
        {
          label: CancelOption.Label,
          value: CancelOption.Value
        },
        ...pointsData.content.map((mappedMachine) => ({
          label: mappedMachine?.name,
          value: mappedMachine?.pointId
        }))
      ]
    }
    return undefined
  }

  return (
    <SelectList
      label={'Точка измерения'}
      isLoading={isLoadingPoints}
      value={selectedPointId}
      handleChange={handleChangePoints}
      options={getPointsOptions()}
      placeholder='Выберите точку измерения'
    />
  )
}
export default PointsList
