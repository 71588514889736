import Input from 'antd/lib/input/Input'
import cn from 'classnames'
import type { ChangeEventHandler, FC } from 'react'
import React from 'react'

import styles from './inputSearch.module.css'

type TProps = {
  onChangeInputSearch: ChangeEventHandler<HTMLInputElement>
  inputSearchValue: string
}

const InputSearch: FC<TProps> = ({ onChangeInputSearch, inputSearchValue }) => (
  <div className={styles.container}>
    <Input
      value={inputSearchValue}
      onChange={onChangeInputSearch}
      placeholder={'Поиск по маршрутам'}
    />
    <span
      className={cn('material-symbols-sharp', styles['search-icon'], {
        [styles['search-icon-close']]: inputSearchValue.length > 0
      })}
    >
      {inputSearchValue.length > 0 ? 'close' : 'search'}
    </span>
  </div>
)

export default InputSearch
